<dicts-header></dicts-header>
<h1>Тип дисциплины</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [data]="gridView" (remove)="removeHandler($event)"
              [height]="500"
              [ngClass]="{noData: disciplinetypes.length < 8}"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true"
              [sort]="sort"
              (sortChange)="sortChange($event)"
              >

    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictDisciplineTypeId" title="AcademicStateId"> </kendo-grid-column> -->
    <kendo-grid-column field="name"
                       title="Название типа дисциплины"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells leftAlignment">
                          {{dataItem.name}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="orderNum"
                        title="Порядок сортировки"
                        [width]="100"
                        headerClass="gridHeader dictionaries"
                        >
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                          <kendo-numerictextbox class="border"
                            [formControl]="formGroup.get('orderNum')"
                                                format="n"
                                                [decimals]="0"
                                                [min]="1"
                          >
                          </kendo-numerictextbox>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.orderNum}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="sName"
                       title="Короткое название типа дисциплины"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.sName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isPractice"
                       editor="boolean"
                       title="Практическое"
                       [width]="150"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{(dataItem.isPractice == true ? "Да" : "Нет")}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="usedInStudyProcess"
                       editor="boolean"
                       title="Используется в обучающем процессе"
                       [width]="150"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{(dataItem.usedInStudyProcess == true ? "Да" : "Нет")}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="hiddenCipher"
                       editor="boolean"
                       title="Скрывать порядковый номер в структуре плана"
                       [width]="150"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{(dataItem.hiddenCipher == true ? "Да" : "Нет")}}
                        </span>
                      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="hiddenLaborIntensity"
                       editor="boolean"
                       title="Не учитывать в суммарной трудоемкости"
                       [width]="150"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{(dataItem.hiddenLaborIntensity == true ? "Да" : "Нет")}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="60" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div kendoDialogContainer></div>
