<card-header></card-header>
<div class="container">
    <h1>Настройки доступа</h1>
    <span class="filter">
        <h3>Поиск по ФИО</h3>
        <kendo-textbox [(ngModel)]="searchFIO" class="search-string-fio"></kendo-textbox>
        <button kendoButton (click)="clearData()">Очистить</button>
        <button kendoButton (click)="filterData(searchFIO)" themeColor="primary">Применить</button>
    </span>

    <kendo-grid class="technical"
                [loading]="loading"
                [kendoGridBinding]="gridData"
                [sortable]="true"
                [sort]="sort"
                (edit)="editHandler($event)"
                (remove)="removeHandler($event)"
                (add)="addHandler($event)">
        <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
        </ng-template>
        <ng-template kendoGridToolbarTemplate>
            <button kendoGridAddCommand themeColor="success" *ngIf="showBtnAdd()" icon="plus">Добавить</button>
            <div *ngIf="isInEditingMode">
                <button kendoButton (click)="cancelHandler()">Отмена</button>
                <button kendoButton themeColor="primary" [disabled]="formGroup!.invalid" (click)="saveCurrent()">Сохранить</button>
            </div>
        </ng-template>
        <kendo-grid-command-column [sticky]="true" title="" [width]="35">
            <ng-template kendoGridCellTemplate>
                <button kendoGridEditCommand themeColor="secondary" icon="edit" fillMode="flat" size="none" class="custom-size" rounded="full">
                </button>
            </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-command-column [sticky]="true" title="" [width]="35">
            <ng-template kendoGridCellTemplate>
                <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size" rounded="full">
                </button>
            </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column [sticky]="true" [width]="250" field="personName" headerClass="gridHeader dictionaries" title="ФИО">
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                <kendo-combobox
                        [kendoDropDownFilter]="filterSettings"
                        [data]="persons"
                        textField="fio"
                        valueField="personExternalId"
                        [valuePrimitive]="true"
                        [virtual]="virtual"
                        [formControl]="formGroup.get('personId')">
                </kendo-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.personName }}
          </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="140" field="cardAccessLevel" headerClass="gridHeader dictionaries" title="Уровень доступа">
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                <kendo-combobox
                    [data]="dropdownOptions"
                    textField="text"
                    valueField="id"
                    [valuePrimitive]="true"
                    [formControl]="formGroup.get('accessLevel')"
                ></kendo-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{ dataItem.accessLevelName }}
              </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [width]="140" field="elderAccessLevel" headerClass="gridHeader dictionaries" title="Настройки доступа">
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                <kendo-combobox
                        textField="text" valueField="id"
                        [data]="boolOptions"
                        [valuePrimitive]="true"
                        [formControl]="formGroup.get('accessSettings')"
                ></kendo-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dataItem.accessSettings ? 'Да' : 'Нет' }}
                </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [width]="140" field="cardAccessLevel" headerClass="gridHeader dictionaries" title="Справочники">
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                <kendo-combobox
                        [data]="dropdownOptions"
                        textField="text"
                        valueField="id"
                        [valuePrimitive]="true"
                        [formControl]="formGroup.get('accessLevelSalaryProject')"
                ></kendo-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{ dataItem.accessLevelSalaryProjectName }}
              </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>

<div kendoDialogContainer></div>
