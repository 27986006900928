import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { PageChangeEvent } from '@progress/kendo-angular-dropdowns/common/models/page-change-event';
import { PagerPosition, PagerType } from '@progress/kendo-angular-grid/pager/pager-settings';
import {SortDescriptor} from '@progress/kendo-data-query';
import { tap } from 'rxjs';

import { EducationPlanSelectModel } from 'src/app/models/education/education-program-select.model';
import { EducationProgramSelectService } from './education-program-select.service';
import {Location} from "@angular/common";

@Component({
  selector: 'education-program-select',
  templateUrl: './education-program-select.component.html',
  styleUrls: ['./education-program-select.component.scss'],
})
export class EducationProgramSelectComponent implements OnInit {

  public gridData: EducationPlanSelectModel.EducationProgram[] = [];
  public type: PagerType = 'numeric';
  public info = true;
  public pageSizes = [20, 50, 100];
  public pageSize = 20;
  public skip = 0;
  public previousNext = true;
  public position: PagerPosition = 'bottom';

  public eLevels: { educationLevelId: string; educationLevelName: string }[] = [];
  public trainingLevels: {
    trainingLevelId: string;
    trainingLevelName: string;
  }[] = [];
  public qualifications: {
    qualificationId: string;
    qualificationName: string;
  }[] = [];
  public studyForms: {
    studyFormId: string;
    studyFormName: string;
  }[] = [];
  public eStandardTypes: {
    educationStandardTypeId: string;
    educationStandardTypeName: string;
  }[] = [];
  public eStudyForms: {studyFormName: string, studyFormOrderNum?: number}[] = []; //чтобы работал фильтр со сложной моделью
  public eYears: {yearAdmission: string}[] = []; //чтобы работал фильтр со сложной моделью
  public eStatuses: {status: number, statusName: string}[] = []; //чтобы работал фильтр со сложной моделью
  public educationPrograms: EducationPlanSelectModel.EducationProgram[] = [];
  public faculties: { facultyName: string }[] = [];

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
  }

  public loading = false;
  // Сортировка
  public sort: SortDescriptor[] = [
    {
      field: "directionTraining",
      dir: "asc",
    },
  ];

  constructor(
    private router: Router,
    private eProgramSelectService: EducationProgramSelectService
  ) {
    this.loading = true;
    this.eProgramSelectService.select().pipe(
      tap((data) => {
        this.eLevels = eProgramSelectService.ePrograms
          .map((elem) => ({
            educationLevelId: elem.educationLevelId,
            educationLevelName: elem.educationLevelName,
          }))
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.educationLevelId === value.educationLevelId)
          );

        this.qualifications = eProgramSelectService.ePrograms
          .map((elem) => ({
            qualificationId: elem.qualificationId,
            qualificationName: elem.qualificationName,
          }))
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.qualificationId === value.qualificationId)
          );

          /*this.studyForms = eProgramSelectService.ePrograms
          .map((elem) => ({
            studyFormId: elem.studyFormId,
            studyFormName: elem.studyFormName,
          }))
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.studyFormId === value.studyFormId)
          );*/

        this.trainingLevels = eProgramSelectService.ePrograms
          .sort((a, b) => a.trainingLevelOrderNum - b.trainingLevelOrderNum)
          .map((elem) => ({
            trainingLevelId: elem.trainingLevelId,
            trainingLevelName: elem.trainingLevelName,
          }))
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.trainingLevelId === value.trainingLevelId)
          )


        this.eStandardTypes = eProgramSelectService.ePrograms
          .map((elem) => ({
            educationStandardTypeId: elem.educationStandardTypeId,
            educationStandardTypeName: elem.educationStandardTypeName,
          }))
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.educationStandardTypeId === value.educationStandardTypeId)
          );
      })
    ).subscribe(result => {
      // сделаем плоский список для грида, где каждый план в отдельной строке
      // при этом не меняем модель и исходные данные на стороне сервиса, чтобы не ломать код выше и использование ePrograms
      this.loading = false;
      this.gridData = [...
        result.flatMap(item => {
          if (item.educationPlans.length > 0)
            return item.educationPlans.map(plan => {
              let tmpItem = Object.create(item);
              //tmpItem.educationPlans = [];//для сокращения объема данных
              tmpItem.educationPlanId = plan.educationPlanId; //строка с нашим планом
              tmpItem.status = plan.status; //строка с нашим планом
              tmpItem.studyFormName = plan.studyFormName + (plan.accelerated ? ' '+plan.accelerated : ''); //строка с нашим планом
              tmpItem.yearAdmission = plan.yearAdmission.toString(); //строка с нашим планом
              tmpItem.facultyName = plan.facultyName; //строка с нашим планом
              tmpItem.facultySerialNumber = plan.facultySerialNumber;
              tmpItem.studyFormOrderNum = plan.studyFormOrderNum;
              return tmpItem;
            });
          item.studyFormName = "Нет";
          item.studyFormOrderNum = 100;
          item.yearAdmission = "Нет";
          item.status= 0;
          return item;
        })
      ];

      // Фильтруем массив учебных планов
      this.gridData.forEach((item) => {
        item.educationProgramNameWithCipher = item.educationProgramCipher + ' - ' + item.educationProgramName;
        const educationPlan = item.educationPlans.find((plan) => plan.educationPlanId === item.educationPlanId);
        if(educationPlan) item.educationPlanName = educationPlan.name;
      })

      this.eStudyForms = [...new Set(
        this.gridData.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.studyFormName === value.studyFormName)
        ).map(elem => ({ studyFormName: elem.studyFormName, studyFormOrderNum: elem.studyFormOrderNum}))
            .sort((a: any, b: any) => a.studyFormOrderNum - b.studyFormOrderNum || a.studyFormName.localeCompare(b.studyFormName))
      )]

      this.eYears = [...new Set(
        this.gridData.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.yearAdmission === value.yearAdmission)
        ).map(elem => ({ yearAdmission: elem.yearAdmission.toString() }))
      )].sort((a, b) => b.yearAdmission.localeCompare(a.yearAdmission))

      this.eStatuses = [...new Set(
        this.gridData.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.status === value.status)
        ).map(elem => ({ status: elem.status, statusName: this.planStatusText(elem.status) }))
      )].sort((a, b) => a.statusName.localeCompare(b.statusName))

      // this.qualifications = [...new Set(
      //   this.gridData.filter(
      //     (value, index, self) =>
      //       index === self.findIndex((t) => t.qualificationName === value.qualificationName)
      //   ).map(elem => ({ qualificationName: elem.qualificationName, qualificationId: elem.qualificationId }))
      // )].sort((a, b) => a.qualificationName.localeCompare(b.qualificationName))

      this.faculties = [...new Set(this.gridData.filter(
          (value, index) =>
              index === this.gridData.findIndex((t) => t.facultyName === value.facultyName && t.facultyName !== undefined)
          ).map(elem => ({ facultyName: elem.facultyName, facultySerialNumber: elem.facultySerialNumber }))
      )].sort((a, b) => {if (a.facultySerialNumber === null && b.facultySerialNumber === null) {
          return a.facultyName.localeCompare(b.facultyName);
        } else if (a.facultySerialNumber === null) {
          return 1;
        } else if (b.facultySerialNumber === null) {
          return -1;
        } else {
          const serialNumberComparison = a.facultySerialNumber - b.facultySerialNumber;
          if (serialNumberComparison === 0) {
            return a.facultyName.localeCompare(b.facultyName);
          } else {
            return serialNumberComparison;
          }
        }
      });
    });
  }

  ngOnInit(): void {}

  public onClick($event: any) {
    let planRoute = `/education/${$event.dataItem.educationProgramId}/plans`;
    const url = this.router.serializeUrl(
        this.router.createUrlTree([planRoute])
    )
    if($event.dataItem.educationPlanId) planRoute += '/plan/' + $event.dataItem.educationPlanId;
    window.open(planRoute, '_blank')
    //this.router.navigate([planRoute]);
  }

  public planStatusText = (status?: number) => {
    switch (status) {
      case 1:
        return 'В разработке';
      case 2:
        return 'Утверждён';
      case 3:
        return 'Закрыт';
      default:
        return 'Отсутствует';
    }
  }
}
