  <h1>Настройки системы</h1>

<kendo-tabstrip class="custom-tabs k-mt-4">
  <kendo-tabstrip-tab *ngFor="let item of tabItems; let i = index"
                      [title]="item.title"
                      [selected]="i === selected">
    <ng-template kendoTabContent>

      <div class="content-container" [hidden]="i !== 0">

        <div class="setting-container">
          <h2>Отображать работникам блоки техподдержки:</h2>
          <div class="grid-box">
            <ng-container *ngFor = "let item of displaySetting; let i = index">
              <div class="child">
                <input type="checkbox" kendoCheckBox [(ngModel)]="item.isDisplay" class="input-check"/>
                <kendo-label class="input-label">{{item.name}}</kendo-label>
              </div>
            </ng-container>
          </div>
        </div>

        <!--Buttons-->
        <div class="functionButtons editModeButtons">
          <button kendoButton
                  size="small"
                  (click)="closeHandler()">
            Закрыть
          </button>
          <button kendoButton
                  size="small"
                  themeColor="primary"
                  (click)="applyHandlerSetting()">
            Применить
          </button>
        </div>
      </div>

    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>



