import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SemesterYearsModel } from '../../../models/middlecontrol/semesterYears.model';
import { MassPrintSheetService } from '../../../services/middlecontrol/massPrintSheet.service';
import { Discipline, GetMassPrintSheetData } from '../../../models/middlecontrol/massPrintSheet.model';
import { SheetService } from '../../../services/middlecontrol/sheet.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import {SignatoriesModel} from 'src/app/models/middlecontrol/sheets.model';
import { TreeCommonModel } from "../../../models/middlecontrol/trees.model";
import {TrainingLevelsModel} from "../../../models/middlecontrol/trainingLevels.model";
import {DownloadFile} from "../../../helpers/downloadFile-helper";
import {DateModel} from "../../../models/middlecontrol/date.model";
import {StatementSettings} from "../../../models/middlecontrol/statementSetting.model";
import {StatementSettingsService} from "../../../services/middlecontrol/statement-settings.service";
import {StatementSettingsEnum} from "../../../models/middlecontrol/enums/statement-setting.enum";

@Component({
  selector: 'app-middlecontrolprintlist',
  templateUrl: './middlecontrolprintstatement.component.html',
  styleUrls: ['./middlecontrolprintstatement.component.scss'],
})
export class MiddlecontrolprintstatementComponent implements OnInit {
  public signer: SignatoriesModel = {
    fio: 'Фамилия Имя Отчество',
    postName: 'Должность',
  } as SignatoriesModel;

  public signerPost: string = 'Должность';
  public semester: SemesterYearsModel | null = null;
  public contingentDate: Date = new Date;
  public markDate: Date = new Date;
  public checkedStatements: string[] = [];
  public facultyId: string = '';
  public loadingEmptyStatement = false;
  public loadingFilledStatement = false;
  public loadingAdditionalSession = false;
  public loadingStatementList = false;
  public loadingAdditionalSessionWithMark = false;
  private groupList: TreeCommonModel[] = [];
  public showSigner: boolean = true;
  public statementSettingsEnum = StatementSettingsEnum;

  constructor(
    private location: Location,
    private massPrintSheetService: MassPrintSheetService,
    private sheetService: SheetService,
    private notificationService: NotificationsService,
    private statementSettingsService: StatementSettingsService,
  ) {}

  ngOnInit(): void {
    this.getStatementSettings();
  }

  public setGroupList(treeData: TreeCommonModel[]) {
    const findGroup = (value: TreeCommonModel[] | null): TreeCommonModel[] => {
      return value!.reduce((list: TreeCommonModel[], item: TreeCommonModel) => item.isGroup ? [...list, item] : [...list, ...findGroup(item.value)], [])
    }
    this.groupList = findGroup(treeData);
  }

  private setLoading(param: number, value: boolean) {
    switch (param) {
      case 0: {
        this.loadingEmptyStatement = value;
        break;
      }
      case 1: {
        this.loadingFilledStatement = value;
        break;
      }
      case 2: {
        this.loadingAdditionalSession = value;
        break;
      }
      case 3: {
        this.loadingAdditionalSessionWithMark = value;
        break;
      }
      default:
        break;
    }
  }

  onChangeSignerPost(post: string) {
    this.signer.postName = post;
  }

  public onPrintStatement(param: number): void {
    const data = this.getData(true);

    if(!this.showSigner)
      data.signatory = undefined;

    if (
      data.disciplines.length &&
      data.signatory?.fio !== 'Фамилия Имя Отчество'
    ) {
      this.setLoading(param, true);
      this.massPrintSheetService
        .getMassPrintSheet(param, data)
        .subscribe((response) => {
          response.body?.size && DownloadFile(response);
          this.setLoading(param, false);
        });
    }
  }

  public onPrintStatementList() {
    const data = this.getData(false);
    if (
      data.disciplines.length &&
      data.signatory?.fio !== 'Фамилия Имя Отчество' &&
      this.semester
    ) {
      this.loadingStatementList = true;

      this.massPrintSheetService
        .getMassPrintSheetList(
          this.semester.semesterNum,
          this.semester.semesterYear,
          data
        )
        .subscribe((response) => {
          response.body?.size && DownloadFile(response);
          this.loadingStatementList = false;
        });
    }
  }

  private getSemesterNumByGroupId(groupId: string): number {
    return this.groupList.find(item => String(item.id) === groupId)!.semesterNum!;
  }

  private getData(enableSemesterNum: boolean): GetMassPrintSheetData {
    const disciplines = this.checkedStatements
      .filter((item) => item.split('_').length === 6)
      .map((item) => {
        let discipline: Discipline = {
          eduGroupId: item.split('_').slice(-3)[0],
          disciplineId: item.split('_').slice(-2)[0],
          electiveDisciplineId: item.split('_').slice(-1)[0].trim() || null,
        };
        if (enableSemesterNum) {
          const groupId = item.split('_').slice(0, 4).join('_');
          const semesterNum = this.getSemesterNumByGroupId(groupId);
          const planId = this.groupList.find(group => String(group.id) == groupId && group.isGroup)?.planId;
          discipline = {...discipline, semesterNum, planId};
        }
        return discipline;
      });

    if (!disciplines.length)
      this.notificationService.showError('Ведомости не выбраны');

    let signer  = {
      personId: this.signer.personId,
      fio: this.signer.fio,
      postName: this.signer.postName,
    };

    if (signer.fio === 'Фамилия Имя Отчество' && this.showSigner)
      this.notificationService.showError('Подписант не выбран');

    const contingentDate = this.contingentDate
    const markDate = this.markDate

    const signatory = this.showSigner ? signer : undefined

    return { signatory, disciplines, contingentDate, markDate };
  }

  public onCancel(): void {
    this.location.back();
  }

  public onChangeDate(value: DateModel) {
    this.contingentDate = value.contingentDate;
    this.markDate = value.markDate;
  }

  public trainingLevelId?: string = ""

  public onChangeTrainingLevel(list: string[] | null) {
    if(list && list.length == 1)
      this.trainingLevelId = list[0]?.toString();
  }

  public changeSemester(value: SemesterYearsModel | null) {
    this.semester = value;
  }

  public onChangeCheckedStatements(list: string[]) {
    this.checkedStatements = list;
    if (list.length) this.facultyId = list[0].split('_')[0];
  }

  public getStatementSettings() {
    this.statementSettingsService.GetStatementSettings().subscribe(
      (response : StatementSettings) => {
        this.showSigner = response.statementSettings
          .find((item: any) => item.statementSettingsEnum === this.statementSettingsEnum.vybor_podpisanta_svodnaya_vedomost)?.value;
      }
    );
  }
}
