<dicts-header></dicts-header>
<h1>Филиалы</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [kendoGridBinding]="allFilial" (remove)="removeHandler($event)"
              [pageable]="true"  [pageSize]="pageSize"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true">

    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <button kendoGridAddCommand  size="small" themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup!.invalid"
                themeColor="primary"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictFilialId" title="dictFilialId"> </kendo-grid-column> -->
    <kendo-grid-column field="filialName"
                       title="Название филиала"
                       headerClass="gridHeader"
                       [width]="400">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                           <span class="alignCells">
                               <kendo-textbox
                                 size="small"
                                 [formControl]="formGroup.get('filialName')"
                                 [clearButton]="true">
                               </kendo-textbox>
                           </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.filialName}}
                      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="filialSName"
                       title="Сокр. название"
                       headerClass="gridHeader"
                       [width]="300">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                           <span class="alignCells">
                               <kendo-textbox
                                 size="small"
                                 [maxlength]=50
                                 [formControl]="formGroup.get('filialSName')"
                                 [clearButton]="true">
                               </kendo-textbox>
                           </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.filialSName}}
                      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="city"
                       title="Город"
                       headerClass="gridHeader"
                       [width]="300">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                           <span class="alignCells">
                               <kendo-textbox
                                 size="small"
                                 [maxlength]=50
                                 [formControl]="formGroup.get('city')"
                                 [clearButton]="true">
                               </kendo-textbox>
                           </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.city}}
                      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="address"
                       title="Адрес"
                       headerClass="gridHeader"
                       [width]="300">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                           <span class="alignCells">
                               <kendo-textbox
                                 size="small"
                                 [formControl]="formGroup.get('address')"
                                 [clearButton]="true">
                               </kendo-textbox>
                           </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.address}}
                      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="order"
                       title="Порядковый номер для сортировки при отображении"
                       headerClass="gridHeader"
                       [width]="170">
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
                           <span class="alignCells">
                               <kendo-numerictextbox
                                 [style.width.px]="170"
                                 [value]="dataItem.order"
                                 (valueChange)="orderChange($event)"
                                 [min]="1"
                                 format="n0"
                                 [formControl]="formGroup.get('order')">
                               </kendo-numerictextbox>
                           </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.order}}
            </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="filialPrintName"
                       title="Печатное название филиала"
                       headerClass="gridHeader"
                       [width]="400">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                           <span class="alignCells">
                               <kendo-textbox
                                 size="small"
                                 [formControl]="formGroup.get('filialPrintName')"
                                 [clearButton]="true">
                               </kendo-textbox>
                           </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.filialPrintName}}
                      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="70" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="small" rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate>
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="5"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-info></kendo-pager-info>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
</div>
<div kendoDialogContainer></div>
