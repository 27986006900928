import {CourseLessProfilingCourseNameEnum} from "./enums/course-less-profiling-course.enum";

export class FilterEduGroupName {
  constructor(settingFilterEduGroupNameExternalId: string,
              value: string,
              dictTrainingLevelId: string | null = null,
              facultyId: string | null = null,
              educationProgramId: string | null = null,
              educationStandardId: string | null = null,
              facultyEducationPlanId: string | null = null,
              dictStudyFormId: string | null = null,
              dictAcceleratedStudyBaseId: string | null = null,
              courseLessProfilingCourseId: string | null = null,
              dictTypeValueFilterEduGroupNameId?: string,) {
    this.settingFilterEduGroupNameExternalId = settingFilterEduGroupNameExternalId;
    this.dictTrainingLevelId = dictTrainingLevelId;
    this.facultyId = facultyId;
    this.educationStandardId = educationStandardId;
    this.facultyEducationPlanId = facultyEducationPlanId;
    this.educationProgramId = educationProgramId;
    this.dictStudyFormId = dictStudyFormId;
    this.dictAcceleratedStudyBaseId = dictAcceleratedStudyBaseId;
    this.courseLessProfilingCourseId = courseLessProfilingCourseId;
    this.dictTypeValueFilterEduGroupNameId = dictTypeValueFilterEduGroupNameId;
    this.value = value;
  }

  externalId: string | null = null;
  settingFilterEduGroupNameExternalId!: string;
  dictTrainingLevelId: string | null = null;
  facultyId: string | null = null;
  educationStandardId: string | null = null;
  facultyEducationPlanId: string | null = null;
  educationProgramId: string | null = null;
  dictStudyFormId: string | null = null;
  dictAcceleratedStudyBaseId: string | null = null;
  courseLessProfilingCourseId: string | null = null;
  dictTypeValueFilterEduGroupNameId?: string;
  value: string = '';
}

export class UsedFilterEduGroupNameInfo {
  externalId!: string;
  message!: string;
  settingFilterEduGroupNameId!: string;
}

export class FilterListAndUsedFilterList {
  filterList: FilterEduGroupName[] = [];
  usedFilterList: UsedFilterEduGroupNameInfo[] = [];
}

export class FilterEduGroupNameAddOrUpdate {
  constructor(settingFilterEduGroupNameId: string,
              value: string | null,
              filterId: string | null,
              dictTrainingLevelId: string | null = null,
              facultyId: string | null = null,
              educationProgramId: string | null = null,
              educationStandardId: string | null = null,
              facultyEducationPlanId: string | null = null,
              dictStudyFormId: string | null = null,
              dictAcceleratedStudyBaseId: string | null = null,
              courseLessProfilingCourseId: string | null = null,
              dictTypeValueFilterEduGroupNameId?: string,) {
    this.settingFilterEduGroupNameId = settingFilterEduGroupNameId;
    this.dictTrainingLevelId = dictTrainingLevelId;
    this.filterId = filterId;
    this.facultyId = facultyId;
    this.educationStandardId = educationStandardId;
    this.facultyEducationPlanId = facultyEducationPlanId;
    this.educationProgramId = educationProgramId;
    this.dictStudyFormId = dictStudyFormId;
    this.dictAcceleratedStudyBaseId = dictAcceleratedStudyBaseId;
    if (courseLessProfilingCourseId === CourseLessProfilingCourseNameEnum.true) {
      this.courseLessProfilingCourse = true;
    } else if (courseLessProfilingCourseId === CourseLessProfilingCourseNameEnum.false) {
      this.courseLessProfilingCourse = false;
    } else {
      this.courseLessProfilingCourse = null;
    }
    this.dictTypeValueFilterEduGroupNameId = dictTypeValueFilterEduGroupNameId;
    this.value = value;
  }

  filterId: string | null = null;
  externalId: string | null = null;
  settingFilterEduGroupNameId!: string;
  dictTrainingLevelId: string | null = null;
  facultyId: string | null = null;
  educationStandardId: string | null = null;
  facultyEducationPlanId: string | null = null;
  educationProgramId: string | null = null;
  dictStudyFormId: string | null = null;
  dictAcceleratedStudyBaseId: string | null = null;
  courseLessProfilingCourse: boolean | null = null;
  dictTypeValueFilterEduGroupNameId?: string;
  value: string | null = '';
}
