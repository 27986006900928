import { Component, OnInit } from '@angular/core';
import { GiaSettingService } from '../../../services/gia/giaSetting.service';
import { GiaSettingModel } from '../../../models/gia/giasettings/gia-setting.model';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { GiaUserAccessService } from '../../../services/useraccess/gia-user-access.service';
import { Router } from '@angular/router';
import { DictDto } from '../../../models/gia/dicts/dicts.model';
import { DictsService } from '../../../services/gia/dicts.service';
import { objectRewrite } from '../../../helpers/multiselect-helper';

@Component({
  selector: 'app-gia-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public giaSettingModel: GiaSettingModel[][] = [];
  public dictTrainingLevels: DictDto[] = [];

  constructor(
    private giaSettingService: GiaSettingService,
    private notificationService: NotificationsService,
    private userAccessService: GiaUserAccessService,
    private dictsService: DictsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getAccessLevel();
    this.getTrainingLevels();
    this.getSettings();
  }

  public getAccessLevel() {
    this.userAccessService.getCurrentUserAccess().subscribe({
      next: (response) => {
        if (!response.isAdmin) {
          this.notificationService.showError('У вас нет прав для просмотра данной страницы');
          this.router.navigate(['/profile']);
        }
      },
      error: () => {},
    });
  }

  public getTrainingLevels(name?: string) {
    this.dictsService.getTrainingLevels(name).subscribe((response) => {
      this.dictTrainingLevels = [{ name: 'Все', id: '' }, ...response];
    });
  }

  public getSettings() {
    this.giaSettingService.getSettings().subscribe((response) => {
      let models = response;
      models.flatMap((x) => {
        let objx = x;
        objx.flatMap((z) => {
          let objz = z;
          if (objz.trainingLevelSettings?.find((x) => x.id === null)) {
            objz.trainingLevelSettings[0].id = '';
          }
          return z;
        });
        return x;
      });
      this.giaSettingModel = models;
    });
  }

  public closeHandler() {
    this.router.navigateByUrl(`/gia`).then();
  }

  public applySetting() {
    this.giaSettingModel.flatMap((x) => {
      let objx = x;
      objx.flatMap((z) => {
        let objz = z;
        if (objz.trainingLevelSettings?.find((x: any) => x.id === '')) {
          objz.trainingLevelSettings[0].id = null;
        }
        return z;
      });
    });
    this.giaSettingService.update(this.giaSettingModel).subscribe({
      next: () => {
        this.notificationService.showSuccess('Сохранено');
        this.closeHandler();
      },
      error: (error) => {
        this.notificationService.showError(error.error);
      },
    });
  }

  public valueChangeStudyForm(value: any, index1: number, index2: number) {
    this.giaSettingModel[index1][index2].trainingLevelSettings = objectRewrite(value);
  }
}
