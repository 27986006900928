import {Guid} from "guid-typescript";

export interface CryptoArmOpenViewModel {
  jsonrpc: string;
  result: {
    uiView: CryptoArmWindow
  };
  id: Guid;
}

export enum CryptoArmWindow {
  Mail = 'MAIL',
  Documents = 'DOCUMENTS',
  Contacts = 'CONTACTS',
  SignAndEncrypt = 'SIGN_AND_ENCRYPT',
  VerifyAndDecrypt = 'VERIFY_AND_DECRYPT',
  CertificatesMy = 'CERTIFICATES_MY',
  CertificatesAddressBook = 'CERTIFICATES_ADDRESS_BOOK',
  CertificatesCA = 'CERTIFICATES_CA',
  Keys = 'KEYS',
  About = 'ABOUT'
}
