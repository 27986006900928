import { Component, OnInit } from '@angular/core';
import {AlignSettings} from '@progress/kendo-angular-layout';
import {PayslipService} from "../../services/payslip/payslip.service";
import {saveAs} from "@progress/kendo-file-saver";

@Component({
  selector: 'app-payslip',
  templateUrl: './payslip.component.html',
  styleUrls: ['./payslip.component.css']
})
export class PayslipComponent implements OnInit {

  constructor(private payslipService: PayslipService) { }

  ngOnInit(): void {
    this.getPayslipsInfo();
  }

  public gridData: any[] = [];

  public getPayslipsInfo() {
    this.payslipService.getPayslipsNames()
      .subscribe(
        response => {
          this.gridData = response.map((nameFile) => ({nameFile: nameFile}))
        }
      )
  }

  public downloadPayslipFile(nameFile: string) {
    this.payslipService.downloadPayslipFile(nameFile)
      .subscribe(response => {
        let blob:any = new Blob([response], { type: `Content-Type', 'application/octet-stream` });
        saveAs(blob, nameFile);
      });
  }
}
