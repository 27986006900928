import { StudyLevel } from "./studylevel.model";
import { OrderTypes } from "./ordertypes.model";
import {Guid} from "guid-typescript";
import {OrderCategoriesEnum} from './enums/order-categories.enum';

export class OrdersCategory {
  public dictOrdersCategoryId!: number;
  public dictOrdersCategoryExternalId?: Guid;
  public categoryName!: string;
  public dictTrainingLevelId?: Guid;
  public dictOrderTypeId!: Guid;
  public dictOrderType!: OrderTypes;
  public fullCategory?:string;
  public actual?:boolean;
  public enums?: OrderCategoriesEnum[];
  public filialId?: Guid;
}

export class OrdersCategoryWithDsl{
  public dictOrdersCategoryId?: number;
  public categoryName!: string;
  public dictStudyLevelId!: number[];
  public dictOrderTypeId!: number;
  public enums?: OrderCategoriesEnum[];
}

export class OrderCategoryEnum {
  value!: OrderCategoriesEnum;
  name?: string;
}
