import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NotificationsService} from '../../../services/notifications/notifications.service';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {StudentService} from '../../../services/contingent/student.service';
import {MilitaryCommissariatService} from '../../../services/contingent/military-commissariat.service';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {MilitarystatusesService} from '../../../services/contingent/militarystatuses.service';
import {MilitaryStatuses} from '../../../models/contingent/militarystatuses.model';
import {EditMode} from 'src/app/models/contingent/enums/military.enum';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MilitaryCommissariat} from '../../../models/contingent/militarycommissariat.model';
import {
  MilitaryCertificate,
  MilitaryDocument,
  MilitaryDocumentForm,
  MilitaryEnlistment,
  MilitaryEnlistmentForm
} from '../../../models/contingent/military.model';
import {EditEvent, RemoveEvent} from '@progress/kendo-angular-grid';
import {DateFromUTCAsLocal, DateToString, TimeZoneFix} from '../../../helpers/date-helper';
import {MilitarydoctypesService} from '../../../services/contingent/militarydoctypes.service';
import {MilitaryDocTypes} from '../../../models/contingent/militarydoctypes.model';
import {MilitarystockcategoriesService} from '../../../services/contingent/militarystockcategories.service';
import {MilitaryStockCategories} from '../../../models/contingent/militarystockcategories.model';
import {MilitaryranksService} from '../../../services/contingent/militaryranks.service';
import {MilitaryRanks} from '../../../models/contingent/militaryranks.model';
import {openDialog, openRemoveDialog} from '../../../helpers/dialogHelper';
import {MilitaryCertificateTypes} from '../../../models/contingent/enums/military-certificate-types.enum';
import {MilitaryProfiles} from '../../../models/contingent/militaryprofiles.model';
import {MilitaryProfilesService} from '../../../services/contingent/militaryprofiles.service';
import {MilitaryCategories} from '../../../models/contingent/militarycategories.model';
import {MilitaryCategoriesService} from '../../../services/contingent/militarycategories.service';
import {MilitarystatesService} from '../../../services/contingent/militarystates.service';
import {MilitaryStates} from '../../../models/contingent/militarystates.model';
import {DisplaySettingEnum} from '../../../models/contingent/enums/display-setting.enum';
import {StudentcarddisplaysettingService} from '../../../services/contingent/studentcarddisplaysetting.service';
import {MilitaryDocumentsService} from '../../../services/contingent/militarydocuments.service';
import {Guid} from 'guid-typescript';
import {Observer} from 'rxjs';
import {
  MilitaryDocTypesEnum,
  militaryDocTypesEnumList
} from '../../../models/contingent/enums/military-document-types.enum';
import {MilitaryFormType} from '../../../models/contingent/militaryformtypes.model';
import {MilitaryFormTypesService} from '../../../services/contingent/militaryformtypes.service';
import {
  EditParams,
  GetParams,
  MilitaryForm, MilitaryFormGet,
  MilitaryFormTen,
} from '../../../models/contingent/military-form.model';
import {saveAs} from '@progress/kendo-file-saver';
import {MilitaryFormService} from '../../../services/contingent/military-form.service';

@Component({
  selector: 'app-military-documents',
  templateUrl: './military-documents.component.html',
  styleUrls: ['./military-documents.component.scss']
})
export class MilitaryDocumentsComponent implements OnInit {

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  public editable = false;
  public editMode = new Map([
    [EditMode.militaryInfo, false],
    [EditMode.documents, false],
    [EditMode.form10, false],
  ]);
  private editedRowIndex?: number;

  private docTypesFilter = MilitaryDocTypesEnum.PersonalECard;

  readonly studentId!: string;
  private fio!: string;
  public studPersonId!: Guid | string;
  public militaryStatuses: MilitaryStatuses[] = [];
  public militaryCategories: MilitaryCategories[] = [];
  public militaryCommissariats: MilitaryCommissariat[] = [];
  public militaryStates: MilitaryStates[] = [];
  public militaryProfiles: MilitaryProfiles[] = [];
  private militaryDocTypes: MilitaryDocTypes[] = [];
  private certificateTypes: MilitaryFormType[] = [];
  public availableMilitaryDocTypes: MilitaryDocTypes[] = [];
  public militaryStockCategories: MilitaryStockCategories[] = [];
  public militaryRanks: MilitaryRanks[] = [];

  public militaryInfo?: MilitaryEnlistment;
  public documents: MilitaryDocument[] = [];
  public form10: MilitaryCertificate[] = [];
  public appendices: MilitaryCertificate[] = [];

  public formTenData?: MilitaryFormGet;

  public militaryInfoForm!: FormGroup;
  public militaryDocumentForm!: FormGroup;

  protected readonly EditMode = EditMode;
  protected readonly DateToString = DateToString;
  protected readonly MilitaryCertificateTypes = MilitaryCertificateTypes;
  protected readonly DisplaySettingEnum = DisplaySettingEnum;

  constructor(
    private activateRoute: ActivatedRoute,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private militaryStatusesService: MilitarystatusesService,
    private studService: StudentService,
    private militaryFormTypesService: MilitaryFormTypesService,
    private militaryCommissariatService: MilitaryCommissariatService,
    private militaryDocTypesService: MilitarydoctypesService,
    private militaryStockCategoriesService: MilitarystockcategoriesService,
    private militaryRanksService: MilitaryranksService,
    private militaryProfilesService: MilitaryProfilesService,
    private militaryCategoriesService: MilitaryCategoriesService,
    private militaryStatesService: MilitarystatesService,
    private displaySettingService: StudentcarddisplaysettingService,
    private militaryDocumentsService: MilitaryDocumentsService,
    private militaryFormService: MilitaryFormService,
  ) {
    if (activateRoute.snapshot.parent) {
      this.studentId = activateRoute.snapshot.parent.params['studentId'];
    }
  }

  ngOnInit(): void {
    this.getDicts();
    this.getStudent();
  }

  private getStudent(): void {
    this.studService.getByIdstudent(this.studentId).subscribe((response) => {
      this.editable = !!response.militaryEditable;
      this.studPersonId = response.studPersonId ?? '';
      this.studService.getStudentNameByIds([this.studentId]).subscribe((response) =>
        this.fio = response[0].fullName ?? '');
      this.getData();
    });
  }

  private getDicts() {
    this.getCertificateTypes();
    this.getMilitaryStatuses();
    this.getMilitaryCategories();
    this.getMilitaryCommissariat();
    this.getMilitaryStates();
    this.getMilitaryProfiles();
    this.getMilitaryDocTypes();
    this.getMilitaryStockCategories();
    this.getMilitaryRanks();
  }

  private getCertificateTypes() {
    this.militaryFormTypesService.getMilitaryFormTypes().subscribe((response) => {
      this.certificateTypes = response;
    });
  }

  private getMilitaryStatuses() {
    this.militaryStatusesService.getAllMilitaryStatuses().subscribe((response) => {
      this.militaryStatuses = response;
    });
  }

  private getMilitaryCategories() {
    this.militaryCategoriesService.getCategories().subscribe((response) => {
      this.militaryCategories = response;
    });
  }

  private getMilitaryCommissariat() {
    this.militaryCommissariatService.getAllMilitaryCommissariat().subscribe((response) => {
      this.militaryCommissariats = response;
    });
  }

  private getMilitaryStates() {
    this.militaryStatesService.getAllMilitaryStates().subscribe((response) => {
      this.militaryStates = response;
    });
  }

  private getMilitaryProfiles() {
    this.militaryProfilesService.getMilitaryProfiles().subscribe((response) => {
      this.militaryProfiles = response;
    });
  }

  private getMilitaryDocTypes() {
    this.militaryDocTypesService.getAllMilitaryDocTypes().subscribe((response) => {
      this.militaryDocTypes = response;
      this.availableMilitaryDocTypes = response;
    })
  }

  private getMilitaryStockCategories() {
    this.militaryStockCategoriesService.getAllMilitaryStockCategories().subscribe((response) => {
      this.militaryStockCategories = response;
    })
  }

  private getMilitaryRanks() {
    this.militaryRanksService.getAllMilitaryRanks().subscribe((response) => {
      this.militaryRanks = response;
    });
  }

  private getData() {
    this.getMilitaryInfo();
    this.getMilitaryDocuments();
    this.getCertificates();
  }

  private getMilitaryInfo() {
    this.militaryDocumentsService.getMilitaryEnlistment(this.studPersonId as string).subscribe((response) =>
      this.militaryInfo = response
    );
  }

  private getMilitaryDocuments() {
    if (!this.isHidden(DisplaySettingEnum.military_documents)) {
      this.militaryDocumentsService.getMilitaryDocuments(this.studPersonId as string).subscribe((response) => {
        this.documents = response ?? [];
        this.documents.forEach((doc) => {
          doc.issueDate = DateFromUTCAsLocal(doc.issueDate);
          doc.issueMilitaryStartDate = DateFromUTCAsLocal(doc.issueMilitaryStartDate);
          doc.issueMilitaryFinishDate = DateFromUTCAsLocal(doc.issueMilitaryFinishDate);
        });
      });
    }
  }

  private getCertificates() {
    if (!this.isHidden(DisplaySettingEnum.military_form10)
      || !this.isHidden(DisplaySettingEnum.military_form452)) {
      this.militaryDocumentsService.getMilitaryCertificates(this.studentId as string).subscribe((response) => {
        this.form10 = response.militaryFormTen ? [response.militaryFormTen] : [];
        if (this.form10[0]) this.form10[0].updateAt = DateFromUTCAsLocal(this.form10[0].updateAt);
        this.appendices = response.militaryForms;
      });
    }
  }

  public isHidden(field: number): boolean {
    return this.displaySettingService.isHidden(field);
  }

  public getCertificateTypeName(value?: MilitaryCertificateTypes) {
    return this.certificateTypes.find((item) =>
      item.formType === value)?.formName;
  }

  public getCommissariatAddress(value?: string) {
    return value ? this.militaryCommissariats.find((item) =>
      item.id === value)?.address  ?? '-' : '-';
  }

  public editMilitaryInfo() {
    this.editHandler(EditMode.militaryInfo);
    this.militaryInfoForm = this.getMilitaryInfoFormGroup();
  }

  public saveMilitaryInfo() {
    if (this.militaryInfoForm && this.militaryInfoForm.valid) {
      const observer: Partial<Observer<MilitaryEnlistmentForm>> = {
        next: () => {
          this.getMilitaryInfo();
          this.closeHandler();
          this.notificationService.showSuccess('Сведения сохранены');
        },
        error: (error) => this.notificationService.showError(error),
      };

      if (this.militaryInfoForm.value?.externalId) {
        this.militaryDocumentsService.updateMilitaryEnlistment(this.militaryInfoForm.value, this.studPersonId as string)
          .subscribe(observer);
      } else {
        this.militaryDocumentsService.addMilitaryEnlistment(this.militaryInfoForm.value, this.studPersonId as string)
          .subscribe(observer);
      }
    }
  }

  public editDocument({rowIndex, dataItem}: EditEvent) {
    this.editHandler(EditMode.documents);
    this.editedRowIndex = rowIndex;
    this.militaryDocumentForm = this.getMilitaryDocumentFormGroup(dataItem);
    this.filterDocTypes(dataItem?.enum);
  }

  private filterDocTypes(value?: MilitaryDocTypesEnum) {
    if (this.documents.length === 0 || this.documents.length === 1 && this.editedRowIndex !== undefined) {
      this.availableMilitaryDocTypes = this.militaryDocTypes;
      return;
    }

    const filter = (include: boolean) => {
      return this.militaryDocTypes.filter((docType) =>
        (this.docTypesFilter === docType.enum) === include);
    };

    const include = value
      ? this.docTypesFilter === value
      : !this.documents.some((doc) =>
        this.docTypesFilter === this.getMilitaryDocTypeEnum(doc.militaryDocTypeName));

    this.availableMilitaryDocTypes = filter(include);
  }

  private getMilitaryDocTypeEnum(name: string) {
    return militaryDocTypesEnumList.find((item) => item.text === name)?.value;
  }

  public saveDocument() {
    if (this.militaryDocumentForm && this.militaryDocumentForm.valid) {
      const observer: Partial<Observer<MilitaryDocumentForm>> = {
        next: () => {
          this.getMilitaryDocuments();
          this.closeHandler();
          this.notificationService.showSuccess('Документ сохранён');
        },
        error: (error) => this.notificationService.showError(error),
      };

      this.militaryDocumentForm.value.issueDate = TimeZoneFix(this.militaryDocumentForm.value.issueDate);
      this.militaryDocumentForm.value.issueMilitaryStartDate &&
        (this.militaryDocumentForm.value.issueMilitaryStartDate = TimeZoneFix(this.militaryDocumentForm.value.issueMilitaryStartDate));
      this.militaryDocumentForm.value.issueMilitaryFinishDate &&
        (this.militaryDocumentForm.value.issueMilitaryFinishDate = TimeZoneFix(this.militaryDocumentForm.value.issueMilitaryFinishDate));

      if (this.militaryDocumentForm.value?.externalId) {
        this.militaryDocumentsService.updateMilitaryDocument(this.militaryDocumentForm.value, this.studPersonId as string)
          .subscribe(observer);
      } else {
        this.militaryDocumentsService.addMilitaryDocument(this.militaryDocumentForm.value, this.studPersonId as string)
          .subscribe(observer);
      }
    }
  }

  public removeDocument({dataItem}: RemoveEvent) {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, dataItem.militaryDocTypeName);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        this.militaryDocumentsService.deleteMilitaryDocument(this.studPersonId as string, dataItem.externalId)
          .subscribe({
            next: () => {
              this.getMilitaryDocuments();
              this.closeHandler();
              this.notificationService.showSuccess('Документ удалён');
            },
            error: (error) => {
              this.notificationService.showError(error);
            },
          });
      }
    });
  }

  public exportCertificate(value: MilitaryCertificate | MilitaryFormTen) {
    const type = (value as MilitaryCertificate)?.type ?? MilitaryCertificateTypes.form10;
    const params: GetParams = {
      type,
      studentId: this.studentId,
      formId: value.externalId as string,
    };
    this.militaryFormService.getPrintForm(params).subscribe({
      next: (file) => saveAs(file, `Форма ${type} ${this.fio}.docx`),
      error: () => this.notificationService.showError('Не удалось экспортировать печатную форму'),
    });
  }

  public editForm10({rowIndex, dataItem}: EditEvent) {
    dataItem ? this.getForm10(rowIndex, dataItem?.externalId) : this.editHandler(EditMode.form10);
  }

  private getForm10(rowIndex: number, formId: string) {
    const params: GetParams = {
      type: MilitaryCertificateTypes.form10,
      studentId: this.studentId,
      formId: formId,
    };
    this.militaryFormService.getCertificateById(params).subscribe(
      (response) => {
        this.formTenData = response;
        this.editHandler(EditMode.form10);
        this.editedRowIndex = rowIndex;
      },
      (error) => this.notificationService.showError(
        error.error.MilitaryForm[0] ?? 'Не удалось получить данные справки'),
    );
  }

  public saveForm10({form, print}: {form: FormGroup, print?: boolean}) {
    const params: EditParams = {
      type: MilitaryCertificateTypes.form10,
      formId: form.value.externalId,
      form: this.TimeZoneFix(form),
    };

    if (this.editedRowIndex === undefined) {
      this.militaryFormService.addCertificate(params).subscribe({
        next: (response) => this.saveSuccessHandler(response, print),
        error: (error) => this.notificationService.showError(error),
      });
    } else {
      this.militaryFormService.updateCertificate(params).subscribe({
        next: (response) => this.saveSuccessHandler(response, print),
        error: (error) => this.notificationService.showError(error),
      });
    }
  }

  private TimeZoneFix(form: FormGroup): MilitaryForm {
    form.value.driverLicense.licenseIssueDate = TimeZoneFix(form.value.driverLicense.licenseIssueDate);
    form.value.driverLicense.licenseEndDate = TimeZoneFix(form.value.driverLicense.licenseEndDate);

    return form.value;
  }

  private saveSuccessHandler(response: MilitaryFormTen, print?: boolean) {
    this.getCertificates();
    this.notificationService.showSuccess('Справка успешно сохранена');
    print && this.exportCertificate(response);
    this.closeHandler();
  }

  public removeForm10({dataItem}: RemoveEvent) {
    const dialog: DialogRef = openDialog(this.dialogService,
      `Удалить справку формы ${dataItem.type}?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        const params: GetParams = {
          type: dataItem.type,
          formId: dataItem.externalId,
          studentId: this.studentId as string,
        };
        this.militaryFormService.deleteCertificate(params).subscribe({
          next: () => {
            this.getCertificates();
            this.notificationService.showSuccess('Справка успешно удалена');
          },
          error: (error) => this.notificationService.showError(error),
        });
      }
    });
  }

  public editHandler(key: EditMode) {
    this.closeHandler();
    this.editMode.set(key, true);
  }

  public closeHandler() {
    this.editedRowIndex = undefined;
    this.editMode.forEach((value, key) => this.editMode.set(key, false));
  }

  private getMilitaryInfoFormGroup() {
    return new FormGroup({
      externalId: new FormControl<string|null>(this.militaryInfo?.externalId ?? null),
      dictMilitaryStatusId: new FormControl<string|null>(this.militaryInfo?.dictMilitaryStatusId ?? null, Validators.required),
      dictMilitaryCategoryId: new FormControl<string|null>(this.militaryInfo?.dictMilitaryCategoryId ?? null, Validators.required),
      dictMilitaryCommissariatId: new FormControl<string|null>(this.militaryInfo?.dictMilitaryCommissariatId ?? null, Validators.required),
      dictMilitaryStateId: new FormControl<string|null>(this.militaryInfo?.dictMilitaryStateId ?? null),
    });
  }

  private getMilitaryDocumentFormGroup(value?: MilitaryDocument) {
    return new FormGroup({
      externalId: new FormControl<string|null>(value?.externalId ?? null),
      dictMilitaryDocTypeId: new FormControl<string|null>(value?.dictMilitaryDocTypeId ?? null),
      serial: new FormControl<string|null>(value?.serial ?? null),
      number: new FormControl<string|null>(value?.number ?? null),
      issueOrganization: new FormControl<string|null>(value?.issueOrganization ?? null),
      issueDate: new FormControl<Date|null>(value?.issueDate ?? null),
      dictMilitaryStockCategoryId: new FormControl<string|null>(value?.dictMilitaryStockCategoryId ?? null),
      dictMilitaryRankId: new FormControl<string|null>(value?.dictMilitaryRankId ?? null),
      dictMilitaryProfileId: new FormControl<string|null>(value?.dictMilitaryProfileId ?? null),
      militarySpecialtyNumber: new FormControl<string|null>(value?.militarySpecialtyNumber ?? null),
      comment: new FormControl<string|null>(value?.comment ?? null),
      outgoingRegistryNumber: new FormControl<string|null>(value?.outgoingRegistryNumber ?? null),
      issueMilitaryStartDate: new FormControl<Date|null>(value?.issueMilitaryStartDate ?? null),
      issueMilitaryFinishDate: new FormControl<Date|null>(value?.issueMilitaryFinishDate ?? null),
      reasonForDeregistration: new FormControl<Date|null>(value?.reasonForDeregistration ?? null),
    });
  }
}
