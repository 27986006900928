import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Contingent,
  ContingentAllStudent, ContingentMassOperationDto,
  ContingentRequest,
  ContingentSearchStudent,
  ContingentSelection,
  ContingentSelectionSet,
  ContingentSet
} from '../../models/contingent/contingent.model';
import {BehaviorSubject, from, Observable, skip, Subject, switchMap, tap, throwError} from 'rxjs';
import { environment } from '../../../environments/environment';
import {catchError, map} from "rxjs/operators";
import { PageResult} from '../../models/contingent/pageresult.model';
import {GridDataResult} from "@progress/kendo-angular-grid";
import {Guid} from "guid-typescript";
import {DepartmentTree, DepartmentTreeRequest} from "../../models/contingent/departmenttree";
import {Orders} from "../../models/contingent/orders.model";

@Injectable({
  providedIn: 'root'
})
export class ContingentService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.contingent}`;
  contingentPageUrl = `${environment.apiEndpoint}${environment.apiPaths.contingentpage}`;
  public loading!: boolean;
  public contingentPage$ = new BehaviorSubject<PageResult<ContingentAllStudent>>(new PageResult<ContingentAllStudent>());
  protected triggerContingentFetch$ = new BehaviorSubject<ContingentRequest>(new ContingentRequest);

  constructor(private http: HttpClient) {
    this.triggerContingentFetch$.pipe(
      skip(1),
      switchMap( (request) => {
        return this.getByStudentPage(request)
      }))
      .subscribe((response) => {
        this.contingentPage$.next(response);
      });
  }

  private getFormDataArray(body: ContingentMassOperationDto) {
    const dataArray = new FormData();
      Object.entries(body).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.length
            ? value.forEach((subItem, i) => {
              Object.entries(subItem).forEach(([subItemKey, subItemValue]) => {
                dataArray.append(`${key}[${i}].${subItemKey}`, <string | File>subItemValue ?? '');
              });
            })
            : dataArray.append(`${key}`, '');
        } else if (value && typeof value === 'object' && !(value instanceof File)) {
          Object.entries(value).forEach(([subItemKey, subItemValue]) => {
            dataArray.append(`${key}.${subItemKey}`, <string | File>subItemValue ?? '');
          });
        } else {
          dataArray.append(`${key}`, <string | File>value ?? '');
        }
      });
    return dataArray;
  }

  public count$ = new Subject<Contingent>();

  public changeContingent(count: Contingent) {
    return this.count$.next(count);
  }

  //Get all student
  public getAllstudent(): Observable<ContingentAllStudent[]> {
    return this.http.get<ContingentAllStudent[]>(this.baseUrl + '/getallcontingent');
  }

  //Get By Id student
  getByIdstudent(id: number|Guid|string): Observable<Contingent> {
    return this.http.get<Contingent>(this.baseUrl + '/getcontingentstudent' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Get By student page
  public getByStudentPage(request: ContingentRequest): Observable<PageResult<ContingentAllStudent>> {
    return this.http.post<PageResult<ContingentAllStudent>>(this.contingentPageUrl, request)
      .pipe(catchError(this.handleError));
  }

  public updateStudentPage(request: ContingentRequest) {
    this.triggerContingentFetch$.next(request);
  }

  searchStudents(request: ContingentRequest): Observable<PageResult<ContingentSearchStudent>> {
    return this.http.post<PageResult<ContingentSearchStudent>>(this.baseUrl + '/searchstudents', request)
      .pipe(catchError(this.handleError));
  }

  getStudentsExport(request: ContingentRequest): Observable<GridDataResult> {
    return  this.http.post<PageResult<ContingentAllStudent>>(this.baseUrl + '/page', request).pipe(
      map(
        (response) =>
          <GridDataResult>{
            data: response.items.map((item, i) => {
              item.studentId = i + 1
              return item}),
            total: response.count,
          }
      ),
      tap(() => (this.loading = false))
    );
  }

  //Get By Id student
  updateSelectedStudents(studentData: ContingentSelection[], newData?: ContingentMassOperationDto): Observable<ContingentSelection[]> {
    let formData = newData ? this.getFormDataArray(newData) : new FormData();
    formData.append('studentData', JSON.stringify(studentData));

    return this.http.post<ContingentSelection[]>(this.baseUrl + '/editselectedcontingent', formData)
      .pipe(catchError(this.handleError));
  }

  //Add additionalfields
  addcontingent(contingent: ContingentSet): Observable<any> {
    return this.http.post<any>(this.baseUrl, contingent)
      .pipe(catchError(this.handleError));
  }

  //Edit filial
  updatecontingent(contingent: ContingentSet): Observable<any> {
    return this.http.put<any>(this.baseUrl + '/' + contingent.studentId, contingent)
      .pipe(catchError(this.handleError));
  }

  public levelTraining = new BehaviorSubject({
    levelTraining: ""
  })


  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
