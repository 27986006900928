<dicts-header></dicts-header>
<h1>Уровень подготовки</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [data]="traininglevels" (remove)="removeHandler($event)"
              [height]="500"
              [ngClass]="{noData: traininglevels.length < 8}"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)">

    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictQualificationId" title="AcademicStateId"> </kendo-grid-column> -->
    <kendo-grid-column field="trainingLevelName"
                       title="Название"
                       [width]="350"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells leftAlignment">
                          {{dataItem.trainingLevelName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="trainingLevelSName"
                       title="Сокр. название"
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.trainingLevelSName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="profileTitle"
                       title="Название профиля"
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.profileTitle}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="studentTitle"
                       title="Обучающийся"
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.studentTitle}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="studentRTitle"
                       title="Обучающиеся в род. падеже"
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.studentRTitle}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="studentSingleRTitle"
                       title="Обучающийся в род. падеже"
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.studentSingleRTitle}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="printStandardTitle"
                       title="Печатное название направления"
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.printStandardTitle}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="printProfileTitle"
                       title="Печатное название профиля"
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.printProfileTitle}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="enum" title="Служебная категория"
                       headerClass="gridHeader"
                       [width]="200"
                       [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells">
            {{ nameEnum(dataItem.enum) }}
         </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-formGroup="formGroup">
        <kendo-combobox [data]="trainingLevelEnumList"
                        textField="text"
                        valueField="value"
                        [valuePrimitive]="true"
                        [formControl]="formGroup.get('enum')">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="nameForStudentNumber"
                       title="Порядковый номер для сортировки"
                       headerClass="gridHeader"
                       [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.orderNum}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-numerictextbox
          size="small"
          [formControl]="formGroup.get('orderNum')"
          format=""
        ></kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="60" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>


  </kendo-grid>

</div>

<div kendoDialogContainer></div>
