export interface DictDirectionActivity {
  id: string;
  name: string;
  filialId?: string;
}

export interface DictDocumentType {
  id: string;
  name: string;
  order: number | null;
}

export interface DictAchievementStatus{
  id: string;
  name: string;
  color: string;
  order: number | null;
  statusEnum: number;
}

enum AchievementStatusEnumList {
  approved = 1,
  rejected,
  returned_for_revision,
  added,
  sended,
  corrected,
}

export var AchievementStatusEnum: any = AchievementStatusEnumList

export interface WorkType{
  id: string;
  parentId?: string;
  name: string;
  mark: number;
  order: number | null;
  directionActivityId: string;
}


export interface WorkTypeTree {
  id: string;
  name: string;
  mark: number;
  items?: WorkTypeTree[];
}
