<h5>Воинский учёт</h5>

<div class="grid-container">
  <div>
    <div class="grid-container">
      <dl *ngIf="!editMode.get(EditMode.militaryInfo); else militaryInfoEditForm" class="rows">
        <div class="content-row">
          <kendo-label text="Отношение к военной службе"></kendo-label>
          <dd>
            {{ militaryInfo?.dictMilitaryStatusName }}
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Годность к военной службе"></kendo-label>
          <dd>
            {{ militaryInfo?.dictMilitaryCategoryName }}
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Военный комиссариат"></kendo-label>
          <dd>
            {{ militaryInfo?.dictMilitaryCommissariatName }}
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Почтовый адрес военного комиссариата"></kendo-label>
          <dd>
            {{ militaryInfo?.dictMilitaryCommissariatAddress }}
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Состоит на воинском учёте"></kendo-label>
          <dd>
            {{ militaryInfo?.dictMilitaryStateName }}
          </dd>
        </div>
      </dl>
      <ng-template #militaryInfoEditForm>
        <form class="k-form" [formGroup]="militaryInfoForm">
          <dl class="edit-form">
            <div class="content-row">
              <kendo-label text="Отношение к военной службе"></kendo-label>
              <dd>
                <kendo-formfield>
                  <kendo-dropdownlist [data]="militaryStatuses"
                                      textField="militaryStatusName"
                                      valueField="dictMilitaryStatusExternalId"
                                      [valuePrimitive]="true"
                                      formControlName="dictMilitaryStatusId">
                  </kendo-dropdownlist>
                  <span class="required">*</span>
                </kendo-formfield>
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Годность к военной службе"></kendo-label>
              <dd>
                <kendo-formfield>
                  <kendo-dropdownlist [data]="militaryCategories"
                                      textField="militaryCategoryName"
                                      valueField="dictMilitaryCategoryExternalId"
                                      [valuePrimitive]="true"
                                      formControlName="dictMilitaryCategoryId">
                  </kendo-dropdownlist>
                  <span class="required">*</span>
                </kendo-formfield>
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Военный комиссариат"></kendo-label>
              <dd>
                <kendo-formfield>
                  <kendo-combobox [kendoDropDownFilter]="filterSettings"
                                  [data]="militaryCommissariats"
                                  textField="commissariatName"
                                  valueField="id"
                                  [openOnFocus]="commissariat"
                                  #commissariat
                                  [valuePrimitive]="true"
                                  formControlName="dictMilitaryCommissariatId">
                  </kendo-combobox>
                  <span class="required">*</span>
                </kendo-formfield>
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Почтовый адрес военного комиссариата"></kendo-label>
              <dd>
                {{ getCommissariatAddress(militaryInfoForm.value?.dictMilitaryCommissariatId) }}
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Состоит на воинском учёте"></kendo-label>
              <dd>
                <kendo-formfield>
                  <kendo-dropdownlist [data]="militaryStates"
                                      textField="militaryStateName"
                                      valueField="dictMilitaryStateExternalId"
                                      [valuePrimitive]="true"
                                      formControlName="dictMilitaryStateId">
                  </kendo-dropdownlist>
                </kendo-formfield>
              </dd>
            </div>
          </dl>
        </form>
        <div>
          <button kendoButton
                  size="small"
                  *ngIf="editMode.get(EditMode.militaryInfo)"
                  (click)="closeHandler()">
            Отмена
          </button>
          <button kendoButton
                  class="save-button"
                  size="small"
                  themeColor="primary"
                  *ngIf="editMode.get(EditMode.militaryInfo)"
                  (click)="saveMilitaryInfo()"
                  [disabled]="militaryInfoForm && militaryInfoForm.invalid">
            Сохранить
          </button>
        </div>
      </ng-template>
    </div>

    <button kendoButton
            class="edit-button"
            fillMode="outline"
            size="small"
            *ngIf="editable && !editMode.get(EditMode.militaryInfo)"
            (click)="editMilitaryInfo()">
      Редактировать
      <span class="k-icon k-i-edit"></span>
    </button>
  </div>

  <div class="grid-container" *ngIf="!isHidden(DisplaySettingEnum.military_documents)">
    <h4>Документы воинского учёта</h4>
    <div>
      <button kendoButton
              themeColor="success"
              iconClass="k-icon k-i-plus"
              *ngIf="editable && !editMode.get(EditMode.documents) && documents.length < 2"
              (click)="editDocument($event)">
        Добавить
      </button>
    </div>
    <kendo-grid class="grid" [kendoGridBinding]="documents"
                *ngIf="!editMode.get(EditMode.documents); else documentEditForm"
                (edit)="editDocument($event)"
                (remove)="removeDocument($event)">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>

      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Название"
                         [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ dataItem.militaryDocTypeName }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Серия и номер документа"
                         [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ dataItem.serial }} {{ dataItem.number }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Дата выдачи"
                         [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ DateToString(dataItem.issueDate) }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Кем выдана"
                         [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ dataItem.issueOrganization }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Воинское звание"
                         [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ dataItem.militaryRankName }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button kendoGridEditCommand type="button"
                  icon="edit"
                  *ngIf="editable"
                  fillMode="flat"
                  rounded="full"
                  size="small">
          </button>
          <button kendoGridRemoveCommand
                  type="button"
                  *ngIf="editable"
                  class="certificate-red-button"
                  icon="delete"
                  fillMode="flat"
                  size="small"
                  rounded="full">
          </button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <ng-template #documentEditForm>
      <form class="k-form" [formGroup]="militaryDocumentForm">
        <dl class="edit-form edit-form_document">
          <div class="content-row">
            <kendo-label text="Документ воинского учета"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-dropdownlist [data]="availableMilitaryDocTypes"
                                    textField="militaryDocTypeName"
                                    valueField="dictMilitaryDocTypeExternalId"
                                    [valuePrimitive]="true"
                                    formControlName="dictMilitaryDocTypeId">
                </kendo-dropdownlist>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Серия"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-textbox formControlName="serial" [clearButton]="true"></kendo-textbox>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Номер"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-textbox formControlName="number" [clearButton]="true"></kendo-textbox>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Кем выдан"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-textbox formControlName="issueOrganization" [clearButton]="true"></kendo-textbox>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Дата выдачи документа воинского учета"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-datepicker formControlName="issueDate"></kendo-datepicker>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Категория запаса"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-dropdownlist [data]="militaryStockCategories"
                                    textField="militaryStockCategoryName"
                                    valueField="dictMilitaryStockCategoryExternalId"
                                    [valuePrimitive]="true"
                                    formControlName="dictMilitaryStockCategoryId">
                </kendo-dropdownlist>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Воинское звание"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-combobox [data]="militaryRanks"
                                textField="militaryRankName"
                                valueField="dictMilitaryRankExternalId"
                                [valuePrimitive]="true"
                                [kendoDropDownFilter]="filterSettings"
                                formControlName="dictMilitaryRankId">
                </kendo-combobox>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Состав (профиль)"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-dropdownlist [data]="militaryProfiles"
                                    textField="militaryProfileName"
                                    valueField="dictMilitaryProfileExternalId"
                                    [valuePrimitive]="true"
                                    formControlName="dictMilitaryProfileId">
                </kendo-dropdownlist>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Военно-учетная специальность"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-textbox formControlName="militarySpecialtyNumber" [clearButton]="true"></kendo-textbox>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Дополнительные сведения"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-textbox formControlName="comment" [clearButton]="true"></kendo-textbox>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Исходящий регистрационный номер сведений, направленных в военный комиссариат"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-textbox formControlName="outgoingRegistryNumber" [clearButton]="true"></kendo-textbox>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Дата регистрации сведений, направленных в военный комиссариат"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-datepicker formControlName="issueMilitaryStartDate"></kendo-datepicker>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Дата снятия с воинского учета"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-datepicker formControlName="issueMilitaryFinishDate"></kendo-datepicker>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Причина снятия с воинского учета"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-textbox formControlName="reasonForDeregistration" [clearButton]="true"></kendo-textbox>
              </kendo-formfield>
            </dd>
          </div>
        </dl>
      </form>
      <div>
        <button kendoButton
                size="small"
                *ngIf="editMode.get(EditMode.documents)"
                (click)="closeHandler()">
          Отмена
        </button>
        <button kendoButton
                class="save-button"
                size="small"
                themeColor="primary"
                *ngIf="editMode.get(EditMode.documents)"
                (click)="saveDocument()"
                [disabled]="militaryDocumentForm && militaryDocumentForm.invalid">
          Сохранить
        </button>
      </div>
    </ng-template>
  </div>

  <div class="grid-container" *ngIf="!isHidden(DisplaySettingEnum.military_form10)">
    <h4>Форма №10</h4>
    <div>
      <button kendoButton
              themeColor="success"
              iconClass="k-icon k-i-plus"
              *ngIf="editable && !editMode.get(EditMode.form10) && !form10.length && militaryInfo?.dictMilitaryStatusId"
              (click)="editForm10($event)">
        Добавить
      </button>
    </div>
    <kendo-grid class="grid" [kendoGridBinding]="form10"
                *ngIf="!editMode.get(EditMode.form10); else form10EditForm"
                (edit)="editForm10($event)"
                (remove)="removeForm10($event)">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>

      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Справка"
                         [width]="300">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{ getCertificateTypeName(dataItem.type) }}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Военный комиссариат"
                         [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{ dataItem.dictMilitaryCommissariatName }}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Последнее изменение"
                         [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{ DateToString(dataItem.updatedAt, 'dd.MM.yyyy HH:mm') }}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Пользователь"
                         [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{ dataItem?.personFio }}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Файл"
                         [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            <button kendoButton type="button"
                    icon="file"
                    fillMode="flat"
                    rounded="full"
                    size="small"
                    (click)="exportCertificate(dataItem)">
            </button>
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [width]="70">
        <ng-template kendoGridCellTemplate>
          <button kendoGridEditCommand type="button"
                  icon="edit"
                  *ngIf="editable"
                  fillMode="flat"
                  rounded="full"
                  size="small">
          </button>
          <button kendoGridRemoveCommand
                  type="button"
                  *ngIf="editable"
                  icon="delete"
                  fillMode="flat"
                  size="small"
                  rounded="full">
          </button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <ng-template #form10EditForm>
      <app-military-certificate-form [type]="MilitaryCertificateTypes.form10"
                                     [data]="formTenData"
                                     [studentId]="studentId"
                                     (close)="closeHandler()"
                                     (save)="saveForm10($event)">
      </app-military-certificate-form>
    </ng-template>
  </div>

  <div class="grid-container" *ngIf="!isHidden(DisplaySettingEnum.military_form452)">
    <h4>Форма приложения №4, Форма приложения №5, Форма приложения №2</h4>
    <kendo-grid class="grid_scrollable" [kendoGridBinding]="appendices">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>

      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Номер"
                         [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{ dataItem?.number }}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Справка"
                         [width]="300">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{ getCertificateTypeName(dataItem.type) }}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Военный комиссариат"
                         [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{ dataItem.dictMilitaryCommissariatName }}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Последнее изменение"
                         [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{ DateToString(dataItem.updatedAt, 'dd.MM.yyyy HH:mm') }}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Пользователь"
                         [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{ dataItem?.personFio }}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Файл"
                         [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            <button kendoButton type="button"
                    icon="file"
                    fillMode="flat"
                    rounded="full"
                    size="small"
                    (click)="exportCertificate(dataItem)">
            </button>
          </span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>

<div kendoDialogContainer></div>
