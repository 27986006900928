import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { TrainingLevelService } from 'src/app/services/dicts/traininglevel.service';
import { TrainingLevel } from 'src/app/models/dicts/traininglevel.model';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  CommonDictionariesUserAccessService
} from "../../../services/useraccess/common-dictionaries-user-access.service";
import { Router} from "@angular/router";
import {trainingLevelEnumList} from '../../../models/dicts/enums/training-level.enum';

@Component({
  selector: 'app-traininglevel',
  templateUrl: './traininglevel.component.html',
  styleUrls: ['./traininglevel.component.css']
})
export class TrainingLevelComponent implements OnInit {

  public traininglevels: TrainingLevel[] = [];
  public traininglevel: TrainingLevel = {
    dictTrainingLevelId: 0,
    trainingLevelName: '',
    trainingLevelSName: '',
    profileTitle: '',
    studentRTitle: '',
    studentTitle: '',
    studentSingleRTitle: '',
    printStandardTitle: '',
    printProfileTitle: '',
    orderNum: 0,
  }
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  protected readonly trainingLevelEnumList = trainingLevelEnumList;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private traininglevelService: TrainingLevelService,
              private renderer: Renderer2, private formBuilder: FormBuilder,
              private notificationService: NotificationsService,
              private userAccessService: CommonDictionariesUserAccessService,
              private dialogService: DialogService,
              private router: Router,
  ) {

  }

  public ngOnInit(): void {
    this.getAccessLevel()
 //   this.getAllTrainingLevel();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public onStateChange(): void {
    this.getAllTrainingLevel();
  }

  public nameEnum(enums?: number) {
    return this.trainingLevelEnumList.find((x) => x.value === enums)?.text ?? "";
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      dictTrainingLevelId: 0,
      trainingLevelName: '',
      trainingLevelSName: '',
      profileTitle: '',
      studentTitle: '',
      studentRTitle: '',
      studentSingleRTitle: '',
      printStandardTitle: '',
      printProfileTitle: ''
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
    sender,
    columnIndex,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.traininglevelService.addTrainingLevel(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllTrainingLevel();
              this.notificationService.showSuccess("Добавлено");
              this.traininglevel = {
                dictTrainingLevelId: 0,
                trainingLevelName: '',
                trainingLevelSName: '',
                profileTitle:'',
                studentTitle: '',
                studentRTitle: '',
                studentSingleRTitle: '',
                printStandardTitle: '',
                printProfileTitle: '',
                orderNum: 0,
              }
            },
            error:(err) => {
              this.notificationService.showError(err);
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.traininglevelService.updateTrainingLevel(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllTrainingLevel();
              this.notificationService.showSuccess("Сохранено");
            },
            error:(err) => {
              this.notificationService.showError(err);
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  getAllTrainingLevel() {
    this.traininglevelService.getAllTrainingLevel()
      .subscribe(
        response => {
          this.traininglevels = response;
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          if (response.accessLevelQualification == 0){
            this.notificationService.showError("У вас нет прав для просмотра данной страницы");
            this.router.navigate(['/profile']);
          } else {
            this.editable = response.accessLevelQualification != 1;
            this.getAllTrainingLevel();
          }
        },error:() => {}});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.trainingLevelName.toLowerCase()}?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {

        if(result.text == "Да"){
          this.opened = false;
          this.traininglevelService.deleteTrainingLevel(dataItem.dictTrainingLevelExternalId)
          .subscribe({
            next:() => {
              this.getAllTrainingLevel();
              this.notificationService.showSuccess("Удалено");
            },
            error:(error) => {
            this.notificationService.showError(error.error);
          }}
          );
            }
          else{
            this.opened = false;
          }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: {
    dictTrainingLevelId?: any;
    dictTrainingLevelExternalId?: any;
    trainingLevelName?: any;
    trainingLevelSName?: any;
    profileTitle?: any;
    studentTitle?: any;
    studentRTitle?: any;
    studentSingleRTitle?: any;
    printStandardTitle?: any;
    printProfileTitle?: any;
    enum?: any;
    orderNum?: any;
  }) =>
  new FormGroup({
    dictTrainingLevelId: new FormControl(dataItem.dictTrainingLevelId),
    dictTrainingLevelExternalId: new FormControl(dataItem.dictTrainingLevelExternalId),
    trainingLevelName: new FormControl(dataItem.trainingLevelName, Validators.required),
    trainingLevelSName: new FormControl(dataItem.trainingLevelSName, Validators.required),
    profileTitle: new FormControl(dataItem.profileTitle, Validators.required),
    studentTitle: new FormControl(dataItem.studentTitle, Validators.required),
    studentRTitle: new FormControl(dataItem.studentRTitle, Validators.required),
    studentSingleRTitle: new FormControl(dataItem.studentSingleRTitle, Validators.required),
    printStandardTitle: new FormControl(dataItem.printStandardTitle, Validators.required),
    printProfileTitle: new FormControl(dataItem.printProfileTitle, Validators.required),
    enum: new FormControl(dataItem.enum),
    orderNum: new FormControl(dataItem.orderNum, Validators.required),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};
