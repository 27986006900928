import {Component, OnInit, EventEmitter, ViewChild, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormationDiplomaService} from '../../../../services/gia/formationdiploma.service';
import {GeneralInformationModel, FillStatusesModel, PrintTemplateDiplomaModel} from '../../../../models/gia/formationdiploma.model';
import {AdditionalDataComponent} from '../additionaldata/additionaldata.component';
import {BasicInformationComponent} from '../basicinformation/basicinformation.component';
import {ApplicationDiplomaComponent} from '../applicationdiploma/applicationdiploma.component';
import {SelectEvent} from "@progress/kendo-angular-layout";
import {saveAs} from "@progress/kendo-file-saver";
import {DiplomaPrintService} from "../../../../services/gia/diplomaPrint.service";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {DownloadFile} from "../../../../helpers/downloadFile-helper";
import {AttachmentTemplateEnumList} from "../../../../models/gia/enums/attachmentTemplate.enum";
import {
  DisciplineWorkloadUserAccessService
} from "../../../../services/useraccess/disciplineworkload-user-access.service";
import {GiaUserAccessService} from "../../../../services/useraccess/gia-user-access.service";
import {GiaTabsEnum} from "../../../../models/gia/enums/giaTabs.enum";

@Component({
    selector : 'app-formationdiploma',
    templateUrl: './formationdiploma.component.html',
    styleUrls : ['./formationdiploma.component.scss']
})

export class FormationDiplomaComponent implements OnInit{

    @ViewChild(AdditionalDataComponent) childAdditional: AdditionalDataComponent | undefined;
    @ViewChild(BasicInformationComponent) childBasicInfo: BasicInformationComponent | undefined;
    @ViewChild(ApplicationDiplomaComponent) childApplication: ApplicationDiplomaComponent | undefined;

    public allowEdit: boolean = false;
    public studentId: string = "";
    public generalInformation: GeneralInformationModel = new GeneralInformationModel;
    public originalGeneralInformation: GeneralInformationModel = new GeneralInformationModel;

    public fillingStatuses: FillStatusesModel[] = [];
    public patternPrints: PrintTemplateDiplomaModel[] = [];

    public basicInformation: boolean = true;
    public application: boolean = false;

    private tab: number = 0;

    public admissionOrder: string = "";

    public error: boolean = false;
    public diplomaInfoId: string = "";

    public basicLoading: boolean = false;
    public loading: boolean = true;

    public fillRequiredFields: boolean = true;
    public blockTabstrip: boolean = false;

    constructor(
        private activatedroute: ActivatedRoute,
        private router: Router,
        private formationDiplomaService: FormationDiplomaService,
        private notificationService: NotificationsService,
        private diplomaPrintService: DiplomaPrintService,
        private userAccessService: GiaUserAccessService
    ) {
        this.activatedroute.params.subscribe(params => {
            this.studentId = params['id'] || this.formationDiplomaService.getStudentId.getValue().studentId;
        });
        this.application = this.formationDiplomaService.tabApplication.getValue().application;
        if (this.application) {
          this.basicInformation = false;
          this.tab = 1;
        }
    }

    async ngOnInit() {
        this.router.navigate([`gia/formatdiploma/${this.studentId}`]);
        await this.getAccessLevel();
        this.getFillStatuses();
        this.getPrintTemplateDiploma();
      //  this.getGeneralInformation();

    }

    public onLoading(load: boolean) {
      this.basicLoading = load;
    }

    public requiredFields(required: boolean) {
      this.fillRequiredFields = required;
      this.changeAdmissionOrder();
    }

    public changeAdmissionOrder(value?: string) {
      !this.validateGeneralField() && this.fillRequiredFields ? this.blockTabstrip = false : this.blockTabstrip = true;
    }

    public getLoad() {
      return !(!this.basicLoading && !this.loading);
    }

    public async getAccessLevel(){
      this.allowEdit = await this.userAccessService.hasEditingGia({
        studentId: this.studentId,
        section: GiaTabsEnum.дипломы
      });
    }

    public getGeneralInformation() {
      this.formationDiplomaService.getDiplomaInformation(this.studentId)
        .subscribe(response => {
          this.generalInformation = response;
          this.originalGeneralInformation = JSON.parse(JSON.stringify(this.generalInformation));
          this.diplomaInfoId = response.id;
          this.loading = false;
          this.admissionOrder = this.generalInformation.admissionOrder;
          this.changeAdmissionOrder();

          const index = this.patternPrints.findIndex(el => el.isDefault);
          if (index > -1) this.generalInformation.printTemplateId = this.patternPrints[index].printTemplateId;
        })
    }

    public getFillStatuses() {
        this.formationDiplomaService.getFillStatuses()
            .subscribe(response => {
                this.fillingStatuses = response;
            })
    }

    public getPrintTemplateDiploma() {
        this.formationDiplomaService.getPrintTemplateDiploma(this.studentId)
            .subscribe(response => {
                this.patternPrints = response;

                this.getGeneralInformation();
            })
    }

    public openCard(studentId: string) {
        window.open(`/contingent/studentmaininfo/${studentId}/progress`);
    }

    public onSelect(value: SelectEvent) {
        this.saveGeneralInfo(value);
    }

    private validateGeneralField() {
      return !this.generalInformation.diplomaFillStatus || !this.generalInformation.printTemplateId || !this.admissionOrder || this.admissionOrder.trim() == "";
    }

    public saveGeneralInfo(value?: SelectEvent) {
        if (!this.allowEdit)
            return;

        if (this.validateGeneralField()) {
            this.notificationService.showError('Не заполнены обязательные поля диплома.');
            this.error = true;
            if (value !== undefined) this.tab = value.index;
            return;
        }

        this.generalInformation.admissionOrder = this.admissionOrder;

        if (this.originalGeneralInformation.diplomaFillStatus !== this.generalInformation.diplomaFillStatus ||
            this.originalGeneralInformation.printTemplateId !== this.generalInformation.printTemplateId ||
            this.originalGeneralInformation.admissionOrder !== this.generalInformation.admissionOrder) {
                this.formationDiplomaService.addDiplioma(this.generalInformation)
                .subscribe(response => {
                        this.notificationService.showSuccess('Диплом успешно сохранен');
                        this.error = false;
                        this.diplomaInfoId = response.toString();
                        this.saveTab();
                        this.getGeneralInformation();
                        if (value !== undefined) this.tab = value.index;
                    },
                    error => {
                        this.error = true;
                        this.notificationService.showError('Произошла ошибка')
                    })
        } else {
          this.saveTab();
          if (value !== undefined) this.tab = value.index;
        }
    }

    public saveTab() {
      if (!this.error && this.diplomaInfoId) {
        switch (this.tab) {
          case 0:
              this.childBasicInfo?.saveBasicInformation(this.diplomaInfoId);
              break;
          case 1:
              this.childApplication?.saveApplicationInfo(this.diplomaInfoId);
              break;
          case 2:
              this.childAdditional?.saveData(this.diplomaInfoId);
              break;
        }
      }
    }

    public getDiploma(){
      if(this.studentId !== "") {
        this.diplomaPrintService.getDiploma(this.studentId).subscribe({
          next: (value) => {
            DownloadFile(value);
          },
          error: () => {
            this.notificationService.showError('Не удалось скачать файл');
          },
        });
      }
    }

    public getAttachment(template: number){
      if(this.studentId !== "") {
        this.diplomaPrintService.getAttachment(this.studentId, template).subscribe({
          next: (value) => {
            DownloadFile(value);
          },
          error: () => {
            this.notificationService.showError('Не удалось скачать файл');
          },
        });
      }
    }

    protected readonly AttachmentTemplateEnumList = AttachmentTemplateEnumList;

    public getBasicInformations() {
      this.childBasicInfo?.getBasicInformations();
    }
}
