import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SelectEvent} from "@progress/kendo-angular-layout";
import {ActivatedRoute, Router} from "@angular/router";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Student} from "src/app/models/contingent/student.model";
import {StudentService} from "src/app/services/contingent/student.service";
import {StudPerson} from "src/app/models/contingent/studperson.model";
import {Contingent} from "src/app/models/contingent/contingent.model";
import {StudentSemestr} from "src/app/models/contingent/studentsemestr.model";
import {DictFilial} from "src/app/models/contingent/dictfilial.model";
import {Citizenship} from 'src/app/models/contingent/citizenship.model';
import {Identifications} from 'src/app/models/contingent/identifications.model';
import {ContingentService} from "src/app/services/contingent/contingent.service";
import {DialogService,} from "@progress/kendo-angular-dialog";
import {IdentificationType} from 'src/app/models/contingent/identificationtype.model';
import {CitizenshipService} from 'src/app/services/contingent/citizenship.service';
import {StudentBudgetState} from 'src/app/models/contingent/studentbudgetstate.model';
import {Budget} from 'src/app/models/contingent/budget.model';
import {ContingentOrganization} from 'src/app/models/contingent/organization.model';
import {BudgetSubCategory} from 'src/app/models/contingent/budgetsubcategory.model';
import {BudgetService} from 'src/app/services/contingent/budget.service';
import {BudgetsubcategoryService} from 'src/app/services/contingent/budgetsubcategory.service';
import {StudEduGroup} from 'src/app/models/contingent/studedugroup.model';
import {EduGroup} from 'src/app/models/contingent/edugroup.model';
import {StudentAcademicState} from 'src/app/models/contingent/studentacademicstate.model';
import {AcademicState} from 'src/app/models/contingent/academicstate.model';
import {AcademicStateService} from 'src/app/services/contingent/academicstate.service';
import {Languages} from 'src/app/models/contingent/languages.model';
import {DictLanguages} from 'src/app/models/contingent/dictlanguages.model';
import {LanguageLevels} from 'src/app/models/contingent/languagelevels.model';
import {DictLanguagesService} from 'src/app/services/contingent/dictlanguages.service';
import {DepartmentName, Faculty} from 'src/app/models/contingent/departmentname.model';
import {DepartmentService} from 'src/app/services/contingent/department.service';
import {EdugroupService} from 'src/app/services/contingent/edugroup.service';
import {StudPlan} from 'src/app/models/contingent/studplan.model';
import {Person} from 'src/app/models/contingent/person.model';
import {ButtonThemeColor} from '@progress/kendo-angular-buttons';
import {EducationService} from 'src/app/services/contingent/education.service';
import {EducationPlan, GroupEducationPlanList} from 'src/app/models/contingent/educationplan.model';
import {DictStudyForm} from 'src/app/models/contingent/dictstudyform.model';
import {MilitarystatusesService} from 'src/app/services/contingent/militarystatuses.service';
import {MilitaryStatuses} from 'src/app/models/contingent/militarystatuses.model';
import {FamilymemberstatusesService} from 'src/app/services/contingent/familymemberstatuses.service';
import {FamilyMemberStatuses} from 'src/app/models/contingent/familymemberstatuses.model';
import {FamilyMembers} from 'src/app/models/contingent/familymembers.model';
import {EducationProgramName} from 'src/app/models/contingent/educationprogram.model';
import {Guid} from 'guid-typescript';
import {DictBenefit} from 'src/app/models/contingent/dictbenefit.model';
import {DictbenefitService} from 'src/app/services/contingent/dictbenefit.service';
import {DictSportType} from 'src/app/models/contingent/dictsporttype.model';
import {DictSportCategory} from 'src/app/models/contingent/dictsportcategory.model';
import {DictsportcategoryService} from 'src/app/services/contingent/dictsportcategory.service';
import {DictsporttypeService} from 'src/app/services/contingent/dictsporttype.service';
import {DicthealthService} from 'src/app/services/contingent/dicthealth.service';
import {DictHealth} from 'src/app/models/contingent/dicthealth.model';
import {environment} from "../../../../environments/environment";
import {CommondictService} from 'src/app/services/contingent/commondict.service';
import {TrainingLevel} from "../../../models/dicts/traininglevel.model";
import {TokenStorageService} from 'src/app/services/token.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {LoaderType} from "@progress/kendo-angular-indicators";
import {DictmaritalstatusService} from "../../../services/contingent/dictmaritalstatus.service";
import {DictMaritalStatus} from "../../../models/contingent/maritalstatus.model";
import {DateFromUTCAsLocal, TimeZoneFix} from "../../../helpers/date-helper";
import {MilitaryCommissariat} from "../../../models/contingent/militarycommissariat.model";
import {MilitaryCommissariatService} from "../../../services/contingent/military-commissariat.service";
import {Military} from "../../../models/contingent/military.model";
import {checkRole} from "../../../helpers/token/role-check";
import {Role} from "../../../models/useraccess/role";
import {CitizenshipEnum} from "../../../models/contingent/enums/citizenship.enum";
import {contingentTabs} from '../../../models/contingent/contingent-tabs';
import {ContingentTabsEnum} from "../../../models/contingent/enums/contingent-tabs.enum";
import {DatePipe} from "@angular/common";
import {AcademicStateEnum} from "../../../models/contingent/enums/academic-state.enum";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {ContingentOrganizationService} from "../../../services/contingent/contingentOrganization.service";
import {validateSnils} from "../../../helpers/snils-helper";
import {StudentcarddisplaysettingService} from "../../../services/contingent/studentcarddisplaysetting.service";
import {DisplaySettingEnum} from "../../../models/contingent/enums/display-setting.enum";
import {PhonesService} from "../../../services/contingent/phones.service";
import {Subscription} from "rxjs";
import {CitizenshipType} from "../../../models/contingent/citizenshiptype.model";
import {CitizenshiptypeService} from "../../../services/contingent/citizenshiptype.service";
import {BudgetCategory} from "../../../models/contingent/budgetcategory.model";
import {BudgetcategoryService} from "../../../services/contingent/budgetcategory.service";
import {PersonService} from "../../../services/contingent/person.service";
import {LanguagelevelsService} from "../../../services/contingent/languagelevels.service";
import {TrainingLevelEnum} from "../../../models/dicts/enums/training-level.enum"


@Component({
  selector: 'app-studentmaininfo',
  templateUrl: './studentmaininfo.component.html',
  styleUrls: ['./studentmaininfo.component.scss']
})
export class StudentmaininfoComponent implements OnInit, OnDestroy {

  @ViewChild("multiselect", {
    static: true
  }) public multiselect: any;

  private phoneSubscription$!: Subscription;

  constructor(private router: Router,
              private activateRoute: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private studService: StudentService,
              private contingentService: ContingentService,
              private citizenshipService: CitizenshipService,
              private budgetService: BudgetService,
              private budgetSubCategoryService: BudgetsubcategoryService,
              private organizationService: ContingentOrganizationService,
              private academicStateService: AcademicStateService,
              private languagesService: DictLanguagesService,
              private departmentService: DepartmentService,
              private eduGroupService: EdugroupService,
              private educationPlanService: EducationService,
              private militaryStatusesService: MilitarystatusesService,
              private familyMemberStatusesService: FamilymemberstatusesService,
              private dictBenefitService: DictbenefitService,
              private dictSportCategoryService: DictsportcategoryService,
              private dictSportTypeService: DictsporttypeService,
              private dictHealthService: DicthealthService,
              private commonDictService: CommondictService,
              private maritalStatusService: DictmaritalstatusService,
              private militaryCommissariatService: MilitaryCommissariatService,
              private phonesService: PhonesService,
              private tokenService: TokenStorageService,
              private jwtService: JwtHelperService,
              private displaySettingService: StudentcarddisplaysettingService,
              private citizenshipTypeService: CitizenshiptypeService,
              private budgetCategoryService: BudgetcategoryService,
              private educationService: EducationService,
              private personsService: PersonService,
              private languageLevelsService: LanguagelevelsService
              )
  {
    this.studentId = activateRoute.snapshot.params["studentId"];
    this.isAdmin = checkRole(tokenService, jwtService, Role.Admin);
  }

  ngOnInit(): void {
    this.getDisplaySettings();
    this.getContingent();
    this.getStudents();
    this.getAllMaritalStatus()
    this.getCitizenship();
    this.getAllLanguageLevels();
    this.getBudget();
    this.getAllBudgetCategories();
    this.getBudgetSubCategory();
    this.getOrganization();
    this.getTargetOrganization()
    this.getAllAcademicState();
    this.getAllLanguages();
    this.getAllFaculty();
    this.getAllStudyForm();
    this.getAllMilitaryStatuses();
    this.getAllMilitaryCommissariat();
    this.getAllFamilyMemberStatuses();
    this.getAllHealths();
    this.getAllBenefits();
    this.getAllSportTypes();
    this.getAllSportCategories();
    this.getAllTrainingLevel();
    this.getAllCitizenshipType();
    this.getAllPersons();
    this.subscribe();
  }

  public maxlength = 9;
  public decimals = 2;
  public format = "#.##";
  public oksm = "-"
  public tabsEnum: any = ContingentTabsEnum;
  public enum = DisplaySettingEnum;
  public loading = false;
  public loaderVisible = false;
  public type: LoaderType = "converging-spinner";
  public hideArrow = false;
  public deleted = false;
  public editMode: boolean = false;
  public isEditCourseNum: boolean = false;
  public isBudget: boolean = true;
  public deleteForever: boolean = false;
  public studentId: Guid;
  public maxCourse = 0;
  public studPersonId!: number;
  public studPersonExternalId!: Guid;
  public formGroup: UntypedFormGroup | undefined;
  public languageLevels: LanguageLevels[] = [];
  public persons: Person[] = [];
  public oneStudent: Student = {
    studentId: 0,
    studentNumber: '',
    dictFilialId: undefined,
    dictFilials: new DictFilial,
    studPersonId: undefined,
    studPersons: new StudPerson,
  }
  public planChangedModal: boolean = false;
  public studyFormChanged: boolean = false;
  public applyPlanChanged: boolean | undefined = undefined;
  public isAdmin: boolean = false;
  public trainingLevels: TrainingLevel[] = [];
  public department: DepartmentName = {};
  public faculties: Faculty[] = [];
  public healthId ? : string | undefined;
  public benefitId ? : string | undefined;
  public citizenshipTypeId ? : string | undefined;
  public sportTypeId ? : string | undefined;
  public sportCategoryId ? : string | undefined;
  public militaryStatusId ? : Guid | undefined;
  public militaryCommissariatId ? : Guid | undefined;
  public familyStatusId ? : string | undefined;
  public isCompatriotId ? : boolean | undefined;
  public snilsRequired = false;
  public citizenshipEnum: any = CitizenshipEnum;
  public maidenNamePlaceholder = environment.maidenName;
  public educationPlanList: GroupEducationPlanList[] = [];
  public trainingLevelEnum:any = TrainingLevelEnum;

  public citizenshipTypes: CitizenshipType[] = [];
  public benefits: DictBenefit[] = [];
  public healths: DictHealth[] = [];
  public sportTypes: DictSportType[] = [];
  public sportCategories: DictSportCategory[] = [];


  public budgets: Budget[] = [];
  public selectedBudgets: Budget[] = [];
  public budgetSubCategories: BudgetSubCategory[] = [];
  public statuses: DictMaritalStatus[] = [];
  public selectedBudgetCategories: BudgetCategory[] = [];
  public budgetCategories: BudgetCategory[] = [];
  public selectedBudgetSubCategories: BudgetSubCategory[] = [];

  public contingent: Contingent = {
    studentId: 0,
    studentNumber: "",
    studPersonId: 0,
    course: 0,
    groupName: "",
    studentBudget: "",
    ball: "",
    studPersons: new StudPerson,
    studentBudgetState: new StudentBudgetState,
    studentSemestr: new StudentSemestr,
    identifications: new Identifications,
    dictCitizenship: new Citizenship,
    studEduGroup: new StudEduGroup,
    studentAcademicState: new StudentAcademicState,
    languages: [],
    studPlan: new StudPlan,
    military: new Military
  }

  public studPerson: StudPerson = {
    studPersonId: 0,
    firstName: '',
    lastName: '',
    buhCode: '',
    isMale: false,
    birthday: new Date,
    dictCitizenships: new Citizenship,
    inn: '',
    snils: '',
    login: '',
    lkPhoto: '',
    lastEdited: new Date,
  }

  public eduGroupId: any;
  public identifications: Identifications[] = [];
  public identification: Identifications = {
    identificationId: 0,
    identificationExternalId: Guid.createEmpty(),
    studPersonId: Guid.createEmpty(),
    studPersons: new StudPerson,
    dictIdentificationTypeId: Guid.createEmpty(),
    dictIdentificationTypes: new IdentificationType,
    serial: '',
    number: '',
    issueDate: new Date,
    issueOrganization: '',
    dateBegin: new Date,
    dateEnd: new Date,
    issueDateEnd: new Date,
    visaDateEnd: new Date,
    contractor: '',
    birthPlace: '',
  }

  public citizenships: Citizenship[] = [];

  public budgetState: StudentBudgetState = {
    studentId: 0,
    student: new Student,
    dictStudentBudgets: new Budget,
    dictOrganizationId: 0,
    dictOrganizations: new ContingentOrganization,
    dictStudentBudgetSubCategoryId: 0,
    dictStudentBudgetSubCategories: new BudgetSubCategory,
    dictOrganizationEmployerId: 0,
    dictOrganizationEmployers: new ContingentOrganization,
  }

  public organizations: ContingentOrganization[] = [];
  public organization: ContingentOrganization = {
    dictOrganizationId: 0,
    organizationName: '',
    ogrn: '',
    inn: '',
    kpp: '',
    isTarget: false,
    isWorkPlace: false,
  }
  public targetOrganizations: ContingentOrganization[] = [];

  public studEduGroup: StudEduGroup = {
    studEduGroupId: 0,
    studentId: undefined,
    student: new Student,
    eduGroupId: undefined,
    eduGroups: new EduGroup,
  }

  public eduGroups: EduGroup[] = [];
  public eduGroup: EduGroup = {
    eduGroupId: 0,
    groupName: '',
    courseNum: 0,
    dateBegin: new Date,
    dateEnd: new Date,
    facultyId: Guid.create(),
  }
  public departSelectEduGroup: EduGroup[] = [];
  public courseSelectEduGroup: EduGroup[] = [];

  public dictStudentAcademicStates: AcademicState[] = [];
  public viewDictStudentAcademicStates: AcademicState[] = [];
  public dictStudentAcademicState: AcademicState = {
    dictStudentAcademicStateId: 0,
    dictStudentAcademicStateExternalId: Guid.createEmpty(),
    academicStateName: "",
    academicStateSName: ""
  }
  public academicStateCategory?: number;
  public academicState: any = AcademicStateEnum;
  public studentAcademicState: StudentAcademicState = {
    studentAcademicStateId: 0,
    student: this.oneStudent,
    dictStudentAcademicStates: this.dictStudentAcademicState,
  }

  public languages: Languages[] = [];
  public selectLanguages: DictLanguages[] = [];
  public educationProfilePlans: EducationProgramName[] = [];
  public educationPlan: EducationPlan | undefined = {
    dictStudyForm: new DictStudyForm,
    yearAdmission: 0,
    educationPlanId: 0,
    developmentPeriod: "",
    acceleratedSign: true,
    dictAcceleratedStudyBaseId: 0,
  };

  public familyMemberStatuses: FamilyMemberStatuses[] = [];

  public familyMember ? : FamilyMembers = {
    familyMemberId: 0,
    fio: undefined,
    dictFamilyMemberStatusId: undefined,
    workPlace: undefined,
    phoneNumber: undefined,
    studPersonId: undefined,
  }

  public studPlan ? : StudPlan = {}

  public militaryStatuses: MilitaryStatuses[] = [];
  public militaryCommissariats: MilitaryCommissariat[] = [];
  public educationProfilePlansId ? : Guid;
  public dictLanguages: DictLanguages[] = [];
  public dictStudyForms: DictStudyForm[] = [];

  //Tabs Name
  public tabs = ['Успеваемость', 'Достижения', 'Приказы и ряжения', 'Персональные данные/документы', 'Справки'];
  public thumbnailSrc: any;

  private subscribe() {
    this.phoneSubscription$ = this.phonesService.phoneNumber$.subscribe(() =>
      this.getContingent()
    );
  }

  public openMultiSelect() {
    this.multiselect.toggle(true);
  }

  public valueChange(value: any): void {
    this.hideArrow = value.length != 0;
  }

  ngAfterViewInit() {
    let elements = document.querySelectorAll('.k-datepicker input');
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('wheel', (e) => {
        e.stopImmediatePropagation();
      });
    }
  }
  onFocus() {
    let elements = document.querySelectorAll('.k-datepicker input');
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('wheel', (e) => {
        e.stopImmediatePropagation();
      });
    }
  }

  public changePlan(changePlan: boolean){
    this.applyPlanChanged = changePlan;
    this.planChangedModal = false;
    this.updateStudent();
  }

  phoneFormat(input: any) { //returns (###) ###-####
    if (input != null) return input.replace(/^\+?(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1 ($2) $3-$4-$5');
  }

  //Getting all entries from dictionary
  public getContingent(): void {
    this.loaderVisible = true;
    this.contingentService.getByIdstudent(this.studentId)
      .subscribe({
        next:(response) => {
          this.contingent = response;
          this.getEducationPlan(this.contingent.studPlan?.planId ? this.contingent.studPlan.planId :
            this.contingent.eduGroup?.planId)
          this.deleted = this.contingent.deletedStudent!
          this.studPersonId = response.studPersons.studPersonId;
          this.studPersonExternalId = response.studPersons.studPersonExternalId!;
          this.toggleButtons()
          this.thumbnailSrc = `${environment.apiEndpoint}contingent/getphoto/${response.studPersons.studPersonExternalId}`
          this.loaderVisible = false;
        },
        error:() => {
          this.router.navigateByUrl(`/contingent`).then();
        }}
      );
  }

  public toggleButtons(): void {
    let e = this.enum.personaldata
    switch (this.router.url) {
      case `/contingent/studentmaininfo/${this.studentId}/progress`:
      case `/contingent/studentmaininfo/${this.studentId}/progress/currentControl`:
        e = this.enum.progress;
        break;
      case `/contingent/studentmaininfo/${this.studentId}/achievements`:
        e = this.enum.achievements;
        break;
      case `/contingent/studentmaininfo/${this.studentId}/targetedtraining`:
        e = this.enum.targetedtraining;
        break;
      case `/contingent/studentmaininfo/${this.studentId}/orders`:
        e = this.enum.orders;
        break;
      case `/contingent/studentmaininfo/${this.studentId}/personaldata/${this.studPersonExternalId}`:
        e = this.enum.personaldata;
        break;
      case `/contingent/studentmaininfo/${this.studentId}/certificate`:
        e = this.enum.certificate;
        break;
      case `/contingent/studentmaininfo/${this.studentId}/military`:
        e = this.enum.military;
        break;
      case `/contingent/studentmaininfo/${this.studentId}/documents`:
        e = this.enum.documents;
        break;
      case `/contingent/studentmaininfo/${this.studentId}/history`:
        e = this.enum.history;
        break;
    }
    this.highlightButton(e)
  }

  public getAllTrainingLevel() {
    this.commonDictService.getAllTrainingLevel()
      .subscribe(
        response => {
          this.trainingLevels = response;
        }
      );
  }

  public getAllCitizenshipType() {
    this.citizenshipTypeService.getAllCitizenshipType()
      .subscribe(
        response => {
          this.citizenshipTypes = response;
        }
      );
  }

  public getAllPersons() {
    this.personsService.getAllperson()
      .subscribe(
        response => {
          this.persons = response;
        }
      );
  }

  public getEducationPlanByGroup(eduGroupId: string){
    this.educationService.getEducationPlanByGroup(eduGroupId)
      .subscribe({
        next: (response) => {
          this.educationPlanList = response;
        }}
      );
  }

  public getDisplaySettings() {
    this.displaySettingService.getStudentCardDisplaySettings()
      .subscribe(
        response => {
          this.displaySettingService.displaySettings$.next(response);
        }
      );
  }

  public getAllStudyForm() {
    this.commonDictService.getAllStudyForm()
      .subscribe(
        response => {
          this.dictStudyForms = response;
        }
      );
  }

  //Getting all entries from dictionary
  public getStudents(): void {
    this.studService.getByIdstudent(this.studentId)
      .subscribe(
        response => {
          this.oneStudent = response;
        }
      );
  }

  public getEduGroupByFaculty(id: any) {
    this.eduGroupService.getEduGroupByFaculty(id)
      .subscribe(
        response => {
          this.eduGroups = response
          this.departSelectEduGroup = this.eduGroups
          //this.departselectedugroup = this.edugroups.filter((s) => s.facultyId == this.edugroup.facultyId);
          this.onCourseChange(this.formEduGroup.value.courseNum != undefined ? this.formEduGroup.value.courseNum: this.eduGroup.courseNum);
          this.onGroupChange(this.contingent.studEduGroup.eduGroupId!);
        }
      );
  }

  public getAllFamilyMemberStatuses() {
    this.familyMemberStatusesService.getAllFamilyMemberStatuses()
      .subscribe(
        response => {
          this.familyMemberStatuses = response;
        }
      );
  }

  public getMaxCourse(id: any){
    this.eduGroupService.getMaxCourseByEduGroupId(id).subscribe({
      next: (response: any) => {
        this.maxCourse = response
      }
    })
  }

  public getEducationPlan(id: any) {

    this.educationPlanService.getEducationPlans(id)
      .subscribe(
        response => {
          this.educationPlan = response;
          this.educationProfilePlansId = this.educationPlan.educationProgramId
          this.getEducationProgram(this.educationPlan?.educationProgram?.educationStandardId)
          this.getDepartment(this.educationPlan?.kafedraId);
          this.contingentService.levelTraining.next({
            levelTraining: this.educationPlan?.educationProgram?.educationStandard?.dictTrainingLevelId ? this.educationPlan?.educationProgram?.educationStandard?.dictTrainingLevelId : ""
          })
        }
      );
  }

  public getEducationProgram(id: any) {
    this.educationPlanService.getEducationProgramByStandard(id)
      .subscribe(
        response => {
          this.educationProfilePlans = response;
        }
      );
  }

  public getAllFaculty() {
    this.departmentService.getAllFaculty(1)
      .subscribe(
        response => {
          this.faculties = response;
          this.loaderVisible = false;
        }
      );
  }

  public getDepartment(id: any) {
    this.departmentService.getDepartmentbyId(id)
      .subscribe(
        response => {
          this.department = response;
        }
      );
  }

  //Getting all entries from dictionary
  public getCitizenship(): void {
    this.citizenshipService.getAllCitizenship()
      .subscribe(
        response => {
          this.citizenships = response;
          for (let i = 0; i < this.citizenships.length; i++) {
            if (this.citizenships[i].country == "Российская Федерация" || this.citizenships[i].country == "Россия") {
              this.citizenships.splice(0, 0, this.citizenships.splice(i, 1)[0]);
              return
            }
          }
        }
      );
  }

  public getAllMaritalStatus() {
    this.maritalStatusService.getAllMaritalStatus()
      .subscribe(
        response => {
          this.listFamilyStateMale = response.filter((x)=>x.isMale === true).slice()
          this.listFamilyStateFemale = response.filter((x)=>x.isMale === false).slice()
        }
      );
  }

  //Getting all entries from dictionary
  public getBudget(): void {
    this.budgetService.getAllBudget()
      .subscribe(
        response => {
          this.budgets = response;
        }
      );
  }

  public getAllBudgetCategories() {
    this.budgetCategoryService.getAllBudgetCategory()
      .subscribe(
        response => {
          this.budgetCategories = response;
        });
  }

  //Getting all entries from dictionary
  public getBudgetSubCategory(): void {
    this.budgetSubCategoryService.getAllBudgetSubCategory()
      .subscribe(
        response => {
          this.budgetSubCategories = response;
        }
      );
  }

  //Getting all entries from dictionary
  public getOrganization(): void {
    this.organizationService.getAllorganization()
      .subscribe(
        response => {
          this.organizations = response;
        }
      );
  }
  public getTargetOrganization(): void {
    this.organizationService.getAllorganization()
      .subscribe(
        response => {
          this.targetOrganizations = response.filter(org=>org.isTarget === true);
        }
      );
  }

  //AcademicState
  getAllAcademicState() {
    this.academicStateService.getAllAcademicState()
      .subscribe(
        response => {
          this.dictStudentAcademicStates = response;
          this.viewDictStudentAcademicStates = this.dictStudentAcademicStates.slice()

          //удаляем Академический статус Удален из возможных вариантов
          let index = this.viewDictStudentAcademicStates.findIndex((x) => x.deleted === true)
          this.viewDictStudentAcademicStates.splice(index, 1)
        }
      );
  }

  getAcademicStateCategory(id: Guid | undefined){
    return this.dictStudentAcademicStates.find((x) => x.dictStudentAcademicStateExternalId === id)?.academicStateCategory
  }

  //Languages
  public getAllLanguages() {
    this.languagesService.getAllLanguages()
      .subscribe(
        response => {
          this.dictLanguages = response;
        }
      );
  }

  public getAllLanguageLevels() {
    this.languageLevelsService.getAlllanguagelevels()
      .subscribe(
        response => {
          this.languageLevels = response;
        }
      );
  }

  //Getting all entries from dictionary
  public getAllMilitaryStatuses() {
    this.militaryStatusesService.getAllMilitaryStatuses()
      .subscribe(
        response => {
          this.militaryStatuses = response;
        }
      );
  }

  public getAllMilitaryCommissariat() {
    this.militaryCommissariatService.getAllMilitaryCommissariat()
      .subscribe(
        response => {
          this.militaryCommissariats = response.sort(function(a, b) {
            if(!a.order) return -1;
            if(!b.order) return -1;
            return a.order - b.order;
          })
        }
      );
  }

  public getAllBenefits() {
    this.dictBenefitService.getAllBenefit()
      .subscribe(
        response => {
          this.benefits = response;
        }
      );
  }

  public getAllSportTypes() {
    this.dictSportTypeService.getAllSportType()
      .subscribe(
        response => {
          this.sportTypes = response;
        }
      );
  }

  public getAllSportCategories() {
    this.dictSportCategoryService.getAllSportCategory()
      .subscribe(
        response => {
          this.sportCategories = response;
        }
      );
  }

  public getAllHealths() {
    this.dictHealthService.getAllHealth()
      .subscribe(
        response => {
          this.healths = response;
        }
      );
  }

  //Факультет
  public nameFamilyMemberState(id: Guid): FamilyMemberStatuses | undefined {
    return this.familyMemberStatuses.find((x) => x.dictFamilyMemberStatusExternalId === id);
  }

  //Направение
  public nameEducationStandard(id: string | undefined): string {
    if (id != undefined)
      return "(" + this.educationPlan?.educationProgram?.educationStandard?.cipher + ") " + this.educationPlan?.educationProgram?.educationStandard?.name
    else return ""
  }

  public nameAcademicStatus(id: Guid | undefined): string {
    if (id != undefined)
      return this.dictStudentAcademicStates.find((x) => x.dictStudentAcademicStateExternalId === id)?.academicStateName ?? ""
    else return ""
  }

  public nameStudyForm(id: any): string {
    if (id != undefined)
      return this.dictStudyForms.find((x) => x.dictStudyFormExternalId === id) !.studyFormName
    else return ""
  }

  public nameTrainingLevel(id: any): string | undefined {
    if (id != undefined)
      return this.trainingLevels.find((x) => x.dictTrainingLevelExternalId == id)?.trainingLevelName
    else return "-"
  }

  //Профиль
  public nameEducationProgram(id: string | undefined): string {
    if (id != undefined) {
      return this.educationPlan?.educationProgram?.name!
    } else return ""
  }

  //Кафедра
  public nameDepartment(id: string | undefined): string | undefined {
    if (id != undefined) {
      return this.department?.name
    } else return ""
  }

  public nameEducationPlan(id: string | undefined): string | undefined {
      return id? this.educationPlan?.name: "-"
  }

  //Get budget name for id
  public nameMilitaryStatus(id: any): MilitaryStatuses | undefined {
    return this.militaryStatuses.find((x) => x.dictMilitaryStatusExternalId === id);
  }

  public nameMilitaryCommissariat(id: any): MilitaryCommissariat | undefined {
    return this.militaryCommissariats.find((x) => x.id === id);
  }

  public nameHealth(id: Guid | string): DictHealth | undefined {
    return this.healths.find((x) => x.dictHealthExternalId === id);
  }

  public nameSportType(id: Guid | string): DictSportType | undefined {
    return this.sportTypes.find((x) => x.dictSportTypeExternalId === id);
  }

  public nameSportCategory(id: Guid | string): DictSportCategory | undefined {
    return this.sportCategories.find((x) => x.dictSportCategoryExternalId === id);
  }

  public nameBenefit(id: Guid | string): DictBenefit | undefined {
    return this.benefits.find((x) => x.dictBenefitExternalId === id);
  }

  public nameCitizenshipType(id: string | undefined): string {
    return this.citizenshipTypes.find((x) => x.dictCitizenshipTypeExternalId === id)?.citizenshipTypeName ?? "-";
  }

  public isHidden(field: number): boolean {
    let isHidden = false;
    switch (field) {
      case this.enum.targetedtraining:
        isHidden = !this.isTargetBudget(this.contingent.studentBudgetState.studentBudgetName);
        break;
    }
    return this.displaySettingService.isHidden(field) || isHidden;
  }

  public listFamilyState: DictMaritalStatus[] = [];
  public listFamilyStateMale: DictMaritalStatus[] = [];
  public listFamilyStateFemale: DictMaritalStatus[] = [];

  //Form
  public form: UntypedFormGroup = new UntypedFormGroup({
    firstName: new UntypedFormControl(),
    middleName: new UntypedFormControl(),
    lastName: new UntypedFormControl(),
    maidenName: new UntypedFormControl(),
    firstNameL: new UntypedFormControl(),
    middleNameL: new UntypedFormControl(),
    lastNameL: new UntypedFormControl(),
    gender: new UntypedFormControl(),
    studentN: new UntypedFormControl(),
    snils: new UntypedFormControl(),
    birthDay: new UntypedFormControl(),
    birthPlace: new UntypedFormControl(),
    marital: new UntypedFormControl(),
    citizenship: new UntypedFormControl(),
    compatriot: new UntypedFormControl(),
    typecitizenship: new UntypedFormControl(),
    dataBudget: new UntypedFormControl(),
    dataResultBudgetCategory: new UntypedFormControl(),
    dataResultOrders: new UntypedFormControl(),
    customer: new UntypedFormControl(),
    employer: new UntypedFormControl(),
    faculty: new UntypedFormControl(),
    course: new UntypedFormControl(),
    group: new UntypedFormControl(),
    direction: new UntypedFormControl(),
    scientificSupervisor: new UntypedFormControl(),
    headPedagogicalPractice: new UntypedFormControl(),
    department: new UntypedFormControl(),
    academicstatus: new UntypedFormControl(),
    languages: new UntypedFormControl(),
    courseChange: new UntypedFormControl(),
  });

  //Form
  public formStudPerson: UntypedFormGroup = new UntypedFormGroup({
    studPersonId: new UntypedFormControl(),
    firstName: new UntypedFormControl(),
    lastName: new UntypedFormControl(),
    middleName: new UntypedFormControl(),
    maidenName: new UntypedFormControl(),
    buhCode: new UntypedFormControl(),
    isMale: new UntypedFormControl(),
    birthday: new UntypedFormControl(new Date()),
    citizenshipId: new UntypedFormControl(),
    dictCitizenships: new UntypedFormControl(),
    inn: new UntypedFormControl(),
    snils: new UntypedFormControl(),
    login: new UntypedFormControl(),
    lkPhoto: new UntypedFormControl(),
    firstNameLatin: new UntypedFormControl(),
    lastNameLatin: new UntypedFormControl(),
    middleNameLatin: new UntypedFormControl(),
    lastEdited: new UntypedFormControl(new Date()),
  });

  public formStudent: UntypedFormGroup = new UntypedFormGroup({
    studentId: new UntypedFormControl(),
    studentNumber: new UntypedFormControl(),
    dictFilialId: new UntypedFormControl(),
    dictFilials: new UntypedFormControl(),
    studPersonId: new UntypedFormControl(),
    studPersons: new UntypedFormControl(),
  });

  public formStudPlan: UntypedFormGroup = new UntypedFormGroup({
    studPlanId: new UntypedFormControl(),
    studentId: new UntypedFormControl(),
    student: new UntypedFormControl(),
    planId: new UntypedFormControl(),
    scientificPersonId: new UntypedFormControl(),
    scientificPerson: new UntypedFormControl(),
    departmentId: new UntypedFormControl(),
    dessertationTitle: new UntypedFormControl(),
    practicePersonId: new UntypedFormControl(),
    practicePerson: new UntypedFormControl(),
    externalId: new UntypedFormControl(),
  });

  public formEduGroup: UntypedFormGroup = new UntypedFormGroup({
    eduGroupId: new UntypedFormControl(),
    groupName: new UntypedFormControl(""),
    planId: new UntypedFormControl(),
    courseNum: new UntypedFormControl(0),
    dateBegin: new UntypedFormControl(new Date()),
    dateEnd: new UntypedFormControl(new Date()),
    facultyId: new UntypedFormControl(0),
  });

  public formIdentification: UntypedFormGroup = new UntypedFormGroup({
    identificationId: new UntypedFormControl(),
    studPersonId: new UntypedFormControl(),
    studPersons: new UntypedFormControl(),
    dictIdentificationTypeId: new UntypedFormControl(),
    dictIdentificationTypes: new UntypedFormControl(),
    serial: new UntypedFormControl(""),
    number: new UntypedFormControl(""),
    issueDate: new UntypedFormControl(new Date()),
    issueOrganization: new UntypedFormControl(""),
    dateBegin: new UntypedFormControl(new Date()),
    dateEnd: new UntypedFormControl(new Date()),
    issueDateEnd: new UntypedFormControl(new Date()),
    visaDateEnd: new UntypedFormControl(new Date()),
    contractor: new UntypedFormControl(""),
    birthPlace: new UntypedFormControl(""),
  });

  public formBudgetState: UntypedFormGroup = new UntypedFormGroup({
    studentBudgetStateId: new UntypedFormControl(),
    studentId: new UntypedFormControl(),
    student: new UntypedFormControl(),
    dictStudentBudgetId: new UntypedFormControl(),
    dictStudentBudgets: new UntypedFormControl(),
    dateBegin: new UntypedFormControl(new Date),
    dateEnd: new UntypedFormControl(new Date),
    dictOrganizationId: new UntypedFormControl(),
    dictOrganizations: new UntypedFormControl(),
    dictStudentBudgetCategoryId: new UntypedFormControl(),
    dictStudentBudgetCategories: new UntypedFormControl(),
    dictStudentBudgetSubCategoryId: new UntypedFormControl(),
    dictStudentBudgetSubCategories: new UntypedFormControl(),
    dictOrganizationEmployerId: new UntypedFormControl(),
    dictOrganizationEmployers: new UntypedFormControl(),
    paidContractNum:  new UntypedFormControl(),
    paidContractDate:  new UntypedFormControl(),
    paidContractCostFull:  new UntypedFormControl(),
    paidContractCostYear:  new UntypedFormControl(),
  });

  public formAcademicState: UntypedFormGroup = new UntypedFormGroup({
    studentAcademicStateId: new UntypedFormControl(),
    studentId: new UntypedFormControl(0),
    student: new UntypedFormControl(),
    dictStudentAcademicStateId: new UntypedFormControl(),
    dictStudentAcademicStates: new UntypedFormControl(),
    dateBegin: new UntypedFormControl(new Date()),
    dateEnd: new UntypedFormControl(new Date()),
  });

  public formFamilyMember: UntypedFormGroup = new UntypedFormGroup({
    familyMemberId: new UntypedFormControl(),
    fio: new UntypedFormControl(),
    dictFamilyMemberStatusId: new UntypedFormControl(),
    workPlace: new UntypedFormControl(),
    phoneNumber: new UntypedFormControl(),
    studPersonId: new UntypedFormControl(),
  });


  public formLanguages: UntypedFormGroup = new UntypedFormGroup({
    languages: new UntypedFormControl([]),
  });

  public formStudEduGroup: UntypedFormGroup = new UntypedFormGroup({
    studEduGroupId: new UntypedFormControl(),
    studentId: new UntypedFormControl(0),
    eduGroupId: new UntypedFormControl(0),
  });

  //Swith on edit mode
  public edit(): void {
    this.healthId = this.contingent.health?.value;
    this.sportCategoryId = this.contingent.sportCategory?.value;
    this.sportTypeId = this.contingent.sportType?.value;
    // Default value for benefits if empty
    let filterBenefits = this.benefits.filter((x) => x.benefitName === "Нет льгот");
    if(this.contingent.benefit?.value) {
      this.benefitId = this.contingent.benefit?.value;
    }
    else {
      this.benefitId = filterBenefits[0]?.dictBenefitExternalId.toString();
    }
    this.citizenshipTypeId = this.contingent.citizenshipType?.value;
    this.militaryStatusId = this.contingent.military?.dictMilitaryStatusId;
    this.militaryCommissariatId = this.contingent.military?.dictMilitaryCommissariatId;
    this.familyStatusId = this.contingent.familyState?.value;
    this.isCompatriotId = this.contingent.compatriot?.value == "true";
    this.studPerson = this.contingent.studPersons;
    this.studPerson.birthday = DateFromUTCAsLocal(this.studPerson.birthday);
    this.identification = this.contingent.identifications;
    this.budgetState = this.contingent.studentBudgetState;
    this.budgetState.paidContractDate = DateFromUTCAsLocal(this.budgetState.paidContractDate);
    this.studentAcademicState = this.contingent.studentAcademicState;
    this.academicStateCategory = this.getAcademicStateCategory(this.studentAcademicState.dictStudentAcademicStateId)
    this.studPlan = this.contingent.studPlan;
    this.studPerson.studPersonId = this.contingent.studPersonId;
    this.familyMember = this.contingent.familyMember;

    if (this.contingent.studPersons.isMale) this.listFamilyState = this.listFamilyStateMale
    else this.listFamilyState = this.listFamilyStateFemale

    if(this.isHidden(this.enum.languages_level))
      this.selectLanguages = this.contingent.languages.map(a => {return {dictLanguageExternalId : a.dictLanguageId, languageName : a.languageName }})

    this.languages = this.contingent.languages;
    this.valueChange(this.languages)
    this.studEduGroup = this.contingent.studEduGroup;
    this.eduGroup = this.contingent.eduGroup!;
    this.isEditCourseNum = true
    this.getEduGroupByFaculty(this.eduGroup.facultyId)
    this.isBudgetOrganizationSelected = this.isTargetBudget(this.budgetState?.studentBudgetName);

    this.isBudgetSelected = this.budgetState?.isBudget!;
    if(this.budgetState.paidContractCostFull != null)
      this.budgetState.paidContractCostFull = this.budgetState.paidContractCostFull / 100
    if(this.budgetState.paidContractCostYear != null)
      this.budgetState.paidContractCostYear = this.budgetState.paidContractCostYear / 100
    this.selectedBudgetCategories = this.budgetCategories.filter((s) => s.dictStudentBudgetId === this.budgetState.dictStudentBudgetId);
    this.selectedBudgetSubCategories = this.budgetSubCategories.filter((s) => s.dictStudentBudgetCategoryId === this.budgetState.dictStudentBudgetCategoryId);
    this.selectedBudgets = this.budgets?.filter((s) => s.isBudget == this.isBudgetSelected);

    this.citizenshipValueChange(this.studPerson.citizenshipId)

    if (this.studPerson.snils && this.studPerson.snils.length === 11)
      this.studPerson.snils =  this.studPerson.snils.replace(/(.{3})(.{3})(.{3})(.{2})/, '$1-$2-$3 $4')

    if (this.snilsRequired && this.studPerson.snils && this.studPerson.snils.length !== 14)
      this.studPerson.snils = undefined

    if (this.identification != null) this.formIdentification.reset(this.identification);
    if (this.studPerson != null) this.formStudPerson.reset(this.studPerson);
    if (this.studentAcademicState != null) this.formAcademicState.reset(this.studentAcademicState);
    if (this.oneStudent != null) this.formStudent.reset(this.oneStudent);
    if (this.budgetState != null) this.formBudgetState.reset(this.budgetState);
    if (this.languages != null) this.formLanguages.value.languages = this.languages;
    if (this.studEduGroup != null) this.formStudEduGroup.reset(this.studEduGroup);
    this.formStudEduGroup.value.eduGroupId = this.studEduGroup.eduGroupId
    this.eduGroupId = this.studEduGroup.eduGroupId
    if (this.eduGroup != null) {
      if(this.contingent.courseNum != null && this.contingent.courseNum != this.eduGroup.courseNum)
        this.eduGroup.courseNum = this.contingent.courseNum
      this.formEduGroup.reset(this.eduGroup)
    }
    if (this.studPlan != null) this.formStudPlan.reset(this.studPlan);
    if (this.familyMember != null) this.formFamilyMember.reset(this.familyMember);

    this.editMode = true;

  }
  public isBudgetSelected:boolean|null = null;
  public isBudgetOrganizationSelected = false;

  public onBudgetChange(dataItem: any): void {
    this.formBudgetState.value.dictStudentBudgetCategoryId = undefined;
    this.isBudgetOrganizationSelected = this.isTargetBudget(dataItem.studentBudgetName);
    this.isBudgetSelected = dataItem?.isBudget!;
    this.selectedBudgetCategories = this.budgetCategories.filter((s) => s.dictStudentBudgetId == dataItem.dictStudentBudgetExternalId);
    this.formBudgetState.value.dictStudentBudgetSubCategoryId = undefined;
    this.selectedBudgetSubCategories = [];
    this.budgetStateReset();
  }

  public onBudgetCategoryChange(categoryId: string): void {
    this.formBudgetState.value.dictStudentBudgetSubCategoryId = undefined;
    this.selectedBudgetSubCategories = this.budgetSubCategories.filter((s) => s.dictStudentBudgetCategoryId === categoryId);
    this.budgetStateReset();
  }

  public budgetStateReset(){
    this.formBudgetState.reset(this.formBudgetState.value);
  }

  public onChangeMale(): void {
    if (this.listFamilyState == this.listFamilyStateFemale) this.listFamilyState = this.listFamilyStateMale
    else this.listFamilyState = this.listFamilyStateFemale
  }

  //Факультет
  public nameFaculty(id: Guid | undefined): Faculty | undefined {
    return this.faculties.find((x) => x.facultyExternalId === id);
  }

  public onDepartmentChange(id: Guid) {
    this.formStudEduGroup.value.eduGroupId = undefined
    this.getEduGroupByFaculty(id)
  }

  public onCourseChange(id: number): void {
    this.courseSelectEduGroup = this.departSelectEduGroup.slice()
    /*this.courseselectedugroup = this.departselectedugroup.filter((s) => s.courseNum == id);
    if(this.isEditCourseNum && this.courseselectedugroup.find((x) => x.eduGroupExternalId === this.eduGroupId) === undefined)
    {
      this.courseselectedugroup.unshift(this.departselectedugroup.find((s) => s.eduGroupExternalId == this.contingent.studEduGroup.eduGroupId)!)
      this.formEduGroup.reset(this.contingent.eduGroup)
    }
    if(!this.isEditCourseNum)
    {
      this.formStudEduGroup.value.eduGroupId = undefined
      this.eduGroupId = undefined
    }*/
    this.isEditCourseNum = false
  }

  public onGroupChange(id: Guid){
    this.formStudEduGroup.value.eduGroupId = id
    this.getMaxCourse(id)
    this.getEducationPlanByGroup(id.toString())
    const idPlan = this.courseSelectEduGroup.find((s) => s.eduGroupExternalId == id)?.planId;
    this.getEducationPlan(this.contingent.studEduGroup.eduGroupId === id
      ? (this.contingent.studPlan?.planId
        ? this.contingent.studPlan.planId
        : this.contingent.eduGroup!.planId)
      : idPlan)
    this.openedChangeGroup = false;
  }

  public onEducationPlanChange(id: string): void {
    this.getEducationPlan(id);
  }

  public onAcademicStateChange(id: Guid){
    let category = this.getAcademicStateCategory(id)
    if((this.academicStateCategory === this.academicState.academic || this.academicStateCategory === this.academicState.expelled)
      && (category === this.academicState.study || category === this.academicState.wait))
    {
      this.formStudEduGroup.value.eduGroupId = undefined
      this.eduGroupId = undefined
      this.notificationService.showWarning('Не забудьте изменить академическую группу студента');
    }
    //this.academicStateCategory = this.getAcademicStateCategory(id)
  }

  //Routing Footer Menu
  public onSelect(e: SelectEvent): void {
    switch (e.index) {
      case 0:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/progress`).then();
        break;
      case 1:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/achievements`).then();
        break;
      case 2:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/orders`).then();
        break;
      case 3:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/personaldata/${this.studPersonId}`).then();
        break;
      case 4:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/certificate`).then();
        break;
    }
  }

  public themeColor: ButtonThemeColor = "base";

  public onSelectTabs(e: number): void {
    this.themeColor = "primary";
    switch (e) {
      case this.tabsEnum.personaldata:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/personaldata/${this.studPersonExternalId}`).then();
        break;
      case this.tabsEnum.progress:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/progress`).then();
        break;
      case this.tabsEnum.achievements:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/achievements`).then();
        break;
      case this.tabsEnum.targetedtraining:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/targetedtraining`).then();
        break;
      case this.tabsEnum.orders:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/orders`).then();
        break;
      case this.tabsEnum.certificate:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/certificate`).then();
        break;
      case this.tabsEnum.military:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/military`).then();
        break;
      case this.tabsEnum.documents:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/documents`).then();
        break;
      case this.tabsEnum.history:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/history`).then();
        break;
      case this.tabsEnum.cards:
        this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/studentcards`).then();
        break;
    }
    this.highlightButton(e)
  }


  public highlightButton(e: any): void {
    for (let button = 0; button < this.buttons.length; button++) {
      this.buttons[button].color = "base";
      if (this.buttons[button].display == e || this.buttons[button].value == e) this.buttons[button].color = "primary";
    }
  }

  public rules: {
    [key: string]: RegExp
  } = {
    L: /[a-zA-Z]/,
    Y: /[\u0400-\u0481\u048A-\u04FF]/,
  };

  public buttons = contingentTabs.slice();

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public opened = false;
  public openedChangeGroup = false;
  public openedManyDelete = false;

  public closeDialog(): void {
    this.opened = false;
  }

  public closeDialogManyDelete(): void {
    this.openedManyDelete = false;
  }

  public openDialog(forever: boolean): void {
    this.deleteForever = forever
    this.opened = true;
  }

  //удаление студента
  public deleteStudent(forever:boolean): void {
    if(forever){
      this.opened = false;
      if(this.contingent.studentCount === 1)
        this.deleteStudentForever()
      else this.openedManyDelete = true;
    }
    else{
      this.opened = false;
      this.deleted = true
      this.updateStudent()
    }
  }

  public showDeleteMessage(forever:boolean) {
    if(forever) return "Вы действительно хотите удалить студента без возможности восстановления?"
    else return "Вы действительно хотите архивировать студента?"
  }

  public deleteStudentForever(){
    this.studService.deletestudent(this.contingent.studentId)
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Студент удален");
          this.router.navigateByUrl(`/contingent`).then()
        },
        error:(error) => {
          this.notificationService.showError(error.error);
        }}
      );
  }

  public deleteAllStudentForever(){
    this.studService.deleteAllStudents(this.contingent.studPersons.studPersonExternalId)
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Студенты удалены");
          this.router.navigateByUrl(`/contingent`).then()
        },
        error:(error) => {
          this.notificationService.showError(error.error);
        }}
      );
  }

  recoverStudent() {
    this.deleted = false
    this.notificationService.showWarning('Для завершения восстановления студента, установите "Академический статус"');
    this.studentAcademicState.dictStudentAcademicStateId = undefined
    this.formAcademicState.reset(this.studentAcademicState);
  }

  updateStudent() {
    //Validate form
    if(!this.deleted){
      if (!this.form.valid) {
        this.form.markAllAsTouched();
        this.notificationService.showError("Не все обязательные поля заполнены");
        return;
      }
      if (!this.formStudPerson.valid || !this.formBudgetState.valid || !this.formEduGroup.valid || !this.formAcademicState.valid || !this.formStudEduGroup.valid) {
        this.formStudPerson.markAllAsTouched();
        this.formEduGroup.markAllAsTouched();
        this.formBudgetState.markAllAsTouched();
        this.formEduGroup.markAllAsTouched();
        this.formStudEduGroup.markAllAsTouched();
        this.formAcademicState.markAllAsTouched();
        this.notificationService.showError("Не все обязательные поля заполнены");
        return;
      }
      if((this.snilsRequired || this.formStudPerson.value.snils) && !validateSnils(this.formStudPerson.value.snils)){
        const control = this.formStudPerson.controls['snils'];
        control.setErrors({notCorrect: true});
        this.formStudPerson.markAllAsTouched();
        return;
      }
    }

    if(this.formEduGroup.value.courseNum > this.maxCourse){
      this.notificationService.showError("Выбранный курс превышает срок обучения");
      return
    }

    this.studyFormChanged = this.educationPlan?.dictStudyFormId != this.contingent?.studyFormId
    if (this.eduGroupId !== this.studEduGroup.eduGroupId
      && this.educationPlan?.externalId !== this.contingent.studPlan?.planId
      && this.applyPlanChanged === undefined)
    {
      this.planChangedModal = true;
      return;
    }

    if(this.selectLanguages.length != 0)
      this.languages = this.selectLanguages.map(a => {return {dictLanguageId : a.dictLanguageExternalId}})

    this.languages = this.languages.filter((x) => x.dictLanguageId)

    if(this.formStudPerson.value.snils && this.formStudPerson.value.snils.length === 11){
      this.formStudPerson.value.snils = this.formStudPerson.value.snils.replace(/(.{3})(.{3})(.{3})(.{2})/, '$1-$2-$3 $4')
    }
    //очистка доп. сведений по финансированию, если выбран другой пункт
    if(this.isBudgetSelected == true){
      this.formBudgetState.value.paidContractNum = null;
      this.formBudgetState.value.paidContractDate = null;
      this.formBudgetState.value.paidContractCostFull = null;
      this.formBudgetState.value.paidContractCostYear = null;
    }
    if(!this.isBudgetOrganizationSelected){
      this.formBudgetState.value.dictOrganizationId = null;
      this.formBudgetState.value.dictOrganizationEmployerId = null;
    }

    this.formGroup = formGroup({
      students: this.formStudent.value,
      studPersons: this.formStudPerson.value,
      studentBudgetState: (this.formBudgetState.value.dictStudentBudgetId !== this.contingent.studentBudgetState.dictStudentBudgetId ||
        this.formBudgetState.value.dictOrganizationId !== this.contingent.studentBudgetState.dictOrganizationId ||
        this.formBudgetState.value.dictOrganizationEmployerId !== this.contingent.studentBudgetState.dictOrganizationEmployerId ||
        this.formBudgetState.value.paidContractNum !== this.contingent.studentBudgetState.paidContractNum ||
        this.formBudgetState.value.paidContractDate !== this.contingent.studentBudgetState.paidContractDate ||
        this.formBudgetState.value.paidContractCostFull !== this.contingent.studentBudgetState.paidContractCostFull ||
        this.formBudgetState.value.paidContractCostYear !== this.contingent.studentBudgetState.paidContractCostYear ||
        this.formBudgetState.value.dictStudentBudgetCategoryId !== this.contingent.studentBudgetState.dictStudentBudgetCategoryId ||
        this.formBudgetState.value.dictStudentBudgetSubCategoryId !== this.contingent.studentBudgetState.dictStudentBudgetSubCategoryId) ? this.formBudgetState.value : null,
      studentAcademicState: this.formAcademicState.value.dictStudentAcademicStateId !== this.contingent.studentAcademicState.dictStudentAcademicStateId || this.deleted ? this.formAcademicState.value : null,
      languages: this.languages,
      studEduGroup: this.eduGroupId !== this.studEduGroup.eduGroupId ? this.formStudEduGroup.value : null,
      studPlan: (this.formStudPlan.value.planId !== this.contingent.studPlan?.planId ||
                 this.formStudPlan.value.scientificPersonId !== this.contingent.studPlan?.scientificPersonId ||
                 this.formStudPlan.value.practicePersonId !== this.contingent.studPlan?.practicePersonId
                  ? this.formStudPlan.value : null),
      studPlanId: this.formStudPlan.value.externalId,
      studentId: this.contingent.studentId,
      changePlan: this.applyPlanChanged !== undefined ? this.applyPlanChanged : false,
      elderGroupId: this.contingent.elder && this.eduGroupId !== this.studEduGroup.eduGroupId ? this.studEduGroup.eduGroupId : null,
      courseChange: {eduGroupId: this.eduGroupId, courseNum: this.formEduGroup.value.courseNum}
    });

    if(this.formGroup.value.studentBudgetState != null && this.formBudgetState.value.paidContractCostFull != null) this.formBudgetState.value.paidContractCostFull = this.formBudgetState.value.paidContractCostFull * 100
    if(this.formGroup.value.studentBudgetState != null && this.formBudgetState.value.paidContractCostYear != null) this.formBudgetState.value.paidContractCostYear = this.formBudgetState.value.paidContractCostYear * 100

    if (this.healthId !== this.contingent.health?.value) {
      this.formGroup.value.health = this.healthId;
      this.formGroup.value.healthId = this.contingent.health?.additionalFieldExternalId;
    }
    if (this.sportCategoryId !== this.contingent.sportCategory?.value) {
      this.formGroup.value.sportCategory = this.sportCategoryId;
      this.formGroup.value.sportCategoryId = this.contingent.sportCategory?.additionalFieldExternalId;
    }
    if (this.sportTypeId !== this.contingent.sportType?.value) {
      this.formGroup.value.sportType = this.sportTypeId;
      if (this.sportTypeId == undefined) this.formGroup.value.sportType = "";
      this.formGroup.value.sportTypeId = this.contingent.sportType?.additionalFieldExternalId;
    }
    if (this.benefitId !== this.contingent.benefit?.value) {
      this.formGroup.value.benefit = this.benefitId;
      this.formGroup.value.benefitId = this.contingent.benefit?.additionalFieldExternalId;
    }
    if (this.citizenshipTypeId !== this.contingent.citizenshipType?.value) {
      this.formGroup.value.citizenshipType = this.citizenshipTypeId;
      this.formGroup.value.citizenshipTypeId = this.contingent.citizenshipType?.additionalFieldExternalId;
    }
    if (this.isCompatriotId !== this.contingent.compatriot?.value) {
      this.formGroup.value.isCompatriot = this.isCompatriotId == true ? "true" : "false";
      this.formGroup.value.isCompatriotId = this.contingent.compatriot?.additionalFieldExternalId;
    }
    if (this.familyStatusId !== this.contingent.familyState?.value) {
      this.formGroup.value.familyStatus = this.familyStatusId;
      this.formGroup.value.familyStatusId = this.contingent.familyState?.additionalFieldExternalId;
    }

    if (this.militaryStatusId !== this.contingent.military?.dictMilitaryStatusId
      || this.militaryCommissariatId !== this.contingent.military?.dictMilitaryCommissariatId) {
      this.formGroup.value.militaryId = this.contingent.military?.militaryExternalId;
      if (this.contingent.military == null) {
        this.formGroup.value.military = {
          studPersonId: this.studPersonExternalId,
          dictMilitaryStatusId: this.militaryStatusId,
          dictMilitaryCommissariatId: this.militaryCommissariatId,
        };
      } else {
        this.formGroup.value.military = this.contingent.military;
        this.formGroup.value.military.dictMilitaryStatusId = this.militaryStatusId;
        this.formGroup.value.military.dictMilitaryCommissariatId = this.militaryCommissariatId;
        this.formGroup.value.military.militaryId = undefined;
      }
    }

    let tmpDate = new DatePipe('ru-Ru').transform(this.formBudgetState.value.paidContractDate, 'dd/MM/yyyy');
    if(tmpDate === '01/01/1970' || tmpDate == null) {
      this.formBudgetState.value.paidContractDate = null;
    }

    this.formGroup.value.deletedStudent = this.deleted;

    this.timeZoneFix();
    this.contingentService.updatecontingent(this.formGroup.value)
      .subscribe({
        next:() => {
          this.editMode = false;
          this.formFamilyMember.reset()
          this.formStudent.reset();
          if (this.deleted) this.notificationService.showSuccess(this.contingent.studPersons.lastName + " " +
            this.contingent.studPersons.firstName + " " + this.contingent.studPersons.middleName +
            (this.contingent.studPersons.isMale ? " архивирован" : " архивирована"));
          else this.notificationService.showSuccess("Сохранено");
          this.getStudents()
          this.getContingent();
        },
        error: (error) => {
          this.editMode = true;
          this.notificationService.showError(error.error);
        }});
    this.applyPlanChanged = undefined
  }

  public addLanguages():void{
    this.languages!.push(new Languages());
  }

  public removeLanguages(i: number):void{
    if (i > -1) {
      this.languages!.splice(i, 1);
    }
  }

  public citizenshipValueChange(value: any): void {
    this.oksm = this.citizenships.find((x) => x.citizenshipExternalId == value)?.code ?? "-"
    this.snilsRequired = this.oksm === this.citizenshipEnum.rf;
  }

  // Convert date to UTC
  public timeZoneFix() {
    this.formStudPerson.value.birthday = TimeZoneFix(this.formStudPerson.value.birthday);
    this.formStudPerson.value.lastEdited = TimeZoneFix(this.formStudPerson.value.lastEdited);
    this.formIdentification.value.dateBegin = TimeZoneFix(this.formIdentification.value.dateBegin);
    this.formIdentification.value.dateEnd = TimeZoneFix(this.formIdentification.value.dateEnd);
    this.formIdentification.value.issueDateEnd = TimeZoneFix(this.formIdentification.value.issueDateEnd);
    this.formIdentification.value.visaDateEnd = TimeZoneFix(this.formIdentification.value.visaDateEnd);
    this.formBudgetState.value.dateBegin = TimeZoneFix(this.formBudgetState.value.dateBegin);
    this.formBudgetState.value.dateEnd = TimeZoneFix(this.formBudgetState.value.dateEnd);
    this.formBudgetState.value.paidContractDate = TimeZoneFix(this.formBudgetState.value.paidContractDate);
    this.formAcademicState.value.dateBegin = TimeZoneFix(this.formAcademicState.value.dateBegin);
    this.formAcademicState.value.dateEnd = TimeZoneFix(this.formAcademicState.value.dateEnd);
  }

  //Close edit form
  public close(): void {
    if(this.budgetState.paidContractCostFull != null) this.budgetState.paidContractCostFull = this.budgetState.paidContractCostFull * 100
    if(this.budgetState.paidContractCostYear != null) this.budgetState.paidContractCostYear = this.budgetState.paidContractCostYear * 100
    this.editMode = false;
  }

  public closePlanChange(): void {
    this.planChangedModal = false;
  }

  public mask = "000-000-000 00";
  public includeLiterals = false;
  public courses: Array <{course: string;courseNum: number}> =
    [
      {
        course: "1 Курс",
        courseNum: 1
      },
      {
        course: "2 Курс",
        courseNum: 2
      },
      {
        course: "3 Курс",
        courseNum: 3
      },
      {
        course: "4 Курс",
        courseNum: 4
      },
      {
        course: "5 Курс",
        courseNum: 5
      },
      {
        course: "6 Курс",
        courseNum: 6
      },
    ];

  public defaultItemFaculty: { name: string;facultyExternalId ? : any } = {
    name: "Институт/факультет...",
    facultyExternalId: null,
  };
  public defaultItemBudget: { studentBudgetName: string;dictStudentBudgetId ? : any } = {
    studentBudgetName: "Источник финансирования",
    dictStudentBudgetId: null,
  };
  public defaultItemCourse: { course: string;courseNum ? : any } = {
    course: "Курс...",
    courseNum: null,
  };
  public defaultItemStatus: { academicStateName: string;dictStudentAcademicStateId ? : any } = {
    academicStateName: "Академический статус...",
    dictStudentAcademicStateId: null,
  };
  public isTargetBudget(name?: string|undefined): boolean{
    if(!name) return false;
    return name.toLowerCase().includes("целев");
  }

  private unsubscribe() {
    this.phoneSubscription$.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  public printStudentCard() {
    if(!this.educationPlan?.educationProgram){
      this.notificationService.showError("Карточка ещё не загрузилась");
      return;
    }
    const trainingLevel = this.trainingLevels
      .find((x) => x.dictTrainingLevelExternalId == this.educationPlan?.educationProgram?.educationStandard?.dictTrainingLevelId)?.dictTrainingLevelId;
    let report = '';
    switch (trainingLevel) {
      case this.trainingLevelEnum?.Master:
          report = 'Contingent/MagisterStudentCard.trdp';
          break;
      case this.trainingLevelEnum?.Graduate:
          report = 'Contingent/PostgraduateStudentCard.trdp';
          break;
      default:
          report = 'Contingent/StudentCard.trdp';
          break;
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['report-viewer'], {
        queryParams: {
          report: report,
          contingentBaseUrl: environment.apiEndpoint.toString().endsWith('/')
            ? environment.apiEndpoint.toString().slice(0, -1) : environment.apiEndpoint.toString(),
          studentId: this.studentId
        }
      })
    );

    window.open(url, '_blank');
  }
}

const formGroup = (dataItem: {
  students ? : any;
  studPersons ? : any;
  studentBudgetState ? : any;
  studentAcademicState ? : any; languages ? : any; studEduGroup ? : any,
  studPlan ? : any,
  studPlanId ? : any,
  familyMember ? : any,
  familyMemberId ? : any,
  healthId ? : any,
  health ? : any,
  isCompatriot ? : any,
  isCompatriotId ? : any,
  familyStatus ? : any,
  familyStatusId ? : any,
  sportCategory ? : any,
  sportCategoryId ? : any,
  sportType ? : any,
  sportTypeId ? : any,
  benefit ? : any,
  benefitId ? : any,
  military ? : any,
  militaryId ? : any,
  deletedStudent ? : any,
  studentId ? : any,
  changePlan ? : any,
  elderGroupId ? : any,
  courseChange ? : any,
  citizenshipType? : string;
  citizenshipTypeId? : string;
}) =>
  new UntypedFormGroup({
    students: new UntypedFormControl(dataItem.students),
    studPersons: new UntypedFormControl(dataItem.studPersons),
    studentBudgetState: new UntypedFormControl(dataItem.studentBudgetState),
    studentAcademicState: new UntypedFormControl(dataItem.studentAcademicState),
    languages: new UntypedFormControl(dataItem.languages),
    studEduGroup: new UntypedFormControl(dataItem.studEduGroup),
    studPlan: new UntypedFormControl(dataItem.studPlan),
    studPlanId: new UntypedFormControl(dataItem.studPlanId),
    familyMemberId: new UntypedFormControl(dataItem.familyMemberId),
    familyMember: new UntypedFormControl(dataItem.familyMember),
    health: new UntypedFormControl(dataItem.health),
    healthId: new UntypedFormControl(dataItem.healthId),
    sportCategory: new UntypedFormControl(dataItem.sportCategory),
    sportCategoryId: new UntypedFormControl(dataItem.sportCategoryId),
    sportType: new UntypedFormControl(dataItem.sportType),
    sportTypeId: new UntypedFormControl(dataItem.sportTypeId),
    benefit: new UntypedFormControl(dataItem.benefit),
    benefitId: new UntypedFormControl(dataItem.benefitId),
    military: new UntypedFormControl(dataItem.military),
    militaryId: new UntypedFormControl(dataItem.militaryId),
    isCompatriot: new UntypedFormControl(dataItem.isCompatriot),
    isCompatriotId: new UntypedFormControl(dataItem.isCompatriotId),
    familyStatus: new UntypedFormControl(dataItem.familyStatus),
    familyStatusId: new UntypedFormControl(dataItem.familyStatusId),
    deletedStudent: new UntypedFormControl(dataItem.deletedStudent),
    studentId: new UntypedFormControl(dataItem.studentId),
    changePlan: new UntypedFormControl(dataItem.changePlan),
    elderGroupId: new UntypedFormControl(dataItem.elderGroupId),
    courseChange: new UntypedFormControl(dataItem.courseChange),
    citizenshipType: new UntypedFormControl(dataItem.citizenshipType),
    citizenshipTypeId: new UntypedFormControl(dataItem.citizenshipTypeId),
  });
