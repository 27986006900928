import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormationDiplomaService} from '../../../../services/gia/formationdiploma.service';
import {basicInformationModel,
        DiplomaStatusesModel,
        ReplacingReasonsModel,
        TypeDiplomaModel,
        GeneralInformationModel,
        basicStudentInformationModel} from '../../../../models/gia/formationdiploma.model';
import {DateFromUTCAsLocal, TimeZoneFix, DateToString, getDayStart} from '../../../../helpers/date-helper';
import {PrintTemplateService} from '../../../../services/gia/printTemplate.service';
import {DictPrintTemplateCategoryEnum} from '../../../../models/gia/enums/dictPrintTemplateCategory.enum';
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import { Validators, FormGroup, FormControl } from "@angular/forms";
import {LoaderType} from "@progress/kendo-angular-indicators";

@Component({
    selector : 'app-basicinformation',
    templateUrl: './basicinformation.component.html',
    styleUrls : ['./basicinformation.component.scss']
})

export class BasicInformationComponent implements OnInit {

    @Input() studentId: string = "";
    @Input() admissionOrder: string = "";
    @Input() allowEdit: boolean = false;
    @Input() generalInformation: GeneralInformationModel = new GeneralInformationModel;
    @Input() originalGeneralInformation: GeneralInformationModel = new GeneralInformationModel;

    @Output() getGeneralInformation = new EventEmitter();
    @Output() saveGeneralInfo = new EventEmitter();
    @Output() basicLoading = new EventEmitter<boolean>();
    @Output() fillRequiredFields = new EventEmitter<boolean>();

    public basicInformation: basicInformationModel = new basicInformationModel;
    public basicStudentInfo:  basicStudentInformationModel = new basicStudentInformationModel
    public loaderVisible: boolean = false;
    public type: LoaderType = "converging-spinner";

    public typesDiploma: TypeDiplomaModel[] = [];
    public statuses: DiplomaStatusesModel[] = [];
    public reasonsReplacement: ReplacingReasonsModel[] = [];

    public diplomaIssueDate: Date | null = null;
    public replacementDate: Date | null = null;
    public giaProtocolDate: Date | null = null;

    Form : FormGroup = new FormGroup({
        diplomaTypeId: new FormControl('', [Validators.required]),
        diplomaRegNumber: new FormControl('', [Validators.required]),
        diplomaIssueDate: new FormControl('', [Validators.required]),
      //  diplomaStatusId: new FormControl('', [Validators.required]),
        diplomaSeries: new FormControl(''),
        diplomaNumber: new FormControl(''),
    //    diplomaAttachmentStatusId: new FormControl('', [Validators.required]),
        diplomaAttachmentSeries: new FormControl(''),
        diplomaAttachmentNumber: new FormControl(''),
        giaProtocol: new FormControl(''),
        giaProtocolDate: new FormControl(''),
        secChairman: new FormControl('')
    });

    constructor(
        private formationDiplomaService: FormationDiplomaService,
        private printTemplateService: PrintTemplateService,
        private notificationService: NotificationsService,
    ) {}

    ngOnInit() {
        this.getDiplomaStatuses();
        this.getBasicInformations();
        this.getBasicStudentInfo();
        this.getDiplomaReplacingReasons();
        this.getTypesDiploma();

        this.formationDiplomaService.tabApplication.next({
            application: false
        });
    }

    public getBasicStudentInfo() {
        this.formationDiplomaService.getBasicStudentInfo(this.studentId)
        .subscribe(response => {
            this.basicStudentInfo = response;
        })
    }

    public getBasicInformations() {
        this.basicLoading.emit(true);
        this.loaderVisible = true;

        this.formationDiplomaService.getBasicDiplomaInfo(this.studentId)
        .subscribe(response => {
            this.basicInformation = response;
            this.basicLoading.emit(false);
            this.loaderVisible = false;

            if (this.basicInformation.diplomaIssueDate) this.diplomaIssueDate = DateFromUTCAsLocal(this.basicInformation.diplomaIssueDate);
            if (this.basicInformation.replacementDate) this.replacementDate = DateFromUTCAsLocal(this.basicInformation.replacementDate);
            if (this.basicInformation.giaProtocolDate) this.giaProtocolDate = DateFromUTCAsLocal(this.basicInformation.giaProtocolDate);

            this.changeForm();
        })
    }

    public getDiplomaStatuses() {
        this.formationDiplomaService.getDiplomaStatuses()
            .subscribe(response => {
                this.statuses = response;
            })
    }

    public getDiplomaReplacingReasons() {
        this.formationDiplomaService.getDiplomaReplacingReasons()
            .subscribe(response => {
                this.reasonsReplacement = response;
            })
    }

    public getTypesDiploma() {
        this.formationDiplomaService.getTypesDiploma(this.studentId)
            .subscribe(response => {
                this.typesDiploma = response;
            })
    }

    public period(value: number) {
        return value ? value > 4 ? `${value} лет` : `${value} года` : '-'
    }

    public saveInformation() {
        this.saveGeneralInfo.emit();
    }

    public saveBasicInformation(diplomaInfoId: string) {
        if (!this.allowEdit)
            return;

        this.basicInformation.diplomaInfoId = diplomaInfoId;

        if (!this.Form.valid) {
            this.notificationService.showError('Не заполнены обязательные поля. Основные сведения нельзя сохранить.');
            return;
        }
        if (this.diplomaIssueDate) this.basicInformation.diplomaIssueDate = new Date(TimeZoneFix(this.diplomaIssueDate).toISOString());
        if (this.replacementDate) this.basicInformation.replacementDate = new Date(TimeZoneFix(this.replacementDate).toISOString());
        if (this.giaProtocolDate) this.basicInformation.giaProtocolDate = new Date(TimeZoneFix(this.giaProtocolDate).toISOString());

        this.formationDiplomaService.addBasicInfo(this.basicInformation)
            .subscribe({
                next: () => {
                    this.notificationService.showSuccess('Основные сведения успешно сохранены');
                    this.getBasicInformations();
                },
                error: () => {
                    this.notificationService.showError('Произошла ошибка');
                    this.getBasicInformations();
                }
        })
    }

    public formatNumber(value: number) {
      return value ? Math.round(value * 100) / 100 : '-';
    }

    public changeForm(value?: string | Date) {
      let fillField = !(this.basicInformation.diplomaTypeId == null || this.basicInformation.diplomaRegNumber == null || this.diplomaIssueDate == null
          || this.basicInformation.diplomaRegNumber.toString().trim() == "")
      
      this.fillRequiredFields.emit(fillField);
    }
}
