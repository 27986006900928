<dicts-header></dicts-header>
<h1>Форма обучения</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [data]="gridView" (remove)="removeHandler($event)"
              [height]="500"
              [ngClass]="{noData: studyforms.length < 8}"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true"
              [sort]="sort"
              (sortChange)="sortChange($event)"
              >

    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup!.invalid"
                themeColor="primary"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictStudyFormId" title="Id"> </kendo-grid-column> -->
    <kendo-grid-column field="studyFormName"
                       title="Название формы обучения"
                       [width]="350"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells leftAlignment">
                          {{dataItem.studyFormName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="studyFormSName"
                       title="Сокр. название"
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.studyFormSName}}
                          </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="studyFormType" title="Тип формы обучения"
                       [width]="200"
                       headerClass="gridHeader">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ namestudyformtype(dataItem.studyFormType)?.studyFormTypeName }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
        <kendo-dropdownlist [data]="studyformtypes"
                            textField="studyFormTypeName"
                            valueField="dictStudyFormTypeExternalId"
                            [valuePrimitive]="true"
                            [formControl]="formGroup.get('studyFormType')">
                          </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="orderNum"
                       title="Порядковый номер для сортировки при отображении"
                       headerClass="gridHeader"
                       [width]="200">
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
                           <span class="alignCells">
                               <kendo-numerictextbox
                                 [style.width.px]="170"
                                 [value]="dataItem.orderNum"
                                 (valueChange)="orderChange($event)"
                                 [min]="1"
                                 format="n0"
                                 [formControl]="formGroup.get('orderNum')">
                               </kendo-numerictextbox>
                           </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.orderNum}}
            </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="60" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full" style="text-overflow: unset;">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>

  </kendo-grid>

</div>

<div kendoDialogContainer></div>
