import { ChangeDetectorRef, Component, Input, OnInit, Renderer2, ViewChild, HostListener, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { SortDescriptor } from "@progress/kendo-data-query";
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AddEvent, GridComponent, RemoveEvent, EditEvent } from '@progress/kendo-angular-grid';
import { DictStudyForm } from 'src/app/models/contingent/dictstudyform.model';
import { TrainingLevel } from 'src/app/models/dicts/traininglevel.model';
import { EdugroupService } from 'src/app/services/contingent/edugroup.service';
import { ContingentUserAccess } from 'src/app/models/useraccess/contingent/contingentuseraccess.model';
import { CommondictService } from 'src/app/services/contingent/commondict.service';
import { ContingentUserAccessService } from 'src/app/services/useraccess/contingent-user-access.service';
import {PersonService} from "../../../services/contingent/person.service";
import {Person} from "../../../models/contingent/person.model";
import {DepartmentService} from "../../../services/contingent/department.service";
import {Faculty} from "../../../models/contingent/departmentname.model";
import {arrayRewrite, DisplayValuesInEditMode, valueChange, valueChangeBySourceName} from "../../../helpers/multiselect-helper";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {checkRole} from "../../../helpers/token/role-check";
import {Role} from "../../../models/useraccess/role";
import { EduGroupua } from '../../../models/contingent/edugroup.model';
import {isChildOf} from "../../../helpers/elementRef-helper";
import {GridDisplayList} from "../../../helpers/grid-helper";
import {dialogResultHelper, openRemoveDialog} from "../../../helpers/dialogHelper";
import {lastValueFrom} from "rxjs";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {getData$} from "../../../../environments/environment";
import {contingentTabs} from "../../../models/contingent/contingent-tabs";
import { DictFilial } from '../../../models/contingent/dictfilial.model';
import { DictFilialService } from '../../../services/contingent/dictfilial.service';

@Component({
  selector: 'app-contingent-access',
  templateUrl: './contingent-access.component.html',
  styleUrls: ['./contingent-access.component.scss']
})
export class ContingentAccessComponent implements OnInit {
  public isAdmin: boolean = false;
  public virtual: any = {
    itemHeight: 28,
  };

  public trainingLevelsEdit: any[] = [];
  public filialsEdit: any[] = [];
  public facultiesEdit: any[] = [];
  public studyFormsEdit: any[] = [];
  public eduGroupsEdit: any[] = [];
  public contingentTabsEdit: any[] = [];

  public accessOptions: any[] = [
    {id: 1, text: 'Да'},
    {id: 2, text: 'Нет'}
  ]
  public achievementOptions: any[] = [
    {id: true, text: 'Запись'},
    {id: false, text: 'Утверждение'}
  ]
  public boolOptions: any[] = [
    {id: true, text: 'Да'},
    {id: false, text: 'Нет'}
  ]
  public dropdownOptions: any[] = [
    {id: 1, text: 'Чтение' },
    {id: 2, text: 'Запись' }
  ]
  public dictDropdownOptions = [
    {id: 0, text: 'Нет'},
    {id: 1, text: 'Чтение'},
    {id: 2, text: 'Запись'},
  ];

  public studyForms: DictStudyForm[] = [];
  public trainingLevels: TrainingLevel[] = [];
  public faculties: Faculty[] = [];
  public eduGroups: EduGroupua[] = [];
  public filials: DictFilial[] = [];
  public contingentTabsList: any[any] = contingentTabs.slice();
  public persons: Person[] = [];
  public accesses: ContingentUserAccess[] = [];
  public access: ContingentUserAccess = {
    id: '',
    personId: '',
    personName: '',
    trainingLevels: [],
    studyForms: [],
    eduGroups: [],
    faculties: [],
    filials: [],
    contingentTabsLevel:[],
    accessLevel: 0,
    accessLevelName: '',
    groupAccessLevel: 0,
    groupAccessLevelName: '',
    dictAccessLevel: 0,
    dictAccessLevelName: '',
    elderAccessLevel: false,
    userAccessLevel: false,
    localSignatoryAccessLevel: false,
    militaryCertificateAccessLevel: 0,
    achievementAccessLevel: false,
    constructorEduGroupNameAccessLevel: false,
  }

  public trainingLevelsShow: TrainingLevel[] = [];
  public filialsShow: DictFilial[] = [];
  public facultiesShow: Faculty[] = [];
  public studyFormsShow: DictStudyForm[] = [];
  public eduGroupsShow: EduGroupua[] = [];
  public contingentTabsShow: EduGroupua[] = [];

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public position: "top" | "bottom" | "both" = "top";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;

  @Input() searchFIO: string = '';

  public sort: SortDescriptor[] = [{ field: "personName", dir: "asc" }];

  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;
  public opened = false;
  public loading = false;

  private dataSources: any[] = ['trainingLevels', 'studyForms', 'faculties', 'eduGroups', 'filials', 'contingentTabsLevel'];


  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public gridData: any[] = [];

  constructor(private renderer: Renderer2,
              private dialogService: DialogService,
              private commondictService: CommondictService,
              private personService: PersonService,
              private eduGroupService: EdugroupService,
              private userAccessService: ContingentUserAccessService,
              private notificationService: NotificationsService,
              private dictFilialService: DictFilialService,
              private cdRef : ChangeDetectorRef,
              private facultyService: DepartmentService,
              private tokenStore: TokenStorageService,
              private elementRef: ElementRef,
              private jwtHelper: JwtHelperService
  ) {
    this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
    this.contingentTabsList.unshift({title:"Все", value: ""})
    this.contingentTabsList.unshift({title:"Нет", value: 0})
  }

  async ngOnInit() {
    getData$.subscribe(() => { this.getAccess();})
    this.getPersons();
    this.getAllStudyForm();
    this.getAllTrainingLevels();
    this.getFaculties();
    this.getAllFilials();
    await this.getEduGroups([]);
    this.contingentTabsShow = this.contingentTabsList;
  }

  public getAccessLevelName(accessLevel: number): string {
    return this.dictDropdownOptions.find((value) => value.id === accessLevel)?.text ?? '';
  }


  // Get access
  public getAccess() {
    this.loading = true;
    this.userAccessService.getAccess().subscribe(response => {
        this.loading = false;
        this.accesses = response;
        if (this.accesses) {
          for (let i = 0; i < this.accesses.length; i++) {
            valueChangeBySourceName(this.accesses[i].trainingLevels,
              "trainingLevelId", null, "");
            valueChangeBySourceName(this.accesses[i].trainingLevels,
              "trainingLevelName", null, "Все");
            valueChangeBySourceName(this.accesses[i].filials,
              "filialId", null, "");
            valueChangeBySourceName(this.accesses[i].filials,
              "filialSName", null, "Все");
            valueChangeBySourceName(this.accesses[i].studyForms,
              "studyFormId", null, "");
            valueChangeBySourceName(this.accesses[i].studyForms,
              "studyFormName", null, "Все");
            valueChangeBySourceName(this.accesses[i].faculties,
              "facultyId",  null, "");
            valueChangeBySourceName(this.accesses[i].faculties,
              "facultyShortName",  null, "Все");
            valueChangeBySourceName(this.accesses[i].eduGroups,
              "eduGroupId",  null, "");
            valueChangeBySourceName(this.accesses[i].eduGroups,
              "groupName",  null, "Все");

            //if (!this.accesses[i].contingentTabsLevel || this.accesses[i].contingentTabsLevel.length == 0 )
              //this.accesses[i].contingentTabsLevel.push("");

            if (this.accesses[i].contingentTabsLevel && this.accesses[i].contingentTabsLevel.length !== 0
              && this.accesses[i].contingentTabsLevel.includes(null))
            {
              this.accesses[i].contingentTabsLevel = ['']
            }
          }
        }
        this.gridData = this.accesses;
      },
      error => {
        this.loading = false;
      });
  }

  //DictStudyForm
  public getAllStudyForm() {
    this.commondictService.getAllStudyForm()
      .subscribe(
        response => {
          this.studyForms = response;
          if (this.studyForms&&this.studyForms.length>1) {
            let item = new DictStudyForm();
            item.dictStudyFormExternalId = "";
            item.studyFormName = 'Все';
            item.dictStudyFormId = 0;
            this.studyForms.unshift(item);
          }
        }
      );
  }

  //DictTrainingLevels
  public getAllTrainingLevels() {
    this.commondictService.getAllTrainingLevel()
      .subscribe(response => {
        this.trainingLevels = response;
        if (this.trainingLevels&&this.trainingLevels.length>1) {
          let item = new TrainingLevel();
          item.dictTrainingLevelExternalId = "";
          item.trainingLevelName = 'Все';
          this.trainingLevels.unshift(item);
        }
      });
  }

  // Get persons
  public getPersons() {
    this.personService.getAllperson()
      .subscribe(
        response => {
          this.persons = response;
        }
      );
  }

  // Get faculties
  public getFaculties() {
    this.facultyService.getAllFaculty(3)
      .subscribe(
        response => {
          this.faculties = response;
          if (this.faculties&&this.faculties.length>1) {
            let item = new Faculty();
            item.facultyExternalId = "";
            item.filialId = "";
            item.name = 'Все';
            item.shortName = 'Все';
            item.facultyFullName = 'Все';
            this.faculties.unshift(item);
          }
        }
      );
  }

  // Get groups
  public async getEduGroups(faculties: any) {
    await lastValueFrom(this.eduGroupService.getEduGroupByFacultyUA(faculties)).then(response => this.eduGroups = response);
  }

  public getAllFilials() {
    this.dictFilialService.getAllfilial()
      .subscribe(
        response => {
          this.filials = response;
          if (this.filials&&this.filials.length>1) {
            let item = new DictFilial();
            item.dictFilialExternalId = "";
            item.filialName = 'Все';
            item.filialSName = 'Все';
            this.filials.unshift(item);
          }
        }
      );
  }

  public getList(dataItem: any, key: any) {
    return GridDisplayList(dataItem, key);
  }

  public getListTabsFromEnum(dataItem: any) {
    let string = "";
    if(dataItem.includes(string) || dataItem.includes(null)) {
      return 'Все';
    }
    for(let i=0; i < dataItem.length; i++) {
      string += this.contingentTabsList.find((x: any) => x.value == dataItem[i]).title + ', ';
    }
    return string.slice(0, -2);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Save data
  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup !== undefined) {
      if(this.formGroup.value.id === 0) {
        this.formGroup.value.id = null;
      }

      for(let i=0; i < this.dataSources.length; i++) {
        valueChange(this.formGroup.value[this.dataSources[i]], "", null)
      }

      this.userAccessService.saveAccess(this.formGroup.value)
        .subscribe(
          response => {
            this.getAccess();
            this.notificationService.showSuccess("Добавлено");
          },
          error => {
            this.notificationService.showError("Не удалось добавить запись");
          }
        );
    }
    this.closeEditor(this.grid);
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //#region Grid Events

  //Start Editing
  public async editHandler(args: EditEvent) {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      return;
    }

    this.trainingLevelsShow = this.trainingLevels;
    this.filialsShow = this.filials;
    this.facultiesShow = this.faculties;
    this.studyFormsShow = this.studyForms;
    await this.getEduGroups([]);
    this.eduGroupsShow = this.eduGroups;

    const targetKey: any[] = ['trainingLevelId', 'studyFormId', 'facultyId', 'eduGroupId', 'filialId'];

    for(let i = 0; i < this.dataSources.length; i++) {
      (this as any)[`${this.dataSources[i]}Edit`] = DisplayValuesInEditMode((args.dataItem[this.dataSources[i]]), targetKey[i]);
      // Фильтруем группы когда пришли факультеты
      if(`${this.dataSources[i]}` === 'faculties' && !(this as any)[`${this.dataSources[i]}Edit`].includes("")) {
        await this.getEduGroups((this as any)[`${this.dataSources[i]}Edit`]);
      }
    }

    this.closeEditor(args.sender);
    this.isLine = true;
    this.contingentTabsEdit = args.dataItem.contingentTabsLevel
    this.formGroup = formGroup(args.dataItem);
    this.editedRowIndex = args.rowIndex;
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  //Deleting an entry
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, `${dataItem.personName} из настроек доступа?`);
    this.opened = dialogResultHelper(dialog.result, this.userAccessService.deleteAccess(dataItem.id), this.notificationService);
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);
    this.trainingLevelsEdit = [];
    this.filialsEdit = [];
    this.facultiesEdit = [];
    this.studyFormsEdit = [];
    this.eduGroupsEdit = [];
    this.contingentTabsEdit = [];
    this.eduGroupsShow = [];
    this.eduGroups = [];

    this.formGroup = formGroup({
      id: 0,
      personId: '',
      personName: '',
      trainingLevels: '',
      filials: '',
      studyForms: '',
      eduGroups: '',
      faculties: '',
      accessLevel: 1,
      dictAccessLevel: 1,
      groupAccessLevel: 1,
      elderAccessLevel: false,
      userAccessLevel: false,
      localSignatoryAccessLevel: false,
      militaryCertificateAccessLevel: 0,
      achievementAccessLevel: false,
      constructorEduGroupNameAccessLevel: false,
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Cancel
  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  // Ивенты ячеек при редактировании
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    let element = (event as any).target;
    if (element.className == 'k-input-inner' && isChildOf(element, 'filter')) {
      this.filterData(element.value);
    }
  }

  public async onMultiSelectTagClick(items: any, sourceName: string) {
    (this as any)[`${sourceName}Show`] = items;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    let element = (event as any).target;
    if(isChildOf(element, 'trainingLevels')) {
      this.trainingLevelsShow = this.trainingLevels;
    }
    if(isChildOf(element, 'faculties')) {
      this.facultiesShow = this.filialsEdit.length > 0 && !this.filialsEdit.includes("") ?
        this.faculties.filter(x => x.filialId == "" || this.filialsEdit.includes(x.filialId?.toString())) :
        this.faculties;
    }
    if(isChildOf(element, 'studyForms')) {
      this.studyFormsShow = this.studyForms;
    }
    if(isChildOf(element, 'eduGroup')) {
      this.eduGroupsShow = this.eduGroups;
    }
    if(isChildOf(element, 'filials')) {
      this.filialsShow = this.filials;
    }
  }

  //#endregion

  clearData() {
    this.gridData = this.accesses;
    this.searchFIO = ''
  }

  filterData(dataItem: any) {
    this.gridData = this.accesses.filter((s) => s.personName.toLowerCase().includes(dataItem.toLowerCase()));
  }

  public async valueChange(value: any, editItem: any) {
    (this as any)[`${editItem}`] = arrayRewrite(value);
    // Редактирование факультета
    if(editItem === 'facultiesEdit') {
      // В режиме редактирования
      if(this.isInEditingMode && this.facultiesEdit.length > 0 && !this.facultiesEdit.includes("")) {
        await this.getEduGroups(this.facultiesEdit);
        this.eduGroupsShow = this.eduGroups;
        // Фильтруем выбранные группы при смене факультета
        this.eduGroupsEdit = this.eduGroups.filter((item) => this.eduGroupsEdit.includes(item.eduGroupExternalId))
            .map((item) => item.eduGroupExternalId);
      }
      else if(this.isInEditingMode && this.facultiesEdit.length === 0) {
        this.eduGroups = [];
        this.eduGroupsShow = [];
        this.eduGroupsEdit = [];
      }
      else if(this.isInEditingMode && this.facultiesEdit.includes("")) {
        await this.getEduGroups([]);
      }
    }
    if(editItem === 'filialsEdit') {
      if(this.isInEditingMode && this.filialsEdit.length > 0 && !this.filialsEdit.includes("")) {
        this.facultiesShow = this.faculties.filter((item) => item.filialId == "" || value.includes(item.filialId));
      }
    }
  }
}

const formGroup = (dataItem:
                     {
                       id?: any;
                       personId?: any;
                       personName?: any;
                       trainingLevels?: any;
                       filials?: any;
                       studyForms?: any;
                       eduGroups?: any;
                       faculties?: any;
                       accessLevel?: any;
                       dictAccessLevel?: any;
                       groupAccessLevel?: any;
                       contingentTabsLevel?: any;
                       elderAccessLevel?: boolean;
                       userAccessLevel?: boolean;
                       localSignatoryAccessLevel?: boolean;
                       militaryCertificateAccessLevel?: 0;
                       achievementAccessLevel?: boolean;
                       constructorEduGroupNameAccessLevel?: boolean;
                     }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    personId: new FormControl(dataItem.personId),
    trainingLevels: new FormControl(dataItem.trainingLevels, Validators.required),
    filials: new FormControl(dataItem.filials, Validators.required),
    studyForms: new FormControl(dataItem.studyForms, Validators.required),
    contingentTabsLevel: new FormControl(dataItem.contingentTabsLevel),
    //eduGroups: new FormControl(dataItem.eduGroups, Validators.required),
    eduGroups: new FormControl(['']),
    faculties: new FormControl(dataItem.faculties, Validators.required),
    accessLevel: new FormControl(dataItem.accessLevel, Validators.required),
    dictAccessLevel: new FormControl(dataItem.dictAccessLevel, Validators.required),
    groupAccessLevel: new FormControl(dataItem.groupAccessLevel, Validators.required),
    elderAccessLevel: new FormControl(dataItem.elderAccessLevel, Validators.required),
    userAccessLevel: new FormControl(dataItem.userAccessLevel, Validators.required),
    localSignatoryAccessLevel: new FormControl(dataItem.localSignatoryAccessLevel, Validators.required),
    militaryCertificateAccessLevel: new FormControl(dataItem.militaryCertificateAccessLevel, Validators.required),
    achievementAccessLevel: new FormControl(dataItem.achievementAccessLevel, Validators.required),
    constructorEduGroupNameAccessLevel: new FormControl(dataItem.constructorEduGroupNameAccessLevel, Validators.required),
  });
