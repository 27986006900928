<h5 class="achievements-title">Достижения</h5>
<div *ngIf="!editMode">
  <button kendoButton class="achievements-title" themeColor="success"
          iconClass="k-icon k-i-plus" *ngIf='addable && editable'
          (click)="addAchievement()">
    Добавить
  </button>
  <!--<div class="learning-text m-b15">
    Средний балл по последним двум семестрам
  </div>-->
  <div *ngFor="let achievement of filteredAchievements" >
    <h5 class="title m-b15">{{achievement.directionActivityName}}</h5>
    <div class="activity m-b15">
      <div>
        <p class="learning-text">Подача заявлений обучающимися: <span class="learning">
          {{ getDate(achievement.achievementPeriod.receiptionStartDate) }} - {{ getDate(achievement.achievementPeriod.receiptionEndDate)}}
        </span></p>
        <p class="learning-text">Утверждение портфолио в деканате (дирекции): <span class="learning">
          {{ getDate(achievement.achievementPeriod.checkingStartDate) }} - {{ getDate(achievement.achievementPeriod.checkingEndDate)}}
        </span></p>
        <p class="learning-text">Период действия нового рейтинга: <span class="learning">
          {{ getDate(achievement.achievementPeriod.pretendentStartDate) }} - {{ getDate(achievement.achievementPeriod.pretendentEndDate)}}
        </span></p>
      </div>
      <div>
        <p class="learning-text m-b15">Общий балл (балл в рейтинг ПГАС)</p>
        <div style="display: flex; justify-content: end;">
          <div *ngFor="let totalMark of achievement.totalMarks">
            <div class="totalMark" [style.background]=totalMark.color title={{totalMark.name}}>{{totalMark.mark}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="hide m-b15"
         (click)="hidePast(achievement.directionActivityId, achievement.hideAchievements)"
         *ngIf="achievement.hideAchievements !== undefined">
      {{ achievement.hideAchievements ? "Отобразить истекшие" : "Скрыть истекшие" }}
    </div>

    <kendo-grid class="m-b15"
                [data]="achievement.achievementList"
                (cellClick)="viewHandler($event)"
      >
      <kendo-grid-column field="status" title="Статус" [width]="130" headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
            <p class="leftAlignment"> {{dataItem.dictAchievementStatusName}} </p>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="name" title="Название" [width]="200" headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
            <p> {{dataItem.name}}  </p>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="type" title="Тип" [width]="130" headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
            <p class="leftAlignment"> {{dataItem.dictWorkTypeName}} </p>
        </ng-template>
      </kendo-grid-column>

      <!--<kendo-grid-column field="subtype" title="Подтип" [width]="130" headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
            <p class="leftAlignment"> {{dataItem.dictWorkTypeName}} </p>
        </ng-template>
      </kendo-grid-column>-->

      <kendo-grid-column field="mark" title="Балл" [width]="100" headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
            <p class="centerAlignment"> {{dataItem.mark}} </p>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column-group headerClass="gridHeader" title="Действует">
        <kendo-grid-column field="dateStart" title="с" [width]="120" headerClass="gridHeader">
          <ng-template kendoGridCellTemplate let-dataItem>
              <p> {{dataItem.dateStart | date:'dd.MM.yyyy'}} </p>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="dateEnd" title="до" [width]="120" headerClass="gridHeader">
          <ng-template kendoGridCellTemplate let-dataItem>
              <p> {{dataItem.dateEnd | date:'dd.MM.yyyy'}} </p>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column field="comment" title="Примечание" [width]="200" headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
            <p class="leftAlignment"> {{dataItem.comment}} </p>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="scan" title="Скан. копия" [width]="60" headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button kendoButton size="small" icon="download" *ngIf="dataItem.files && dataItem.files.length>0" (click)="downloadFile(dataItem.files[0])"></button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>

    <div class="footer m-b15">
      Дата заявления:
      <kendo-datepicker
        #applicationDate
        class="date m-l8"
        format="dd.MM.yyyy"
        [openOnFocus]="applicationDate"
      >
      </kendo-datepicker>

      <button kendoButton class="m-l15">Отправить в журнал претендентов ПГАС</button>
    </div>
  </div>
</div>

<!---Edit-->
<div class="container" *ngIf="editMode">
  <form novalidate class="k-form m-b15" [formGroup]="editForm">
    <h5 class="title m-b15">Редактирование работы</h5>
    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Статус</h2>
        <kendo-textbox [disabled]="true" class="w-400" formControlName="dictAchievementStatusName">
        </kendo-textbox>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Направление деятельности</h2>
        <kendo-dropdownlist class="w-400"
                            textField="name"
                            valueField="id"
                            required
                            formControlName="directionActivityId"
                            (valueChange)="valueChangeDirectionActivity($event)"
                            [data]="directionActivitiesByFilter"
                            [valuePrimitive]="true"
                            [disabled]="!addable">
        </kendo-dropdownlist>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Название работы/мероприятия/достижения</h2>
        <kendo-textbox formControlName="name"
                       required
                       class="w-400"
                       (valueChange)="formChanged()"
        ></kendo-textbox>
        <span class="red">*</span>
      </div>
      <kendo-formerror class="m-l400">Ошибка: Заполните необходимое поле</kendo-formerror>
    </kendo-formfield>

      <kendo-formfield>
        <div class="portfolioField">
          <h2 class="portfolioField__title">Тип работы</h2>
          <kendo-dropdowntree kendoDropDownTreeExpandable
                              class="w-400"
                              [data]="workTypesTree"
                              [dataItem]="selectedWorkTypesTree || {}"
                              [formControl]="$any(editForm).controls['dictWorkTypeId']"
                              textField="name"
                              valueField="id"
                              formControlName="dictWorkTypeId"
                              (valueChange)="valueChangeType($event)"
                              [fetchChildren]="children"
                              [hasChildren]="hasChildren"
                              [valuePrimitive]="true"
          >
          </kendo-dropdowntree>
        </div>
      </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Балл</h2>
        <kendo-textbox [disabled]="true"  class="w-400" formControlName="mark" [(ngModel)]="mark">
        </kendo-textbox>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Коэффициент</h2>
        <div kendoTooltip position="right" tooltipClass="tooltip" [tooltipWidth]="300" style="color:black !important">
          <kendo-numerictextbox title = "Если необходимо разделить баллы, укажите коэффициент здесь.
                                         Например, чтобы разделить баллы между 3 соавторами, напишите 0,33.
                                        Если ВУЗ не является организатором или участником мероприятия, укажите коэффициент 0,5
                                        (в части общественной, культурно-творческой и спортивной деятльности)."
                                class="w-400" required
                                formControlName="coefficient"
                                (valueChange)="onChangeCoefficient()"
                                [max]="1"
                                [min]="0"
                                [(ngModel)]="coefficient">
          </kendo-numerictextbox>
          <span class="red">*</span>
        </div>
      </div>
      <kendo-formerror class="m-l400">Ошибка: Заполните необходимое поле</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Балл с учетом коэффициента</h2>
        <kendo-textbox [disabled]="true"
                       class="w-400"
                       formControlName="markWithCoefficient"
                       [(ngModel)]="markWithCoefficient">
        </kendo-textbox>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Действует с</h2>
        <kendo-datepicker  class="w-400"
          #dateStart
          format="dd.MM.yyyy"
          [openOnFocus]="dateStart"
          formControlName="dateStart"
          (valueChange)="formChanged()"
          required
        >
        </kendo-datepicker>
        <span class="red">*</span>
      </div>
      <kendo-formerror class="m-l400">Ошибка: Заполните необходимое поле</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Действует до</h2>
        <kendo-datepicker  class="w-400"
          #dateEnd
          format="dd.MM.yyyy"
          [openOnFocus]="dateEnd"
          formControlName="dateEnd"
          (valueChange)="formChanged()"
          required
        >
        </kendo-datepicker>
        <span class="red">*</span>
      </div>
      <kendo-formerror class="m-l400">Ошибка: Заполните необходимое поле</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Примечание</h2>
        <kendo-textarea class="w-400" [rows]="3"
          formControlName="comment"
          (valueChange)="formChanged()"
        >
        </kendo-textarea>
      </div>
    </kendo-formfield>

    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Сканированная копия</h2>
        <div class="file-select">
          <kendo-fileselect style="width: 400px"
                            [restrictions]="restrictions"
                            [multiple]="false"
                            [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="fileUploads"
                            (select)="fileSelected()"
                            (remove)="fileRemoved()">
            <kendo-fileselect-messages
              invalidFileExtension="Недопустимый формат файла. Используйте только .pdf файлы"
              [invalidMaxFileSize]="maxFileSizeMessage">
            </kendo-fileselect-messages>
          </kendo-fileselect>
          <span class="k-display-flex link"
                *ngFor='let file of fileUploadedList'
                (click)="downloadFile(file)">
            <span class="k-icon k-i-file"></span>
            <h2 class="link">{{file.name}}</h2>
          </span>
        </div>
      </div>
    </kendo-formfield>
    <h2 class="warning" *ngIf="!fileUploaded">* Загрузите скан.копию для добавления нового достижения</h2>
  </form>
  <div >
    <button kendoButton class="bg-yellow" (click)="changeStatus(achievementStatusEnum.approved)">Утвердить</button>
    <button kendoButton class="bg-korall m-l15" (click)="changeStatus(achievementStatusEnum.rejected)">Отклонить</button>
    <button kendoButton class="m-l15" (click)="changeStatus(achievementStatusEnum.returned_for_revision)">На доработку</button>
    <button kendoButton class="saveButton m-l15" (click)="saveAchievement()" themeColor="primary">Сохранить</button>
    <button kendoButton class="m-l15" (click)="cancelEdit()">Отмена</button>
  </div>
</div>
<div kendoDialogContainer></div>
