import {Guid} from "guid-typescript";
import {CryptoArmOpenViewModel, CryptoArmWindow} from "../../models/crypto-arm/crypto-arm-open-view.model";

export function CryptoArmOpenViewURIFactory(window: CryptoArmWindow): string {
  const uri: string = 'cryptoarm://startView/';
  const cryptoArmOpenViewParams: CryptoArmOpenViewModel = {
    jsonrpc: '2.0',
    result : {
      uiView: window
    },
    id: Guid.create()
  }
  return encodeURI(uri + JSON.stringify(cryptoArmOpenViewParams));
}
