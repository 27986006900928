import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {DisplaySettingService} from "../../../services/support/display-setting.service";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DisplaySetting} from "../../../models/support/system-setting.model";

@Component({
  selector: 'app-support-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SupportSettingsComponent implements OnInit {

  public selected = 0;
  public displaySetting: DisplaySetting[] = [];
  public tabItems = [
    { title: "Настройки отображения", }
  ];

  constructor(
    private router: Router,
    private displaySettingService: DisplaySettingService,
    private notificationService: NotificationsService,
  ) { }

  ngOnInit(): void {
    this.getDisplaySystemSetting();
  }

  public applyHandlerSetting(){
    this.displaySettingService.EditDisplaySetting(this.displaySetting)
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Сохранено");
          this.closeHandler();
        },
        error: (error) => {
          this.notificationService.showError(error.error);
        }});
  }

  public closeHandler(){
    this.router.navigateByUrl(`/support`).then();
  }

  public getDisplaySystemSetting() {
    this.displaySettingService.GetAllDisplaySettings()
      .subscribe(
        response => {
          this.displaySetting = response;
        }
      );
  }

}
