import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GiaSettingModel } from '../../models/gia/giasettings/gia-setting.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GiaSettingService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.gia.settings}`;

  constructor(private http: HttpClient) {}

  public getSettings() {
    return this.http.get<GiaSettingModel[][]>(this.baseUrl).pipe(catchError(this.handleError));
  }

  public getTrainingLevelSettingsByCategory(category: number): Observable<(number | null)[]> {
    return this.http.get<(number | null)[]>(`${this.baseUrl}/GetTrainingLevelSettingsByCategory/${category}`).pipe(catchError(this.handleError));
  }

  public update(giaSettingModel: GiaSettingModel[][]): Observable<GiaSettingModel> {
    return this.http.put<GiaSettingModel>(this.baseUrl, giaSettingModel).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
