<kendo-expansionpanel [(expanded)]="isErrorsVisible"
  style="border: 0; outline: 0; box-shadow: none; background: none;">
  <ng-template kendoExpansionPanelTitleDirective>
    <label class="expander-label">{{isErrorsVisible ? 'Скрыть проверку ограничений' : 'Проверка ограничений' }}</label>
  </ng-template>
  <div class="k-mb-5">
    <a [routerLink]="'/education/dictstandardlimitation/' + educationPlanDetailsService.currentEducationStandard.educationStandardId"
      target="_blank">
      <button type="button" kendoButton>
        Ограничения стандарта
      </button>
    </a>
  </div>

  <label *ngFor="let error of summary.errorList; let index" style="color: red; display: block">{{error}}</label>
</kendo-expansionpanel>
<div class="k-mb-5">
  <label style="color: red">Найдено ошибок: {{summary.errorList.length}}</label>
</div>


<kendo-grid class="baseEducationPlanGrid" [loading]="isLoading" [data]="disciplines" [rowClass]="disciplinesClassCallback"
  [height]="disciplines.length ? 750 : 130" (cellClick)="onEditDiscipline($event)" [selectedKeys]="disciplineSelected"
  kendoGridSelectBy="id">
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <ng-template kendoGridNoRecordsTemplate>Нет записей для отображения</ng-template>
  <kendo-grid-column field="cipher" title="Шифр" [width]="125">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div [ngClass]="{'bigger-text': dataItem.level === 0, 'bold-text': dataItem.level < 2, 'k-text-left': true }">
        {{ dataItem.cipher }}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="name" title="Дисциплина" [width]="320" [style]="{ 'text-align': 'left' }">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span [ngClass]="{'bigger-text': dataItem.level === 0, 'bold-text': dataItem.level < 2, 'italic-text': dataItem.idOriginal !== null }">
        {{ dataItem.name }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="departmentName" title="Кафедра" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div class="k-display-flex k-justify-content-center">
        <button *ngIf="dataItem.level < 2 && ExistsDiscipline(dataItem.dictComponentId) && (educationPlanDetailsService.currentEducationPlan.status === 1 ||
        (editableFacultative&&dataItem.optionalMarks))&&(educationPlanDetailsService.currentEducationPlan.status !== 1 || editable)" kendoButton
          class="table-button" (click)="addDiscipline(dataItem)">Добавить</button>

      </div>

      <span *ngIf="dataItem.level >= 2">
        {{ dataItem.departmentName }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="semesterName" title="Семестр" [width]="90">
  </kendo-grid-column>
  <kendo-grid-column field="totalLaborIntensity" title="З.ед." class="bold-text" [width]="50">
  </kendo-grid-column>
  <kendo-grid-column field="totalHours" title="Часы" class="bold-text" [width]="70"></kendo-grid-column>

  <kendo-grid-column *ngFor="let typeWork of typeWorksItems" [field]="typeWork.field" [title]="typeWork.title"
    [width]="75">
  </kendo-grid-column>

  <kendo-grid-column field="hoursSelfWork" title="Сам. раб." [width]="80"></kendo-grid-column>
  <kendo-grid-column field="control" title="Контроль" [width]="90"></kendo-grid-column>
  <kendo-grid-column title="К освоению, з. ед." class="bold-text" [headerStyle]="{'white-space': 'pre-wrap'}" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>
        {{ (dataItem.totalLaborIntensity - dataItem.hoursCredited/36.0 > 0 ? dataItem.totalLaborIntensity - dataItem.hoursCredited/36.0 : 0) | number: '1.0-2' || ''}}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="hoursCredited" title="Зачтено" [width]="80"></kendo-grid-column>
</kendo-grid>

<h4 class="title k-mt-12 k-mb-5">Нагрузка на студента</h4>
<kendo-grid [data]="summary.studentSummary.rows">
  <ng-template kendoGridNoRecordsTemplate>Нет записей для отображения</ng-template>
  <kendo-grid-column [style]="{ 'text-align': 'left' }" field="name" title="Семестр" [width]="400"></kendo-grid-column>
  <kendo-grid-column *ngFor="let studentSummarySemester of studentSummarySemesters; let index" [title]="index"
    [width]="90">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>
        {{dataItem.semesters[index-1]?.value || ''}}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="total" title="Всего" [width]="90"></kendo-grid-column>
</kendo-grid>
<h4 class="title k-mt-12 k-mb-5">Объем образовательной программы</h4>
<kendo-grid [data]="summary.hoursSummary" [style.width.px]="750">
  <ng-template kendoGridNoRecordsTemplate>Нет записей для отображения</ng-template>
  <kendo-grid-column [style]="{ 'text-align': 'left' }" title="Показатель" [width]="500">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span [ngClass]="{'bold-text': dataItem.level === 1, 'k-pl-5': dataItem.level === 3 }">
        {{dataItem.name}}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="value" title="Величина" [width]="200">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span [ngClass]="{'bold-text': dataItem.level === 1}">
        {{dataItem.value}}
      </span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
