<contingent-header></contingent-header>
<h1>Категории приказов</h1>

<div class="selectContent" *ngIf="!opened">
  <dd style="display: flex; align-items: center">
    <span class="selectText">
      Филиал
    </span>
    <kendo-dropdownlist [data]="filials"
                        textField="filialSName"
                        valueField="dictFilialExternalId"
                        [valuePrimitive]="true"
                        name="filial"
                        (valueChange)="applyFilter()"
                        [(ngModel)]="secondFilter.dictFilialExternalId"
                        [defaultItem]="defaultItemFilial"
                        style="width: 400px;">
    </kendo-dropdownlist>
  </dd>
</div>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [kendoGridBinding]="orderCategoriesByFilter"
              [group]="group"
              (remove)="removeHandler($event)"
              [pageable]="true"  [pageSize]="pageSize"
              *ngIf="!editMode; else editForm"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true"
              [filterable]="true"
  >
    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <button kendoGridAddCommand size="small"
              [disabled]="secondFilter.dictFilialExternalId == null"
              themeColor="success"
              *ngIf="!isInEditingMode"
              icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()" size="small">
          Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup!.invalid"
                themeColor="primary"
                (click)="saveCurrent()" size="small">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictOrdersCategoryId" title="Id"> </kendo-grid-column> -->
    <kendo-grid-column field="categoryName"
                       title="Название категории приказа"
                       headerClass="gridHeader"
                       [width]="350">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox
          size="small"
          [formControl]="formGroup.get('categoryName')"
          [clearButton]="true"
        ></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.categoryName}}
                      </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <!--Study Level-->
    <kendo-grid-column field="dictTrainingLevelId" title="Название уровня подготовки" headerClass="gridHeader"
                       [width]="350"
                       [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ nameTrainingLevel(dataItem.dictTrainingLevelId)?.trainingLevelName }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-formGroup="formGroup">

        <kendo-combobox [data]="trainingLevels"
                        textField="trainingLevelName"
                        valueField="dictTrainingLevelExternalId"
                        [valuePrimitive]="true"
                        #trainingLevel
                        [openOnFocus]="trainingLevel"
                        [kendoDropDownFilter]="filterSettings"
                        [formControl]="formGroup.get('dictTrainingLevelId')">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown [filter]="filter" [data]="trainingLevels" textField="trainingLevelName" valueField="trainingLevelName">
        </filter-dropdown>
    </ng-template>
    </kendo-grid-column>


    <!--Order Types-->
    <kendo-grid-column field="dictOrderTypeId" title="Название подкатегории" headerClass="gridHeader"
                       [width]="350"
                       [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ nameOrderTypes(dataItem.dictOrderTypeId) }}
        </span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group="group">
        <span>
          {{ group.value ? "Категория приказа":""}} : {{nameOrderTypes(group.value)}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-formGroup="formGroup">
        <kendo-combobox [data]="visibleOrderTypes"
                        textField="typeName"
                        valueField="dictOrderTypeExternalId"
                        [valuePrimitive]="true"
                        #citizenshipId
                        [openOnFocus]="citizenshipId"
                        [kendoDropDownFilter]="filterSettings"
                        [formControl]="formGroup.get('dictOrderTypeId')">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="enums" title="Служебная категория"
                       headerClass="gridHeader"
                       [width]="200"
                       [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ nameEnum(dataItem.enums) }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-multiselect [data]="listOrderEnums"
                           kendoMultiSelectSummaryTag
                           textField="name"
                           valueField="value"
                           [autoClose]="false"
                           [valuePrimitive]="true"
                           [formControl]="formGroup.get('enums')">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span>{{ dataItems.length }} выбрано</span>
          </ng-template>
        </kendo-multiselect>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="actual"
                       editor="boolean"
                       title="Актуально"
                       headerClass="gridHeader"
                       [width]="150"
                       [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{(dataItem.actual == true ? "Да" : "Нет")}}
            </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <span class="alignCells">
                <input type="checkbox" kendoCheckBox [formControl]="formGroup.get('actual')"/>
            </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="70" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand
                  themeColor="secondary"
                  icon="delete"
                  fillMode="flat"
                  size="none" class="custom-size"
                  rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate>
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="5"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-info></kendo-pager-info>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>

  </kendo-grid>

</div>


<ng-template #editForm >
  <form class="k-form" [formGroup]="form">

    <dl class="about">
      <!--Название категории-->
      <kendo-label [for]="name" text="Название подкатегории"></kendo-label>
      <dd>
        <kendo-formfield>
          <kendo-textbox
            class="field"
            [clearButton]="true"
            placeholder="Название подкатегории"
            formControlName="categoryName"
            #name
            required
          ></kendo-textbox>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>

      <kendo-label [for]="trainingLevel" text="Уровни подготовки"></kendo-label>
      <dd>
        <kendo-formfield>
          <kendo-multiselect
            [data]="trainingLevels"
            class="field"
            kendoMultiSelectSummaryTag
            placeholder="Уровни подготовки"
            #trainingLevel
            [checkboxes]="true"
            textField="trainingLevelName"
            valueField="dictTrainingLevelExternalId"
            [valuePrimitive]="true"
            formControlName="dictTrainingLevelId"
            required>
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span></span>
              {{ dataItems.length }} выбрано
            </ng-template>
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              {{ nameTrainingLevel(dataItem.dictTrainingLevelExternalId)?.trainingLevelName }}
            </ng-template>
          </kendo-multiselect>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>

      <kendo-label [for]="dictOrdersCategoryId" text="Категория приказа"></kendo-label>
      <dd>
        <kendo-formfield>
          <kendo-combobox [data]="visibleOrderTypes"
                          class="field"
                          textField="typeName"
                          valueField="dictOrderTypeExternalId"
                          #dictOrdersCategoryId
                          [valuePrimitive]="true"
                          #comboboxComponent
                          [openOnFocus]="comboboxComponent"
                          [kendoDropDownFilter]="filterSettings"
                          formControlName="dictOrderTypeId"
                          required>
          </kendo-combobox>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>

      <kendo-label [for]="enums" text="Служебная категория"></kendo-label>
      <dd>
        <kendo-formfield>
          <kendo-multiselect [data]="listOrderEnums"
                             class="field"
                             kendoMultiSelectSummaryTag
                             textField="name"
                             valueField="value"
                             [autoClose]="false"
                             [valuePrimitive]="true"
                             #enums
                             formControlName="enums">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span>{{ dataItems.length }} выбрано</span>
            </ng-template>
          </kendo-multiselect>
        </kendo-formfield>
      </dd>
    </dl>
  </form>
  <div class="editModeButtons">

    <button kendoButton
            size="small"
            (click)="closeAddForm()">
      Отмена
    </button>

    <button kendoButton
            size="small"
            themeColor="primary"
            [disabled]="!form.valid"
            (click)="onSave()">
      Сохранить
    </button>
  </div>
</ng-template>
<div kendoDialogContainer></div>
