<oferta-settings></oferta-settings>

<h1>Настройки присоединения к положениям</h1>

<div class="grid-container">
  <div>
    <form class="k-form" [formGroup]="regulationForm">
      <dl class="edit-form">
        <div class="content-row">
          <kendo-label text="Тип положения"></kendo-label>
          <dd>
            <kendo-dropdownlist [data]="regulationTypes"
                                textField="name"
                                valueField="id"
                                [valuePrimitive]="true"
                                (valueChange)="onChangeSignatureType($event)"
            >
            </kendo-dropdownlist>
          </dd>
        </div>

        <div class="content-row" *ngIf="selectRegulationType">
          <kendo-label text="Использование"></kendo-label>
          <dd>
            <kendo-dropdownlist [data]="simpleModules"
                                textField="name"
                                valueField="value"
                                [valuePrimitive]="true"
                                (valueChange)="onChangeSimpleModuleEnum($event)"
            >
            </kendo-dropdownlist>
          </dd>
        </div>

        <div class="content-row" *ngIf="settingsVis && isReinforced">
          <kendo-label text="Модули"></kendo-label>
          <dd>
            <kendo-formfield class="formfield">
              <kendo-multiselect [data]="availableModules"
                                 kendoMultiSelectSummaryTag
                                 textField="serviceTextName"
                                 valueField="id"
                                 [autoClose]="false"
                                 [checkboxes]="true"
                                 [valuePrimitive]="true"
                                 formControlName="moduleIds"
                                 [disabled]="!editable">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span>{{ dataItems.length }} выбрано</span>
                </ng-template>
              </kendo-multiselect>
            </kendo-formfield>
          </dd>
          <kendo-icon kendoTooltip
                      [tooltipWidth]="320"
                      [title]="tooltip"
                      name="info"
                      tooltipClass="mfc-tooltip"
                      position="right"
                      size="medium">
          </kendo-icon>
        </div>

        <div class="content-row" *ngIf="settingsVis">
          <kendo-label text="Наименование положения"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-textarea [rows]="4"
                              formControlName="regulationName"
                              [disabled]="!editable"
              >
              </kendo-textarea>
              <span class="required">*</span>
            </kendo-formfield>
            <kendo-formerror *ngIf="regulationForm.controls?.['regulationName']?.errors?.['required']">Ошибка: Заполните необходимое поле</kendo-formerror>
          </dd>
        </div>

        <div class="content-row" *ngIf="settingsVis">
          <kendo-label text="Файл положения"></kendo-label>
          <dd>
            <kendo-formfield class="formfield">
              <div class="file-container">
                <kendo-fileselect class="full-width-fileselect"
                                  formControlName='regulationFile'
                                  (remove)="removeFile('regulationFileName')"
                                  [multiple]="false"
                                  [showFileList]="true"
                                  [restrictions]="restrictions"
                                  [accept]="allowedExtensions"
                                  [disabled]="!editable"
                >
                  <kendo-fileselect-messages
                    invalidFileExtension="Выбранный файл должен быть в формате {{ allowedExtensions }}">
                  </kendo-fileselect-messages>
                </kendo-fileselect>
                <span class="required">*</span>
              </div>
            </kendo-formfield>
            <a *ngIf="regulationForm?.value.regulationFileName" class="link"
               (click)="getRegulationFile(regulation?.regulationFileDto)">
              {{ regulationForm?.value.regulationFileName }}
              <span (click)="removeFile('regulationFileName')" class="k-icon k-i-x" *ngIf="editable"></span>
            </a>
          </dd>
        </div>

        <div class="content-row" *ngIf="settingsVis">
          <kendo-label text="Текст уведомления о присоединении к положению"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-textarea [rows]="8"
                              formControlName="notificationText"
                              [disabled]="!editable"
              >
              </kendo-textarea>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row" *ngIf="settingsVis && isPerson">
          <kendo-label text="Наименование заявления о присоединении к положению"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-textarea [rows]="3"
                              formControlName="statementText"
              >
              </kendo-textarea>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row" *ngIf="settingsVis && isPerson">
          <kendo-label text="Файл заявления о присоединении к положению"></kendo-label>
          <dd>
            <kendo-formfield class="formfield">
              <kendo-fileselect formControlName='statementFile'
                                (remove)="removeFile('statementFileName')"
                                [multiple]="false"
                                [showFileList]="true"
                                [restrictions]="restrictions"
                                [accept]="allowedExtensions"
                                [disabled]="!editable"
              >
                <kendo-fileselect-messages
                  invalidFileExtension="Выбранный файл должен быть в формате {{ allowedExtensions }}">
                </kendo-fileselect-messages>
              </kendo-fileselect>
            </kendo-formfield>
            <a *ngIf="regulationForm?.value.statementFileName" class="link"
               (click)="getRegulationFile(regulation?.statementFileDto)">
              {{ regulationForm?.value?.statementFileName }}
              <span (click)="removeFile('statementFileName')" class="k-icon k-i-x" *ngIf="editable"></span>
            </a>
          </dd>
        </div>

        <div class="content-row" *ngIf="settingsVis && isPerson">
          <kendo-label text="Текст уведомления о блокировке"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-textarea [rows]="7"
                              formControlName="banText"
                              [disabled]="!editable"
              >
              </kendo-textarea>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row" *ngIf="settingsVis && isPerson">
          <kendo-label text="Количество дней до блокировки"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-numerictextbox formControlName="daysUntilBlocking"
                                    [decimals]="0"
                                    [min]="1"
                                    format="n0"
                                    [value]="regulation?.daysUntilBlocking!"
                                    [disabled]="!editable"
              >
              </kendo-numerictextbox>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row" *ngIf="settingsVis && isPerson && editable">
          <kendo-label text="Пересчитать для уже присоединившихся к положению пользователей"></kendo-label>
          <dd class="center">
            <kendo-formfield>
              <input type="checkbox" kendoCheckBox formControlName="recalculateDaysUntilBlocking"/>
            </kendo-formfield>
          </dd>
        </div>

      </dl>

      <div class="buttons" *ngIf="settingsVis && editable">
        <button kendoButton
                size="small"
                (click)="getRegulation()"
                [disabled]="!settingsVis"
        >
          Отмена
        </button>
        <button kendoButton
                class="save-button"
                size="small"
                themeColor="primary"
                (click)="saveRegulation()"
                [disabled]="!settingsVis"
        >
          Сохранить
        </button>
      </div>

    </form>
  </div>
</div>
