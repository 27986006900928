import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, Observable} from "rxjs";
import {
  DictTypeValueFilterEduGroupName
} from "../../models/constructor-edu-group-name/dict-type-value-filter-edu-group-name";
import {handleError} from "../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class DictTypeValueFilterEduGroupNameService {
  baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.constructorEduGroupNames.dictTypeValueFilterEduGroupName}`;

  constructor(private http: HttpClient) { }

  public getListDictTypeValueFilterEduGroupName(): Observable<DictTypeValueFilterEduGroupName[]>{
    return this.http.get<DictTypeValueFilterEduGroupName[]>(`${this.baseUrl}/GetListDictTypeValueFilterEduGroupName`)
      .pipe(
        catchError(handleError));
  }
}
