import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { StudyLevel } from '../../models/contingent/studylevel.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DictTrainingLevel } from 'src/app/models/contingent/dicttraininglevel.model';
import {DictStudyForm, DictStudyFormName} from 'src/app/models/contingent/dictstudyform.model';
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CommondictService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.commondict}`;

  constructor(private http: HttpClient) { }

  //Get all StudyLevel
  public getAllStudyLevel(): Observable<StudyLevel[]> {
    return this.http.get<StudyLevel[]>(this.baseUrl  + '/getalldictstudylevel');
  }

  //Get all StudyLevel
  public getAllTrainingLevel(): Observable<DictTrainingLevel[]> {
    return this.http.get<DictTrainingLevel[]>(this.baseUrl  + '/getalldicttraininglevel');
  }

  //Get all StudyFrom
  public getAllStudyForm(): Observable<DictStudyForm[]> {
    return this.http.get<DictStudyForm[]>(this.baseUrl + '/getalldictstudyform');
  }

  getNamesDictStudyForm(): Observable<DictStudyFormName[]> {
    return this.http.get<DictStudyFormName[]>(this.baseUrl + '/GetNamesDictStudyForm')
      .pipe(catchError(this.handleError));
  }

  updateDictStudyForm(request: DictStudyForm): Observable<DictStudyForm> {
    return this.http.put<DictStudyForm>(this.baseUrl + '/UpdateDictStudyForm/' + request.dictStudyFormExternalId, request)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}
