import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, Observable, throwError} from "rxjs";
import {Guid} from "guid-typescript";
import {
  LocalSignatoryAdd, LocalSignatoryCertificate,
  LocalSignatoryRead,
  LocalSignatoryUpdate
} from "../../models/contingent/localsignatory.model";
import {MilitaryCertificateTypes} from "../../models/contingent/enums/military-certificate-types.enum";

@Injectable({
  providedIn: "root"
})
export class LocalSignatoryService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.contingentPath.localSignatory}`;

  constructor(private http: HttpClient) {
  }

  private getMilitaryFormNumber(type: MilitaryCertificateTypes) {
    switch (type) {
      case MilitaryCertificateTypes.appendix2:
        return 'Two';
      case MilitaryCertificateTypes.appendix4:
        return 'Four';
      case MilitaryCertificateTypes.appendix5:
        return 'Five';
      default:
        return '';
    }
  }

  public get(): Observable<LocalSignatoryRead[]> {
    return this.http.get<LocalSignatoryRead[]>(this.baseUrl);
  }

  public getCertificateSigners(studentId?: Guid | string): Observable<LocalSignatoryCertificate[]> {
    return this.http.get<LocalSignatoryCertificate[]>(this.baseUrl + '/GetCertificateLocalSignatory/' + studentId);
  }

  public getMilitaryFormSignatory(
    type: MilitaryCertificateTypes,
    studentId?: Guid | string): Observable<LocalSignatoryCertificate[]> {
    return this.http.get<LocalSignatoryCertificate[]>(
      `${this.baseUrl}/GetMilitaryForm${this.getMilitaryFormNumber(type)}Signatory/${studentId}`);
  }
  public getMassMilitaryTwoFormSignatory(data: Guid[]): Observable<LocalSignatoryCertificate[]> {
    return this.http.post<LocalSignatoryCertificate[]>(
      `${this.baseUrl}/GetMassMilitaryFormTwoSignatory`, data);
  }

  public add(data: LocalSignatoryAdd): Observable<Guid | string> {
    return this.http.post<Guid | string>(this.baseUrl, data)
      .pipe(catchError(this.handleError));
  }

  public update(data: LocalSignatoryUpdate): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl, data)
      .pipe(catchError(this.handleError));
  }

  public delete(signatoryId: Guid | string): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + '/' + signatoryId)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
