import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {
  AddEvent,
  EditEvent,
  GridComponent,
  RemoveEvent
} from "@progress/kendo-angular-grid";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {getData$} from "../../../../environments/environment";
import {dialogResultHelper, openRemoveDialog} from "../../../helpers/dialogHelper";
import {isChildOf} from "../../../helpers/elementRef-helper";
import {PersonService} from "../../../services/contingent/person.service";
import {Person} from "../../../models/contingent/person.model";
import {DictStudyForm} from "../../../models/contingent/dictstudyform.model";
import {TrainingLevel} from "../../../models/dicts/traininglevel.model";
import {Faculty} from "../../../models/contingent/departmentname.model";
import {EduGroupua} from "../../../models/contingent/edugroup.model";
import {SortDescriptor} from "@progress/kendo-data-query";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {checkRole} from "../../../helpers/token/role-check";
import {Role} from "../../../models/useraccess/role";
import {CardsUserAccess} from "../../../models/useraccess/cards/cardsuseraccess.model";
import {CardsUserAccessService} from "../../../services/useraccess/cards-user-access.service";
import {boolOptions, dropdownOptions } from 'src/app/models/useraccess/options';
import {CardsUserAccessLevelEnum} from "../../../models/useraccess/cards/enums/cards-user-access-level.enum";

@Component({
  selector: 'app-cards-access',
  templateUrl: './cards-access.component.html',
  styleUrls: ['./cards-access.component.scss']
})
export class CardsAccessComponent implements OnInit {
  public isAdmin: boolean = false;
  public virtual = {
    itemHeight: 28,
  };

  public boolOptions = boolOptions;
  public dropdownOptions = dropdownOptions;

  public studyForms: DictStudyForm[] = [];
  public trainingLevels: TrainingLevel[] = [];
  public faculties: Faculty[] = [];
  public eduGroups: EduGroupua[] = [];
  public persons: Person[] = [];
  public accesses: CardsUserAccess[] = [];
  public access: CardsUserAccess = {
    id: '',
    personId: '',
    personName: '',
    accessLevel: 0,
    accessLevelName: '',
    accessSettings: false,
    accessLevelSalaryProject: 0,
    accessLevelSalaryProjectName: '',
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public formGroup?: FormGroup;

  @ViewChild(GridComponent) private grid!: GridComponent;

  @Input() searchFIO: string = '';

  public sort: SortDescriptor[] = [{ field: "personName", dir: "asc" }];

  private editedRowIndex?: number;
  private isNew = false;
  private isLine = false;
  public opened = false;
  public loading = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public gridData: CardsUserAccess[] = [];

  private cardsUserAccess!: CardsUserAccess;

  constructor(private dialogService: DialogService,
              private personService: PersonService,
              private userAccessCardsService: CardsUserAccessService,
              private notificationService: NotificationsService,
              private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService
  ) {
    this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
  }

  async ngOnInit() {
    getData$.subscribe(() => { this.getAccess();})
    this.getPersons();
    this.getUserAccess();
  }

  public showBtnAdd(): boolean {
    if (this.isInEditingMode) {
      return false;
    }

    return this.isAdmin || this.cardsUserAccess.accessSettings;
  }

  private getUserAccess() {
    this.userAccessCardsService.getAccessLevel()
        .subscribe(
            response => {
              this.cardsUserAccess = response;
            }
        )
  }

  // Get access
  public getAccess() {
    this.loading = true;
    this.userAccessCardsService.getAccess().subscribe(response => {
          this.loading = false;
          this.accesses = response;
          this.gridData = this.accesses;
        },
        error => {
          this.notificationService.showError("Не удалось загрузить записи прав доступа");
        });
  }

  // Get persons
  public getPersons() {
    this.personService.getAllperson()
        .subscribe(
            response => {
              this.persons = response;
            }
        );
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Save data
  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup !== undefined) {
      this.userAccessCardsService.saveAccess(this.formGroup.value)
          .subscribe(
              response => {
                this.getAccess();
                this.notificationService.showSuccess("Добавлено");
              },
              error => {
                this.notificationService.showError("Не удалось добавить запись");
              }
          );
    }
    this.closeEditor(this.grid);
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //#region Grid Events

  //Start Editing
  public async editHandler(args: EditEvent) {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      return;
    }

    this.closeEditor(args.sender);
    this.isLine = true;
    this.formGroup = formGroup(args.dataItem);
    this.editedRowIndex = args.rowIndex;
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  //Deleting an entry
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, `${dataItem.personName} из настроек доступа?`);
    this.opened = dialogResultHelper(dialog.result, this.userAccessCardsService.deleteAccess(dataItem.id), this.notificationService);
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      id: null,
      personId: '',
      personName: '',
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Cancel
  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  // Ивенты ячеек при редактировании
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    let element = (event as any).target;
    if (element.className == 'k-input-inner' && isChildOf(element, 'filter')) {
      this.filterData(element.value);
    }
  }

  //#endregion

  clearData() {
    this.gridData = this.accesses;
    this.searchFIO = ''
  }

  filterData(dataItem: string) {
    this.gridData = this.accesses.filter((s) => s.personName.toLowerCase().includes(dataItem.toLowerCase()));
  }
}

const formGroup = (dataItem:
                       {
                         id?: any;
                         personId?: any;
                         personName?: any;
                         accessLevel?: any;
                         accessSettings?: any;
                         accessLevelSalaryProject?: any;
                         accessLevelSalaryProjectName?: any;
                       }) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      personId: new FormControl(dataItem.personId),
      accessLevel: new FormControl(dataItem.accessLevel, Validators.required),
      accessSettings: new FormControl(dataItem.accessSettings, Validators.required),
      accessLevelSalaryProject: new FormControl(dataItem.accessLevelSalaryProject, Validators.required),
    });
