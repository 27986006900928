import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, firstValueFrom, Observable} from "rxjs";
import {UserSignatureBrief} from "../../models/oferta/usersignature/userSignatureBrief.model";
import {UserTypeEnum} from "../../models/oferta/usersignature/userType.model";
import {UserSignature} from "../../models/oferta/usersignature/userSignature.model";
import {UserSignaturesCommand} from "../../models/oferta/usersignature/command/UserSignaturesCommand";
import {handleError} from "../../helpers/errorHandle-helper";
import {JoinPersonCommand} from "../../models/oferta/usersignature/command/joinPersonCommand";
import {UserSignatureInfo} from "../../models/oferta/usersignature/userSignatureInfo.model";

@Injectable({
  providedIn: 'root'
})
export class UserSignatureService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.oferta.userSignature}`

  constructor(private http: HttpClient) { }

  getAllUserSignatures(): Observable<UserSignatureBrief[]> {
    return this.http.get<UserSignatureBrief[]>(this.baseUrl + '/GetAllUserSignatures')
  }

  getUserTypeCategories(): Observable<UserTypeEnum[]> {
    return this.http.get<UserTypeEnum[]>(this.baseUrl + '/GetUserTypeCategories')
  }

  getUserSignatures(query: HttpParams): Observable<UserSignature[]> {
    return this.http.get<UserSignature[]>(this.baseUrl + '/GetUserSignatures/', {params: query})
  }

  getAllUserSignaturesInfo(personId: string): Observable<UserSignatureInfo[]> {
    return this.http.get<UserSignatureInfo[]>(this.baseUrl + '/GetAllUserSignatures/' + personId);
  }

  getPersonRegulation(query: HttpParams): Observable<UserSignatureInfo> {
    return this.http.get<UserSignatureInfo>(this.baseUrl + '/GetPersonRegulation/', {params: query})
  }

  personIsBlocked(query: HttpParams): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + '/PersonIsBlocked/', {params: query})
      .pipe(catchError(handleError));
  }

  personIsJoinedReinforcedRegulation(query: HttpParams): Promise<boolean> {
    return firstValueFrom(this.http.get<boolean>(this.baseUrl + '/PersonIsJoinedReinforcedRegulation/', { params: query }));
  }

  personHasRightsForReinforcedModule(query: HttpParams): Promise<boolean> {
    return firstValueFrom(this.http.get<boolean>(this.baseUrl + '/PersonHasRightsForReinforcedModule/', { params: query }));
  }

  editUserSignature(userSignatureCommand: UserSignaturesCommand) {
    return this.http.put(this.baseUrl + '/EditUserSignature/', userSignatureCommand);
  }

  personHasSignature(query: HttpParams): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + '/PersonHasUserSignature', {params: query});
  }

  joinPersonToRegulation(command: JoinPersonCommand) {
    return this.http.post(this.baseUrl + '/JoinPerson', command)
  }
}
