import {Guid} from "guid-typescript";
import {BudgetTypeEnum} from "./enums/budget-type.enum";

export class Budget {
  public dictStudentBudgetId!: number;
  public dictStudentBudgetExternalId?: Guid | string | null;
  public studentBudgetName!: string;
  public studentBudgetSName?: string;
  public isBudget!: boolean;
  public order?: number;
  public budgetType?: BudgetTypeEnum;
  public filialId!: string;
}
