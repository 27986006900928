<h1>Настройки</h1>
<contingent-header></contingent-header>

<div class="content-container">

  <div class="title">
    <h3>Выбор полей для отображения</h3>
  </div>

  <!--Персональные данные-->
  <div class="content">
    <h2>Персональные данные</h2>
    <div class="chechbox-box">
      <!--ФИО
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.fio"/>
        <kendo-label  text="ФИО"></kendo-label>
      </div>-->
      <div class="child">
        <!--Пол-->
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.citizenship" />
        <kendo-label text="Гражданство"></kendo-label>
      </div>
      <!--Гражданство-->
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.regRegion"/>
        <kendo-label text="Регион регистрации"></kendo-label>
      </div>
      <!--Возраст-->
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.health"/>
        <kendo-label text="Группа здоровья"></kendo-label>
      </div>
      <div class="child">
        <!--Пол-->
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.agedate" />
        <kendo-label text="Дата рождения"></kendo-label>
      </div>
      <!--ФИО-->
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.isMale"/>
        <kendo-label  text="Пол"></kendo-label>
      </div>
      <div class="child">
        <!--Пол-->
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.age" />
        <kendo-label text="Возраст"></kendo-label>
      </div>
      <!--Гражданство-->
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.militaryCategory"/>
        <kendo-label text="Категория воинского учета"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.militaryCommissariat"/>
        <kendo-label text="Военкомат"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.benefit"/>
        <kendo-label text="Льготы"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.graduateYear"/>
        <kendo-label text="Год получения предыдущего образования"></kendo-label>
      </div>
      <div class="child">
      </div>
      <div class="child">
      </div>
    </div>
  </div>

  <!--Учеба-->
  <div class="content">
    <h2>Учеба</h2>
    <!--1 строка-->
    <div class="chechbox-box">
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.institution"/>
        <kendo-label  text="Наименование предыдущей учебной организации"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.thisInstitution"/>
        <kendo-label  text="Предыдущее образование получено в этом учебном заведении"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.secondEducation"/>
        <kendo-label  text="Получает второе высшее"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.filial"/>
        <kendo-label  text="Филиал"></kendo-label>
      </div>
      <!--Уровень подготовки-->
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.trainingLevel"/>
        <kendo-label  text="Уровень подготовки"></kendo-label>
      </div>
      <div class="child">
        <!--Форма обучения-->
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.studyForm"/>
        <kendo-label text="Форма обучения"></kendo-label>
      </div>
      <!--Направление подготовки-->
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.educationWay"/>
        <kendo-label text="Направление подготовки"></kendo-label>
      </div>
      <!--Год выпуска-->
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.finishYear"/>
        <kendo-label text="Год выпуска"></kendo-label>
      </div>
      <!--2 строка-->
      <!--Академический статус-->
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.academicState"/>
        <kendo-label  text="Академический статус"></kendo-label>
      </div>
      <div class="child">
        <!--Курс-->
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.course"/>
        <kendo-label text="Курс"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.educationProgram"/>
        <kendo-label text="Профиль (программа)"></kendo-label>
      </div>
      <!--Год начала обучения-->
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.startYear"/>
        <kendo-label text="Год начала обучения"></kendo-label>
      </div>
      <!--3 строка-->
      <!--Институт/факультет-->
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.faculty"/>
        <kendo-label  text="Институт/факультет"></kendo-label>
      </div>
      <div class="child">
        <!--Группа-->
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.eduGroup"/>
        <kendo-label text="Группа"></kendo-label>
      </div>
      <!--Источник финансирования-->
      <div class="child">
        <input type="checkbox" kendoCheckBox  [(ngModel)]="fmodel.isBudget"/>
        <kendo-label text="Источник финансирования"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox  [(ngModel)]="fmodel.budgetCategory"/>
        <kendo-label text="Категория финансирования"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox  [(ngModel)]="fmodel.budgetSubCategory"/>
        <kendo-label text="Подкатегория финансирования"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox  [(ngModel)]="fmodel.employer"/>
        <kendo-label text="Заказчики целевого обучения и работодатели"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox  [(ngModel)]="fmodel.departments"/>
        <kendo-label text="Кафедра"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox  [(ngModel)]="fmodel.scientificPerson"/>
        <kendo-label text="Научный руководитель (куратор)"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox  [(ngModel)]="fmodel.dissertationDate"/>
        <kendo-label text="Дата защиты диссертации"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox  [(ngModel)]="fmodel.elder"/>
        <kendo-label text="Староста"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox  [(ngModel)]="fmodel.employed"/>
        <kendo-label text="Трудоустроен к заказчику целевого обучения"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox  [(ngModel)]="fmodel.map"/>
        <kendo-label text="Карты"></kendo-label>
      </div>
    </div>
  </div>

  <!--Прочее-->
  <div class="content">
    <h2>Прочее</h2>
    <div class="chechbox-box">
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.orderType"/>
        <kendo-label  text="Тип категории приказа"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.orderCategory"/>
        <kendo-label text="Категория приказа"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.hasOrder"/>
        <kendo-label text="Наличие приказа"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.orderNumber"/>
        <kendo-label text="Номер приказа"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.orderDate"/>
        <kendo-label text="Дата приказа"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.orderStartDate"/>
        <kendo-label text="Начало действия приказа"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.approveVAKDate"/>
        <kendo-label  text="Дата утверждения ВАК"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.isWork"/>
        <kendo-label  text="Ведет трудовую деятельность"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.post"/>
        <kendo-label  text="Должность"></kendo-label>
      </div>

      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.dictAchievements"/>
        <kendo-label  text="Категория достижений"></kendo-label>
      </div>
      <div class="child">
        <input type="checkbox" kendoCheckBox [(ngModel)]="fmodel.hasAchievements"/>
        <kendo-label  text="Наличие достижений"></kendo-label>
      </div>
      <div class="child">
      </div>
      <div class="child">
      </div>
    </div>
  </div>

  <!--Buttons-->
  <div class="functionButtons editModeButtons">
    <button kendoButton
            size="small"
            (click)="closeHandler()">
      Закрыть
    </button>
    <button kendoButton
            size="small"
            themeColor="primary"
            (click)="applyHandler()"
            [routerLink]="['/contingent']"
            [state]="{apply}">
      Применить
    </button>
  </div>
</div>


<div class="content-container">
  <div class="title">
    <h3>Настройки таблицы</h3>
  </div>

  <div class="content-listbox">
    <!--Поля для отображения-->
    <kendo-listbox
      kendoListBoxDataBinding
      [data]="hiddenColumns"
      size = 'small'
      [connectedWith]="list"
      [toolbar]="toolbarSettingsFirst"
      (actionClick)="listBoxChange($event)">
    </kendo-listbox>
    <kendo-listbox
      #list [data]="visibleColumns"
      [toolbar]="false"
      size = 'small'
      style="margin-left: 12px">
    </kendo-listbox>
  </div>
</div>
