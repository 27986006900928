import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { EducationPlanDetailsService } from "../../../../services/education/EducationPlan/education-plan-details.service";
import { EducationPlanDetailsModels } from "../../../../models/education/education-plan-details-model";
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import {EducationUserAccessService} from "../../../../services/useraccess/education-user-access.service";
import {lastValueFrom} from "rxjs";
import {Location} from "@angular/common";
import { DateToString } from '../../../../helpers/date-helper';

@Component({
  selector: 'education-plan-details',
  templateUrl: './education-plan-details.component.html',
  styleUrls: ['./education-plan-details.component.scss']
})
export class EducationPlanDetailsComponent implements OnInit {
  public eduProgramId: string = '';
  public educationPlanId?: string;
  public eduPlanEdit: boolean = false;
  public detailsExtended: boolean = true;
  public isExporting = false;

  @ViewChild("dropdownlist") public dropdownlist: any;
  public listenerFn: any;
  public isModifyShedule: boolean = false;

  constructor(
    public activateRoute: ActivatedRoute,
    private router: Router,
    public educationPlanDetailsService: EducationPlanDetailsService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService:EducationUserAccessService,
    private location: Location,
    private renderer: Renderer2,
  ) {
    activateRoute.params.subscribe(params => {
      this.eduProgramId = params['education_program_id'] || this.educationPlanDetailsService.currentEducationProgram.educationProgramId;
      this.educationPlanId = params['education_plan_id'];
    });

  }

  ngOnInit(): void {
    this.router.navigate([this.eduProgramId
      ? (`/education/${this.eduProgramId}/plans` +
        (this.educationPlanId
        ? `/plan/${this.educationPlanId}`
        : ''))
      : '/education']);
    this.eduProgramId && this.educationPlanDetailsService.getDetails(this.eduProgramId).subscribe(result => {
      if(this.educationPlanId) {
        let plans = result.educationPrograms.flatMap(ep => ep.educationPlans).filter(plan => plan.educationPlanId == this.educationPlanId);
        if (plans.length > 0) {
          this.educationPlanDetailsService.setEducationPlan(plans[0]);
        }
      }
      this.editable = this.getAccessLevel(this.educationPlanDetailsService.currentEducationPlan.educationPlanId)
      this.allowNew = this.getProgramAccesssLevel(this.eduProgramId)
    });
    this.educationPlanId && this.educationPlanDetailsService.getDetails(this.eduProgramId).subscribe();
    this.getExtended();

    window.addEventListener('beforeunload', (event) => {
      if (this.educationPlanDetailsService.modification.getValue().value == true ){
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
      return false;
    });
    //Проверка перехода на страницы
    let observeUrlChange = () => {
      let oldHref = document.location.href;
      const body = document.querySelector("body");
      const observer = new MutationObserver(mutations => {
        if (oldHref !== document.location.href) {
          oldHref = document.location.href;

          if (this.exceptionAddresses(document.location.href) )
            this.educationPlanDetailsService.locationURL.next({
              value: false
          });

          if (this.educationPlanDetailsService.modification.getValue().value == true  &&
              !( document.location.href.includes('plans/plan') || this.exceptionAddresses(document.location.href))){

              this.notificationService.showError("График учебного процесса не сохранён");
              this.educationPlanDetailsService.locationURL.next({
                value: true
              });
              this.router.navigate([`/education/${this.eduProgramId}/plans/plan//${this.educationPlanId}`]);
          }
        }
      });
      observer.observe(document, { childList: true, subtree: true });
    };

    window.onload = observeUrlChange;

    if (this.educationPlanDetailsService.modification.getValue().value == true){
      observeUrlChange = ()  => {};
      if (this.educationPlanDetailsService.locationURL.getValue().value == true)
         this.isModifyShedule = true;
      else this.isModifyShedule = false;
    }
  }

  public getCurrentPlanProtocol() {
    const number =  this.educationPlanDetailsService.currentEducationPlan.protocolNumber;
    const date = this.educationPlanDetailsService.currentEducationPlan.protocolDate ?
      ' от ' + DateToString(this.educationPlanDetailsService.currentEducationPlan.protocolDate) + 'г.' :
      '';
    return number ? `№${number + date}` : '-';
  }

  exceptionAddresses(adress: any){
    if (adress.includes('add-discipline') || adress.includes('copy-plan') || adress.includes('reattach-plan') || adress.includes('global-schedule-settings'))
    return true
    else return false;
  }

  onChangeEduProgram($event: EducationPlanDetailsModels.EProgram): void {
    this.educationPlanDetailsService.setEducationProgram($event.educationProgramId);
    this.eduProgramId = $event.educationProgramId
    this.router.navigate([`/education/${$event.educationProgramId}/plans`]);
  }

  onChangeEducationPlan($event: EducationPlanDetailsModels.EPlan): void {
    this.educationPlanDetailsService.setEducationPlan($event);
    this.location.replaceState(`/education/${this.eduProgramId}/plans/plan/${$event.educationPlanId}`);
  }

  openEducationPlan(){
    if (this.modificationSchedule() == true)
      this.dropdownlist.toggle(false);
  }

  async onBlurEduPlanDescription() {
    if (!this.educationPlanDetailsService.currentEducationPlan.educationPlanId) {
      this.notificationService.showError('Выберите учебный план');
      return;
    }
    await lastValueFrom(this.educationPlanDetailsService.changeEduPlanStatus());
  }

  async onChangeEduPlanStatus(id: number) {
    if (!this.educationPlanDetailsService.currentEducationPlan.educationPlanId) {
      this.notificationService.showError('Выберите учебный план');
      return;
    }

    let tmpStatus = this.educationPlanDetailsService.currentEducationPlan.status;
    this.educationPlanDetailsService.currentEducationPlan.status = id;

    try {
      await lastValueFrom(this.educationPlanDetailsService.changeEduPlanStatus());
      await lastValueFrom(this.educationPlanDetailsService.getDetails(this.eduProgramId));
    }
    catch (e) {
      this.educationPlanDetailsService.currentEducationPlan.status = tmpStatus;
    }
  }

  async goToEduProgramList(): Promise<void> {
    if (this.modificationSchedule() == false)
    await this.router.navigate([`/education`]);
  }

  modificationSchedule(){
    if (this.educationPlanDetailsService.modification.getValue().value == true) {
      this.notificationService.showError("График учебного процесса не сохранён");
      return true
    } else return false
  }

  onDeleteEduPlan(): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить УП?",
      actions: [{ text: "Нет" }, { text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) { }
      else {
        if (result.text == "Да") {
          this.educationPlanDetailsService.deleteEduPlan().subscribe(() => {
            this.notificationService.showSuccess("Учебный план удален");
          })
        }
      }
    });

  }

  onExportEduPlan(): void {
    if (!this.educationPlanDetailsService.currentEducationPlan.educationPlanId) {
      this.notificationService.showError('Выберите учебный план');
      return
    }
    this.isExporting = true
    this.educationPlanDetailsService.exportEduPlan().subscribe((response) => {
      if (!response.body) return;
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
      const contentDisposition = response.headers.get('content-disposition');
      let fileName1 = this.educationPlanDetailsService.currentEducationPlan.longName;
      let type = contentDisposition!.split(';')[1].split('filename')[1].split('=')[1].split('.')[2].trim();
      let fullFileName = fileName1 + '.' + type;
      downloadLink.download = fullFileName;
      downloadLink.click();
      this.isExporting = false;
    });
  }

  showEduPlanEditForm(value: boolean) {
    this.eduPlanEdit = value
  }

  public onCreateNewPlan() {
    if (this.modificationSchedule() == false)
    this.router.navigate([`/education/${this.eduProgramId}/plans/add-plan`]);
  }

  public onCopyPlan() {
    this.router.navigate([`/education/${this.eduProgramId}/plans/copy-plan/${this.educationPlanDetailsService.currentEducationPlan.educationPlanId}`]);
  }

  public onReattachmentPlan() {
    this.router.navigate([`/education/${this.eduProgramId}/plans/reattach-plan/${this.educationPlanDetailsService.currentEducationPlan.educationPlanId}`]);
  }

  onUpdateEduPlan(eduPlan: EducationPlanDetailsModels.EPlan) {
    this.educationPlanDetailsService.updateEduPlan(eduPlan).subscribe(
      _ => {
        this.eduPlanEdit = false;
        this.notificationService.showSuccess("Учебный план обновлен");
      }
    );
  }

  public editable: boolean = false;
  public allowNew: boolean = false; //доп. флаг для создания плана с нуля

  public getAccessLevel(educationPlanId:string):any{
    this.editable = false;
    if(educationPlanId)
      this.userAccessService.getPlanEditAccessLevel(educationPlanId)
        .subscribe(
          response => {
            this.editable = response.accessLevel;
          }
        );
  }
  public getProgramAccesssLevel(educationProgramId:string):any{
    this.userAccessService.getProgramAccessLevel(educationProgramId).subscribe({
      next: (response) => {
        this.allowNew = response.accessLevel;
      },
      error: () => {}
    })
  }

  public onChange(event: boolean){
    localStorage.setItem('educationPlanDetails', event.toString());
    this.detailsExtended = !this.detailsExtended;
  }

  public getExtended(){
    if (localStorage.getItem('educationPlanDetails') == 'true') this.detailsExtended = true
    else this.detailsExtended = false;
  }
}
