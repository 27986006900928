import {Component, OnInit, NgZone} from '@angular/core';
import {StudentService} from "src/app/services/contingent/student.service";
import {StudPerson} from "src/app/models/contingent/studperson.model";
import {DictFilial} from 'src/app/models/contingent/dictfilial.model';
import {StudentSemestr} from 'src/app/models/contingent/studentsemestr.model';
import {StudpersonService} from "src/app/services/contingent/studperson.service";
import {
  Contingent,
  ContingentAllStudent,
  ContingentRequest,
  Filter,
  Page, SavedFilters
} from "src/app/models/contingent/contingent.model";
import {ContingentService} from "src/app/services/contingent/contingent.service";
import {
  CellClickEvent, ExcelExportEvent,
  GridComponent,
  GridDataResult,
  PageChangeEvent
} from '@progress/kendo-angular-grid';

import {Router} from "@angular/router";
import {
  DialogService,
} from "@progress/kendo-angular-dialog";
import {Identifications} from 'src/app/models/contingent/identifications.model';
import {Citizenship} from 'src/app/models/contingent/citizenship.model';
import {StudentBudgetState} from 'src/app/models/contingent/studentbudgetstate.model';
import {StudEduGroup} from 'src/app/models/contingent/studedugroup.model';
import {StudentAcademicState} from 'src/app/models/contingent/studentacademicstate.model';
import {ListBoxToolbarConfig} from '@progress/kendo-angular-listbox';
import {OrderTypes} from 'src/app/models/contingent/ordertypes.model';
import {OrdertypesService} from 'src/app/services/contingent/ordertypes.service';
import {OrdersCategory} from 'src/app/models/contingent/orderscategory.model';
import {OrderscategoryService} from 'src/app/services/contingent/orderscategory.service';
import {AcademicState} from 'src/app/models/contingent/academicstate.model';
import {AcademicStateService} from 'src/app/services/contingent/academicstate.service';
import {CitizenshipService} from 'src/app/services/contingent/citizenship.service';
import {EduGroup} from 'src/app/models/contingent/edugroup.model';
import {EdugroupService} from 'src/app/services/contingent/edugroup.service';
import {Budget} from 'src/app/models/contingent/budget.model';
import {BudgetService} from 'src/app/services/contingent/budget.service';
import {BudgetSubCategory} from 'src/app/models/contingent/budgetsubcategory.model';
import {BudgetsubcategoryService} from 'src/app/services/contingent/budgetsubcategory.service';
import {CommondictService} from 'src/app/services/contingent/commondict.service';
import {StudyLevel} from 'src/app/models/contingent/studylevel.model';
import {DepartmentName, Faculty} from 'src/app/models/contingent/departmentname.model';
import {DepartmentService} from 'src/app/services/contingent/department.service';
import {OrdersStudyPeriod} from 'src/app/models/contingent/ordersstudyperiod.model';
import {StudPlan} from 'src/app/models/contingent/studplan.model';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {DictState} from 'src/app/models/contingent/dictstate.model';
import {DictStateService} from 'src/app/services/contingent/dictstate.service';
import {MilitaryStatuses} from 'src/app/models/contingent/militarystatuses.model';
import {MilitarystatusesService} from 'src/app/services/contingent/militarystatuses.service';
import {MilitaryStockCategories} from 'src/app/models/contingent/militarystockcategories.model';
import {MilitarystockcategoriesService} from 'src/app/services/contingent/militarystockcategories.service';
import {DictStudyForm} from 'src/app/models/contingent/dictstudyform.model';
import {PersistanceService} from 'src/app/services/contingent/persistance.service';
import {DictTrainingLevel} from 'src/app/models/contingent/dicttraininglevel.model';
import {Observable, of, Subscription} from 'rxjs';
import {
  CheckableSettings,
  CheckedState,
  TreeItem,
} from '@progress/kendo-angular-treeview';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {IdentificationType} from 'src/app/models/contingent/identificationtype.model';
import {IdentificationtypeService} from 'src/app/services/contingent/identificationtype.service';
import {EducationDocTypes} from 'src/app/models/contingent/educationdoctypes.model';
import {EducationdoctypesService} from 'src/app/services/contingent/educationdoctypes.service';
import {EducationProgramName} from 'src/app/models/contingent/educationprogram.model';
import {EducationService} from 'src/app/services/contingent/education.service';
import {EducationStandard} from 'src/app/models/contingent/educationstandard.model';
import {Guid} from 'guid-typescript';
import {DicthealthService} from 'src/app/services/contingent/dicthealth.service';
import {DictHealth} from 'src/app/models/contingent/dicthealth.model';
import {NodeClickEvent} from "@progress/kendo-angular-treeview/node-click-event.interface";
import { TokenStorageService } from 'src/app/services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";
import {dependentColumns, listbox, selectlistbox} from 'src/app/models/contingent/listbox-items';
import {toDataURL, WorkbookSheetRow, WorkbookSheetRowCell} from "@progress/kendo-angular-excel-export";
import {saveAs} from "@progress/kendo-file-saver";
import {DictBenefit} from "../../../models/contingent/dictbenefit.model";
import {DictbenefitService} from "../../../services/contingent/dictbenefit.service";
import {CurrentDayEnd, TimeZoneFix} from "../../../helpers/date-helper";
import {MilitaryCommissariatService} from "../../../services/contingent/military-commissariat.service";
import {MilitaryCommissariat} from "../../../models/contingent/militarycommissariat.model";
import {MultiSelectEnum} from "../../../models/contingent/enums/multiselect.enum";
import {AcademicStateEnum} from "../../../models/contingent/enums/academic-state.enum";
import {ContingentOrganizationService} from "../../../services/contingent/contingentOrganization.service";
import {ContingentOrganization} from "../../../models/contingent/organization.model";
import {DictAchievements} from "../../../models/contingent/dictachievement.model";
import {DictAchievementService} from "../../../services/contingent/dictachievement.service";
import {BudgetCategory} from "../../../models/contingent/budgetcategory.model";
import {BudgetcategoryService} from "../../../services/contingent/budgetcategory.service";
import {PersonService} from "../../../services/contingent/person.service";
import {Person} from "../../../models/contingent/person.model";
import {Moment} from "moment";
import {DictemployerpostService} from "../../../services/contingent/dictemployerpost.service";
import {DictEmployerPost} from "../../../models/contingent/dictemployerpost.model";
import { DictFilialService } from '../../../services/contingent/dictfilial.service';
import {StudentcarddisplaysettingService} from "../../../services/contingent/studentcarddisplaysetting.service";
import {DisplaySettingEnum} from "../../../models/contingent/enums/display-setting.enum";
import {FilterSelect} from "../../../models/contingent/filterselect.model";

@Component({
  selector: 'app-contingent',
  templateUrl: './contingenthome.component.html',
  styleUrls: ['./contingenthome.component.scss'],
  providers: []
})

export class ContingentHomeComponent implements OnInit {

  private departmentTree$!: Subscription;
  private contingentPage$!: Subscription;

  constructor(private router: Router,
              private dialogService: DialogService,
              private studPersonService: StudpersonService,
              private studentService: StudentService,
              private contingentService: ContingentService,
              private orderTypesService: OrdertypesService,
              private ordersCategoryService: OrderscategoryService,
              private academicStateService: AcademicStateService,
              private eduGroupService: EdugroupService,
              private persistenceService: PersistanceService,
              private commonDictService: CommondictService,
              private budgetService: BudgetService,
              private citizenshipService: CitizenshipService,
              private budgetSubCategoryService: BudgetsubcategoryService,
              private departmentService: DepartmentService,
              private organizationService: ContingentOrganizationService,
              private notificationService: NotificationsService,
              private dictStateService: DictStateService,
              private militaryStatusesService: MilitarystatusesService,
              private militaryStockCategoriesService: MilitarystockcategoriesService,
              private identificationTypeService: IdentificationtypeService,
              private educationDocTypesService: EducationdoctypesService,
              private educationPlanService: EducationService,
              private dictHealthService: DicthealthService,
              private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService,
              private userAccessService: ContingentUserAccessService,
              private zone: NgZone,
              private dictBenefitService: DictbenefitService,
              private militaryCommissariatService: MilitaryCommissariatService,
              private dictAchievementService: DictAchievementService,
              private budgetCategoryService: BudgetcategoryService,
              private personsService: PersonService,
              private employerPostService: DictemployerpostService,
              private dictFilialService: DictFilialService,
              private displaySettingService: StudentcarddisplaysettingService,
  ) {
    this.loadItems();
  }

  ngOnInit(): void {
    this.loadItems();

    if(history.state.back){
      const contingent_settings = localStorage.getItem('contingent_settings');

      if (contingent_settings !== null) {
        let storage = JSON.parse(contingent_settings)
        this.skip = storage.skip
        this.sort = storage.sort
        this.pageSize = storage.pageSize
        this.filterContingentSaved = storage.filter
        this.selectableSettings = storage.selectableSettings
        this.isInOperationMode = storage.isInOperationMode
        this.selectionChange(storage.studentSelection)
        this.markedStudentSelection = storage.markedstudentSelection
        this.selection = storage.selection
        this.pageNumber = storage.pageNumber
        this.tempHiddenColumns = storage.temphiddenColumns
        this.tempVisibleColumns = storage.tempvisibleColumns
        this.applyHandler()
        localStorage.removeItem("contingent_settings")
      }
    }
    else localStorage.removeItem("contingent_settings")

    this.getAccessLevel();
    this.getAllFilials();
    this.getAllOrderTypes();
    this.getAllOrdersCategory();
    this.getAllFaculty();
    this.getAllDepartment();
    this.getAllStudyLevel();
    this.getAllTrainingLevel();
    this.getAllStudyForm();
    this.getBudget();
    this.getAllBudgetCategories();
    this.getCitizenship();
    this.getAllEduGroup();
    this.getBudgetSubCategory();
    this.getOrganization();
    this.getDictState();
    this.getMilitaryStatuses();
    this.getMilitarystockcategories();
    this.getAllIdentificationTypes();
    this.getAllEducationDocTypes();
    this.getAllEducationStandard();
    this.getAllHealths();
    this.getAllBenefits();
    this.getAllPersons();
    this.getAllMilitaryCommissariat();
    this.getAllDictAchievements();
    this.getAllEmployerPost();
    this.subscribe();
    this.getDisplaySettings();
    this.getAllAcademicState();

    //Work with local data

    const sel_filter = localStorage.getItem('SEL_FLTR');
    const sav_filter = localStorage.getItem('savedFilters');

    if(history.state.apply)this.listBoxReset(true);
    else this.listBoxReset(false);

    this.reordinateColumns()
    if (sel_filter !== null) {
      this.fmodel = JSON.parse(sel_filter);
      if (!this.fmodel.fio && !this.fmodel.citizenship && !this.fmodel.regRegion && !this.fmodel.isMale && !this.fmodel.age && !this.fmodel.agedate &&
        !this.fmodel.militaryCategory && !this.fmodel.militaryCommissariat && !this.fmodel.health && !this.fmodel.graduateYear) this.groupPersonalData = false;
      if (!this.fmodel.orderType && !this.fmodel.orderCategory && !this.fmodel.orderNumber && !this.fmodel.orderDate && !this.fmodel.orderStartDate &&
        !this.fmodel.approveVAKDate && !this.fmodel.isWork && !this.fmodel.post && !this.fmodel.dictAchievements && !this.fmodel.hasAchievements && !this.fmodel.hasOrder) this.groupOther = false;
      if (!this.fmodel.institution && !this.fmodel.thisInstitution && !this.fmodel.secondEducation && !this.fmodel.trainingLevel && !this.fmodel.studyForm &&
        !this.fmodel.educationWay && !this.fmodel.finishYear && !this.fmodel.academicState && !this.fmodel.course && !this.fmodel.educationProgram &&
        !this.fmodel.startYear && !this.fmodel.faculty && !this.fmodel.eduGroup && !this.fmodel.isBudget && !this.fmodel.budgetCategory &&
        !this.fmodel.budgetSubCategory && !this.fmodel.employer && !this.fmodel.departments && !this.fmodel.scientificPerson &&
        !this.fmodel.dissertationDate && !this.fmodel.elder && !this.fmodel.employed && !this.fmodel.map && !this.fmodel.filial) this.groupEducation = false;
      if (!this.groupPersonalData && !this.groupOther && !this.groupEducation) this.groupAll = true;
    }
    if (sav_filter != null) {
      this.savedFilters = JSON.parse(sav_filter)
      this.savedFilters = this.savedFilters.sort()
    }
    this.treeFill(this.filter.trainingLevel);
  }

  ngOnDestroy(): void {
    this.departmentTree$.unsubscribe();
    this.contingentPage$.unsubscribe();
  }

  private subscribe() {
    this.departmentTree$ = this.departmentService.departmentTree$.subscribe((response) => {
      this.dataTree = response;
      this.loadingTreeData = false;
    })

    this.contingentPage$ = this.contingentService.contingentPage$.subscribe({
      next: (response) => {
        this.allContingentStudent = response.items;
        this.count = response.count;
        this.gridView = {
          data: this.allContingentStudent,
          total: this.count,
        };
        this.loading = false;
        this.dateView()
      },
      error: (error) => {
        console.log(error.error);
        this.notificationService.showError("Отсутствует подключение к серверу");
        this.loading = false;
      }});
  }

  public isEditDate: boolean = false;
  public currentContingentDate: Date = CurrentDayEnd();
  public contingentDate: Date = CurrentDayEnd();
  public currentRaitingDate: Date = CurrentDayEnd();
  public raitingDate: Date = CurrentDayEnd();
  public multiselectEnum: any = MultiSelectEnum;
  public academicState: any = AcademicStateEnum;
  public enum = DisplaySettingEnum;

  public selectableSettings: boolean = false;
  public disableEduFilters = false;
  public loading = false;
  public editable ? : boolean
  public currentFilial ? : string = undefined;
  public isInOperationMode: boolean = false;
  public count!: number;
  public benefits: DictBenefit[] = [];
  public orderTypes: OrderTypes[] = [];
  public selectOrderTypes: OrderTypes[] = [];
  public orderCategories: OrdersCategory[] = [];
  public selectOrderCategories: OrdersCategory[] = [];
  public dictAchievements: DictAchievements[] = [];
  public filteredDictAchievements: DictAchievements[] = [];
  public employerPosts: DictEmployerPost[] = [];
  public selectEducationProgram: EducationProgramName[] = [];
  public militaryStatuses: MilitaryStatuses[] = [];
  public militaryCommissariats: MilitaryCommissariat[] = [];
  public identificationTypes: IdentificationType[] = [];
  public educationDocTypes: EducationDocTypes[] = [];
  public militaryStockCategories: MilitaryStockCategories[] = [];
  public dictStudentAcademicStates: AcademicState[] = [];
  public dictStates: DictState[] = [];
  public persons: Person[] = [];
  public state: State = {
    sort: []
  };
  public healths: DictHealth[] = [];
  public selectedEducationWay ? : string | undefined = undefined;
  public filters: boolean = true;
  public filtersNumber: number = 1;
  public allContingentStudent: ContingentAllStudent[] = [];
  public contingent: Contingent = {
    studentId: 0,
    studentNumber: "",
    studPersonId: 0,
    course: 0,
    groupName: "",
    studentBudget: "",
    studentBudgetState: new StudentBudgetState,
    ball: "",
    studPersons: new StudPerson,
    studentSemestr: new StudentSemestr,
    identifications: new Identifications,
    dictCitizenship: new Citizenship,
    eduGroups: new EduGroup,
    studEduGroup: new StudEduGroup,
    studentAcademicState: new StudentAcademicState,
    languages: [],
    ordersStudyPeriod: new OrdersStudyPeriod,
    studPlan: new StudPlan,
  }
  public educationPrograms: EducationProgramName[] = [];
  public educationStandard: EducationStandard[] = [];
  public selectionEducationStandard: EducationStandard[] = [];
  public educationProgram: EducationProgramName | undefined = {};
  public eduGroups: EduGroup[] = [];
  public departSelectEduGroup: EduGroup[] = [];
  public eduGroup: EduGroup = {
    eduGroupId: 0,
    groupName: "",
    courseNum: 0,
    dateBegin: new Date,
    dateEnd: new Date,
    facultyId: Guid.create(),
  }
  public budgetCategories: BudgetCategory[] = [];
  public selectedBudgetCategories: BudgetCategory[] = [];
  public budgets: Budget[] = [];
  public budgetSubCategories: BudgetSubCategory[] = [];
  public selectedBudgetSubCategories: BudgetSubCategory[] = [];
  public studyLevels!: StudyLevel[];
  public trainingLevels!: DictTrainingLevel[]
  public dictStudyForms: DictStudyForm[] = [];
  public faculties: Faculty[] = [];
  public departments: DepartmentName[] = [];
  public studentSelection: any[]=[];
  public filials: DictFilial[] = []
  public filteredBudgets: Budget[] = [];
  public filteredFaculties: Faculty[] = [];

  public markedStudentSelection: Array < any > = [];
  public tempStudentSelection: Array < any > = [];
  public selection: boolean = true;
  public newFilter: boolean = true;
  public selectedCommissariatId: Guid[] | undefined = [];
  public selectedDictMilitaryStatusId: Guid | undefined = undefined;
  public organizations: ContingentOrganization[] = [];
  public gridHide: boolean = false;
  public loaderExcelExport = false;
  public isGroupOpen = false
  public pageSize = 50;
  public pageNumber = 1;
  public sizes = [20, 50, 200, 500];
  public skip = 0;

  public page: Page = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
  }

  public pageExcel: Page = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
  }

  public sort ? : SortDescriptor[] = [
    {
      "dir": "asc",
      "field": "studPersons.fullName"
    }
  ];
  public filterContingentSaved?: Filter;

  public orderBegin: any;
  public orderEnd: any;
  public dissertationDateBegin!: Date | Moment;
  public dissertationDateEnd!: Date | Moment;
  public vakDateBegin!: Date | Moment;
  public vakDateEnd!: Date | Moment;
  public orderStartBegin!: Date | Moment;
  public orderStartEnd!: Date | Moment;

  public filter: Filter = {
    fio: "",
    isMale: undefined,
    citizenshipId: undefined,
    ageBegin: undefined,
    ageEnd: undefined,
    ageStart: undefined,
    ageFinish: undefined,
    orderBegin: undefined,
    orderEnd: undefined,
    orderStartBegin: undefined,
    orderStartEnd: undefined,
    orderStart: undefined,
    orderFinish: undefined,
    orderType: undefined,
    orderCategory: undefined,
    orderNumber: "",
    graduateYear: undefined,
    budgetCategory: undefined,
    budgetSubCategory: undefined,
    course: undefined,
    group: undefined,
    trainingLevel: undefined,
    dictStudentAcademicStateId: undefined, //[ "9c95aa3c-5fcb-4da4-90ba-186b1b14935f"],//undefined
    organizations: undefined,
    regState: undefined,
    scientificPersons: undefined,
    dictMilitaryStatusId: undefined,
    healthId: undefined,
    faculties: undefined,
    departments: undefined,
    dictStudyFormId: undefined,
    studyStart: undefined,
    studyFinish: undefined,
    educationStandard: undefined,
    sideFilterItems: undefined,
    work: undefined,
    post: undefined,
    organizationEmployerId: undefined,
    approveVAKDateBegin: undefined,
    approveVAKDateEnd: undefined,
    dissertationDateBegin: undefined,
    dissertationDateEnd: undefined,
    educationProgram: undefined,
    elder: undefined,
    commissariatId: undefined,
    militaryEnlistmentCommissariatId: undefined,
    dictMilitaryEnlistmentStatusId: undefined,
    smartSearch: '',
    directionActivityId: undefined,
    hasAchievements: undefined,
    hasOrder: undefined,
    institution: "",
    thisInstitution: undefined,
    secondHigherEducation: undefined,
    filial: undefined
  }
  public citizenships: Citizenship[] = [];
  public contingentRequest: ContingentRequest = {
    filter: this.filter,
    page: this.page,
    sort: this.sort,
  }
  public contingentRequestExcel: ContingentRequest = {
    filter: this.filter,
    page: this.pageExcel,
    sort: this.sort,
  }
  public savedFilters: Array < string > = [];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
  public savedFilterName: string = '';
  public selectSavedFilterName: string = '';

  public dataStateChange(state: SortDescriptor[]): void {
    this.sort = state;
    this.getContingentPage(1);
  }

  public gridView!: GridDataResult;
  public columns: any[] = [{
    field: 'studentId',
    title: "№ п/п",
    width: 120,
    orderIndex: 0
  },
    {
      field: 'address.address',
      title: 'Адрес по прописке',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'studPersons.email',
      title: 'Адрес электронной почты',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'studentAcademicState',
      title: 'Академический статус',
      width: 200,
      orderIndex: 100
    },
    {
      field: 'sportType',
      title: 'Вид спорта',
      width: 200,
      orderIndex: 100
    },
    { field: !this.isHiddenStudentCard(this.enum.military) ? 'military.militaryStockCategoryName' : 'militaryEnlistment.militaryStockCategoryName', title:'Военная категория', width: 200, orderIndex: 100 },
    { field: !this.isHiddenStudentCard(this.enum.military) ? 'military.militarySpecialtyNumber' : 'militaryEnlistment.militarySpecialtyNumber', title:'Военно-учетная специальность', width: 200, orderIndex: 100 },
    { field: !this.isHiddenStudentCard(this.enum.military) ? 'military.militaryRankName' : 'militaryEnlistment.militaryRankName', title:'Воинское звание', width: 200, orderIndex: 100 },
    { field: !this.isHiddenStudentCard(this.enum.military) ? '' : 'militaryEnlistment.militaryCategory', title:'Годность к военной службе', width: 200, orderIndex: 100 },
    {
      field: !this.isHiddenStudentCard(this.enum.military) ? 'military.commissariatName' : 'militaryEnlistment.commissariatName',
      title: 'Военный комиссариат',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'yearStart',
      title: 'Год начала обучения',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'yearFinish',
      title: 'Год окончания обучения',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'educationDoc.graduateYear',
      title: 'Год получения документа о предыдущем образовании',
      width: 350,
      orderIndex: 100
    },
    {
      field: 'studPersons.country',
      title: 'Гражданство',
      width: 300,
      orderIndex: 100
    },
    {
      field: 'groupName',
      title: 'Группа',
      width: 150,
      orderIndex: 100
    },
    {
      field: 'health',
      title: 'Группа здоровья',
      width: 150,
      orderIndex: 100
    },
    {
      field: 'studPersons.medicalCertificate',
      title: 'Данные о справках',
      width: 350,
      orderIndex: 100
    },
    {
      field: 'identifications.issueDateFormatted',
      title: 'Дата выдачи документа (удостоверение личности)',
      width: 300,
      orderIndex: 100
    },
    { field: !this.isHiddenStudentCard(this.enum.military) ? 'military.issueDateFormatted' : 'militaryEnlistment.issueDateFormatted', title:'Дата выдачи документа воинского учета', width: 200, orderIndex: 100 },
    { field: 'educationDoc.issueDateFormatted', title: 'Дата выдачи документа об образовании', width: 200, orderIndex: 100},
    { field: 'studentBudgetState.paidContractDateFormatted', title: 'Дата договора на платное обучение', width: 250, orderIndex: 100  },
    { field: 'educationDoc.kandidateDisserDateFormatted', title:'Дата защиты диссертации', width: 200, orderIndex: 100 },
    {
      field: 'dateStartFormatted',
      title: 'Дата начала обучения',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'dateFinishFormatted',
      title: 'Дата окончания обучения',
      width: 250,
      orderIndex: 100
    },
    { field: 'orderStartOrderDateFormatted', title:'Дата приказа о зачислении', width: 200, orderIndex: 100 },
    { field: !this.isHiddenStudentCard(this.enum.military) ? 'military.issueMilitaryStartDateFormatted' : 'militaryEnlistment.issueMilitaryStartDateFormatted', title:'Дата регистрации сведений, направленных в военный комиссариат', width: 250, orderIndex: 100 },
    {
      field: 'studPersons.birthdayFormatted',
      title: 'Дата рождения',
      width: 200,
      orderIndex: 100
    },
    { field: !this.isHiddenStudentCard(this.enum.military) ? 'military.issueMilitaryFinishDateFormatted' : 'militaryEnlistment.issueMilitaryFinishDateFormatted', title:'Дата снятия с воинского учета', width: 250, orderIndex: 100 },
    { field: 'educationDoc.approveVAKDateFormatted', title:'Дата утверждения ВАК', width: 200, orderIndex: 100 },
    { field: 'studentBudgetState.paidContract', title: 'Договор на платное обучение', width: 250, orderIndex: 100 },
    { field: 'educationDoc.string', title: 'Документ об образовании', width: 250, orderIndex: 100 },
    { field: 'post', title: 'Должность', width: 170, orderIndex: 100 },
    { field: 'address.house', title: 'Дом (адрес по прописке)', width: 200, orderIndex: 100 },
    { field: 'actualAddress.house', title: 'Дом (фактический адрес проживания)', width: 250, orderIndex: 100 },
    { field: !this.isHiddenStudentCard(this.enum.military) ? 'military.comment' : 'militaryEnlistment.comment', title:'Дополнительные сведения (ВУС)', width: 200, orderIndex: 100 },
    {
      field: 'studentBudgetState.dictOrganizations.organizationName',
      title: 'Заказчик целевого обучения',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'studPersons.login',
      title: 'Идентификатор студента',
      width: 210,
      orderIndex: 100
    },
    { field: 'admissionStudentId', title: 'Идентификатор студента в ПК', width: 210, orderIndex: 100 },
    {
      field: 'languages',
      title: 'Изучаемые языки',
      width: 200,
      orderIndex: 100
    },
    {
      field: 'studPersons.firstName',
      title: 'Имя',
      width: 150,
      orderIndex: 100
    },
    {
      field: 'studPersons.firstNameLatin',
      title: 'Имя (латиница)',
      width: 180,
      orderIndex: 100
    },

    { field: 'address.zipCode', title: 'Индекс (адрес по прописке)', width: 200, orderIndex: 100 },
    { field: 'actualAddress.zipCode', title: 'Индекс (фактический адрес проживания)', width: 250, orderIndex: 100 },

    {
      field: 'studPersons.inn',
      title: 'ИНН',
      width: 250,
      orderIndex: 100
    },
    { field: 'studentBudgetState.dictOrganizations.inn', title:'ИНН заказчика целевого обучения', width: 200, orderIndex: 100},
    {
      field: 'facultyName',
      title: 'Институт/факультет',
      width: 300,
      orderIndex: 100
    },

    { field: 'familyMember.familyMember', title: 'Информация о родственниках', width: 300, orderIndex: 100 },
    { field: 'familyMember.address', title: 'Информация о родственниках (адрес проживания)', width: 300, orderIndex: 100 },
    { field: 'familyMember.phoneNumber', title: 'Информация о родственниках (номер телефона)', width: 300, orderIndex: 100 },
    { field: 'familyMember.memberStatus', title: 'Информация о родственниках (степень родства)', width: 300, orderIndex: 100 },
    { field: 'familyMember.fio', title: 'Информация о родственниках (ФИО)', width: 300, orderIndex: 100 },

    {
      field: 'studentBudgetState.studentBudgetName',
      title: 'Источник финансирования',
      width: 210,
      orderIndex: 100
    },
    { field: 'militaryEnlistment.outgoingRegistryNumber', title:'Исходящий регистрационный номер сведений, направленных в военный комиссариат', width: 250, orderIndex: 100 },
    {
      field: 'military.militaryStatusName',
      title: 'Категория воинского учета',
      width: 250,
      orderIndex: 100
    },
    { field: 'studentBudgetState.studentBudgetCategoryName', title: 'Категория финансирования', width: 200, orderIndex: 100},
    {
      field: 'kafedra',
      title: 'Кафедра',
      width: 250,
      orderIndex: 100
    },

    { field: 'address.flat', title: 'Квартира (адрес по прописке)', width: 200, orderIndex: 100 },
    { field: 'actualAddress.flat', title: 'Квартира (фактический адрес проживания)', width: 250, orderIndex: 100 },
    { field: 'identifications.issueOrganization', title: 'Кем выдан документ (удостоверение личности)', width: 350, orderIndex: 100 },
    { field: !this.isHiddenStudentCard(this.enum.military) ? 'military.issueOrganization' : 'militaryEnlistment.issueOrganization', title:'Кем выдан документ воинского учета', width: 250, orderIndex: 100 },
    { field: 'educationDoc.issueOrganization', title:'Кем выдан документ об образовании', width: 250, orderIndex: 100 },
    {
      field: 'educationStandardCipher',
      title: 'Код направления подготовки',
      width: 250,
      orderIndex: 100
    },
    { field: 'studPersons.countryCode', title:'Код ОКСМ', width: 180, orderIndex: 100 },
    { field: 'identifications.issueCode', title: 'Код подразделения документа (удостоверение личности)', width: 300, orderIndex: 100 },
    { field: 'achievementsCount', title: 'Кол-во достижений', width: 130, orderIndex: 100 },
    { field: 'address.corpus', title: 'Корпус (адрес по прописке)', width: 200, orderIndex: 100 },
    { field: 'actualAddress.corpus', title: 'Корпус (фактический адрес проживания)', width: 250, orderIndex: 100 },
    { field: 'studentBudgetState.dictOrganizations.kpp', title: 'КПП заказчика целевого обучения', width: 250, orderIndex: 100},
    { field: 'studentBudgetState.dictOrganizationEmployers.kpp', title: 'КПП работодателя по договору о целевом обучении', width: 250, orderIndex: 100},
    {
      field: 'courseNum',
      title: 'Курс',
      width: 130,
      orderIndex: 100
    },
    {
      field: 'benefit',
      title: 'Льготы',
      width: 350,
      orderIndex: 100
    },
    { field: 'organizationName', title: 'Место работы', width: 300, orderIndex: 100 },
    {
      field: 'identifications.birthPlace',
      title: 'Место рождения',
      width: 200,
      orderIndex: 100
    },
    {
      field: 'educationDoc.issueOrganization',
      title: 'Название предыдущего учебного заведения',
      width: 300,
      orderIndex: 100
    },
    {
      field: 'educationStandard',
      title: 'Наименование направления подготовки',
      width: 250,
      orderIndex: 100
    },
    {field: 'medicalAvailability', title: 'Наличие медицинской справки', width: 200, orderIndex: 100},
    {
      field: 'educationStandardName',
      title: 'Направление подготовки',
      width: 250,
      orderIndex: 100
    },
    { field: 'address.city', title: 'Населённый пункт (адрес по прописке)', width: 250, orderIndex: 100 },
    { field: 'actualAddress.city', title: 'Населённый пункт (фактический адрес проживания)', width: 350, orderIndex: 100 },
    { field: 'scientificPersonName', title: 'Научный руководитель (куратор)', width: 200, orderIndex: 100},
    { field: 'needHostel', title: 'Необходимость в общежитии', width: 250, orderIndex: 100 },
    { field: '', title: 'Номер диплома выпускника', width: 250, orderIndex: 100 },
    { field: 'studentBudgetState.paidContractNum', title: 'Номер договора на платное обучение', width: 250, orderIndex: 100 },
    { field: 'identifications.number', title: 'Номер документа (удостоверение личности)', width: 300, orderIndex: 100 },
    { field: !this.isHiddenStudentCard(this.enum.military) ? 'military.number' : 'militaryEnlistment.number', title: 'Номер документа воинского учета', width: 250, orderIndex: 100 },
    { field: 'educationDoc.number', title: 'Номер документа об образовании', width: 250, orderIndex: 100},
    {
      field: 'studentNumber',
      title: 'Номер зачетной книжки',
      width: 190,
      orderIndex: 100
    },
    {
      field: 'hostelRoom',
      title: 'Номер комнаты в общежитии',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'hostel',
      title: 'Номер общежития',
      width: 250,
      orderIndex: 100
    },
    { field: '', title: 'Номер реестра выгрузки в банк', width: 200, orderIndex: 100 },
    { field: 'studentBudgetState.dictOrganizations.ogrn', title: 'ОГРН заказчика целевого обучения', width: 250, orderIndex: 100 },
    { field: 'studentBudgetState.dictOrganizationEmployers.ogrn', title: 'ОГРН работодателя по договору о целевом обучении', width: 250, orderIndex: 100 },
    { field: 'militaryEnlistment.militaryStatusName', title: 'Отношение к военной службе', width: 250, orderIndex: 100 },
    {
      field: 'studPersons.middleName',
      title: 'Отчество',
      width: 200,
      orderIndex: 100
    },
    {
      field: 'studPersons.middleNameLatin',
      title: 'Отчество (латиница)',
      width: 200,
      orderIndex: 100
    },
    { field: 'studentBudgetState.studentBudgetSubCategoryName', title: 'Подкатегория финансирования', width: 250, orderIndex: 100},
    {
      field: 'studPersons.isMaleName',
      title: 'Пол',
      width: 200,
      orderIndex: 100
    },
    { field: 'secondHigherEducation', title: 'Получает второе высшее', width: 180, orderIndex: 100 },
    { field: !this.isHiddenStudentCard(this.enum.military) ? 'military.commissariatAddress' : 'militaryEnlistment.commissariatAddress', title: 'Почтовый адрес военного комиссариата', width: 250, orderIndex: 100 },
    { field: 'graduateThis', title: 'Предыдущее образование получено в этом учебном заведении', width: 200, orderIndex: 100 },
    {
      field: 'studPersons.maidenName',
      title: 'Прежняя фамилия',
      width: 200,
      orderIndex: 100
    },

    { field: 'orderStart', title: 'Приказ о зачислении', width: 200, orderIndex: 100 },
    { field: 'orderStartOrderDateFormatted', title: 'Приказ о зачислении (дата)', width: 200, orderIndex: 100 },
    { field: 'orderStartDateBeginFormatted', title: 'Приказ о зачислении (начало действия)', width: 250, orderIndex: 100 },
    { field: 'orderStartNumber', title: 'Приказ о зачислении (номер)', width: 250, orderIndex: 100 },

    {
      field: 'orderFinishOrderDateFormatted',
      title: 'Приказ об отчислении',
      width: 250,
      orderIndex: 100
    },
    { field: 'militaryEnlistment.reasonForDeregistration', title:'Причина снятия с воинского учета', width: 250, orderIndex: 100 },
    {
      field: 'hostelAvailability',
      title: 'Проживает в общежитии',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'educationProgramName',
      title: 'Профиль (программа)',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'studentBudgetState.dictOrganizationEmployers.organizationName',
      title: 'Работодатель',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'address.district',
      title: 'Район (адрес по прописке)',
      width: 200,
      orderIndex: 100
    },
    {
      field: 'actualAddress.district',
      title: 'Район (фактический адрес проживания)',
      width: 200,
      orderIndex: 100
    },

    { field: '', title: 'Рег. номер диплома выпускника', width: 200, orderIndex: 100 },
    { field: 'address.state', title: 'Регион (адрес по прописке)', width: 200, orderIndex: 100 },
    { field: 'actualAddress.state', title: 'Регион (фактический адрес проживания)', width: 250, orderIndex: 100 },
    {
      field: 'familyState',
      title: 'Семейное положение',
      width: 250,
      orderIndex: 100
    },
    {field: '', title: 'Серия диплома выпускника', width: 200, orderIndex: 100},
    {field: 'identifications.serial', title: 'Серия документа (удостоверение личности)', width: 300, orderIndex: 100},
    { field: 'militaryEnlistment.militaryRegistrationDocSerial', title: 'Серия документа воинского учета', width: 250, orderIndex: 100 },
    { field: 'educationDoc.serial', title: 'Серия документа об образовании', width: 250, orderIndex: 100},
    {
      field: 'studPersons.snils',
      title: 'СНИЛС',
      width: 170,
      orderIndex: 100
    },
    { field: 'compatriot', title:'Соотечественник', width: 150, orderIndex: 100},
    { field: 'militaryEnlistment.militaryProfileName', title:'Состав (ВУС)', width: 200, orderIndex: 100},
    { field: 'militaryEnlistment.militaryStateName', title:'Состоит на воинском учете', width: 200, orderIndex: 100},
    {
      field: 'studPersons.socialNetwork',
      title: 'Социальная сеть',
      width: 250,
      orderIndex: 100
    },
    {
      field: '',
      title: 'Специальность по диплому',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'sportCategory',
      title: 'Спортивный разряд',
      width: 200,
      orderIndex: 100
    },
    { field: 'medicalDate', title: 'Справка о медицинском осмотре действительна до', width: 200, orderIndex: 100 },
    {
      field: 'ball',
      title: 'Средний балл',
      width: 150,
      orderIndex: 100
    },
    {
      field: 'identifications.visaDateEndFormatted',
      title: 'Срок действия визы',
      width: 200,
      orderIndex: 100
    },
    { field: 'studentBudgetState.paidContractCostFullFormatted', title: 'Стоимость обучения в год, руб.', width: 200, orderIndex: 100 },
    { field: 'studentBudgetState.paidContractCostYearFormatted', title: 'Стоим. в сем., руб.', width: 200, orderIndex: 100 },
    { field: 'address.country', title: 'Страна (адрес по прописке)', width: 200, orderIndex: 100 },
    { field: 'actualAddress.country', title: 'Страна (фактический адрес проживания)', width: 250, orderIndex: 100 },

    { field: 'address.building', title: 'Строение (адрес по прописке)', width: 200, orderIndex: 100 },
    { field: 'actualAddress.building', title: 'Строение (фактический адрес проживания)', width: 250, orderIndex: 100 },

    {
      field: 'studPersons.phoneFormatted',
      title: 'Телефон',
      width: 250,
      orderIndex: 100
    },
    { field: 'citizenshipType', title: 'Тип гражданства', width: 200, orderIndex: 100 },
    {
      field: 'educationDoc.educationDocTypeName',
      title: 'Тип документа об образовании',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'identifications.identificationTypeName',
      title: 'Тип документа удостоверения личности',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'identifications.string',
      title: 'Удостоверение личности',
      width: 400,
      orderIndex: 100
    },
    { field: '', title: 'Укрупненна группа специальностей', width: 200, orderIndex: 100 },
    { field: 'address.street', title: 'Улица (адрес по прописке)', width: 200, orderIndex: 100 },
    { field: 'actualAddress.street', title: 'Улица (фактический адрес проживания)', width: 250, orderIndex: 100 },
    {
      field: 'studyLevel',
      title: 'Уровень образования',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'trainingLevel',
      title: 'Уровень подготовки',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'educationDoc.studyLevelName',
      title: 'Уровень предыдущего образования',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'actualAddress.address',
      title: 'Фактический адрес проживания',
      width: 300,
      orderIndex: 100
    },
    {
      field: 'studPersons.lastName',
      title: 'Фамилия',
      width: 200,
      orderIndex: 100
    },
    {
      field: 'studPersons.lastNameLatin',
      title: 'Фамилия (латиница)',
      width: 200,
      orderIndex: 100
    },
    {
      field: 'studyForm',
      title: 'Форма обучения',
      width: 250,
      orderIndex: 100
    },
    {
      field: 'studPersons.fullName',
      title: 'ФИО',
      width: 300,
      orderIndex: 100
    },
    {
      field: 'studPersons.fullNameLatin',
      title: 'ФИО (латиница)',
      width: 300,
      locked: false,
      orderIndex: 100
    },
  ];
  public hiddenColumns: string[] = [];
  public tempHiddenColumns: string[] = [];
  public treeViewVisibility = false;
  public visibleColumns: string[] = [];
  public tempVisibleColumns: string[] = [];

  phoneFormat(input: any) {
    return input.replace(/^\+?(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1 ($2) $3-$4-$5');
  }

  public fmodel: FilterSelect = {
    fio: true,
    citizenship: true,
    regRegion: true,
    health: true,
    isMale: true,
    age: true,
    agedate: true,
    militaryCategory: true,
    militaryCommissariat: true,
    graduateYear: true,
    orderType: true,
    orderCategory: true,
    orderNumber: true,
    orderDate: true,
    trainingLevel: true,
    studyForm: true,
    educationWay: true,
    educationProgram: true,
    finishYear: true,
    academicState: true,
    course: true,
    startYear: true,
    faculty: true,
    eduGroup: true,
    isBudget: true,
    scientificPerson: true,
    dissertationDate: true,
    vakYear: true,
    isWork: true,
    customerName: true,
    organizationName: true,
    post: true,
    benefit: true,
    elder: true,
    dictAchievements: true,
    hasAchievements: true,
    hasOrder: true,
    budgetCategory: true,
    budgetSubCategory: true,
    employer: true,
    institution: true,
    thisInstitution: true,
    secondEducation: true,
    departments: true,
    employed: true,
    map: true,
    orderStartDate: true,
    approveVAKDate: true,
    filial: true,
  };

  public groupPersonalData: boolean = true;
  public groupOther: boolean = true;
  public groupEducation: boolean = true;
  public groupAll: boolean = false;

  public loadingTreeData: boolean = false;

  ngAfterViewInit() {
    let elements = document.querySelectorAll('.k-datepicker input');

    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('wheel', (e) => {
        e.stopImmediatePropagation();
      });
    }
  }

  public exportToExcel(grid: GridComponent | undefined): void {
    this.loaderExcelExport = true
    this.exportSelected = false
    this.allData = this.allData.bind(this);
    if (grid != undefined) grid.saveAsExcel();
    this.dateView()
  }

  public exportSelected: boolean = false

  public exportToSelected(grid: GridComponent | undefined): void {
    this.exportSelected = true
    this.allData = this.allData.bind(this);
    if (grid != undefined) grid.saveAsExcel();
    this.dateView()
  }


  public data: any[] = [{
    groupName: "Все",
    items: this.eduGroups,
  }];

  //Получение названий по guid ам

  public namehealth(id: any): DictHealth | undefined {
    return this.healths.find((x) => x.dictHealthExternalId === id);
  }

  public nameMilitaryStatus(id: Guid): MilitaryStatuses | undefined {
    return this.militaryStatuses.find((x) => x.dictMilitaryStatusExternalId === id);
  }

  public nameOrderType(id: Guid): OrderTypes | undefined {
    return this.orderTypes.find((x) => x.dictOrderTypeExternalId === id);
  }

  public nameOrderCategory(id: Guid): OrdersCategory | undefined {
    return this.orderCategories.find((x) => x.dictOrdersCategoryExternalId === id);
  }

  public nameFaculty(id: Guid | undefined): Faculty | undefined {
    return this.faculties.find((x) => x.facultyExternalId === id);
  }

  public nameOrganization(id: string): ContingentOrganization | undefined {
    return this.organizations.find((x) => x.dictOrganizationExternalId === id);
  }

  public nameBenefit(id ? : Guid | string): DictBenefit | undefined {
    if(this.benefits!=undefined)
      return this.benefits.find((x) => x.dictBenefitExternalId === id);
    else return undefined
  }

  public nameEducationProgram(id: Guid | undefined): string {
    if (id != undefined) {
      this.educationProgram = this.educationPrograms.find((s) => s.externalId == id);
      return this.educationProgram?.name + ""
    } else return ""
  }

  public nameBudgetCategory(id: string): string {
    return this.budgetCategories.find((s) => s.dictStudentBudgetCategoryExternalId == id)?.categoryName ?? ""
  }

  public nameBudgetSubCategory(id: string): string {
    return this.budgetSubCategories.find((s) => s.dictStudentBudgetSubCategoryExternalId == id)?.budgetSubCategoryName ?? ""
  }

  public nameFilial(id: string): string {
    return this.filials.find((s) => s.dictFilialExternalId == id)?.filialSName ?? ""
  }

  public expandedState = false;
  public expandedSetState = false;

  public filterSetHandler() {
    if (this.filterEdit) {
      this.savedFilterName = this.selectSavedFilterName
      this.filterRemoveHandler()
      this.filterSaveHandler()
    }
    else {
      const savsel_fltr = localStorage.getItem(this.selectSavedFilterName);
      if (savsel_fltr !== null) {
        const filters = JSON.parse(savsel_fltr);
        this.filter = filters.filter ? filters.filter: filters;
        if (filters.tempHiddenColumns)
          this.tempHiddenColumns = filters.tempHiddenColumns;
        if (filters.tempVisibleColumns)
          this.tempVisibleColumns = filters.tempVisibleColumns;
      }
    }

    if(this.tempHiddenColumns.length != 0 || this.tempVisibleColumns.length != 0)
      this.applyHandler();
    this.contingentRequest.filter = this.filter;
    this.expandedSetState = !this.expandedSetState;
    if (this.expandedState) this.expandedState = !this.expandedState;
    this.filterEdit = false
    this.filterHandler();
    this.savedFilters = this.savedFilters.sort()
  }

  public filterHandler() {
    this.filterNullCheck()
    this.filtersNumber = 0;
    for (let key in this.filter) {
      if (this.filter[key as keyof typeof this.filter] != undefined) this.filtersNumber++;
    }
    if (this.selectedEducationWay != undefined) this.filtersNumber++;
    if (this.filter.fio?.length == 0) {
      this.filtersNumber--;
    }
    if (this.filter.orderNumber?.length == 0)
    {
      this.filtersNumber--;
    }
    if (this.filtersNumber != 0) this.filters = true;
    else this.notificationService.showWarning("Фильтры не выбраны");

    this.timeZoneFix();
    if (this.expandedState) this.expandedState = !this.expandedState;
    if (this.expandedSetState) this.expandedSetState = !this.expandedSetState;

    this.getContingentPage(1);
  }

  public filterRemoveHandler() {
    if (this.selectSavedFilterName) {
      localStorage.removeItem(this.selectSavedFilterName)
      let i = this.savedFilters.indexOf(this.selectSavedFilterName)
      if (i > -1) {
        this.savedFilters.splice(i, 1)
      }
      localStorage.removeItem('savedFilters')
      if (!this.filterEdit) this.notificationService.showSuccess("Удалено")
      localStorage.setItem('savedFilters', JSON.stringify(this.savedFilters))
      if (!this.filterEdit) this.selectSavedFilterName = ""
    } else this.notificationService.showError("Сохраненный набор фильтров не выбран");
  }

  public filterEdit: boolean = false

  public filterEditHandler() {
    if (this.selectSavedFilterName) {
      this.filterEdit = true
      const savsel_fltr = localStorage.getItem(this.selectSavedFilterName);
      if (savsel_fltr !== null) this.filter = JSON.parse(savsel_fltr);
      if (this.filter != undefined) this.filters = true
    } else this.notificationService.showError("Сохраненный набор фильтров не выбран");
  }

  public filterSaveHandler() {
    this.newFilter = true
    if (this.savedFilters.find(filter => filter === this.savedFilterName) != undefined) {
      this.notificationService.showError("Фильтр с таким названием уже существует")
      this.savedFilterName = ''
    }
    else {
      this.savedFilters.push(this.savedFilterName);
      localStorage.removeItem('savedFilters');
      localStorage.setItem('savedFilters', JSON.stringify(this.savedFilters));

      let savedFilters: SavedFilters = {
        filter: this.filter,
        tempHiddenColumns: this.tempHiddenColumns,
        tempVisibleColumns: this.tempVisibleColumns,
      }
      localStorage.setItem(this.savedFilterName, JSON.stringify(savedFilters));

      this.savedFilterName = ''
      this.notificationService.showSuccess("Сохранено");
    }
  }

  public filterCloseHandler() {
    this.savedFilterName = ''
    this.newFilter = true
  }

  public addFilter() {
    this.newFilter = false
  }

  public async cleanSelectedFilters(){
    await this.filterClean()
    this.columnClean()
  }

  public async filterClean() {
    this.filters = false;
    this.checkedKeys = [];
    this.currentContingentDate = CurrentDayEnd();
    this.currentRaitingDate = new Date()
    this.filter = {
      fio: "",
      isMale: undefined,
      citizenshipId: undefined,
      ageBegin: undefined,
      ageEnd: undefined,
      graduateYear: undefined,
      dictStudentAcademicStateId: undefined, //["Учится"],
      group: undefined,
      course: undefined,
      orderType: undefined,
      orderCategory: undefined,
      orderNumber: "",
      orderBegin: undefined,
      orderEnd: undefined,
      orderStartBegin: undefined,
      orderStartEnd: undefined,
      orderStart: undefined,
      orderFinish: undefined,
      budgetCategory: undefined,
      budgetSubCategory: undefined,
      trainingLevel: undefined,
      regState: undefined,
      faculties: undefined,
      departments: undefined,
      scientificPersons: undefined,
      work: undefined,
      post: undefined,
      approveVAKDateBegin: undefined,
      approveVAKDateEnd: undefined,
      dissertationDateBegin: undefined,
      dissertationDateEnd: undefined,
      benefit: undefined,
      elder: undefined,
      commissariatId: undefined,
      militaryEnlistmentCommissariatId: undefined,
      dictMilitaryEnlistmentStatusId: undefined,
      smartSearch: '',
      institution: '',
      thisInstitution: undefined,
      secondHigherEducation: undefined,
      employed: undefined,
      map: undefined,
      filial: undefined,
    }
    this.cleanTrainingLevel()
    this.selectSavedFilterName = "";
    this.setAcademicStateDefaultValue()
    this.filter.faculties = undefined;
    this.filter.isBudgetSelected = undefined;
    this.departSelectEduGroup = this.eduGroups.slice();
    this.onBudgetChange(undefined);
    this.contingentRequest.filter = this.filter;
    this.sideFilterValueChange();
    this.treeCheckingClear();
    await this.getContingentPage(1)

    if (this.expandedState) this.expandedState = !this.expandedState;
    if (this.expandedSetState) this.expandedSetState = !this.expandedSetState;
    this.getEducationProgram(this.educationPrograms.slice())
  }

  public columnClean() {
    this.listBoxReset(true);
    this.reordinateColumns()

    for (let column = 1; column < this.columns.length; column++) {
      this.columns[column].orderIndex = 100;
      if (this.columns[column].title == "ФИО") this.columns[column].locked = false;
    }

    this.tempHiddenColumns = [];
    this.tempVisibleColumns = [];
    this.tempHiddenColumns = this.tempHiddenColumns.concat(this.hiddenColumns);
    this.tempVisibleColumns = this.tempVisibleColumns.concat(this.visibleColumns);

    if (this.expandedState) this.expandedState = !this.expandedState;
    if (this.expandedSetState) this.expandedSetState = !this.expandedSetState;
    this.getEducationProgram(this.educationPrograms.slice())
  }


  public listHideHandler() {
    this.tempHiddenColumns = [];
    this.tempVisibleColumns = [];
    this.gridHide = true;
    this.tempHiddenColumns = this.getVisibleColumns(this.tempHiddenColumns.concat(this.hiddenColumns));
    this.tempVisibleColumns = this.getVisibleColumns(this.tempVisibleColumns.concat(this.visibleColumns));
    let e = document.getElementById("grid");
    if (e != null) e.style.display = 'none';
  }

  public listBoxClose() {
    let e = document.getElementById("grid");
    if (e != null) e.style.display = 'block';
    this.gridHide = false;
  }

  public reordinateColumns() {
    for (let vcolumn = 0; vcolumn < this.visibleColumns.length; vcolumn++) {
      for (let column = 0; column < this.columns.length; column++) {
        if (this.visibleColumns[vcolumn] == this.columns[column].title) this.columns[column].orderIndex = vcolumn;
        if (this.columns[column].title == "ФИО" && this.columns[column].orderIndex > 0) this.columns[column].locked = false;
      }
    }
    this.columns = this.columns.sort((a, b) => a.orderIndex - b.orderIndex);
  }

  public applyHandler() {
    let e = document.getElementById("grid");
    if (e != null) e.style.display = 'block';

    this.hiddenColumns = [];
    this.visibleColumns = [];
    this.hiddenColumns = this.hiddenColumns.concat(this.tempHiddenColumns);
    this.visibleColumns = this.visibleColumns.concat(this.tempVisibleColumns);
    localStorage.setItem('TMP_VIS_COL', JSON.stringify(this.tempVisibleColumns));
    localStorage.setItem('TMP_HIDD_COL', JSON.stringify(this.tempHiddenColumns));
    this.reordinateColumns();
    this.gridHide = false;
  }

  //Clean Filter View Buttons
  public oneFilterClean(e: any) {
    switch (e) {
      case 1:
        this.filter.fio = "";
        break;
      case 2:
        this.filter.isMale = undefined;
        break;
      case 3:
        this.filter.citizenshipId = undefined;
        break;
      case 4:
        if (this.filter.ageBegin != undefined && this.filter.ageEnd != undefined) this.filtersNumber--;
        this.filter.ageBegin = undefined;
        this.filter.ageEnd = undefined;
        break;
      case 5:
        this.filter.regState = undefined;
        break;
      case 6:
        this.filter.dictMilitaryStatusId = undefined;
        break;
      case 7:
        this.filter.healthId = undefined;
        break;
      case 8:
        this.filter.graduateYear = undefined;
        break;
      case 9:
        if (this.filter.orderType != undefined && this.filter.orderCategory != undefined) this.filtersNumber--;
        this.filter.orderType = undefined;
        this.filter.orderCategory = undefined;
        break;
      case 10:
        this.filter.orderCategory = undefined;
        break;
      case 11:
        if (this.filter.orderBegin != undefined && this.filter.orderEnd != undefined) this.filtersNumber--;
        this.filter.orderBegin = undefined;
        this.filter.orderEnd = undefined;
        break;
      case 12:
        this.filter.trainingLevel = undefined;
        this.cleanTrainingLevel()
        break;
      case 13:
        this.filter.dictStudentAcademicStateId = undefined; //["Учится"];
        break;
      case 14:
        this.filter.faculties = undefined;
        break;
      case 15:
        this.filter.dictStudyFormId = undefined;
        break;
      case 16:
        this.filter.course = undefined;
        break;
      case 17:
        this.removeArrayTreeGroup();
        this.filter.group = undefined;
        break;
      case 18:
        this.filter.educationStandard = undefined;
        this.getEducationProgram(this.educationPrograms.slice())
        break;
      case 19:
        this.filter.isBudgetSelected = undefined;
        this.onBudgetChange(undefined);
        break;
      case 20:
        if (this.filter.studyStart != undefined && this.filter.studyFinish != undefined) this.filtersNumber--;
        this.filter.studyStart = undefined;
        this.filter.studyFinish = undefined;
        break;
      case 21:
        this.filter.scientificPersons = undefined;
        break;
      case 22:
        this.filter.work = undefined;
        break;
      case 23:
        this.filter.organizations = undefined;
        break;
      case 24:
        this.filter.organizationEmployerId = undefined;
        break;
      case 25:
        this.filter.post = undefined;
        break;
      case 26:
        if (this.filter.dissertationDateBegin != undefined && this.filter.dissertationDateEnd != undefined) this.filtersNumber--;
        this.filter.dissertationDateBegin = undefined;
        this.filter.dissertationDateEnd = undefined;
        break;
      case 27:
        if (this.filter.approveVAKDateBegin != undefined && this.filter.approveVAKDateEnd != undefined) this.filtersNumber--;
        this.filter.approveVAKDateBegin = undefined;
        this.filter.approveVAKDateEnd = undefined;
        break;
      case 28:
        this.filter.educationProgram = undefined;
        break;
      case 29:
        if (this.filter.ageStart != undefined && this.filter.ageFinish != undefined) this.filtersNumber--;
        this.filter.ageStart = undefined;
        this.filter.ageFinish = undefined;
        break;
      case 30:
        this.filter.benefit = undefined;
        break;
      case 31:
        this.filter.elder = undefined;
        break;
      case 32:
        this.filter.commissariatId = undefined;
        break;
      case 33:
        this.filter.orderNumber = "";
        break;
      case 34:
        this.removeDirectionActivity();
        this.filter.hasAchievements = undefined;
        break;
      case 35:
        this.filter.directionActivityId = undefined;
        break;
      case 36:
        this.filter.smartSearch = '';
        break;
      case 37:
        this.filter.institution = '';
        break;
      case 38:
        this.filter.thisInstitution = undefined;
        break;
      case 39:
        this.filter.secondHigherEducation = undefined;
        break;
      case 40:
        this.filter.budgetCategory = undefined;
        this.selectedBudgetSubCategories = this.budgetSubCategories.slice();
        break;
      case 41:
        this.filter.budgetSubCategory = undefined;
        break;
      case 42:
        this.filter.departments = undefined;
        break;
      case 43:
        this.filter.employed = undefined;
        break;
      case 44:
        this.filter.map = undefined;
        break;
      case 45:
        if (this.filter.orderStartBegin != undefined && this.filter.orderStartEnd != undefined) this.filtersNumber--;
        this.filter.orderStartBegin = undefined;
        this.filter.orderStartEnd = undefined;
        break;
      case 46:
        this.filter.filial = undefined;
        break;
      case 47:
        this.selectedCommissariatId = undefined;
        this.filter.militaryEnlistmentCommissariatId = undefined;
        break;
      case 48:
        this.selectedDictMilitaryStatusId = undefined;
        this.filter.dictMilitaryEnlistmentStatusId = undefined;
        break;
      case 49:
        this.filter.hasOrder = undefined;
        break;
    }
    this.filtersNumber--;
    if (this.filtersNumber == 0) this.filters = false;
    this.sideFilterValueChange();
    this.getContingentPage(1);
  }

  fileName = 'Students.xlsx';
  forceProxy = false;
  proxyURL = '';

  private changeDateTypeForExcelExport(e: ExcelExportEvent): void {
    const dateCells: number[] = [];
    const rows = e.workbook.sheets[0].rows as WorkbookSheetRow[];

    rows.forEach((row: WorkbookSheetRow) => {
      row.cells && row.cells.forEach((cell: WorkbookSheetRowCell, index: number) => {
        switch (row.type) {
          case 'header':
            if (typeof cell.value === 'string' && (cell.value.includes('дата') || cell.value.includes('Дата'))) {
              dateCells.push(index);
              return;
            }
            break;
          case 'data':
            if (!dateCells.includes(index))
              return;
            if (typeof cell.value === 'string' && cell.value != '') {
              const [day, month, year] = cell.value.split('.');
              cell.value = new Date(+year, +month - 1, +day);
            }
            cell.format = 'dd.mm.yyyy';
            break;
        }
      });
    });
  }

  onExcelExport(e: ExcelExportEvent): void {
    e.preventDefault();

    //изменяем тип дат для excel (из строки в дату)
    this.changeDateTypeForExcelExport(e);

    this.zone.runOutsideAngular(() => {
      toDataURL(e.workbook).then((dataURL: string) => {
        this.zone.run(() => this.loaderExcelExport = false);

        saveAs(dataURL, this.fileName, {
          forceProxy: this.forceProxy,
          proxyURL: this.proxyURL
        });
      });
    })
  }

  //Start Editing
  public transitionHandler({
                             dataItem,
                           }: CellClickEvent): void {
    if (!this.isInOperationMode) {
      this.contingent = dataItem;
      this.contingentService.changeContingent(this.contingent);
      window.open(`/contingent/studentmaininfo/${dataItem.studentExternalId}/personaldata/${dataItem.studPersons.studPersonExternalId}`);
    }
    else{
      const indexOfClickedItem = this.studentSelection.indexOf(dataItem.studentExternalId);

      if (indexOfClickedItem > -1) {
        this.studentSelection.splice(indexOfClickedItem, 1);
      } else {
        this.studentSelection.push(dataItem.studentExternalId);
      }

      this.selectionChange(this.studentSelection)
    }
  }


  public massOperation(): void {
    this.isInOperationMode = true;
    this.selectableSettings = true;
    this.selection = false;
  }

  public close(): void {
    this.isInOperationMode = false;
    this.selectableSettings = false;
    this.selection = true;
  }

  public selectMarked() {
    this.studentSelection.forEach((element:any) => {
      if(this.markedStudentSelection.length===0||this.markedStudentSelection.find((x)=>x.studentExternalId===element)===undefined){
        let currentPageSelection = this.allContingentStudent.find((x)=>x.studentExternalId===element)??undefined
        if(currentPageSelection!==undefined)this.markedStudentSelection.push(currentPageSelection)

        let previousPageSelection = this.tempStudentSelection.find((x)=>x.studentExternalId===element)??undefined
        if(previousPageSelection!==undefined)this.markedStudentSelection.push(previousPageSelection)
      }
    });
    this.markedStudentSelection = this.markedStudentSelection.filter((el, i, a) => el != undefined && this.studentSelection.includes(el.studentExternalId)
      && i === a.indexOf(a.find(f => f.studentExternalId === el.studentExternalId))).slice()
    this.selection = false;
  }

  public saveContingentSettings(): void{
    let contingentSaveSettings: any = {
      sort: this.sort,
      skip: this.skip,
      pageSize: this.pageSize,
      filter: this.filter,
      selectableSettings: this.selectableSettings,
      isInOperationMode: this.isInOperationMode,
      selection: this.selection,
      studentSelection: this.studentSelection,
      markedstudentSelection: this.markedStudentSelection,
      pageNumber: this.pageNumber,
      temphiddenColumns: this.tempHiddenColumns,
      tempvisibleColumns: this.tempVisibleColumns,
    }
    localStorage.setItem('contingent_settings', JSON.stringify(contingentSaveSettings));
  }

  public getToday(date: Date): string {
    date = new Date(date)
    let dateToday = date.getDate();
    return `${dateToday < 10 ? '0' : ''}${dateToday}.${
      ((date.getMonth() + 1) > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1))
    }.${date.getFullYear()}`;
  }

  public toggleEditDate() {
    this.isEditDate = !this.isEditDate;
    if (this.isEditDate) {
      this.contingentDate = this.currentContingentDate;
      this.raitingDate = this.currentRaitingDate;
    }
  }

  public acceptDate(editMode: boolean) {
    this.currentContingentDate = this.contingentDate;
    this.currentRaitingDate = this.raitingDate;

    if(editMode)
    {
      this.toggleEditDate();
      this.treeFill(this.filter.trainingLevel);
      this.getContingentPage(1);
    }
  }

  public cleanMarked(): void {
    this.studentSelection=[]
    this.markedStudentSelection = [];
    this.tempStudentSelection = [];
    this.selection = false;
  }

  //Default Item
  public defaultItemMilitaryStatuses: {
    militaryStatusName: string;dictMilitaryStatusId ? : any
  } = {
    militaryStatusName: "Все",
    dictMilitaryStatusId: null,
  };
  public defaultItemHealth: {
    healthName: string;dictHealthId ? : string | null
  } = {
    healthName: "Все",
    dictHealthId: null,
  };
  public defaultItem: {text: string;value ? : boolean} = {
    text: "Все",
    value: undefined,
  };
  public defaultItemElder: {text: string; value ? : boolean} = {
    text: "Весь контингент",
    value: undefined,
  };

  public elderList: Array <{text: string; value: boolean}> = [
    { text: "Только старосты", value: true},
    //{ text: "Нет", value: false},
  ];

  public educationList: Array <{text: string; value: boolean}> = [
    { text: "Да", value: true},
  ];

  public employedList: Array <{text: string; value: boolean}> = [
    { text: "Да", value: true},
    { text: "Нет", value: false},
  ];

  public hasOrderList: Array <{text: string; value: boolean}> = [
    { text: "Есть приказ", value: true},
    { text: "Нет приказа", value: false},
  ]

  public mapList: Array <{text: string; value: boolean}> = [
    { text: "Включен в реестр", value: true},
    { text: "Не включен в реестр", value: false},
  ];

  public listIsMale: Array < {
    text: string;value: boolean
  } > = [{
    text: "Мужской",
    value: true
  },
    {
      text: "Женский",
      value: false
    },
  ];

  public defaultItemHasAchievements: {text: string;value ? : boolean} = {
    text: "Весь контингент",
    value: undefined,
  };

  public listHasAchievements: Array < {
    text: string;value: boolean
  } > = [{
    text: "Есть",
    value: true
  },
    {
      text: "Нет",
      value: false
    },
  ];

  public defaultItemCategory: {
    categoryName: string;dictOrdersCategoryId: number
  } = {
    categoryName: "Все",
    dictOrdersCategoryId: 0,
  };

  public pageChange(event: PageChangeEvent): void {
    if(this.isInOperationMode) this.studentSelection.forEach((element:any) => {
      let student = this.allContingentStudent.find((x)=>x.studentExternalId===element)
      if(student) this.tempStudentSelection.push(student)})
    this.skip = event.skip;
    this.pageSize = event.take;
    this.pageNumber = event.skip / event.take + 1
    this.getContingentPage(event.skip / event.take + 1);
    this.loadItems();
  }

  public selectionChange(selection: any):void{
    this.studentSelection = []
    this.studentSelection = selection.slice()
  }

  public firstElement = "№ п/п"

  public  listBoxChange(dataItem:any):void{
    if(!this.moveButtons.includes(dataItem)) {
      this.tempHiddenColumns.sort()
      if(this.tempHiddenColumns.includes(this.firstElement))this.setOnFirstPosition(this.tempHiddenColumns)
      /*this.tempVisibleColumns.sort()
      if(this.tempVisibleColumns.includes(this.firstElement))this.setOnFirstPosition(this.tempVisibleColumns)*/
    }
  }

  public setOnFirstPosition(arr:any[]):void{
    arr.unshift(...arr.splice(arr.indexOf(this.firstElement),1))
  }

  private loadItems(): void {
    this.gridView = {
      data: this.allContingentStudent,
      total: this.count,
    };
  }

  //ContingentPage
  public getContingentPage(page: number) {
    this.loading = true;
    this.pageNumber = page;
    this.skip = (this.pageNumber - 1) * this.pageSize;
    this.page.pageNumber = page;
    this.page.pageSize = this.pageSize;
    if (this.filter.healthId != undefined) this.contingentRequest.filter.healthId = this.filter.healthId.toString()
    //Контингент на дату
    this.contingentRequest.filter.contingentDate = this.currentContingentDate
    this.contingentRequest.filter.markDate = TimeZoneFix(this.currentRaitingDate)
    this.contingentRequest.sort = this.sort;

    this.contingentService.updateStudentPage(this.contingentRequest);
    this.dateView()
  }

  public allData = (): any => {
    this.timeZoneFix()
    this.pageExcel.pageNumber = 1;
    this.pageExcel.pageSize = this.count;
    this.contingentRequestExcel.sort = this.sort;
    this.contingentRequestExcel.filter = this.filter;
    if (this.exportSelected) return {
      data: this.markedStudentSelection,
      total: this.count,
    }
    return this.contingentService.getStudentsExport(this.contingentRequestExcel)
  };

  public getAllFaculty() {
    this.departmentService.getAllFaculty(2)
      .subscribe(
        response => {
          this.faculties = response;
          this.filteredFaculties = response.filter(_ => _.filialId == this.filter.filial);
        }
      );
  }

  public getAllDepartment() {
    this.departmentService.getAllDepartment()
      .subscribe(
        response => {
          this.departments = response;
        }
      );
  }

  public getAllDictAchievements() {
    this.dictAchievementService.getCategoryAchievement()
      .subscribe(
        response => {
          this.dictAchievements = response;
          this.filteredDictAchievements = response.filter(_ => _.filialId == this.filter.filial);
        }
      )
  }

  public getAllEmployerPost() {
    this.employerPostService.getAllEmployerPost()
      .subscribe(
        response => {
          this.employerPosts = response;
        }
      )
  }

  public getAllPersons() {
    this.personsService.getAllperson()
      .subscribe(
        response => {
          this.persons = response;
        }
      );
  }

  public getMilitaryStatuses() {
    this.militaryStatusesService.getAllMilitaryStatuses()
      .subscribe(
        response => {
          this.militaryStatuses = response;
        }
      );
  }

  public getAllMilitaryCommissariat() {
    this.militaryCommissariatService.getAllMilitaryCommissariat()
      .subscribe(
        response => {
          this.militaryCommissariats = response;
        }
      );
  }

  public getMilitarystockcategories() {
    this.militaryStockCategoriesService.getAllMilitaryStockCategories()
      .subscribe(
        response => {
          this.militaryStockCategories = response;
        }
      );
  }

  public getAllIdentificationTypes() {
    this.identificationTypeService.getAllIdentificationType()
      .subscribe(
        response => {
          this.identificationTypes = response;
        }
      );
  }

  public getAllEducationDocTypes() {
    this.educationDocTypesService.getAllEducationDocTypes()
      .subscribe(
        response => {
          this.educationDocTypes = response;
        }
      );
  }

  public getAllEducationProgram() {
    this.educationPlanService.getAllEducationProgramName()
      .subscribe(
        response => {
          this.educationPrograms = response.filter((x) => (this.selectionEducationStandard.map(a => a.name)).includes(x.standardName))
          this.getEducationProgram(this.educationPrograms.slice())
        }
      );
  }

  public getAllFilials() {
    this.dictFilialService.getAllfilial()
      .subscribe(
        response => {
          this.filials = response;
          this.setFilialFilter();
        }
      );
  }

  public setFilialFilter(){
    if (this.filials && this.filials.length > 0) {
      this.filter.filial = !this.currentFilial
        ? this.filials[0].dictFilialExternalId?.toString()
        : this.currentFilial;
    }
  }

  public getEducationProgram(arr:any[]){
    this.selectEducationProgram = [...arr].reduce((a:any[], c) => {
      if (Object.values(a).map((e:any)=>e.name).indexOf(c.name) === -1) a.push(c);
      return a;},[])
  }

  public getAllEducationStandard() {
    this.educationPlanService.getAllEducationStandard()
      .subscribe(
        response => {
          this.educationStandard = response;
          this.selectionEducationStandard = this.educationStandard.slice();
          this.getAllEducationProgram()
          this.onSelectionEduGroup()
        }
      );
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next: (response) => {
          this.editable = response.accessLevel != 1;
          this.currentFilial = response.filialId;
          this.setFilialFilter();
        },
        error: () => {}
      });
  }

  public getBudget(): void {
    this.budgetService.getAllBudget()
      .subscribe(
        response => {
          this.budgets = response
          this.filteredBudgets = response.filter(_ => _.filialId == this.filter.filial);
        }
      );
  }

  public getAllBudgetCategories() {
    this.budgetCategoryService.getAllBudgetCategory()
      .subscribe(
        response => {
          this.budgetCategories = response;
          this.selectedBudgetCategories = this.budgetCategories.slice().filter(_ => _.filialId == this.filter.filial);
        });
  }

  public getBudgetSubCategory(): void {
    this.budgetSubCategoryService.getAllBudgetSubCategory()
      .subscribe(
        response => {
          this.budgetSubCategories = response;
          this.selectedBudgetSubCategories = this.budgetSubCategories.slice().filter(_ => _.filialId == this.filter.filial);
        }
      );
  }

  public getAllStudyLevel() {
    this.commonDictService.getAllStudyLevel()
      .subscribe(
        response => {
          this.studyLevels = response;
        }
      );
  }

  public getAllTrainingLevel() {
    this.commonDictService.getAllTrainingLevel()
      .subscribe(
        response => {
          this.trainingLevels = response;
          this.filter.trainingLevel = Array.from(this.trainingLevels, (x: DictTrainingLevel) => x.dictTrainingLevelExternalId!);
          this.trainingLevels.sort((a, b) => a.orderNum - b.orderNum);
          /*
          if (this.trainingLevels.length === 1) {
            this.filter.trainingLevel = this.trainingLevels[0].dictTrainingLevelExternalId
          } else {
            this.trainingLevels.unshift({
              dictTrainingLevelId: 0,
              trainingLevelName: "Все",
              dictTrainingLevelExternalId: ""
            })
            this.filter.trainingLevel = ""
          }*/
        }
      );
  }

  public getAllStudyForm() {
    this.commonDictService.getAllStudyForm()
      .subscribe(
        response => {
          this.dictStudyForms = response;
        }
      );
  }

  public getCitizenship(): void {
    this.citizenshipService.getAllCitizenship()
      .subscribe(
        response => {
          this.citizenships = response;
          for (let i = 0; i < this.citizenships.length; i++) {
            //ToDo
            if (this.citizenships[i].country == "Российская Федерация" || this.citizenships[i].country == "Россия") {
              this.citizenships.splice(0, 0, this.citizenships.splice(i, 1)[0]);
              return
            }
          }
        }
      );
  }

  public getAllEduGroup() {
    this.eduGroupService.getAlledugroup(2)
      .subscribe(
        response => {
          this.eduGroups = response;
          this.courses = this.courses.filter((x) => (this.eduGroups.map(a => a.courseNum)).includes(x.courseNum))
          this.departSelectEduGroup = this.eduGroups.slice();
          this.onSelectionEduGroup()
        }
      );
  }

  public getDictState() {
    this.dictStateService.getAllstate()
      .subscribe(
        response => {
          this.dictStates = response;
        }
      );
  }

  public getOrganization(): void {
    this.organizationService.getAllorganization()
      .subscribe(
        response => {
          this.organizations = response;
        }
      );
  }

  public getAllHealths() {
    this.dictHealthService.getAllHealth()
      .subscribe(
        response => {
          this.healths = response;
        }
      );
  }

  public getAllBenefits() {
    this.dictBenefitService.getAllBenefit()
      .subscribe(
        response => {
          this.benefits = response;
        }
      );
  }

  //Order Types
  public getAllOrderTypes() {
    this.orderTypesService.getAllordertypes(false)
      .subscribe(
        response => {
          this.orderTypes = response;
          this.selectOrderTypes = response.slice().filter(_ => _.filialId == this.filter.filial);
        }
      );
  }

  //OrderCategory
  public getAllOrdersCategory() {
    this.ordersCategoryService.getAllorderscategory(false)
      .subscribe(
        response => {
          this.orderCategories = response.filter((item, i, arr) => arr.findIndex((t) => t.categoryName === item.categoryName) === i);
          this.selectOrderCategories = this.orderCategories.slice().filter(_ => _.filialId == this.filter.filial);
        }
      );
  }

  //AcademicState
  getAllAcademicState() {
    this.academicStateService.getAllAcademicState()
      .subscribe(
        response => {
          this.dictStudentAcademicStates = response;

          this.setAcademicStateDefaultValue()

          /*if (this.dictStudentAcademicStates && this.dictStudentAcademicStates.length > 1) {
            let item = new AcademicState();
            item.dictStudentAcademicStateExternalId = "";
            item.academicStateName = 'Все';
            this.dictStudentAcademicStates.unshift(item);
          }*/
          if(this.filterContingentSaved !== undefined) {
            this.filter = this.filterContingentSaved
            this.contingentRequest.filter = this.filter
          }
          this.getContingentPage(this.pageNumber);

        }
      );
  }

  setAcademicStateDefaultValue(){
    this.filter.dictStudentAcademicStateId = []

    const study = this.dictStudentAcademicStates.find(_ => _.academicStateCategory === this.academicState.study)?.dictStudentAcademicStateExternalId.toString();
    if (study)
      this.filter.dictStudentAcademicStateId.push(study)

    const transferred = this.dictStudentAcademicStates.find(_ => _.academicStateCategory === this.academicState.transferred)?.dictStudentAcademicStateExternalId.toString();
    if (transferred)
      this.filter.dictStudentAcademicStateId.push(transferred)

    if(!study && !transferred)
      this.filter.dictStudentAcademicStateId = undefined;

  }

  public timeZoneFix() {
    if(this.filter.orderBegin) {
      this.orderBegin = this.filter.orderBegin
      this.filter.orderBegin = TimeZoneFix(this.filter.orderBegin);
    }
    if(this.filter.orderEnd) {
      this.orderEnd = this.filter.orderEnd
      this.filter.orderEnd = TimeZoneFix(this.filter.orderEnd);
    }
    if(this.filter.orderStartBegin) {
      this.orderStartBegin = this.filter.orderStartBegin
      this.filter.orderStartBegin = TimeZoneFix(this.filter.orderStartBegin);
    }
    if(this.filter.orderStartEnd) {
      this.orderStartEnd = this.filter.orderStartEnd
      this.filter.orderStartEnd = TimeZoneFix(this.filter.orderStartEnd);
    }
    if(this.filter.dissertationDateBegin) {
      this.dissertationDateBegin = this.filter.dissertationDateBegin
      this.filter.dissertationDateBegin = TimeZoneFix(this.filter.dissertationDateBegin);
    }
    if(this.filter.dissertationDateEnd) {
      this.dissertationDateEnd = this.filter.dissertationDateEnd
      this.filter.dissertationDateEnd = TimeZoneFix(this.filter.dissertationDateEnd);
    }
    if(this.filter.approveVAKDateBegin) {
      this.vakDateBegin = this.filter.approveVAKDateBegin
      this.filter.approveVAKDateBegin = TimeZoneFix(this.filter.approveVAKDateBegin);
    }
    if(this.filter.approveVAKDateEnd) {
      this.vakDateEnd = this.filter.approveVAKDateEnd
      this.filter.approveVAKDateEnd = TimeZoneFix(this.filter.approveVAKDateEnd);
    }
  }

  public dateView(){
    if(this.filter.orderBegin) this.filter.orderBegin = this.orderBegin
    if(this.filter.orderEnd) this.filter.orderEnd = this.orderEnd
    if(this.filter.dissertationDateBegin) this.filter.dissertationDateBegin = this.dissertationDateBegin
    if(this.filter.dissertationDateEnd) this.filter.dissertationDateEnd = this.dissertationDateEnd
    if(this.filter.approveVAKDateBegin) this.filter.approveVAKDateBegin = this.vakDateBegin
    if(this.filter.approveVAKDateEnd) this.filter.approveVAKDateEnd = this.vakDateEnd
    if(this.filter.orderStartBegin) this.filter.orderStartBegin = this.orderStartBegin
    if(this.filter.orderStartEnd) this.filter.orderStartEnd = this.orderStartEnd

  }

  public isHidden(columnName: string): boolean {
    if(!(this.hiddenColumns.indexOf(columnName) > -1||this.visibleColumns.indexOf(columnName) > -1)) return true
    else return this.hiddenColumns.indexOf(columnName) > -1;
  }

  public onSelectionChange(event: TreeItem): void {
    //var i = this.checkedKeys.indexOf(event.dataItem.id)
    if (event.index.length >= 7)
    {
      this.checkedKeys = []
      this.checkedKeys.push(event.dataItem.id)
      this.onCheckedChange()
    }
  }

  public onDepartmentChange(): void {
    this.onSelectionEduGroup()
  }

  public onTrainingLevelChange(): void {
    this.treeFill(this.filter.trainingLevel)
    this.filterNullCheck()
    if (this.filter.trainingLevel) {
      this.filter.group = undefined
      this.filter.educationStandard = undefined
    }
    this.onSelectionEduGroup()
  }

  public onStudyFormChange(): void {
    this.onSelectionEduGroup()
  }

  public onGroupChange(): void {
    this.onSelectionEduGroup()
  }

  public onCourseChange(): void {
    this.onSelectionEduGroup()
  }

  public onStandardChange(ids: any): void {
    this.getEducationProgram(this.educationPrograms?.filter((s) => s.standardFullName == ids).slice())
    if (ids == null) {
      this.filter.educationProgram = undefined
      this.getEducationProgram(this.educationPrograms.slice())
    }
  }

  public onTypeChange(id: Guid): void {
    this.selectOrderCategories = this.orderCategories.filter((s) => s.dictOrderTypeId == id).slice();
    if(id === undefined) this.selectOrderCategories = this.orderCategories.slice();
    this.filter.orderCategory = undefined;
    this.filter.orderBegin = undefined;
    this.filter.orderEnd = undefined;
    this.filter.orderStartBegin = undefined;
    this.filter.orderStartEnd = undefined;
    this.filter.orderStart = undefined;
    this.filter.orderFinish = undefined;
  }

  public rowCallback = () => {
    if (!this.isInOperationMode) return { notSelected: true };
    return ""
  };


  public courses: Array <{course: string;courseNum: number}> =
    [
      { course: "0 курс", courseNum: 0 },
      { course: "1 курс", courseNum: 1 },
      { course: "2 курс", courseNum: 2 },
      { course: "3 курс", courseNum: 3 },
      { course: "4 курс", courseNum: 4 },
      { course: "5 курс", courseNum: 5 },
      { course: "6 курс", courseNum: 6 },
    ];

  public filterNullCheck(): void {
    if (this.filter.dictStudentAcademicStateId?.length == 0) this.oneFilterClean(13);
    if (this.filter.trainingLevel?.length == 0) this.oneFilterClean(12);
    if (this.filter.citizenshipId?.length == 0) this.oneFilterClean(3);
    if (this.filter.dictStudyFormId?.length == 0) this.oneFilterClean(15);
    if (this.filter.course?.length == 0) this.oneFilterClean(16);
    if (this.filter.group?.length == 0) this.oneFilterClean(17);
    if (this.filter.faculties?.length == 0) this.oneFilterClean(14);
    if (this.filter.regState?.length == 0) this.oneFilterClean(5);
    if (this.filter.commissariatId?.length == 0) this.oneFilterClean(32);
    if (this.filter.militaryEnlistmentCommissariatId?.length == 0) this.oneFilterClean(47);
    if (this.filter.isBudgetSelected?.length == 0) this.oneFilterClean(19);
    if (this.filter.directionActivityId?.length == 0) this.oneFilterClean(35);
  }

  public removeArrayTreeGroup() {
    this.treeCheckingClear()
  }

  public removeDirectionActivity() {
    this.filter.directionActivityId = undefined;
  }

  public sideFilterValueChange() {
    if (this.filter.trainingLevel != null && this.filter.dictStudentAcademicStateId?.length) {
      this.treeFill(this.filter.trainingLevel);
      this.treeViewVisibility = true;
    } else this.treeViewVisibility = false;
    this.expandedState = !this.expandedState;

    this.onValueChange()
    this.filterNullCheck();

    this.filtersNumber = 0;
    for (let key in this.filter) {
      if (this.filter[key as keyof typeof this.filter] != undefined) this.filtersNumber++;
    }
    if (this.filter.trainingLevel?.length == 0) this.filtersNumber--;
    if (this.filter.faculties != undefined) this.filtersNumber++;
    if (this.selectedEducationWay != undefined) this.filtersNumber++;
    if (this.filter.isBudgetSelected != undefined) this.filtersNumber++;
    if (this.filter.fio?.length == 0) this.filtersNumber--;
    if (this.filter.orderNumber?.length == 0) this.filtersNumber--;
    if (this.filtersNumber != 0) this.filters = true;

    this.timeZoneFix();
    this.expandedState = !this.expandedState;
    this.getContingentPage(1);
  }

  public checkedKeys: any[] = [];
  public expandedKeys: any[] = [];

  public key = "id";

  public get checkableSettings(): CheckableSettings {
    return {
      checkChildren: true,
      checkParents: false,
    };
  }

  public children = (dataItem: any): Observable < any[] > => of(dataItem.value);
  public hasChildren = (dataItem: any): boolean => !!dataItem.value;
  public isExpanded = (dataItem: any, index: string) => {
    return this.expandedKeys.indexOf(index) > -1;
  };

  // Custom logic handling Indeterminate state when custom data item property is persisted
  public isChecked = (dataItem: any): CheckedState => {
    if (this.containsItem(dataItem)) {
      return "checked";
    }

    if (dataItem.value && this.isIndeterminate(dataItem.value!)) {
      return "indeterminate";
    }
    return "none";
  };

  private containsItem(item: any): boolean {
    return this.checkedKeys.indexOf(item[this.key]) > -1;
  }

  private onSelectionEduGroup() {
    this.departSelectEduGroup = this.eduGroups.slice()
    this.getEducationProgram(this.educationPrograms.slice())
    this.selectionEducationStandard = this.educationStandard.slice()
    if (this.filter.trainingLevel && this.filter.trainingLevel.length !== 0) {
      let selection = []
      for (const trainingLevel of this.filter.trainingLevel) {
        let selectGroup = this.departSelectEduGroup.filter((s) => s.trainingLevelId === trainingLevel)
        for (let i = 0; i < selectGroup.length; i++) {
          selection.push(selectGroup[i])
        }
      }
      this.departSelectEduGroup = selection;
    }
    if (this.filter.faculties !== undefined && this.filter.faculties.length !== 0) {
      let selection = []
      for (const faculty of this.filter.faculties) {
        let selectGroup = this.departSelectEduGroup.filter((s) => s.facultyId == faculty)
        for (let i = 0; i < selectGroup.length; i++) {
          selection.push(selectGroup[i])
        }
      }
      this.departSelectEduGroup = selection;
    }
    if (this.filter.dictStudyFormId !== undefined && this.filter.dictStudyFormId.length !== 0) {
      let selection = []
      for (const studyForm of this.filter.dictStudyFormId) {
        let selectGroup = this.departSelectEduGroup.filter((s) => s?.studyFormExternalId == studyForm)
        for (let i = 0; i < selectGroup.length; i++) {
          selection.push(selectGroup[i])
        }
      }
      this.departSelectEduGroup = selection;
    }
    if (this.filter.course !== undefined && this.filter.course.length !== 0) {
      let selection = []
      for (const course of this.filter.course) {
        let selectGroup = this.departSelectEduGroup.filter((s) => s?.courseNum == course)
        for (let i = 0; i < selectGroup.length; i++) {
          selection.push(selectGroup[i])
        }
      }
      this.departSelectEduGroup = selection;
    }
    if (this.departSelectEduGroup.length > 0 && this.educationStandard.length > 0 && this.departSelectEduGroup.length !== this.eduGroups.length) {
      let selection = []
      let eduGroups = []
      if(this.filter.group !== undefined && this.filter.group.length !== 0)
        for (const eduGroup of this.filter.group)
          eduGroups.push(this.departSelectEduGroup.find((s) => s?.eduGroupExternalId == eduGroup))
      else eduGroups = this.departSelectEduGroup

      for (const eduGroup of eduGroups) {
        let standard = this.educationStandard.filter((s) => s?.educationStandardExternalId == eduGroup?.educationStandardId)
        for (let i = 0; i < standard.length; i++) {
          selection.push(standard[i])
        }
      }
      this.selectionEducationStandard = Array.from(new Set(selection));
    }
    if(this.departSelectEduGroup.length === 0  && this.eduGroups.length !== 0)
    {
      this.selectionEducationStandard = []
      this.getEducationProgram([])
    }
    if(this.departSelectEduGroup.length > 0 && this.educationPrograms.length > 0 && this.selectionEducationStandard.length !== this.educationStandard.length){
      let selection = []
      for (const standard of this.selectionEducationStandard) {
        let program = this.educationPrograms.filter((s) => s.educationStandardId == standard.educationStandardExternalId)
        for (let i = 0; i < program.length; i++) {
          selection.push(program[i])
        }
      }
      this.getEducationProgram(selection.slice())
    }
    if (this.filter.educationStandard) {
      this.getEducationProgram(
        this.educationPrograms?.filter((program) =>
          program.standardFullName == this.filter.educationStandard).slice()
      );
    }
  }

  public onNodeClick(event: NodeClickEvent): void {
    if (event.item) {
      const index = this.expandedKeys.indexOf(event.item.index)
      if (index > -1)
        this.expandedKeys.splice(index, 1)
      else
        this.expandedKeys.push(event.item.index)
    }

    if(this.isGroupOpen) this.openGroup(event.item?.dataItem.id.split("_").pop())
    this.isGroupOpen = false
  }

  public treeCheckingClear() {
    this.checkedKeys = []
    this.disableEduFilters = false
    this.filter.group = undefined
    this.getContingentPage(1);
  }

  public onOpenGroup() {
    this.isGroupOpen = true
  }

  public openGroup(id: any) {
    window.open(`/contingent/editacademicgroups/${id}`);
  }

  public iconClass(): any {
    return "k-i-gear k-icon"
  }

  public async onCheckedChange() {
    this.disableEduFilters = this.checkedKeys.length > 0
    this.filter.faculties = []
    this.filter.dictStudyFormId = []
    this.filter.group = []
    this.filter.course = []
    this.departSelectEduGroup = []

    for (const selection of this.checkedKeys) {
      for (const eduGroup of this.eduGroups) {
        if (eduGroup.eduGroupExternalId == selection.split("_").pop() && !this.filter.group.find(s => s === eduGroup.eduGroupExternalId!)) {
          this.filter.group.push(eduGroup.eduGroupExternalId!)
        }
      }
    }
    if (this.filter.faculties.length == 0) this.filter.faculties = undefined
    if (this.filter.dictStudyFormId.length == 0) this.filter.dictStudyFormId = undefined
    if (this.filter.group.length == 0) this.filter.group = undefined
    if (this.filter.course.length == 0) this.filter.course = undefined
    this.timeZoneFix();

    this.filters = !(this.filter.faculties == undefined &&
      this.filter.dictStudyFormId == undefined &&
      this.filter.group == undefined &&
      this.filter.course == undefined &&
      this.filter.dictStudentAcademicStateId == undefined);

    await this.getContingentPage(1);
    this.onSelectionEduGroup()
  }

  private isIndeterminate(items: any[] = []): boolean {
    let idx = 0;
    let item;

    while ((item = items[idx])) {
      if ((item.value && this.isIndeterminate(item.value!)) || this.containsItem(item)) {
        return true;
      }

      idx += 1;
    }
    return false;
  }

  public treeFill(id ? : any): void {
    this.loadingTreeData = true;
    this.departmentService.updateDepartmentTree({
      contingentDate :  this.currentContingentDate,
      trainingLevelId : (id && id.length == 0 ? undefined : id)
    })
  }

  public dataTree: any[] = [];

  public toolbarSettingsFirst: ListBoxToolbarConfig = {
    tools: ["transferTo", "transferFrom", "transferAllTo", "transferAllFrom", "moveUp", "moveDown"],
  }

  public moveButtons: any[] = [
    "moveUp",
    "moveDown",
  ]

  listBoxReset(saved: boolean): void {
    const oldData_vis = localStorage.getItem(saved ? 'VIS_COL' : 'TMP_VIS_COL');
    const oldData_hid = localStorage.getItem(saved ? 'HIDD_COL' : 'TMP_HIDD_COL');

    if (oldData_vis !== null) this.visibleColumns = JSON.parse(oldData_vis);
    else this.visibleColumns = []

    if (oldData_hid !== null) this.hiddenColumns = JSON.parse(oldData_hid);
    else this.hiddenColumns = []

    if ( this.hiddenColumns.length === 0 && this.visibleColumns.length === 0 && saved){
      this.hiddenColumns = listbox.slice()
      this.visibleColumns = selectlistbox.slice()
    }

    if ( this.hiddenColumns.length === 0 && this.visibleColumns.length === 0 && !saved){
      this.listBoxReset(true)
    }

    if (saved) {
      localStorage.setItem('TMP_VIS_COL', JSON.stringify(this.visibleColumns));
      localStorage.setItem('TMP_HIDD_COL', JSON.stringify(this.hiddenColumns));
    }
  }

  public cleanTrainingLevel() {
    if (!this.filter.trainingLevel || this.filter.trainingLevel.length == 0) {
      this.filter.trainingLevel = Array.from(this.trainingLevels, (x: DictTrainingLevel) => x.dictTrainingLevelExternalId!) ;
      this.isCheckedMultiSelectTrainingLevel = true;
    }
  }

  //Пункт "Выбрать все" в выпадающем списке гражданства

  public isCheckedMultiSelect = false;
  public isCheckedMultiSelectAcademicState = false;
  public isCheckedMultiSelectTrainingLevel = true;
  public isCheckedMultiSelectBudgetState = false;
  public isCheckedMultiSelectDirectionActivity = false;
  public isCheckedMultiSelectOrganization = false;
  public isCheckedMultiSelectDepartment = false;
  public isCheckedMultiSelectScientific = false;
  public isCheckedMultiSelectPost = false;

  public isIndet(filter: number) {
    if (filter == this.multiselectEnum.citizenship && this.filter.citizenshipId != undefined)
      return (
        this.filter.citizenshipId.length !== 0 && this.filter.citizenshipId.length !== this.citizenships.length
      );
    if (filter == this.multiselectEnum.academicState && this.filter.dictStudentAcademicStateId != undefined)
      return (
        this.filter.dictStudentAcademicStateId.length !== 0 && this.filter.dictStudentAcademicStateId.length !== this.dictStudentAcademicStates.length
      );
    if (filter == this.multiselectEnum.budget && this.filter.isBudgetSelected != undefined)
      return (
        this.filter.isBudgetSelected.length !== 0 && this.filter.isBudgetSelected.length !== this.budgets.length
      );
    if (filter == this.multiselectEnum.trainingLevel && this.filter.trainingLevel != undefined)
      return (
        this.filter.trainingLevel.length !== 0 && this.filter.trainingLevel.length !== this.trainingLevels.length
      );
    if (filter == this.multiselectEnum.dictAchievements && this.filter.directionActivityId !== undefined)
      return (
        this.filter.directionActivityId.length !== 0 && this.filter.directionActivityId.length !== this.dictAchievements.length
      );
    if (filter == this.multiselectEnum.organization && this.filter.organizations !== undefined)
      return (
        this.filter.organizations.length !== 0 && this.filter.organizations.length !== this.organizations.length
      );
    if (filter == this.multiselectEnum.department && this.filter.departments !== undefined)
      return (
        this.filter.departments.length !== 0 && this.filter.departments.length !== this.departments.length
      );
    if (filter == this.multiselectEnum.scientificPerson && this.filter.scientificPersons !== undefined)
      return (
        this.filter.scientificPersons.length !== 0 && this.filter.scientificPersons.length !== this.persons.length
      );
    if (filter == this.multiselectEnum.post && this.filter.post !== undefined)
      return (
        this.filter.post.length !== 0 && this.filter.post.length !== this.employerPosts.length
      );
    return false
  }

  public get toggleAllText() {
    return this.isCheckedMultiSelect ? "Снять выбор" : "Выбрать все";
  }

  public isItemSelected(item: any, filter: number) {
    if (filter == this.multiselectEnum.citizenship && this.filter.citizenshipId != undefined)
      return this.filter.citizenshipId.some((x) => x === item.citizenshipExternalId);
    if (filter == this.multiselectEnum.academicState && this.filter.dictStudentAcademicStateId != undefined)
      return this.filter.dictStudentAcademicStateId.some((x) => x === item.dictStudentAcademicStateExternalId);
    if (filter == this.multiselectEnum.budget && this.filter.isBudgetSelected != undefined)
      return this.filter.isBudgetSelected.some((x) => x === item.dictStudentBudgetExternalId);
    if (filter == this.multiselectEnum.trainingLevel && this.filter.trainingLevel != undefined)
      return this.filter.trainingLevel.some((x) => x === item.dictTrainingLevelExternalId);
    if (filter == this.multiselectEnum.directionActivityId && this.filter.directionActivityId != undefined)
      return this.filter.directionActivityId.some((x) => x === item.id);
    if (filter == this.multiselectEnum.organization && this.filter.organizations != undefined)
      return this.filter.organizations.some((x) => x === item.dictOrganizationExternalId);
    if (filter == this.multiselectEnum.department && this.filter.departments != undefined)
      return this.filter.departments.some((x) => x === item.externalId);
    if (filter == this.multiselectEnum.scientificPerson && this.filter.scientificPersons != undefined)
      return this.filter.scientificPersons.some((x) => x === item.personExternalId);
    if (filter == this.multiselectEnum.post && this.filter.post != undefined)
      return this.filter.post.some((x) => x === item.externalId);
    return false;
  }

  public onClick(filter: number, side?: boolean) {
    if (filter == this.multiselectEnum.citizenship) {
      this.isCheckedMultiSelect = !this.isCheckedMultiSelect;
      this.filter.citizenshipId = this.isCheckedMultiSelect ? Array.from(this.citizenships.slice().reverse(), x => x.citizenshipExternalId!) : undefined;
    }
    if (filter == this.multiselectEnum.academicState) {
      this.isCheckedMultiSelectAcademicState = !this.isCheckedMultiSelectAcademicState;
      this.filter.dictStudentAcademicStateId = this.isCheckedMultiSelectAcademicState ? Array.from(this.dictStudentAcademicStates.slice().reverse(), x => x.dictStudentAcademicStateExternalId!) : undefined;
      if(side) this.sideFilterValueChange()
    }
    if (filter == this.multiselectEnum.trainingLevel) {
      this.isCheckedMultiSelectTrainingLevel = !this.isCheckedMultiSelectTrainingLevel;
      this.filter.trainingLevel = this.isCheckedMultiSelectTrainingLevel ? Array.from(this.trainingLevels.slice().reverse(), x => x.dictTrainingLevelExternalId!) : undefined;
      if (side && this.filter.trainingLevel && this.filter.trainingLevel.length !== 0)
        this.sideFilterValueChange()
    }
    if (filter == this.multiselectEnum.budget) {
      this.isCheckedMultiSelectBudgetState = !this.isCheckedMultiSelectBudgetState;
      this.filter.isBudgetSelected = this.isCheckedMultiSelectBudgetState ? Array.from(this.budgets.slice().reverse(), x => x.dictStudentBudgetExternalId!) : undefined;
      this.selectedBudgetCategories = this.budgetCategories.slice();
      this.selectedBudgetSubCategories = this.budgetSubCategories.slice();
    }
    if (filter == this.multiselectEnum.directionActivities) {
      this.isCheckedMultiSelectDirectionActivity = !this.isCheckedMultiSelectDirectionActivity;
      this.filter.directionActivityId = this.isCheckedMultiSelectDirectionActivity ? Array.from(this.dictAchievements.slice().reverse(), x => x.id!) : undefined;
      this.onAcievementChange();
    }
    if (filter == this.multiselectEnum.organization) {
      this.isCheckedMultiSelectOrganization = !this.isCheckedMultiSelectOrganization;
      this.filter.organizations = this.isCheckedMultiSelectOrganization ? Array.from(this.organizations.slice().reverse(), x => x.dictOrganizationExternalId!) : undefined;
    }
    if (filter == this.multiselectEnum.department) {
      this.isCheckedMultiSelectDepartment = !this.isCheckedMultiSelectDepartment;
      this.filter.departments = this.isCheckedMultiSelectDepartment ? Array.from(this.departments.slice().reverse(), x => x.externalId!) : undefined;
    }
    if (filter == this.multiselectEnum.scientificPerson) {
      this.isCheckedMultiSelectScientific = !this.isCheckedMultiSelectScientific;
      this.filter.scientificPersons = this.isCheckedMultiSelectScientific ? Array.from(this.persons.slice().reverse(), x => x.personExternalId!) : undefined;
    }
    if (filter == this.multiselectEnum.post) {
      this.isCheckedMultiSelectPost = !this.isCheckedMultiSelectPost;
      this.filter.post = this.isCheckedMultiSelectPost && this.employerPosts.length !== 0 ? Array.from(this.employerPosts.slice().reverse(), x => x.externalId!) : undefined;
    }
  }

  public onChangeSmartSearch(value: string){
    if(!value)
      this.filterHandler()
  }

  public onValueChange() {
    if (this.filter.citizenshipId)
      this.isCheckedMultiSelect = this.filter.citizenshipId.length === this.citizenships.length;
    if (this.filter.dictStudentAcademicStateId)
      this.isCheckedMultiSelectAcademicState = this.filter.dictStudentAcademicStateId.length === this.dictStudentAcademicStates.length;
    if (this.filter.isBudgetSelected)
      this.isCheckedMultiSelectBudgetState = this.filter.isBudgetSelected.length === this.budgets.length;
    if (this.filter.directionActivityId)
      this.isCheckedMultiSelectDirectionActivity = this.filter.directionActivityId.length === this.dictAchievements.length;
    if (this.filter.trainingLevel)
      this.isCheckedMultiSelectTrainingLevel = this.filter.trainingLevel.length === this.trainingLevels.length;
  }

  public onFilialChange(value: string) {
    this.filteredFaculties = this.faculties.filter((item: Faculty) => item.filialId == value);
    this.filteredBudgets = this.budgets.filter((item: Budget) => item.filialId == value);
    this.filteredDictAchievements = this.dictAchievements.filter((item: DictAchievements)=> item.filialId == this.filter.filial);
    this.selectedBudgetCategories = this.budgetCategories.filter((item: BudgetCategory) => item.filialId == value);
    this.selectedBudgetSubCategories = this.budgetSubCategories.filter((item: BudgetSubCategory) => item.filialId == value);
    this.selectOrderTypes = this.orderTypes.filter((item: OrderTypes)=> item.filialId == this.filter.filial);
    this.selectOrderCategories = this.orderCategories.filter((item: OrdersCategory)=> item.filialId == this.filter.filial);
  }

  public onCommissariatChange() {
    this.isHiddenStudentCard(this.enum.military) ? this.filter.commissariatId = this.selectedCommissariatId : this.filter.militaryEnlistmentCommissariatId = this.selectedCommissariatId;
  }

  public onMilitaryStatusChange() {
    this.isHiddenStudentCard(this.enum.military) ? this.filter.dictMilitaryStatusId = this.selectedDictMilitaryStatusId : this.filter.dictMilitaryEnlistmentStatusId = this.selectedDictMilitaryStatusId;
  }

  public onBudgetChange(budgetId: string[] | undefined): void {
    this.onValueChange();

    this.selectedBudgetCategories = (this.filter.isBudgetSelected && this.filter.isBudgetSelected.length !== 0)
      ? this.budgetCategories.filter((s) => budgetId?.includes(s.dictStudentBudgetId))
      : this.selectedBudgetCategories = this.budgetCategories.slice();

    this.selectedBudgetSubCategories = this.budgetSubCategories.filter((s) => this.selectedBudgetCategories.map(x => x.dictStudentBudgetCategoryExternalId)?.includes(s.dictStudentBudgetCategoryId));
  }

  public onBudgetCategoryChange(categoryId: string): void {
    this.selectedBudgetSubCategories = categoryId
      ? this.budgetSubCategories.filter((s) => s.dictStudentBudgetCategoryId === categoryId)
      : this.budgetSubCategories.slice();
  }

  public onAcievementChange() {
    if (this.filter.directionActivityId !== undefined) {
      this.isCheckedMultiSelectDirectionActivity = this.filter.directionActivityId.length === this.dictAchievements.length;
      this.filter.hasAchievements = true;
    }
  }

  public onHasAcievementChange() {
    if (this.filter.hasAchievements === undefined || this.filter.hasAchievements === false) {
      this.isCheckedMultiSelectDirectionActivity = false;
      this.filter.directionActivityId = undefined;
    }
  }

  public getDisplaySettings() {
    this.displaySettingService.getStudentCardDisplaySettings()
      .subscribe(
        response => {
          this.displaySettingService.displaySettings$.next(response);
        }
      );
  }

  public isHiddenStudentCard(field: number): boolean {
    return this.displaySettingService.isHidden(field);
  }

  private getVisibleColumns(arr: string[]): string[]
  {
    let visibleColumns: string[] = [];
    arr.forEach((x) => {
      let column = dependentColumns.find((n) => n.field === x);
      if (column) {
        if (this.isHiddenStudentCard(column.visEnum) === column.isHiddenStudentCard)
          visibleColumns.push(x);
      }
      else {
        visibleColumns.push(x);
      }
    });

    return visibleColumns;
  }
}
