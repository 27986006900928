import { Component, OnInit } from '@angular/core';
import { items } from '../../../models/support/support-menu-items';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  //Menu items
  public items = items;

  constructor() { }

  ngOnInit(): void {
  }

}
