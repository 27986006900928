import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {UsefulLinkDto} from "../../models/support/usefulLink/useful-link.dto";
import {CreateUsefulLinkDto} from "../../models/support/usefulLink/create-useful-link.dto";

@Injectable({
  providedIn: 'root'
})
export class UsefulLinkService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.support.usefulLink}`;

  constructor(private http: HttpClient) { }

  public GetUsefulLinks(): Observable<UsefulLinkDto[]> {
    return this.http.get<UsefulLinkDto[]>(this.baseUrl + '/GetUsefulLinks/');
  }

  public CreateUsefulLink(dto: CreateUsefulLinkDto) {
    return this.http.post(this.baseUrl + '/CreateUsefulLink/', dto)
      .pipe(catchError(this.handleError));
  }

  public UpdateUsefulLink(dto: UsefulLinkDto) {
    return this.http.put(this.baseUrl + '/UpdateUsefulLink/', dto)
      .pipe(catchError(this.handleError));
  }

  public DeleteUsefulLink(id: string) {
    return this.http.delete(this.baseUrl + '/DeleteUsefulLink/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}
