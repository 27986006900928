import {Component, OnInit,} from '@angular/core';
import {
  DictTypeValueFilterEduGroupNameService
} from "../../../services/constructor-edu-group-name/dict-type-value-filter-edu-group-name.service";
import {FilterEduGroupNameService} from "../../../services/constructor-edu-group-name/filter-edu-group-name.service";
import {
  SettingFilterEduGroupNameService
} from "../../../services/constructor-edu-group-name/setting-filter-edu-group-name.service";
import {SettingFilterEduGroupName} from "../../../models/constructor-edu-group-name/setting-filter-edu-group-name";
import {
  FilterEduGroupName, FilterEduGroupNameAddOrUpdate,
  UsedFilterEduGroupNameInfo
} from "../../../models/constructor-edu-group-name/filter-edu-group-name";
import {
  DictTypeValueFilterEduGroupName
} from "../../../models/constructor-edu-group-name/dict-type-value-filter-edu-group-name";
import {FilterEduGroupNameEnum} from "../../../models/constructor-edu-group-name/enums/filter-edu-group-name.enum";
import {DictStudyFormName} from "../../../models/contingent/dictstudyform.model";
import {FacultyName} from "../../../models/contingent/faculty.model";
import {EducationProgramNameAndId} from "../../../models/contingent/educationprogram.model";
import {EducationStandardName} from "../../../models/contingent/educationstandard.model";
import {DictTrainingLevelName} from "../../../models/contingent/dicttraininglevel.model";
import {DictAcceleratedStudyBaseName} from "../../../models/contingent/dict-accelerated-study-base.model";
import {DictTrainingLevelService} from "../../../services/contingent/dicttraininglevels.service";
import {DictFacultyService} from "../../../services/contingent/dictfaculty.service";
import {DictAcceleratedStudyBaseService} from "../../../services/contingent/dict-accelerated-study-base.service";
import {CommondictService} from "../../../services/contingent/commondict.service";
import {EducationService} from "../../../services/contingent/education.service";
import {CourseLessProfilingCourseName} from "../../../models/constructor-edu-group-name/course-less-profiling-course.model";
import {lastValueFrom} from "rxjs";
import {CourseLessProfilingCourseNameEnum} from "../../../models/constructor-edu-group-name/enums/course-less-profiling-course.enum";
import {
  DictTypeValueFilterEduGroupNameEnum
} from "../../../models/constructor-edu-group-name/enums/dict-type-value-filter-edu-group-name.enum";
import {ChipListRemoveEvent} from "@progress/kendo-angular-buttons";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openRemoveDialog} from "../../../helpers/dialogHelper";

@Component({
  selector: 'app-constructor-edu-group-name',
  templateUrl: './constructor-edu-group-name.component.html',
  styleUrls: ['./constructor-edu-group-name.component.scss']
})
export class ConstructorEduGroupNameComponent implements OnInit {

  // region Переменные

  // region Переменные для Типов фильтров
  public dictTypeValueFilterEduGroupNameList: DictTypeValueFilterEduGroupName[] = [];

  // region Переменные для Типа фильтров Диаопзон чисел
  public startRange: string = "";
  public endRange: string = "";
  // endregion

  // region Переменные для Типа фильтров строки
  public typeFilterStringsList: Chip[] = [];
  public typeFilterString: string = "";
  // endregion
  // endregion

  // region Переменные для Настроек фильтров
  public currSettingFilter!: SettingFilterEduGroupName;
  public settingFilterEduGroupNameList: SettingFilterEduGroupName[] = [];
  // endregion

  // region Переменные для drag & drop настроек фильтров
  private dragSettingFilter!: SettingFilterEduGroupName;
  public dragOverSettingFilterEduGroupName?: SettingFilterEduGroupName;
  // endregion

  // region Переменные для Используемых фильтров
  private usedFilterEduGroupNameInfoList: UsedFilterEduGroupNameInfo[] = [];
  public usedFilterBySettingOrderList: UsedFilterEduGroupNameInfo[] = [];

  public viewUsedFilter: boolean = false;
  // endregion

  // region Переменные для Фильтров
  public currFilter!: FilterEduGroupName;

  public filterEduGroupNameList: FilterEduGroupName[] = [];

  public dictTrainingLevelNameList: DictTrainingLevelName[] = [{externalId: null, name: 'Все'}];
  public facultyNameList: FacultyName[] = [{externalId: null, name: 'Все'}];
  public educationProgramNameList: EducationProgramNameAndId[] = [{externalId: null, name: 'Все'}];
  public educationStandardNameList: EducationStandardName[] = [{externalId: null, name: 'Все'}];
  public dictStudyFormNameList: DictStudyFormName[] = [{externalId: null, name: 'Все'}];
  public dictAcceleratedStudyBaseNameList: DictAcceleratedStudyBaseName[] = [{externalId: null, name: 'Все'}];
  public courseLessProfilingCourseNameList: CourseLessProfilingCourseName[] = [{externalId: null, name: 'Все'}];

  public valuesDropDownFilters: (FilterAllType | null)[] = [];
  // endregion

  // region Переменные для drag & drop фильтров
  public dragOverFilterEduGroupName?: FilterEduGroupNameEnum;
  private dragFilter!: FilterEduGroupNameEnum;
  // endregion

  // region Переменные для drag & drop
  private dragElem!: HTMLElement;
  // endregion

  // endregion

  constructor(private dictTypeValueFilterEduGroupNameService: DictTypeValueFilterEduGroupNameService,
              private filterEduGroupNameService: FilterEduGroupNameService,
              private settingFilterEduGroupNameService: SettingFilterEduGroupNameService,
              private dictTrainingLevelService: DictTrainingLevelService,
              private dictFacultyService: DictFacultyService,
              private educationService: EducationService,
              private commonDictService: CommondictService,
              private dictAcceleratedStudyBaseService: DictAcceleratedStudyBaseService,
              private notificationService: NotificationsService,
              private dialogService: DialogService,) { }

  async ngOnInit() {
    await this.getListFilterEduGroupName();
    await this.getListDictTypeValueFilterEduGroupName();
    await this.getUsedFilterListInfo();
    await this.getSettingFilterEduGroupNameList();
    await this.initFilters();
    if (!this.currSettingFilter) { // Если в базе нет ни фильтров, ни их настроек, то создадим начальные
      await this.initValues();
    }
    this.changeValues(this.currSettingFilter);
  }

  private async initValues() {
    await this.initAddSettingFilter();
    this.currFilter = this.getFilterOrCreate(this.currSettingFilter);
  }

  // Изменяет все значения страницы по настройке фильтров
  public changeValues(settingFilter: SettingFilterEduGroupName): void {
    this.changeSettingFilter(settingFilter);
    this.changeUsedFilters(settingFilter.externalId);
    this.fillFilterDropDown(settingFilter);
    this.setFilterValueByFilter(settingFilter);
    this.setTypeFilterStrings(this.currFilter);
    this.setTypeFilterRangeNumber(this.currFilter);
  }

  // region Типы фильтров

  // region Логика Типы фильтров

  private getTypeFilterName(id: string | undefined) {
    const typeFilterNameArr =
      this.dictTypeValueFilterEduGroupNameList.filter(_ => _.externalId === id);

    return typeFilterNameArr.length === 0 ? '' : typeFilterNameArr[0].name;
  }

  public isStringTypeFilter(): boolean {
    const typeFilterName = this.getTypeFilterName(this.currFilter!.dictTypeValueFilterEduGroupNameId)
    return typeFilterName === DictTypeValueFilterEduGroupNameEnum.String;
  }

  public isNumberTypeFilter(): boolean {
    const typeFilterName = this.getTypeFilterName(this.currFilter!.dictTypeValueFilterEduGroupNameId)
    return typeFilterName === DictTypeValueFilterEduGroupNameEnum.Number;
  }

  public isRangeNumbersTypeFilter(): boolean {
    const typeFilterName = this.getTypeFilterName(this.currFilter!.dictTypeValueFilterEduGroupNameId)
    return typeFilterName === DictTypeValueFilterEduGroupNameEnum.RangeNumbers;
  }

  public isStringsTypeFilter(): boolean {
    const typeFilterName = this.getTypeFilterName(this.currFilter!.dictTypeValueFilterEduGroupNameId)
    return typeFilterName === DictTypeValueFilterEduGroupNameEnum.Strings;
  }

  // region Тип фильтра Диапазон чисел

  private setTypeFilterRangeNumber(filter: FilterEduGroupName): void {
    const isRangeNumbersTypeFilter = this.isRangeNumbersTypeFilter();
    this.startRange = isRangeNumbersTypeFilter ? filter.value.split('-')[0] : '';
    this.endRange = isRangeNumbersTypeFilter ? filter.value.split('-')[1] : '';
  }

  // endregion

  // region Тип фильтра Строки

  private setTypeFilterStrings(filter: FilterEduGroupName): void {
    this.typeFilterStringsList = this.isStringsTypeFilter()
      ? filter.value.split('|').map(_ => ({
        label: _,
        removable: true,
      }) as Chip)
      : [];
  }

  public addString(): void {
    if (this.typeFilterString === "") {
      return;
    }

    this.typeFilterStringsList.push({label: this.typeFilterString, removable: true})

    this.typeFilterString = "";
  }

  public deleteString(e: ChipListRemoveEvent): void {
    const removedString = e.removedChip;

    this.typeFilterStringsList.forEach((string, index) => {
      if (string.label === removedString.label) {
        this.typeFilterStringsList.splice(index, 1);
      }
    });
  }

  // endregion

  // endregion

  // region Получение данных из сервисов и инициализация переменных для Типов фильтров
  private async getListDictTypeValueFilterEduGroupName(): Promise<void> {
    await lastValueFrom(this.dictTypeValueFilterEduGroupNameService.getListDictTypeValueFilterEduGroupName())
      .then(response => {
        this.dictTypeValueFilterEduGroupNameList = response;
      })
  }
  // endregion

  // endregion

  // region Натройки фильтров

  // region Логика Настроек фильтров

  private changeSettingFilter(settingFilterEduGroupName: SettingFilterEduGroupName): void {
    this.currSettingFilter = settingFilterEduGroupName;
  }

  public addSettingFilter(): void {
    const orderNewSetting = this.settingFilterEduGroupNameList.length - 1;
    this.settingFilterEduGroupNameService.addSettingFilterEduGroupName(orderNewSetting)
      .subscribe(response => {
        this.settingFilterEduGroupNameList = response;
      })
  }

  private async initAddSettingFilter() {
    await lastValueFrom(this.settingFilterEduGroupNameService.addSettingFilterEduGroupName(-1))
      .then(response => {
        this.settingFilterEduGroupNameList = response;
        this.currSettingFilter = response[0];
      })
  }

  public onSelectNavContextMenu(orderSettingFilter: number): void {
    const nextSettingFilter: SettingFilterEduGroupName = (this.settingFilterEduGroupNameList.length > orderSettingFilter)
      ? this.settingFilterEduGroupNameList[orderSettingFilter]
      : this.settingFilterEduGroupNameList[orderSettingFilter - 1];

    const deleteSettingId = this.settingFilterEduGroupNameList[orderSettingFilter].externalId;
    this.deleteSettingFilter(deleteSettingId);

    this.changeValues(nextSettingFilter);
  }

  public deleteSettingFilter(deleteSettingId: string) : void {
    if (this.settingFilterEduGroupNameList.length === 1) { // Для работы страницы нужно оставить хотя бы одну настройка фильтра
      return;
    }

    const dialog: DialogRef = openRemoveDialog(this.dialogService, `выбранную настройку фильтра`);

    dialog.result.subscribe((result ) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да" && this.currSettingFilter) {
        this.settingFilterEduGroupNameService.deleteSettingFilterEduGroupName(deleteSettingId)
          .subscribe(response => {
              this.settingFilterEduGroupNameList = response;
            },
            error => {
              this.notificationService.showError("Не удалось удалить настройку");
            })
      }
    })
  }

  public changeIndexSetting(newOrder: number, settingFilterId: string) {
    const oldOrder = this.dragSettingFilter.order;
    if (oldOrder === newOrder) { // если перемести на тоже место, то не имеет смысла делать запрос перемещения на бэк
      return;
    }

    this.settingFilterEduGroupNameService.changeIndexOrder(newOrder, settingFilterId)
      .subscribe(response => {
        this.settingFilterEduGroupNameList = response;

        const currSettingFilter =
          this.settingFilterEduGroupNameList.filter(_ => _.externalId === settingFilterId)[0]
        this.changeValues(currSettingFilter);

        this.notificationService.showSuccess("Настройка успешно перемещена");
      },
      error => {
        this.notificationService.showError("Не удалось переместить настройку");
      })
  }

  // region Переключение настроек стрелочками

  public toNextSettingFilter(): void {
    if (!this.currSettingFilter) {
      return;
    }

    const orderNextSettingFilter = this.currSettingFilter.order + 1;
    if (orderNextSettingFilter < this.settingFilterEduGroupNameList.length) {
      let newCurrSettingFilter = this.settingFilterEduGroupNameList[orderNextSettingFilter];
      this.changeValues(newCurrSettingFilter);
    }
  }

  public toPrevSettingFilter(): void {
    if (!this.currSettingFilter) {
        return;
    }

    const orderNextSettingFilter = this.currSettingFilter.order - 1;
    if (orderNextSettingFilter >= 0) {
      let newCurrSettingFilter = this.settingFilterEduGroupNameList[orderNextSettingFilter];
      this.changeValues(newCurrSettingFilter);
    }
  }

  // endregion

  // endregion

  // region Получение данных из сервисов и инициализация переменных для Настроек фильтров

  private async getSettingFilterEduGroupNameList(): Promise<void> {
    await lastValueFrom(this.settingFilterEduGroupNameService.getSettingFilterEduGroupNameList())
      .then(response => {
        this.settingFilterEduGroupNameList = response;
        this.currSettingFilter = response[0];
      })
  }

  // endregion

  // region drag & drop настроек фильтров

  public dragStartSettingFilter(dragSettingFilter: SettingFilterEduGroupName){
    this.dragSettingFilter = dragSettingFilter;
    this.dragStart(event as DragEvent);
  }

  public dragEnterSettingFilter(settingFilterEduGroupName: SettingFilterEduGroupName): void {
    this.dragOverSettingFilterEduGroupName = settingFilterEduGroupName;
    (event?.target as HTMLElement).classList.add('nav-elem_drag-over');
  }

  public dragOverSettingFilter(settingFilterEduGroupName: SettingFilterEduGroupName): void {
    this.dragOverSettingFilterEduGroupName = settingFilterEduGroupName;
    this.dragOver(event as DragEvent);
  }

  public dragDropSettingFilter(dropSettingFilter: SettingFilterEduGroupName): void {
    let dropElem = event?.target as HTMLElement;

    this.dragOverSettingFilterEduGroupName = undefined;
    dropElem.classList.remove('nav-elem_drag-over');

    this.changeIndexSetting(dropSettingFilter.order, this.dragSettingFilter.externalId);

    this.dragDropOrEnd();
  }

  public dragLeaveSettingFilter(): void {
    this.dragOverSettingFilterEduGroupName = undefined;
    (event?.target as HTMLElement).classList.remove('nav-elem_drag-over');
  }

  public dragEndSettingFilter(): void {
    this.dragOverSettingFilterEduGroupName = undefined;
    (event?.target as HTMLElement).classList.remove('nav-elem_drag-over');

    this.dragDropOrEnd();
  }

  // endregion

  // endregion

  // region Используемые фильтры

  // region Логика Используемых фильтров

  private changeUsedFilters(settingFilterEduGroupNameId: string): void {
    this.usedFilterBySettingOrderList =
        this.usedFilterEduGroupNameInfoList.filter((usedFilter) =>
            usedFilter.settingFilterEduGroupNameId === settingFilterEduGroupNameId
        )
  }

  // region Изменение отображения Используемых фильтров

  public arrowButton: {[key: string]: boolean} = {};

  public showUsedFiltersBySetting(): void {
    this.viewUsedFilter = !this.viewUsedFilter;
    this.arrowButton = {
      'rotate-arrow-180': this.viewUsedFilter,
      'rotate-arrow-0': !this.viewUsedFilter
    };
  }

  // endregion

  // endregion

  // region Получение данных из сервисов и инициализация переменных для Используемых фильтров
  private async getUsedFilterListInfo(): Promise<void> {
    await lastValueFrom(this.filterEduGroupNameService.getUsedFilterListInfo())
        .then(response => {
          this.usedFilterEduGroupNameInfoList = response;
        })
  }
  // endregion

  // endregion

  // region Фильтры

  // region Логика фильтров

  private getFilter(settingFilter: SettingFilterEduGroupName): FilterEduGroupName | null {
    let filter = this.filterEduGroupNameList
        .filter(_ =>
            _.settingFilterEduGroupNameExternalId === settingFilter.externalId
            && _.dictTrainingLevelId === this.valuesDropDownFilters[FilterEduGroupNameEnum.DictTrainingLevel]?.externalId
            && _.facultyId === this.valuesDropDownFilters[FilterEduGroupNameEnum.Faculty]?.externalId
            && _.educationProgramId === this.valuesDropDownFilters[FilterEduGroupNameEnum.EducationProgram]?.externalId
            && _.educationStandardId === this.valuesDropDownFilters[FilterEduGroupNameEnum.EducationStandard]?.externalId
            && _.facultyEducationPlanId === this.valuesDropDownFilters[FilterEduGroupNameEnum.FacultyEducationPlan]?.externalId
            && _.dictStudyFormId === this.valuesDropDownFilters[FilterEduGroupNameEnum.DictStudyForm]?.externalId
            && _.dictAcceleratedStudyBaseId === this.valuesDropDownFilters[FilterEduGroupNameEnum.DictAcceleratedStudyBase]?.externalId
            && _.courseLessProfilingCourseId == this.valuesDropDownFilters[FilterEduGroupNameEnum.CourseLessProfilingCourse]?.externalId);

    return filter.length === 0 ? null : filter[0];
  }

  private createFilter(settingFilter: SettingFilterEduGroupName) {
      return new FilterEduGroupName(
        settingFilter.externalId,
        '',
        this.valuesDropDownFilters[FilterEduGroupNameEnum.DictTrainingLevel]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.Faculty]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.EducationProgram]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.EducationStandard]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.FacultyEducationPlan]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.DictStudyForm]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.DictAcceleratedStudyBase]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.CourseLessProfilingCourse]?.externalId,);
  }

  public getFilterOrCreate(settingFilter: SettingFilterEduGroupName): FilterEduGroupName {
    let filter = this.getFilter(settingFilter);
    return filter ? filter : this.createFilter(settingFilter);
  }

  private setFilterValueByFilter(settingFilter: SettingFilterEduGroupName): void {
    this.currFilter = this.getFilterOrCreate(settingFilter);
  }

  public getDataByFilterEnum = (filter: FilterEduGroupNameEnum) : FilterAllType[] => {
    switch (filter) {
      case FilterEduGroupNameEnum.DictTrainingLevel:
        return this.dictTrainingLevelNameList;
      case FilterEduGroupNameEnum.Faculty:
        return this.facultyNameList;
      case FilterEduGroupNameEnum.EducationProgram:
        return this.educationProgramNameList;
      case FilterEduGroupNameEnum.EducationStandard:
        return this.educationStandardNameList;
      case FilterEduGroupNameEnum.FacultyEducationPlan:
        return this.facultyNameList;
      case FilterEduGroupNameEnum.DictStudyForm:
        return this.dictStudyFormNameList;
      case FilterEduGroupNameEnum.DictAcceleratedStudyBase:
        return this.dictAcceleratedStudyBaseNameList;
      case FilterEduGroupNameEnum.CourseLessProfilingCourse:
        return this.courseLessProfilingCourseNameList;
      default:
        return [];
    }
  }

  public changeFilter(e: FilterAllType, filterEnum: FilterEduGroupNameEnum): void {
    this.valuesDropDownFilters[filterEnum] = e;
    this.currFilter = this.getFilterOrCreate(this.currSettingFilter);
  }

  public changeFilterByUsedFilter(filterId: string): void {
    this.currFilter = this.filterEduGroupNameList.filter(_ => _.externalId === filterId)[0];

    this.fillFilterDropDown(this.currSettingFilter);
    this.showUsedFiltersBySetting();
    this.setTypeFilterStrings(this.currFilter);
    this.setTypeFilterRangeNumber(this.currFilter);
  }

  public deleteFilter(filterId: string | null): void {
    if (!filterId) {
      return;
    }

    this.filterEduGroupNameService.deleteFilterEduGroupName(filterId)
      .subscribe(response => {
        this.usedFilterEduGroupNameInfoList = response.usedFilterList;
        this.filterEduGroupNameList = response.filterList;

        this.changeValues(this.currSettingFilter);

        this.notificationService.showSuccess('Фильтр успешно удален');
      },
      error => {
        this.notificationService.showError('Не удалось удалить фильтр');
      })
  }

  private getTypeFilter(): string | null {
    if (!this.currFilter.dictTypeValueFilterEduGroupNameId) {
      return null;
    }

    return this.currFilter.dictTypeValueFilterEduGroupNameId.toString();
  }

  private stringIsNumber(str: string): boolean {
    return !isNaN((Number(str)));
  }

  private isValidValueTypeNumber(): boolean {
    const currNumber = this.currFilter.value;

    if (!this.stringIsNumber(currNumber)) {
      this.notificationService.showError('В поле типа "Число" должны быть только цифры');
      return false;
    }

    return true;
  }

  private isValidValueTypeRangeNumber(): boolean {
    const startRange = this.startRange;
    const endRange = this.endRange;

    if (!this.stringIsNumber(startRange) || !this.stringIsNumber(endRange)) {
      this.notificationService.showError('В полях типа "Дипазон чисел" должны быть только цифры');
      return false;
    }
    if (Number(startRange) > Number(endRange)) {
      this.notificationService.showError('Конец диапазона должен быть больше начала');
      return false;
    }

    return true;
  }

  private isValidValue(): boolean {
    const typeFilter = this.getTypeFilter();

    if (typeFilter === DictTypeValueFilterEduGroupNameEnum.Number) {
      return this.isValidValueTypeNumber();
    }
    if (typeFilter === DictTypeValueFilterEduGroupNameEnum.RangeNumbers) {
      return this.isValidValueTypeRangeNumber();
    }

    return true;
  }

  private getValue(): string | null {
    const typeFilter = this.getTypeFilter();
    switch (typeFilter) {
      case DictTypeValueFilterEduGroupNameEnum.String:
        return this.currFilter!.value;
      case DictTypeValueFilterEduGroupNameEnum.Number:
        return this.currFilter!.value;
      case DictTypeValueFilterEduGroupNameEnum.RangeNumbers:
        return this.startRange + '-' + this.endRange;
      case DictTypeValueFilterEduGroupNameEnum.Strings:
        return this.typeFilterStringsList.map(_ => _.label).join('|');
      default:
        return null;
    }
  }

  private createFilterEduGroupNameAddOrUpdate(): FilterEduGroupNameAddOrUpdate {
    return new FilterEduGroupNameAddOrUpdate(
        this.currSettingFilter!.externalId,
        this.getValue(),
        this.currFilter!.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.DictTrainingLevel]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.Faculty]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.EducationProgram]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.EducationStandard]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.FacultyEducationPlan]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.DictStudyForm]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.DictAcceleratedStudyBase]?.externalId,
        this.valuesDropDownFilters[FilterEduGroupNameEnum.CourseLessProfilingCourse]?.externalId,
        this.currFilter!.dictTypeValueFilterEduGroupNameId
      );
  }

  public saveFilter(): void {
    if (!this.isValidValue()) {
      return;
    }

    const filterAddOrUpdate = this.createFilterEduGroupNameAddOrUpdate();
    this.filterEduGroupNameService.addOrUpdateFilterEduGroupName(filterAddOrUpdate)
      .subscribe(response => {
        this.usedFilterEduGroupNameInfoList = response.usedFilterList;
        this.filterEduGroupNameList = response.filterList;

        this.changeValues(this.currSettingFilter);

        this.notificationService.showSuccess('Значение фильтра успешно сохранено');
      },
      error => {
        this.notificationService.showError('Не удалось сохранить настройку фильтра');
      })
  }

  public changeIndexFilter() {
    this.settingFilterEduGroupNameService.changeIndexFilter(this.currSettingFilter!.externalId, this.currSettingFilter!.listOrderPartCondition)
      .subscribe(response => {
        this.settingFilterEduGroupNameList = response;
      })
  }

  // region Отображение при изменении фильтров

  private setValueDropDownFilter(filterEnum: FilterEduGroupNameEnum, filter: FilterEduGroupName) : void {
    switch (filterEnum) {
      case FilterEduGroupNameEnum.DictTrainingLevel:
        this.valuesDropDownFilters[filterEnum] = filter
          ? this.dictTrainingLevelNameList.filter(_ => _.externalId == filter!.dictTrainingLevelId)[0]
          : this.dictTrainingLevelNameList[0];
        break;
      case FilterEduGroupNameEnum.Faculty:
        this.valuesDropDownFilters[filterEnum] = filter
          ? this.facultyNameList.filter(_ => _.externalId == filter.facultyId)[0]
          : this.facultyNameList[0];
        break;
      case FilterEduGroupNameEnum.EducationProgram:
        this.valuesDropDownFilters[filterEnum] = filter
          ? this.educationProgramNameList.filter(_ => _.externalId == filter.educationProgramId)[0]
          : this.educationProgramNameList[0];
        break;
      case FilterEduGroupNameEnum.EducationStandard:
        this.valuesDropDownFilters[filterEnum] = filter
          ? this.educationStandardNameList.filter(_ => _.externalId == filter.educationStandardId)[0]
          : this.educationStandardNameList[0];
        break;
      case FilterEduGroupNameEnum.FacultyEducationPlan:
        this.valuesDropDownFilters[filterEnum] = filter
          ? this.facultyNameList.filter(_ => _.externalId == filter.facultyEducationPlanId)[0]
          : this.facultyNameList[0];
        break;
      case FilterEduGroupNameEnum.DictStudyForm:
        this.valuesDropDownFilters[filterEnum] = filter
          ? this.dictStudyFormNameList.filter(_ => _.externalId == filter.dictStudyFormId)[0]
          : this.dictStudyFormNameList[0];
        break;
      case FilterEduGroupNameEnum.DictAcceleratedStudyBase:
        this.valuesDropDownFilters[filterEnum] = filter
          ?  this.dictAcceleratedStudyBaseNameList.filter(_ => _.externalId == filter.dictAcceleratedStudyBaseId)[0]
          : this.dictAcceleratedStudyBaseNameList[0];
        break;
      case FilterEduGroupNameEnum.CourseLessProfilingCourse:
        this.valuesDropDownFilters[filterEnum] = filter
          ?  this.courseLessProfilingCourseNameList.filter(_ => _.externalId == filter.courseLessProfilingCourseId)[0]
          : this.courseLessProfilingCourseNameList[0];
        break;
    }
  }

  private fillFilterDropDown(settingFilter: SettingFilterEduGroupName) {
    const listOrderPartConditionLength = settingFilter.listOrderPartCondition.length;

    for (let filterEnum = 0; filterEnum < listOrderPartConditionLength; filterEnum++) {
      this.setValueDropDownFilter(filterEnum, this.currFilter);
    }
  }

  public getFilterName(filter: FilterEduGroupNameEnum) {
    switch (filter) {
      case FilterEduGroupNameEnum.DictTrainingLevel:
        return 'Уровень подготовки';
      case FilterEduGroupNameEnum.Faculty:
        return 'Факультет';
      case FilterEduGroupNameEnum.EducationProgram:
        return 'Образовательная программа';
      case FilterEduGroupNameEnum.EducationStandard:
        return 'Образовательный стандарт';
      case FilterEduGroupNameEnum.FacultyEducationPlan:
        return 'Факультет образовательного плана';
      case FilterEduGroupNameEnum.DictStudyForm:
        return 'Форма обучения';
      case FilterEduGroupNameEnum.DictAcceleratedStudyBase:
        return 'База ускоренного обучения';
      case FilterEduGroupNameEnum.CourseLessProfilingCourse:
        return 'Номер курса меньше курса профилизации';
      default:
        return 'Неизвестный фильтр';
    }
  }

  // endregion

  // endregion

  // region Получение данных из сервисов и инициализация переменных для Фильтров

  private async getListFilterEduGroupName(): Promise<void>  {
    await lastValueFrom(this.filterEduGroupNameService.getListFilterEduGroupName())
        .then(response => {
          this.filterEduGroupNameList = response;
        })
  }

  // Заполняем dropdownlist фильтров
  private async initFilters(): Promise<void> {
    await this.getDictTrainingLevelNameList();
    await this.getFacultyNameList();
    await this.getEducationProgramNameList();
    await this.getEducationStandardNameList();
    await this.getDictStudyFormNameList();
    await this.getDictAcceleratedStudyBaseNameList();
    this.getCourseLessProfilingCourseNameList();
  }

  private async getDictTrainingLevelNameList(): Promise<void> {
    await lastValueFrom(this.dictTrainingLevelService.getNamesDictTrainingLevel())
        .then(response => {
          this.dictTrainingLevelNameList.push(...response);
        })
  }

  private async getFacultyNameList(): Promise<void> {
    await lastValueFrom(this.dictFacultyService.getDictFacultyNames())
        .then(response => {
          this.facultyNameList.push(...response);
        })
  }

  private async getEducationProgramNameList(): Promise<void> {
    await lastValueFrom(this.educationService.getListEducationProgramNameAndId())
        .then(response => {
          this.educationProgramNameList.push(...response);
        })
  }

  private async getEducationStandardNameList(): Promise<void> {
    await lastValueFrom(this.educationService.getNamesEducationStandard())
        .then(response => {
          this.educationStandardNameList.push(...response);
        })
  }

  private async getDictStudyFormNameList(): Promise<void> {
    await lastValueFrom(this.commonDictService.getNamesDictStudyForm())
        .then(response => {
          this.dictStudyFormNameList.push(...response);
        })
  }

  private async getDictAcceleratedStudyBaseNameList(): Promise<void> {
    await lastValueFrom(this.dictAcceleratedStudyBaseService.getDictAcceleratedStudyBaseNames())
        .then(response => {
          this.dictAcceleratedStudyBaseNameList.push(...response);
        })
  }

  private getCourseLessProfilingCourseNameList(): void {
    this.courseLessProfilingCourseNameList.push(new CourseLessProfilingCourseName(CourseLessProfilingCourseNameEnum.true, 'Да'));
    this.courseLessProfilingCourseNameList.push(new CourseLessProfilingCourseName(CourseLessProfilingCourseNameEnum.false, 'Нет'));
  }

  // endregion

  // region drag & drop фильтров

  public dragStartFilter(filter: FilterEduGroupNameEnum): void{
    this.dragFilter = filter;
    this.dragStart(event as DragEvent);
  }

  public dragEnterFilter(filter: FilterEduGroupNameEnum) {
    this.dragOverFilterEduGroupName = filter;
  }

  public dragOverFilter(filter: FilterEduGroupNameEnum): void {
    this.dragOverFilterEduGroupName = filter;
    this.dragOver(event as DragEvent);
  }

  public dragLeaveFilter() {
    this.dragOverFilterEduGroupName = undefined;
  }

  public dragDropFilter(filter: FilterEduGroupNameEnum): void {
    if (!this.currSettingFilter) {
      return;
    }

    this.dragOverFilterEduGroupName = undefined;

    let listOrderPartCondition = this.currSettingFilter.listOrderPartCondition;
    let indexDragFilter = listOrderPartCondition.indexOf(this.dragFilter);
    let indexDropFilter = listOrderPartCondition.indexOf(filter);
    listOrderPartCondition.splice(indexDragFilter, 1);
    listOrderPartCondition.splice(indexDropFilter, 0, this.dragFilter);

    this.changeIndexFilter();

    this.dragDropOrEnd();
  }

  public dragEndFilter(): void {
    this.dragOverFilterEduGroupName = undefined;

    this.dragDropOrEnd();
  }

  // endregion

  // endregion

  // region drag & drop

  public dragStart(dragEvent: DragEvent) : void {
    // скрыть элемент
    let dragElem = dragEvent.target as HTMLElement
    this.dragElem = dragElem;
    dragElem.style.opacity = '0.01';
  }

  public dragOver(dragEvent: DragEvent): void {
    dragEvent?.preventDefault(); // меняет курсор, когда наводимся на draggable элементе
  }

  public dragDropOrEnd(): void {
    this.dragElem.style.opacity = '1';
  }

  // endregion
}

export type Chip  = {
  label: string;
  removable: boolean;
}

export type FilterAllType = DictTrainingLevelName | FacultyName | EducationProgramNameAndId | EducationStandardName
    | DictStudyFormName | DictAcceleratedStudyBaseName | CourseLessProfilingCourseName;

