import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {SupportContactDto} from "../../models/support/supportContact/support-contact.dto";
import {CreateSupportContactDto} from "../../models/support/supportContact/create-support-contact.dto";

@Injectable({
  providedIn: 'root'
})
export class SupportContactService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.support.supportContact}`;

  constructor(private http: HttpClient) { }

  public GetSupportContacts(): Observable<SupportContactDto[]> {
    return this.http.get<SupportContactDto[]>(this.baseUrl + '/GetSupportContacts/');
  }

  public CreateSupportContact(dto: CreateSupportContactDto) {
    return this.http.post(this.baseUrl + '/CreateSupportContact/', dto)
      .pipe(catchError(this.handleError));
  }

  public UpdateSupportContact(dto: SupportContactDto) {
    return this.http.put(this.baseUrl + '/UpdateSupportContact/', dto)
      .pipe(catchError(this.handleError));
  }

  public DeleteSupportContact(id: string) {
    return this.http.delete(this.baseUrl + '/DeleteSupportContact/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}
