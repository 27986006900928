export const items = [
  {
    text: "Настройки",
    icon: "cog",
    items: [
      {
        text: "Настройки системы",
        path: "/support/settings",
      }
    ]
  }
];
