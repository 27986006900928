<h1>Настройки доступа</h1>
<contingent-header></contingent-header>

<span class="filter">
    <h3>Поиск по ФИО</h3>
    <kendo-textbox [(ngModel)]="searchFIO" style="width: 300px"></kendo-textbox>
    <button kendoButton (click)="clearData()">Очистить</button>
    <button kendoButton (click)="filterData(searchFIO)" themeColor="primary">Применить</button>
</span>

<kendo-grid class="technical"
            [loading]="loading"
            [kendoGridBinding]="gridData"
            [sortable]="true"
            [sort]="sort"
            (edit)="editHandler($event)"
            (remove)="removeHandler($event)"
            (add)="addHandler($event)">
    <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
    </ng-template>
    <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
        <div *ngIf="isInEditingMode">
          <button kendoButton (click)="cancelHandler()">Отмена</button>
          <button kendoButton themeColor="primary" [disabled]="formGroup!.invalid" (click)="saveCurrent()">Сохранить</button>
        </div>
      </ng-template>
    <kendo-grid-command-column [sticky]="true" title="" [width]="35">
      <ng-template kendoGridCellTemplate>
          <button kendoGridEditCommand themeColor="secondary" *ngIf="!isInEditingMode" icon="edit" fillMode="flat" size="none" class="custom-size" rounded="full">
          </button>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-command-column [sticky]="true" title="" [width]="35">
      <ng-template kendoGridCellTemplate>
          <button kendoGridRemoveCommand themeColor="secondary" *ngIf="!isInEditingMode" icon="delete" fillMode="flat" size="none" class="custom-size" rounded="full">
          </button>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-column [sticky]="true" [width]="250" field="personName" headerClass="gridHeader dictionaries" title="ФИО">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [kendoDropDownFilter]="filterSettings"
        [data]="persons"
        textField="fio"
        valueField="personExternalId"
        [valuePrimitive]="true"
        [virtual]="virtual"
        [formControl]="formGroup.get('personId')">
      </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.personName }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="195" field="filials" headerClass="gridHeader dictionaries" title="Филиал">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="filials"
        [kendoDropDownFilter]="filterSettings"
        textField="filialSName"
        [(ngModel)]="filialsEdit"
        valueField="dictFilialExternalId"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('filials')"
        (valueChange)="valueChange($event, 'filialsEdit')">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span  class="multiTag" (click)="onMultiSelectTagClick(dataItems, 'filials')">
              {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.filials, 'filialSName') }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="195" field="trainingLevels" headerClass="gridHeader dictionaries" title="Уровень подготовки">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-multiselect class="trainingLevels"
        kendoMultiSelectSummaryTag
        [kendoDropDownFilter]="filterSettings"
        [data]="trainingLevelsShow"
        textField="trainingLevelName"
        [(ngModel)]="trainingLevelsEdit"
        valueField="dictTrainingLevelExternalId"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('trainingLevels')"
        (valueChange)="valueChange($event, 'trainingLevelsEdit')">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span class="multiTag" (click)="onMultiSelectTagClick(dataItems, 'trainingLevels')">
            {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.trainingLevels, 'trainingLevelName') }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="195" field="faculties" headerClass="gridHeader dictionaries" title="Институт/факультет">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-multiselect class="faculties"
        kendoMultiSelectSummaryTag
        [kendoDropDownFilter]="filterSettings"
        [data]="facultiesShow"
        [(ngModel)]="facultiesEdit"
        textField="shortName"
        [valuePrimitive]="true"
        valueField="facultyExternalId"
        [formControl]="formGroup.get('faculties')"
        (valueChange)="valueChange($event, 'facultiesEdit')">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span class="multiTag" (click)="onMultiSelectTagClick(dataItems, 'faculties')">
          {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.faculties, 'facultyShortName')}}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="195" field="studyForms" headerClass="gridHeader dictionaries" title="Форма обучения">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-multiselect class="studyForms"
        kendoMultiSelectSummaryTag
        [kendoDropDownFilter]="filterSettings"
        [data]="studyFormsShow"
        [(ngModel)]="studyFormsEdit"
        [valuePrimitive]="true"
        textField="studyFormName"
        valueField="dictStudyFormExternalId"
        [formControl]="formGroup.get('studyForms')"
        (valueChange)="valueChange($event, 'studyFormsEdit')">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span class="multiTag" (click)="onMultiSelectTagClick(dataItems, 'studyForms')">
          {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.studyForms, 'studyFormName') }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="195" field="eduGroups" headerClass="gridHeader dictionaries" title="Группа">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-multiselect class="eduGroups"
        kendoMultiSelectSummaryTag
        [kendoDropDownFilter]="filterSettings"
        [data]="eduGroupsShow"
        [(ngModel)]="eduGroupsEdit"
        [valuePrimitive]="true"
        textField="groupName"
        valueField="eduGroupExternalId"
        [formControl]="formGroup.get('eduGroups')"
        (valueChange)="valueChange($event, 'eduGroupsEdit')">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span class="multiTag" (click)="onMultiSelectTagClick(dataItems, 'eduGroups')">
          {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.eduGroups, 'groupName') }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="140" field="accessLevel" headerClass="gridHeader dictionaries" title="Уровень доступа">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('accessLevel')"></kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.accessLevelName }}
          </span>
      </ng-template>
    </kendo-grid-column>
  <kendo-grid-column [sortable]="false" [width]="220" field="contingentTabsLevel" headerClass="gridHeader dictionaries" title="Запись в разделах карточки обучающегося">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
      <kendo-multiselect class="contingent-tabs"
                         kendoMultiSelectSummaryTag
                         [kendoDropDownFilter]="filterSettings"
                         [data]="contingentTabsShow"
                         [(ngModel)]="contingentTabsEdit"
                         [valuePrimitive]="true"
                         textField="title"
                         valueField="value"
                         [formControl]="formGroup.get('contingentTabsLevel')"
                         (valueChange)="valueChange($event, 'contingentTabsEdit')">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span class="multiTag" (click)="onMultiSelectTagClick(dataItems, 'contingentTabsLevel')">
          {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getListTabsFromEnum(dataItem.contingentTabsLevel) }}
          </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="140" field="groupAccessLevelName" headerClass="gridHeader dictionaries" title="Академические группы">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('groupAccessLevel')"></kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.groupAccessLevelName }}
        </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="140" field="elderAccessLevel" headerClass="gridHeader dictionaries" title="Изменение старосты"
                     *ngIf="isAdmin">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        textField="text" valueField="id"
        [data]="boolOptions"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('elderAccessLevel')"
      ></kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.elderAccessLevel ? 'Да' : 'Нет' }}
          </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="140" field="militaryCertificateAccessLevel" headerClass="gridHeader dictionaries" title="Справки по воинскому учёту">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="dictDropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('militaryCertificateAccessLevel')"></kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getAccessLevelName(dataItem.militaryCertificateAccessLevel) }}
          </span>
    </ng-template>
  </kendo-grid-column>

    <kendo-grid-column [width]="140" field="dictAccessLevel" headerClass="gridHeader dictionaries" title="Справочники">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('dictAccessLevel')"></kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.dictAccessLevelName }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="140" field="userAccessLevel" headerClass="gridHeader dictionaries" title="Настройки доступа"
                       *ngIf="isAdmin"
    >
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
          textField="text" valueField="id"
          [data]="boolOptions"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('userAccessLevel')"
        ></kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.userAccessLevel ? 'Да' : 'Нет' }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="140" field="localSignatoryAccessLevel" headerClass="gridHeader dictionaries" title="Настройки локального списка подписантов"
                       *ngIf="isAdmin"
    >
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
          textField="text" valueField="id"
          [data]="boolOptions"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('localSignatoryAccessLevel')"
        ></kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
              {{ dataItem.localSignatoryAccessLevel ? 'Да' : 'Нет' }}
            </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="140" field="achievementAccessLevel" headerClass="gridHeader dictionaries" title="Достижения"
                       *ngIf="isAdmin"
    >
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
          textField="text" valueField="id"
          [data]="achievementOptions"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('achievementAccessLevel')"
        ></kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.achievementAccessLevel ? 'Запись' : 'Утверждение' }}
              </span>
      </ng-template>
    </kendo-grid-column>
  <kendo-grid-column [width]="140" field="constructorEduGroupNameAccessLevel" headerClass="gridHeader dictionaries" title="Конструктор названий групп" *ngIf="isAdmin">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        textField="text" valueField="id"
        [data]="boolOptions"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('constructorEduGroupNameAccessLevel')"
      ></kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
              {{ dataItem.constructorEduGroupNameAccessLevel ? 'Да' : 'Нет' }}
            </span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>
