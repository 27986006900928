<gia-settings></gia-settings>
<h1>Настройки ГИА</h1>

<div class="content-container">
  <div *ngFor="let settings of giaSettingModel; let i = index">
    <div class="title">
      <h3>{{ settings[i].sectionTitle }}</h3>
    </div>
    <div *ngFor="let setting of settings; let j = index">
      <div class="content">
        <h2>{{ settings[j].name }}</h2>
        <div class="grid-box">
          <kendo-multiselect
            [data]="dictTrainingLevels"
            [ngModel]="this.giaSettingModel[i][j].trainingLevelSettings"
            class="w-600"
            textField="name"
            valueField="id"
            (valueChange)="valueChangeStudyForm($event, i, j)"
          ></kendo-multiselect>
        </div>
      </div>
    </div>
  </div>

  <!--Buttons-->
  <div class="functionButtons editModeButtons">
    <button kendoButton size="small" (click)="closeHandler()">Закрыть</button>
    <button kendoButton size="small" themeColor="primary" (click)="applySetting()">Применить</button>
  </div>
</div>
