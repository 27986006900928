import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {lastValueFrom, Observable, Subject} from 'rxjs';
import {TokenStorageService} from "../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Role} from "../models/useraccess/role";
import {map} from "rxjs/operators";
import {Location} from "@angular/common";
import {CardsUserAccessService} from "../services/useraccess/cards-user-access.service";
import {ContingentUserAccessService} from "../services/useraccess/contingent-user-access.service";

@Injectable({
  providedIn: 'root'
})
export class ConstructorEduGroupNameAccessLevelGuard implements CanActivate {

  constructor(private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService,
              private userAccessService: ContingentUserAccessService,
              private location: Location,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const token = this.tokenStore.getAuthToken()
    const title = document.title;
    // Checking if token is not null
    if(token !== null) {
      const user = this.jwtHelper.decodeToken(token!);
      const roles = user?.role;
      // Admin accesses page without any restrictions
      if (roles?.includes(Role.Admin))
        return true;
      else {
        return this.userAccessService.getConstructorEduGroupNameAccessLevel().pipe(
          map( response => {
            if (response)
              return true;
            alert('Недостаточно прав для доступа на эту страницу');
            localStorage.setItem('last_url', this.location.path())
            this.router.navigate([`/profile`]);
            return false;
          })
        );
      }
    }
    localStorage.setItem('last_url', this.location.path())
    return false;
  }
}
