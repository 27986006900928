import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {handleError} from "../../helpers/errorHandle-helper";
import {
  FilterEduGroupName, FilterEduGroupNameAddOrUpdate, FilterListAndUsedFilterList,
  UsedFilterEduGroupNameInfo
} from "../../models/constructor-edu-group-name/filter-edu-group-name";

@Injectable({
  providedIn: 'root'
})
export class FilterEduGroupNameService {
  baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.constructorEduGroupNames.filterEduGroupName}`;

  constructor(private http: HttpClient) { }

  public getListFilterEduGroupName(): Observable<FilterEduGroupName[]>{
    return this.http.get<FilterEduGroupName[]>(`${this.baseUrl}/GetListFilterEduGroupName`)
      .pipe(
        catchError(handleError));
  }

  public getUsedFilterListInfo(): Observable<UsedFilterEduGroupNameInfo[]>{
    return this.http.get<UsedFilterEduGroupNameInfo[]>(`${this.baseUrl}/GetUsedFilterListInfo`)
      .pipe(
        catchError(handleError));
  }

  public deleteFilterEduGroupName(deletedFilterId: string): Observable<FilterListAndUsedFilterList> {
    return this.http.delete<FilterListAndUsedFilterList>(`${this.baseUrl}/Delete?deletedFilterId=${deletedFilterId}`)
        .pipe(
            catchError(handleError));
  }

  public addOrUpdateFilterEduGroupName(filterEduGroupNameAddOrUpdate: FilterEduGroupNameAddOrUpdate): Observable<FilterListAndUsedFilterList> {
    return this.http.put<FilterListAndUsedFilterList>(`${this.baseUrl}/AddOrUpdate`, filterEduGroupNameAddOrUpdate)
        .pipe(
            catchError(handleError));
  }
}
