import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, Observable, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {Announcement} from "../../models/support/announcement.model";

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.support.lkPersonAnnouncement}`;

  constructor(private http: HttpClient) {}

  public GetAnnouncement(): Observable<Announcement> {
    return this.http.get<Announcement>(this.baseUrl + '/GetAnnouncement/');
  }

  public EditAnnouncement(request: Announcement) {
    console.log(environment.lkPersonApiEndpoint);
    return this.http.post(this.baseUrl + '/EditAnnouncement/', request)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}
