import { Injectable } from '@angular/core';
import {
  RetakeEditModel,
  RetakeModel,
} from '../../models/middlecontrol/retake.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, Observable, of } from 'rxjs';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'

@Injectable({
  providedIn: 'root',
})
export class RetakeService {
  baseUrl = `${environment.apiEndpoint}`;

  constructor(
    private httpClient: HttpClient,
    private notifications: NotificationsService
  ) {}

  public getDisciplineData(
    studentId: string,
    semesterNum: number
  ): Observable<RetakeModel[]> {
    return this.httpClient
      .get<RetakeModel[]>(
        this.baseUrl +
          environment.apiPaths.session.retakes +
          `/${studentId}/${semesterNum}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить информацию о пересдачах'
          );
          return of([]);
        })
      );
  }

  public getRetakeEditData(
    studentId: string,
    disciplineId: string,
    electiveDisciplineId?: string | null,
  ): Observable<RetakeEditModel[]> {
    return this.httpClient
      .get<RetakeEditModel[]>(
        this.baseUrl +
          environment.apiPaths.session.studentmarks +
          `/${studentId}/${disciplineId}` + (electiveDisciplineId ? `/${electiveDisciplineId}`: ``)
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить информацию о пересдачах'
          );
          return of([]);
        })
      );
  }

  public saveRetakeEditData(
    saveData: Pick<RetakeEditModel, 'id' | 'isInTime' | 'isVisible'>[]
  ): Observable<any> {
    return this.httpClient
      .post<any>(
        this.baseUrl + environment.apiPaths.session.updatestudentmarks,
        saveData
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось сохранить изменения');
          return of([]);
        })
      );
  }

  public formateDateStringFromUTC(date: string) {
    return date.split('T')[0].split('-').reverse().join('.');
  }
}
