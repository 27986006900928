import { Routes } from '@angular/router';
import { DiplomHomeComponent } from '../components/gia/diplomhome/diplomhome.component';
import { DiplomTableComponent } from '../components/gia/diploms/diplomatable/diplomatable.component';
import { FormationDiplomaComponent } from '../components/gia/diploms/formationdiploma/formationdiploma.component';
import { DictPrintTemplateCategoryComponent } from '../components/gia/dicts/dict-print-template-category/dict-print-template-category.component';
import { PrintTemplateComponent } from '../components/gia/print-template/print-template.component';
import { AddPrintTemplateComponent } from '../components/gia/print-template/add-print-template/add-print-template.component';
import { StateExamCommissionComponent } from '../components/gia/state-exam-commission/state-exam-commission.component';
import { AddStateExamCommissionComponent } from '../components/gia/state-exam-commission/add-state-exam-commission/add-state-exam-commission.component';
import { DictStateExamCommissionTypeComponent } from '../components/gia/dicts/dict-state-exam-commission-type/dict-state-exam-commission-type.component';
import { GiaDictComponent } from '../components/gia/dicts/dict/gia-dict.component';
import { GiaRoleGuard } from '../guards/gia-role.guard';
import { DictDesignerComponent } from '../components/gia/dicts/dict-designer/dict-designer.component';
import { GiaAccessComponent } from '../components/useraccess/gia-access/gia-access.component';
import { GiaUserAccessGuard } from '../guards/gia-user-access.guard';
import { GiaDictLocalSignatoryRoleComponent } from '../components/gia/dicts/dict-local-signatory-role/gia-dict-local-signatory-role.component';
import { GiaLocalSignatoryComponent } from '../components/gia/local-signatory/gia-local-signatory.component';
import { AddDisciplineComponent } from '../components/gia/diploms/adddiscipline/adddiscipline.component';
import { DictDiplomaTypeComponent } from '../components/gia/dicts/dict-diploma-type/dict-diploma-type.component';
import { GiaPageComponent } from '../components/gia/diploms/giapage/giapage.component';
import { DictStateExamCommissionMemberRoleComponent } from '../components/gia/dicts/dict-state-exam-commission-member-role/dict-state-exam-commission-member-role.component';
import { ReportListComponent } from '../components/gia/report-list/report-list.component';
import { ConstructorGiaProtocolComponent } from '../components/gia/constructor-giaprotocol/constructor-giaprotocol.component';
import { AdmissionOrderHomeComponent } from '../components/gia/admission-order/admission-order-home/admission-order-home.component';
import { AdmissionOrderTableComponent } from '../components/gia/admission-order/admission-order-table/admission-order-table.component';
import { ReleaseOrderHomeComponent } from '../components/gia/release-order/release-order-home/release-order-home.component';
import { ReleaseOrderTableComponent } from '../components/gia/release-order/release-order-table/release-order-table.component';
import { AdmissionOrderFormComponent } from '../components/gia/admission-order/admission-order-form/admission-order-form.component';
import { ReleaseOrderFormComponent } from '../components/gia/release-order/release-order-form/release-order-form.component';
import { ReleaseOrderAddStudentComponent } from '../components/gia/release-order/release-order-add-student/release-order-add-student.component';
import { AdmissionOrderAddStudentComponent } from '../components/gia/admission-order/admission-order-add-student/admission-order-add-student.component';
import { StudentDebtComponent } from '../components/gia/admission-order/student-debt/student-debt.component';
import { GiaProtocolHomeComponent } from '../components/gia/gia-protocol/giaprotocolhome/giaprotocolhome.component';
import { GiaProtocolTableComponent } from '../components/gia/gia-protocol/giaprotocoltable/giaprotocoltable.component';
import { StudentProtocolComponent } from '../components/gia/gia-protocol/student-protocol/student-protocol.component';
import { SettingsComponent } from '../components/gia/gia-settings/settings.component';
import { Type } from '@angular/core';

export const GiaRoutes: Routes = [
  { path: 'gia', component: GiaPageComponent, canActivate: [GiaRoleGuard] },
  {
    path: 'gia/diploma',
    component: DiplomHomeComponent,
    canActivate: [GiaRoleGuard],
    children: [
      {
        path: ':studyFormId/:standardId',
        component: DiplomTableComponent,
        canActivate: [GiaRoleGuard],
      },
    ],
  },
  {
    path: 'gia/admissionorder',
    component: AdmissionOrderHomeComponent,
    canActivate: [GiaRoleGuard],
    children: [
      {
        path: ':standardId/:facultyId/:graduateYear/:studyFormId',
        component: AdmissionOrderTableComponent,
        canActivate: [GiaRoleGuard],
      },
    ],
  },
  {
    path: 'gia/releaseorder',
    component: ReleaseOrderHomeComponent,
    canActivate: [GiaRoleGuard],
    children: [
      {
        path: ':standardId/:facultyId/:graduateYear/:studyFormId',
        component: ReleaseOrderTableComponent,
        canActivate: [GiaRoleGuard],
      },
    ],
  },
  { path: 'gia/admissionorder/studentdebt/:studentId', component: StudentDebtComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/admissionorderform', component: AdmissionOrderFormComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/admissionorderform/:orderId', component: AdmissionOrderFormComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/admissionorderform/:orderId/add', component: AdmissionOrderAddStudentComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/releaseorderform', component: ReleaseOrderFormComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/releaseorderform/:orderId', component: ReleaseOrderFormComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/releaseorderform/:orderId/add', component: ReleaseOrderAddStudentComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/formatdiploma', component: FormationDiplomaComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/formatdiploma/:id', component: FormationDiplomaComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/formatdiploma/:id/adddiscipline', component: AddDisciplineComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/dictprinttemplatecategory', component: DictPrintTemplateCategoryComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/printtemplate', component: PrintTemplateComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/printtemplate/add', component: AddPrintTemplateComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/printtemplate/add/:id', component: AddPrintTemplateComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/stateExamCommission', component: StateExamCommissionComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/stateExamCommission/add', component: AddStateExamCommissionComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/stateExamCommission/add/:id', component: AddStateExamCommissionComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/dictstateexamcommissiontype', component: DictStateExamCommissionTypeComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/dictstateexamcommissionmemberrole', component: DictStateExamCommissionMemberRoleComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/dict/:dictId', component: GiaDictComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/dictdesigner', component: DictDesignerComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/useraccess', component: GiaAccessComponent, canActivate: [GiaUserAccessGuard] },
  { path: 'gia/localsignatoryrole', component: GiaDictLocalSignatoryRoleComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/signatory', component: GiaLocalSignatoryComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/dictdiplomatype', component: DictDiplomaTypeComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/report-list', component: ReportListComponent, canActivate: [GiaRoleGuard] },
  { path: 'gia/constructorgia', component: ConstructorGiaProtocolComponent, canActivate: [GiaRoleGuard] },
  {
    path: 'gia/giaprotocol',
    component: GiaProtocolHomeComponent,
    canActivate: [GiaRoleGuard],
    children: [
      { path: ':id', component: GiaProtocolTableComponent, canActivate: [GiaRoleGuard] },
      { path: ':id/student/:studentid', component: StudentProtocolComponent, canActivate: [GiaRoleGuard] },
    ],
  },
  { path: 'gia/gia-settings', component: SettingsComponent, canActivate: [GiaRoleGuard] },
];

export const GiaComponents: Type<unknown>[] = [SettingsComponent];
