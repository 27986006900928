<kendo-grid
  [data]="group"
  class="table"
  scrollable="virtual"
  kendoGridSelectBy="studentId"
  [(selectedKeys)]="selectedStudents"
  [selectable]="{ enabled: true, checkboxOnly: true }"
  [loading]="isLoading"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="table__loader">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <kendo-grid-column
    title="№"
    [width]="50"
    [class]="'table__cell'"
    [headerClass]="'table__header'"
  >
    <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">{{
      rowIndex + 1
    }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    title="ФИО"
    [width]="200"
    [headerClass]="'table__header'"
    [class]="'table__cell table__cell--fio'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p (click)="navigateTo(dataItem.studentId)">{{ dataItem.fio }}</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    title="№ зачетной книжки"
    field="studentNumber"
    [headerClass]="'table__header'"
    [width]="80"
    [class]="'table__cell'"
  ></kendo-grid-column>
  <kendo-grid-column
    title="Тема курсовой работы / проекта"
    [width]="220"
    [headerClass]="'table__header'"
    [class]="'table__cell'"
    *ngIf="controlActionType.includes('Курсов')"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="table__cell table__cell--custom table__cell--input">
        <kendo-textbox
          [disabled]="!editable"
          [(value)]="dataItem.projectName"
          (inputBlur)="changeProjectName(dataItem.studentId)"
        >
        </kendo-textbox>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    title="Оценка"
    [width]="150"
    [headerClass]="'table__header'"
    [class]="'table__cell'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="table__cell table__cell--custom table__cell--input debt">
        <kendo-dropdownlist
          [disabled]="!editable || !dataItem.sessionAllowed"
          [data]="gradeLevelList"
          textField="markName"
          valueField="markValue"
          #mark
          [value]="dataItem.disciplineMark"
          (opened)="onStudentMarkOpened(dataItem.studentId)"
          (closed)="onStudentMarkClosed(dataItem.studentId, dataItem.disciplineMark)"
          (valueChange)="onStudentMarkChanged($event, [dataItem.studentId], mark)"
        >
        </kendo-dropdownlist>
        <p
          class="debt-message"
          *ngIf="!dataItem.sessionAllowed"
        >
          Имеется задолженность в тек. контроле (или перезачет)
        </p>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    *ngIf="!choiceOfTeacher"
    title="Экзаменатор"
    [width]="150"
    [headerClass]="'table__header'"
    [class]="'table__cell'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="table__cell table__cell--custom table__cell--input" [ngClass]="{debtAlign : !dataItem.sessionAllowed}">
        <kendo-autocomplete
          [data]="persons"
          [disabled]="!editable || !dataItem.sessionAllowed"
          valueField="fio"
          [clearButton]="true"
          [filterable]="true"
          [ngModel]="dataItem?.examinerName"
          (filterChange)="handleFilter($event)"
          (valueChange)="onStudentExaminerChanged($event, dataItem)"
        >
        </kendo-autocomplete>
      </div>
    </ng-template>
    <ng-template kendoGridFooterTemplate>
      <div class="table__footer-container">
        <kendo-label
          text="Выберите действие"
          class="select-action-label"
        >
          <kendo-autocomplete
            [data]="persons"
            [disabled]="!editable"
            [clearButton]="true"
            [filterable]="true"
            (filterChange)="handleFilter($event)"
            (valueChange)="onAllStudentExaminerChanged($event)"
            [ngModel]="massExaminer"
            valueField="fio"
            [popupSettings]="{
              width: 300
            }"
            [kendoDropDownFilter]="filterSettings"
          >
          </kendo-autocomplete>
        </kendo-label>
        <button
          kendoButton
          class="table__button"
          [disabled]="!massExaminer"
          (click)="applyExaminerChanged()"
        >
          Применить
        </button>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Дата получения оценки"
    [width]="130"
    [headerClass]="'table__header'"
    [class]="'table__cell'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p>{{ getDateString(dataItem.markDate) }}</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    title="Пересдачи"
    [width]="130"
    [headerClass]="'table__header'"
    [class]="'table__cell'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="table__cell table__cell--custom table__cell--retake">
        <p
          class="retake"
          *ngIf=" dataItem.disciplineMark !== null &&
            (dataItem.disciplineMark?.markValue === 2 ||
              dataItem.disciplineMark?.markValue < 1 && (dataItem.disciplineMark?.markValue !== -1 || dataItem.retake > 0))
          "
        >
          Попыток осталось:&nbsp;{{ getRetakeNumber(dataItem.retake) }}
        </p>
        <ng-template
          [ngIf]="
            !!dataItem.retake &&
            !!dataItem.retakes &&
            dataItem.disciplineMark?.markValue !== 2 &&
            dataItem.disciplineMark?.markValue > 0
          "
        >
          <div class="retake-info">
            <p class="retake-info__text" *ngFor="let mark of dataItem.retakes">
              {{ mark.markDate }}&nbsp;({{ marks[mark.markName].markIcon }})
            </p>
          </div>
        </ng-template>

        <button
          *ngIf="editable"
          kendoButton
          [disabled] = '!dataItem.sessionAllowed'
          icon="edit"
          fillMode="clear"
          (click)="openRetakeEdit(dataItem.studentId, dataItem.generalDisciplineId)"
        ></button>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    *ngIf="editable"
    [width]="180"
    [resizable]="false"
    [headerClass]="'table__header'"
    [class]="'table__cell'"
    [footerClass]="'table__footer'"
  >
    <ng-template kendoGridHeaderTemplate let-dataItem>
      <div class="table__header table__header--custom">
        <p>Массовые операции</p>
        <kendo-label class="label label--right" text="Выбрать всех">
          <input
            type="checkbox"
            kendoCheckBox
            [checked]="isCheckStudentAll"
            (click)="selectAll()"
          />
        </kendo-label>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="table__cell table__cell--custom table__cell--text">
        <input
          type="checkbox"
          kendoCheckBox
          [disabled]="!dataItem.sessionAllowed"
          [checked]="checkedStudent[dataItem.studentId]"
          (change)="checkedStudentChange($event, dataItem.studentId)"
        />
      </div>
    </ng-template>
    <ng-template kendoGridFooterTemplate>
      <div class="table__footer-container">
        <kendo-label
          text="Выберите действие"
          class="select-action-label"
        >
          <kendo-dropdownlist
            [data]="gradeLevelList"
            textField="markName"
            valueField="markValue"
            style="width: 155px"
            [popupSettings]="{
              width: 200
            }"
            (valueChange)="changeMarkToAll($event)"
          >
          </kendo-dropdownlist>
        </kendo-label>
        <button
          kendoButton
          class="table__button"
          [disabled]="!markToAll"
          (click)="applyMarkToAll()"
        >
          Применить
        </button>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="180" [footerClass]="'table__footer'" *ngIf="editable">
    <ng-template kendoGridHeaderTemplate let-dataItem>
      <div class="table__header table__header--custom">
        <p>Массовая смена даты получения оценки</p>
        <kendo-label class="label label--right" text="Выбрать всех">
          <input
            type="checkbox"
            kendoCheckBox
            [checked]="isCheckDateAll"
            (click)="selectAllDateChecked()"
          />
        </kendo-label>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="table__cell table__cell--custom table__cell--text">
        <input
          type="checkbox"
          kendoCheckBox
          [disabled]="!dataItem.sessionAllowed"
          [checked]="checkedDate[dataItem.studentId]"
          (change)="checkedChange($event, dataItem.studentId)"
        />
      </div>
    </ng-template>
    <ng-template kendoGridFooterTemplate>
      <div class="table__footer-container">
        <button kendoButton class="table__button" (click)="applyDateToAll()">
          Применить
        </button>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Экзам. лист"
    *ngIf="showExamList === true"
    [width]="60"
    [headerClass]="'table__header'"
    [class]="'table__cell'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
        <button
          *ngIf="editable"
          kendoButton
          icon="save"
          fillMode="clear"
          (click)="printExamList(dataItem.studentId)"
        ></button>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>
<kendo-dialog
  title="Пожалуйста подтвердите"
  *ngIf="isOpenDialog"
  (close)="closeDialog()"
  [minWidth]="380"
  [width]="600"
  [class]="'modal'"
>
  <kendo-label text="Причина смены оценки" class="modal__label">
    <kendo-dropdownlist
      class="modal__input"
      [data]="dictReasons"
      textField="name"
      valueField="id"
      (valueChange)="onChangeReason($event)"
    >
    </kendo-dropdownlist>
  </kendo-label>
  <kendo-label text="Дата получения оценки" class="modal__label">
    <kendo-datepicker
      calendarType="infinite"
      [value]="reasonMarkDate"
      (valueChange)="changeMarkDateReason($event)"
      class="modal__input"
    >
    </kendo-datepicker>
  </kendo-label>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeDialog()">Нет</button>
    <button kendoButton themeColor="primary" (click)="acceptDialog()">
      Да
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
