<contingent-header></contingent-header>

<h2 class="title-page">Конструктор названий групп</h2>

<div class="nav">
  <div
    *ngFor="let settingFilterEduGroupName of settingFilterEduGroupNameList"
    class="nav-elem-container"
  >
    <div [class.nav-elem_drop]="settingFilterEduGroupName === dragOverSettingFilterEduGroupName"></div>
    <button
      kendoButton
      (click)="changeValues(settingFilterEduGroupName)"
      draggable="true"
      class="nav-elem"
      (dragstart)="dragStartSettingFilter(settingFilterEduGroupName)"
      (dragenter)="dragEnterSettingFilter(settingFilterEduGroupName)"
      (drop)="dragDropSettingFilter(settingFilterEduGroupName)"
      (dragleave)="dragLeaveSettingFilter()"
      (dragover)="dragOverSettingFilter(settingFilterEduGroupName)"
      (dragend)="dragEndSettingFilter()"
      [class.curr-nav-elem]="settingFilterEduGroupName === currSettingFilter"
      #navElem
    >
      {{settingFilterEduGroupName.order + 1}}
    </button>
    <kendo-contextmenu
      [target]="navElem"
      [items]="[{text: 'Удалить'}]"
      (select)="onSelectNavContextMenu(settingFilterEduGroupName.order)"
    >
    </kendo-contextmenu>
  </div>
  <button class="nav__btn-add" (click)="addSettingFilter()">
    <kendo-icon name="plus" class="icon-plus"></kendo-icon>
  </button>
</div>

<div class="filter-list">
  <div
    *ngFor="let filter of currSettingFilter.listOrderPartCondition"
    class="filter-container"
    draggable="true"
    (dragstart)="dragStartFilter(filter)"
    (dragenter)="dragEnterFilter(filter)"
    (dragover)="dragOverFilter(filter)"
    (dragleave)="dragLeaveFilter()"
    (drop)="dragDropFilter(filter)"
    (dragend)="dragEndFilter()"
  >
    <span
      *ngIf="filter != currSettingFilter.listOrderPartCondition[0] || filter === dragOverFilterEduGroupName"
      class="filter-elem__separator filter-elem_drop"
      [class.filter-elem_drop]="filter === dragOverFilterEduGroupName"
    >
    </span>
    <div>
      <div class="filter-name">
        {{getFilterName(filter)}}
      </div>
      <kendo-dropdownlist
        [data]="getDataByFilterEnum(filter)"
        textField="name"
        valueField="externalId"
        class="filter-drop-list"
        (valueChange)="changeFilter($event, filter)"
        id="filter-id-{{filter}}"
        [(value)]="valuesDropDownFilters[filter]"
      ></kendo-dropdownlist>
    </div>
  </div>
</div>

<div class="used-filter-list">
  <div
    class="used-filter-list__title"
    (click)="showUsedFiltersBySetting()"
  >
    <h2 class="used-filter-list__title-text">Используемые фильтры</h2>
    <button
      kendoButton
      [ngClass]="arrowButton"
      fillMode="clear"
      icon="kpi-trend-decrease"
      class="used-filter-list__title-btn"
      id="used-filter-list-arrow"
    ></button>
  </div>
  <ul *ngIf="viewUsedFilter">
    <li *ngFor="let usedFilter of usedFilterBySettingOrderList" class="used-filter">
      <button
        kendoButton
        fillMode="outline"
        themeColor="error"
        class="used-filter__btn-delete"
        (click)="deleteFilter(usedFilter.externalId)"
      >
        удалить
      </button>
      <p class="used-filter__text" (click)="changeFilterByUsedFilter(usedFilter.externalId)">{{usedFilter.message}}</p>
    </li>
  </ul>
</div>

<div class="type-value-filter-container">
  <div class="type-value-filter">
    <button
      kendoButton
      icon="arrow-chevron-left"
      class="prev-filter-btn"
      (click)="toPrevSettingFilter()"
    ></button>
    <div class="text-box-list">
      <kendo-dropdownlist
        [data]="dictTypeValueFilterEduGroupNameList"
        textField="name"
        valueField="externalId"
        [valuePrimitive]="true"
        [(value)]="currFilter.dictTypeValueFilterEduGroupNameId"
        class="text-box type-value-filter__drop-list"
      ></kendo-dropdownlist>

      <kendo-textbox
        *ngIf="isNumberTypeFilter() || isStringTypeFilter()"
        [placeholder]="isNumberTypeFilter() ? 'Введите число' : 'Введите текст'"
        class="text-box"
        [(ngModel)]="currFilter.value"
      ></kendo-textbox>

      <div
        *ngIf="isRangeNumbersTypeFilter()"
        class="type-value-filter__range-numbers"
      >
        <kendo-textbox
          placeholder="Введите начало диапазона"
          class="text-box range-numbers-text-box"
          [(ngModel)]="startRange"
        ></kendo-textbox>
        <div class="range-numbers__separator"></div>
        <kendo-textbox
          placeholder="Введите конец диапазона"
          class="text-box range-numbers-text-box"
          [(ngModel)]="endRange"
        ></kendo-textbox>
      </div>

      <div *ngIf="isStringsTypeFilter()" class="type-value-filter__strings">
        <button kendoButton themeColor="success" (click)="addString()">Добавить</button>
        <kendo-textbox
          class="strings-text-box"
          [(value)]="typeFilterString"
          placeholder="Введите текст и нажмите Добавить"
        ></kendo-textbox>
        <div class="type-value-filter__chip-list">
          <kendo-chiplist
            (remove)="deleteString($event)"
          >
            <kendo-chip
              *ngFor="let typeFilterStrings of typeFilterStringsList"
              [label]="typeFilterStrings.label"
              [removable]="typeFilterStrings.removable">
            </kendo-chip>
          </kendo-chiplist>
        </div>
      </div>

    </div>
    <button
      kendoButton
      icon="arrow-chevron-right"
      class="next-filter-btn"
      (click)="toNextSettingFilter()"
    ></button>
  </div>
  <div class="type-value-filter__btn-list">
    <button
      kendoButton
      themeColor="success"
      class="type-value-filter__btn-list_btn"
      (click)="saveFilter()"
    >
      Сохранить
    </button>
    <button
      kendoButton
      fillMode="outline"
      themeColor="error"
      class="type-value-filter__btn-list_btn type-value-filter__btn-list_btn-delete"
      (click)="deleteFilter(currFilter.externalId)"
    >
      Удалить
    </button>
  </div>
</div>

<div kendoDialogContainer></div>
