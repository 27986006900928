import { Component, OnInit, ViewChild, Renderer2} from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent, PagerPosition } from '@progress/kendo-angular-grid';
import { PagerType } from '@progress/kendo-angular-pager';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DialogService, DialogRef, DialogCloseResult } from "@progress/kendo-angular-dialog";
import { DictAchievementService } from 'src/app/services/contingent/dictachievement.service'
import { DictAchievements } from "../../../models/contingent/dictachievement.model";
import { isChildOf } from "src/app/helpers/elementRef-helper";
import { openRemoveDialog } from "src/app/helpers/dialogHelper";
import { DictFilialService } from 'src/app/services/contingent/dictfilial.service';
import { DictFilial } from 'src/app/models/contingent/dictfilial.model';
import { DictFilter } from 'src/app/models/contingent/filter.model';
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";

@Component({
    selector: 'app-dictachievement',
    templateUrl: './dictachievement.component.html',
    styleUrls: ['./dictachievement.component.scss'],
  })
  
export class DictAchievement implements OnInit {

    public categoryAchievements: DictAchievements[] = [];
    public categoryAchievementsByFilter: DictAchievements[] = [];
    public filials: DictFilial[] = [];
    public secondFilter: DictFilter = {
      dictFilialExternalId: undefined
    }
    public type: PagerType = 'numeric';
    public buttonCount = 5;
    public info = true;
    public pageSizes = [10, 20, 50];
    public previousNext = true;
    public positionPager: PagerPosition = 'bottom';

    public pageSize = 10;
    public sizes = [10, 20, 50];
    public position: "top" | "bottom" | "both" = "bottom";
    public formGroup: UntypedFormGroup | undefined;

    @ViewChild(GridComponent) private grid!: GridComponent;
    private editedRowIndex: number | undefined;
    private isNew = false;
    private isLine = false;

    public editable?: boolean = true;
    public id: string = "";
    public get isInEditingMode(): boolean {
        return this.editedRowIndex !== undefined || this.isNew;
      }

    public exists: boolean = false;
    public opened = false;

constructor(
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private dictAchievementService: DictAchievementService,
    private userAccessService: ContingentUserAccessService,
    private renderer: Renderer2,
    private dictFilialService: DictFilialService,
){}

public ngOnInit(): void {
    this.getAccessLevel();
    this.getAllFilials();
    this.getAchievement();

    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
}

  public getAchievement() {
      this.dictAchievementService.getCategoryAchievement()
          .subscribe(response => {
              this.categoryAchievements = response;
              this.applyFilter();
          })
  }

  public getAllFilials() {
    this.dictFilialService.getAllfilial()
      .subscribe(
        response => {
          this.filials = response;
        }
      );
  }

  public defaultItemFilial: { filialSName: string; dictFilialExternalId?: any } = {
    filialSName: "Не выбрано",
    dictFilialExternalId: null,
  };

  public applyFilter() {
    this.categoryAchievementsByFilter = [];
    this.categoryAchievementsByFilter = this.categoryAchievements.filter((item: DictAchievements) => item.filialId == this.secondFilter.dictFilialExternalId);
  }

  public removeHandler({ dataItem }: RemoveEvent) {

    const dialog: DialogRef = openRemoveDialog(this.dialogService, dataItem.name, 450, 200, 250)

    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult))
        if (result.text == "Да") {
          this.opened = false;
          this.dictAchievementService.deleteCategoryAchievement(dataItem.id)
            .subscribe(
              (next) => {
                this.getAchievement();
                this.notificationService.showSuccess("Удалено");
              },
              (error: any) => {
                this.notificationService.showError(error.error);
              });
        }
        else {
          this.opened = false;
        }
    });
  }

  public editHandler({
      sender,
      columnIndex,
      rowIndex,
      dataItem,
    }: CellClickEvent) {
      if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
          this.saveCurrent();
          return;
        }
      if (!this.editable) return;
      this.isLine = true;

      this.id = dataItem.id;

      this.formGroup = formGroup(dataItem);
      this.editedRowIndex = rowIndex;

      sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  public addHandler({ sender }: AddEvent): void {
      this.closeEditor(sender);

      this.formGroup = formGroup({
        name: "",
      });

      this.isLine = true;
      this.isNew = true;
      sender.addRow(this.formGroup);
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.editable = response.dictAccessLevel != 1
        },error:() => {}});
  }

  public cancelHandler() {
      this.closeEditor(this.grid, this.editedRowIndex);
  }

  private closeEditor(grid: GridComponent, rowIndex: number | undefined = this.editedRowIndex
      ): void {
        this.isNew = false;
        this.isLine = false;
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
  }

  public saveCurrent() {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    
      if (this.isInEditingMode) {
          //Adding new entry to dictionary
          if (this.isNew && this.formGroup) {
              this.exists = false;

              const name = this.formGroup.value.name.toLowerCase().trim();

              if (this.categoryAchievements && this.categoryAchievements.findIndex((item: DictAchievements) =>
                item.name.toLowerCase().trim() == name && item.filialId == this.secondFilter.dictFilialExternalId) !== -1) { 
                  this.notificationService.showError("Невозможно сохранить, так как такое название уже существует");
                  this.exists = true;
              }

              if (!this.exists) {
                this.dictAchievementService.addCategoryAchievement({ ...this.formGroup?.value, ...this.secondFilter })
                  .subscribe({
                    next:() => {
                      this.getAchievement();
                      this.notificationService.showSuccess("Добавлено");
                    },
                    error:() => {
                      this.notificationService.showError('Не удалось добавить категорию достижений');
                    }});
              }          
          }
          //Update dictionary
          else if (this.formGroup) {
              this.exists = false;

              const name = this.formGroup.value.name.toLowerCase().trim();
              const findElement = this.categoryAchievements.find((item: DictAchievements) =>
                item.name.toLowerCase().trim() == name && item.filialId == this.secondFilter.dictFilialExternalId);

              if (this.categoryAchievements.findIndex((item: DictAchievements) =>
                item.name.toLowerCase().trim() == name && item.filialId == this.secondFilter.dictFilialExternalId) !== -1 && findElement?.id !== this.id) { 
                  this.notificationService.showError("Невозможно сохранить, так как такое название уже существует");
                  this.exists = true;
              }

              if (!this.exists) {
                this.dictAchievementService.updateCategoryAchievement({ ...this.formGroup?.value, ...this.secondFilter }, this.id)
                  .subscribe({
                    next:() => {
                    this.getAchievement();
                    this.notificationService.showSuccess("Сохранено");
                  },
                  error:() => {
                    this.notificationService.showError('Не удалось сохранить изменения');
                  }});
              }
          }
        }
        this.closeEditor(this.grid);
  }
    
}

const formGroup = (dataItem: { name: string; order?: number; }) =>
  new UntypedFormGroup({
    order: new UntypedFormControl(dataItem.order),
    name: new UntypedFormControl(dataItem.name, Validators.required),
});