import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {NameStudPerson, StudPerson} from '../../models/contingent/studperson.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class StudpersonService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.studperson}`;

  constructor(private http: HttpClient) { }

  //Get all studperson
  public getAllstudperson(): Observable<StudPerson[]> {
    return this.http.get<StudPerson[]>(this.baseUrl);
  }

    //Get By Id student
    getByIdstudent(id: Guid): Observable<StudPerson> {
      return this.http.get<StudPerson>(this.baseUrl + '/' + id)
        .pipe(catchError(this.handleError));
    }


  //Add studperson
  addstudperson(studperson: StudPerson): Observable<StudPerson> {
    return this.http.post<StudPerson>(this.baseUrl, studperson)
      .pipe(catchError(this.handleError));
  }

  //Delete studperson
  deletestudperson(id: string): Observable<StudPerson> {
    return this.http.delete<StudPerson>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit studperson
  updatestudperson(studperson: StudPerson): Observable<StudPerson> {
    return this.http
      .put<StudPerson>(this.baseUrl + '/' + studperson.studPersonId, studperson)
      .pipe(catchError(this.handleError));
  }

  //Edit studperson
  getNewLogin(dictStudentBudgetId: string|null, yearAdmission: number): Observable<string> {
    return this.http
      .post(this.baseUrl + '/' + 'GetNewLogin', {
        dictStudentBudgetId: dictStudentBudgetId,
        yearAdmission: yearAdmission,
      }, {responseType: 'text'})
      .pipe(catchError(this.handleError));
  }

  getNewForeignerLogin(): Observable<string> {
    return this.http
      .post(this.baseUrl + '/' + 'GetNewForeignerLogin', {}, {responseType: 'text'})
      .pipe(catchError(this.handleError));
  }

  getNameStudPersons(): Observable<NameStudPerson[]> {
    return this.http.get<NameStudPerson[]>(`${this.baseUrl}/GetNameStudPersons`)
      .pipe(catchError(this.handleError));
  }

  getNameStudPersonsNotExpelledNotGraduated(): Observable<NameStudPerson[]> {
    return this.http.get<NameStudPerson[]>(`${this.baseUrl}/GetNameStudPersonsNotExpelledNotGraduated`)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}
