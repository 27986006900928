import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  DisciplineInfo,
  SessionDateGrid,
} from 'src/app/models/middlecontrol/sesssiondate.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import { SessionService } from 'src/app/services/middlecontrol/session.service';
import {DateFromUTCAsLocal} from "../../../helpers/date-helper";
import {CellClickEvent, CellCloseEvent, EditEvent, GridComponent} from "@progress/kendo-angular-grid";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-sessiondate',
  templateUrl: './sessiondate.component.html',
  styleUrls: ['./sessiondate.component.scss'],
})
export class SessiondateComponent implements OnInit {
  @Input() semesterNumber: number = 0;
  @Input() studentId: string = '';
  @Input() studentName: string = '';
  @Input() groupName: string = '';
  @Output() onComeback: EventEmitter<void> = new EventEmitter<void>();
  @Output() studentIdChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() studentNameChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() groupNameChange: EventEmitter<string> = new EventEmitter<string>();

  public today = new Date();
  public disciplineList: DisciplineInfo[] = [];
  public gridData: SessionDateGrid[] = [];
  public isLoading: boolean = false;
  public isEditing: boolean = false;
  private editedRowIndex: number | undefined;
  public formGroup: FormGroup | undefined;
  @ViewChild('grid') private grid!: GridComponent;
  private emptyStudentSessionDiscipline: DisciplineInfo = {
    id: null,
    name: 'Без дисциплины',
    controlActionName: '',
  };

  constructor(
    private sessionService: SessionService,
    private notificationService: NotificationsService,
    private renderer: Renderer2
  ) {
    // Save values on outside click event
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid") && this.editedRowIndex != null) {
        this.savePreviousValues(this.editedRowIndex);
        this.closeEditor(this.editedRowIndex);
      }
    });
  }

  ngOnInit(): void {
    this.disciplineList = this.sessionService.sessionDisciplines$
      .getValue()
      .map((discipine) => {
        return {
          id: discipine.educationPlanDisciplineId,
          name: (discipine.electiveName ? discipine.electiveName + ` (${discipine.name})` : discipine.name) + ` (${discipine.controlActionName})`,
          controlActionName: discipine.controlActionName,
        };
      });
    this.disciplineList.unshift(this.emptyStudentSessionDiscipline);
    this.isLoading = true;
    this.sessionService
      .getSessionDateByStudent(this.studentId, this.semesterNumber)
      .subscribe((response) => {
        this.gridData = response.reduce((tableData, item) => {
          if (item.studentSessionDisciplines?.length)
            item.studentSessionDisciplines.forEach((studentDiscipline) => {
              tableData.push({
                ...item,
                sessionBegin: DateFromUTCAsLocal(item.sessionBegin),
                sessionEnd: DateFromUTCAsLocal(item.sessionEnd),
                orderDate: DateFromUTCAsLocal(item.orderDate),
                studentSessionDiscipline:
                  this.disciplineList.find(
                    (discipline) => discipline.id === studentDiscipline.id
                  ) || this.emptyStudentSessionDiscipline,
              });
            });
          else
            tableData.push({
              ...item,
              sessionBegin: DateFromUTCAsLocal(item.sessionBegin),
              sessionEnd: DateFromUTCAsLocal(item.sessionEnd),
              orderDate: DateFromUTCAsLocal(item.orderDate),
              studentSessionDiscipline: this.emptyStudentSessionDiscipline,
            });

          return tableData;
        }, [] as SessionDateGrid[]);
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this.studentIdChange.emit('');
    this.studentNameChange.emit('');
    this.groupNameChange.emit('');
  }

  public handleClose() {
    this.onComeback.emit();
  }

  public handleSave() {
    if(this.editedRowIndex != undefined) {
      this.savePreviousValues(this.editedRowIndex);
      this.grid.closeRow(this.editedRowIndex);
    }
    const saveData = this.gridData.map((item) => {
      return {
        ...item,
        sessionBegin: this.sessionService.convertDateToUTCDateOnly(item.sessionBegin),
        sessionEnd: this.sessionService.convertDateToUTCDateOnly(item.sessionEnd),
        orderDate: this.sessionService.convertDateToUTCDateOnly(item.orderDate),
        studentSessionDisciplines: item.studentSessionDiscipline?.id
          ? [item.studentSessionDiscipline?.id]
          : [],
      };
    });
    this.sessionService
      .saveSessionDateByStudent(this.studentId, this.semesterNumber, saveData)
      .subscribe((response) => {
        this.notificationService.showSuccess(
          'Индивидуальные сроки сессии успешно обновлены'
        );
        this.handleClose();
      });
  }

  public addItem() {
    if(this.isEditing)
      this.grid.closeRow(this.editedRowIndex);

    this.gridData.push({
      id: null,
      sessionBegin: this.today,
      sessionEnd: this.today,
      orderDate: this.today,
      orderNumber: '',
      orderReason: '',
      studentSessionDiscipline: this.emptyStudentSessionDiscipline,
    });
  }

  public deleteItem(rowIndex: number) {
    this.gridData.splice(rowIndex, 1);
  }

  public editHandler(args: CellClickEvent): void {
    this.isEditing = true;
    const { dataItem } = args;
    if (args.isEdited || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    // Save previous values
    if(this.editedRowIndex != undefined) {
      this.savePreviousValues(this.editedRowIndex);
      this.closeEditor(this.editedRowIndex);
    }

    this.formGroup = new FormGroup({
      sessionBegin: new FormControl(dataItem.sessionBegin),
      sessionEnd: new FormControl(dataItem.sessionEnd, Validators.required),
      orderDate: new FormControl(dataItem.orderDate),
      orderNumber: new FormControl(dataItem.orderNumber),
      orderReason: new FormControl(dataItem.orderReason),
      studentSessionDiscipline: new FormControl(dataItem.studentSessionDiscipline)
    });
    this.editedRowIndex = args.rowIndex;
    // put the row in edit mode, with the `FormGroup` build above
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  public closeRow(args: CellCloseEvent): void {
    this.closeEditor(args.rowIndex);
  }

  public savePreviousValues(rowIndex: number) {
    if(this.formGroup?.valid && this.formGroup && this.gridData.length >= rowIndex) {
      this.gridData[rowIndex].sessionBegin = this.formGroup.value.sessionBegin;
      this.gridData[rowIndex].sessionEnd = this.formGroup.value.sessionEnd;
      this.gridData[rowIndex].orderDate = this.formGroup.value.orderDate;
      this.gridData[rowIndex].orderNumber = this.formGroup.value.orderNumber;
      this.gridData[rowIndex].orderReason = this.formGroup.value.orderReason;
      this.gridData[rowIndex].studentSessionDiscipline = this.formGroup.value.studentSessionDiscipline;
    }
  }

  private closeEditor(rowIndex: number): void {
    this.grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
}

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};
