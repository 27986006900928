import {Component, OnInit, ViewChild} from '@angular/core';
import {JwtHelperService} from "@auth0/angular-jwt";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {AddEvent, EditEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {DisplaySetting} from "../../../models/support/system-setting.model";
import {Announcement} from "../../../models/support/announcement.model";
import {UsefulLinkDto} from "../../../models/support/usefulLink/useful-link.dto";
import {SupportContactDto} from "../../../models/support/supportContact/support-contact.dto";
import {TokenStorageService} from "../../../services/token.service";
import {DisplaySettingService} from "../../../services/support/display-setting.service";
import {AnnouncementService} from "../../../services/support/announcement.service";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {UsefulLinkService} from "../../../services/support/useful-link.service";
import {SupportContactService} from "../../../services/support/support-contact.service";
import {checkRole} from "../../../helpers/token/role-check";
import {Role} from "../../../models/useraccess/role";
import {openRemoveDialog} from "../../../helpers/dialogHelper";
import {CreateSupportContactDto} from "../../../models/support/supportContact/create-support-contact.dto";
import {CreateUsefulLinkDto} from "../../../models/support/usefulLink/create-useful-link.dto";
import {DisplaySettingEnum} from "../../../models/support/enums/display-setting.enum";

@Component({
  selector: 'app-support-home',
  templateUrl: './technical-support.component.html',
  styleUrls: ['./technical-support.component.scss']
})
export class TechnicalSupportComponent implements OnInit {

  public isAdmin = false;
  public enum = DisplaySettingEnum;
  public displaySetting: DisplaySetting[] = [];
  public announcement: Announcement = new Announcement();
  public usefulLinks: UsefulLinkDto[] = [];
  public supportContacts: SupportContactDto[] = [];
  public supportContact: SupportContactDto = new SupportContactDto();
  public usefulLink: UsefulLinkDto = new UsefulLinkDto();
  public usefulLinkFormGroup: FormGroup = usefulLinkFormGroup(this.usefulLink);
  public announcementContentCopy? = '';
  public announcementInEditingMode= false;
  public usefulLinkInEditingMode = false;
  public borderEditorRed = false;
  public phoneNumberMask = '+9 (999) 000-00-00';

  @ViewChild(GridComponent) private grid!: GridComponent;
  public formGroup: FormGroup = formGroup(this.supportContact);
  public opened = false;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private displaySettingService: DisplaySettingService,
    private announcementService: AnnouncementService,
    private notificationService: NotificationsService,
    private usefulLinkService: UsefulLinkService,
    private supportContactService: SupportContactService,
    private dialogService: DialogService
  ) {
    this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
  }

  ngOnInit(): void {
    this.getDisplaySystemSetting();
    this.GetAnnouncement();
    this.GetUsefulLinks();
    this.GetSupportContacts();
  }

  public getDisplaySystemSetting() {
    this.displaySettingService.GetAllDisplaySettings()
      .subscribe(
        response => {
          this.displaySetting = response;
        }
      );
  }

  public GetAnnouncement() {
    this.announcementService.GetAnnouncement()
      .subscribe(
        response => {
          this.announcement = response;
        }
      );
  }

  public GetUsefulLinks() {
    this.usefulLinkService.GetUsefulLinks()
      .subscribe(
        response => {
          this.usefulLinks = response;
        }
      );
  }

  public GetSupportContacts() {
    this.supportContactService.GetSupportContacts()
      .subscribe(
        response => {
          this.supportContacts = response;
        }
      );
  }

  public isShowed(field: number): boolean | undefined {
    const isHidden = false;
    const displaySetting = this.displaySetting.find((x) => x.type === field);
    return displaySetting?.isDisplay
  }

  public TitleName() {
    if (this.announcementInEditingMode)
      return 'Редактировать объявление';
    if (this.usefulLinkInEditingMode)
      return 'Добавить полезную ссылку';
    return 'Техподдержка';
  }

  public valueChangeEditor() {
    if (!(this.announcement.content == null || this.announcement.content.trim() === '' || this.announcement.content == '<p></p>'))
      this.borderEditorRed = false;
  }

  public EditAnnouncement() {
    this.announcementInEditingMode = true;
    this.announcementContentCopy = this.announcement.content;
  }

  public CancelAnnouncementEditingMode() {
    this.announcement.content = this.announcementContentCopy;
    this.announcementInEditingMode = false;
  }

  public SaveAnnouncement() {
    this.announcementService.EditAnnouncement(this.announcement)
      .subscribe(
        response => {
          this.announcementInEditingMode = false;
          this.notificationService.showSuccess('Сохранено');
        }
      );
  }

  public EditUsefulLink(link: UsefulLinkDto) {
    this.usefulLinkFormGroup = usefulLinkFormGroup(link);
    this.usefulLinkInEditingMode = true;
  }

  public DeleteUsefulLink(link: UsefulLinkDto) {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, `${link.name}`);
    dialog.result.subscribe((result ) => {
      if (result instanceof DialogCloseResult) {}
      else {
        if (result.text == "Да") {
          this.usefulLinkService.DeleteUsefulLink(link.externalId).subscribe({
            next: () => {
              this.GetUsefulLinks();
              this.notificationService.showSuccess("Ссылка удалена");
            },
            error: err => {
              this.notificationService.showError(err);
            }
          })
        }
      }
    });
  }

  public removeHandler({dataItem}: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, dataItem.name, 450, 200, 250);

    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult)) {
        this.opened = false;
        if (result.text == 'Да') {
          this.supportContactService.DeleteSupportContact(dataItem.externalId).subscribe({
            next: () => {
              this.GetSupportContacts();
              this.notificationService.showSuccess('Удалено');
            },
            error: (error) => {
              this.notificationService.showError(error.error);
            },
          });
        }
      }
    });
  }

  public editHandler(args: EditEvent): void {
    /*if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }*/
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(args.dataItem);
    this.editedRowIndex = args.rowIndex;
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  public saveCurrent(): void {

    if (this.formGroup) {

      if (this.hasErrorsInValidation()) {
        this.notificationService.showError('Форма содержит ошибки. Пожалуйста, исправьте их перед сохранением.');
        return;
      }

      this.isLine = false;
      this.saveRow();
    }
  }

  public hasErrorsInValidation(): boolean {
    const formGroup = this.formGroup;
    const emails = formGroup.get('emails') as FormArray;
    const chatLink = formGroup.get('chatLink')?.value;

    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const chatLinkPattern = /^https:\/\/.*/;

    let hasError = false;

    // Проверка email
    emails.controls.forEach((control, index) => {
      const email = control.get('email')?.value;
      const isSingleAndEmpty = emails.length === 1 && !email;
      if (!isSingleAndEmpty && email && !emailPattern.test(email)) {
        hasError = true;
      }
    });

    // Проверка chatLink
    if (chatLink && !chatLinkPattern.test(chatLink)) {
      hasError = true;
    }

    return hasError;
  }

  public addHandler({sender}: AddEvent): void {
    this.closeEditor(sender);
    this.formGroup = formGroup(new SupportContactDto());
    this.isLine = true;
    this.isNew = true;
    this.addPhoneNumber(this.formGroup);
    this.addEmail(this.formGroup);
    sender.addRow(this.formGroup);
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = formGroup(new SupportContactDto());
  }

  private saveRow(): void {
    if (this.isInEditingMode) {
      if (this.formGroup !== undefined) {

        if (this.isNew) {
          const dto = this.mapFormGroupToCreateDto(this.formGroup);
          this.supportContactService.CreateSupportContact(dto).subscribe({
            next: () => {
              this.GetSupportContacts();
              this.notificationService.showSuccess('Добавлено');
              this.resetSupportContactForm();
            },
            error: () => {
              this.notificationService.showError('Не удалось добавить контакт тех.служб');
            },
          });
        } else {
          const updateDto = this.mapFormGroupToUpdateDto(this.formGroup);
          this.supportContactService.UpdateSupportContact(updateDto).subscribe({
            next: () => {
              this.GetSupportContacts();
              this.notificationService.showSuccess('Сохранено');
            },
            error: () => {
              this.notificationService.showError('Не удалось сохранить изменения');
            },
          });
        }
      }
    }
    this.closeEditor(this.grid);
  }

  private mapFormGroupToCreateDto(formGroup: FormGroup): CreateSupportContactDto {
    const phoneNumbersArray = (formGroup.get('phoneNumbers') as FormArray).controls.map(control => control.value.phoneNumber);
    const emailsArray = (formGroup.get('emails') as FormArray).controls.map(control => control.value.email);

    const dto: CreateSupportContactDto = {
      name: formGroup.get('name')?.value,
      phoneNumbers: phoneNumbersArray,
      emails: emailsArray,
      chatLink: formGroup.get('chatLink')?.value
    };

    return dto;
  }

  private mapFormGroupToUpdateDto(formGroup: FormGroup): SupportContactDto {
    const phoneNumbersArray = (formGroup.get('phoneNumbers') as FormArray).controls.map(control => control.value.phoneNumber);
    const emailsArray = (formGroup.get('emails') as FormArray).controls.map(control => control.value.email);

    const dto: SupportContactDto = {
      externalId: formGroup.get('externalId')?.value,
      name: formGroup.get('name')?.value,
      phoneNumbers: phoneNumbersArray,
      emails: emailsArray,
      chatLink: formGroup.get('chatLink')?.value
    };

    return dto;
  }

  private resetSupportContactForm(): void {
    this.supportContact = {
      externalId: '',
      name: '',
      phoneNumbers: [],
      emails: [],
      chatLink: '',
    };
  }

  public CancelUsefulLinkEditingMode() {
    this.usefulLinkInEditingMode = false;
  }

  public SaveUsefulLink() {
    if (this.usefulLinkFormGroup.value.externalId){
      const usefulLink: UsefulLinkDto = {
        externalId: this.usefulLinkFormGroup.value.externalId,
        name: this.usefulLinkFormGroup.value.name,
        url: this.usefulLinkFormGroup.value.url,
        order: this.usefulLinkFormGroup.value.order,
        isVisible: this.usefulLinkFormGroup.value.isVisible ? true : false
      };
      this.usefulLinkService.UpdateUsefulLink(usefulLink).subscribe({
        next: () => {
          this.GetUsefulLinks();
          this.notificationService.showSuccess("Ссылка изменена");
          this.usefulLinkInEditingMode = false;
        },
        error: err => {
          this.notificationService.showError(err);
        }
      })
    }
    else {
      const usefulLink: CreateUsefulLinkDto = {
        name: this.usefulLinkFormGroup.value.name,
        url: this.usefulLinkFormGroup.value.url,
        order: this.usefulLinkFormGroup.value.order,
        isVisible: this.usefulLinkFormGroup.value.isVisible ? true : false
      };
      this.usefulLinkService.CreateUsefulLink(usefulLink).subscribe({
        next: () => {
          this.GetUsefulLinks();
          this.notificationService.showSuccess("Ссылка добавлена");
          this.announcementInEditingMode = false;
        },
        error: err => {
          this.notificationService.showError(err);
        }
      })
    }
  }

  public checkURLFormat(value: string): boolean {
    const url = 'https://';
    return value.startsWith(url);
  }

  public isFormInvalid(): boolean {
    if (this.usefulLinkInEditingMode) {
      const urlControl = this.usefulLinkFormGroup.controls['url'];
      return this.usefulLinkFormGroup.invalid || !this.checkURLFormat(urlControl.value);
    }
    return true;
  }

  public createPhoneNumber(): FormGroup {
    return new FormGroup({
      phoneNumber: new FormControl('', Validators.required) // Установка начального значения как '7'
    });
  }

  public addPhoneNumber(formGroup: FormGroup, shouldFocus: boolean = false): void {
    const control = <FormArray>formGroup.controls['phoneNumbers'];
    control.push(this.createPhoneNumber());

    if (shouldFocus) {
      setTimeout(() => {
        const lastPhoneInput = document.querySelectorAll('.fixed-input input')[control.length - 1] as HTMLInputElement;
        if (lastPhoneInput) {
          lastPhoneInput.focus();
          lastPhoneInput.setSelectionRange(4, 4); // Установка курсора после '+7 ('
        }
      }, 0);
    }
  }

  public removePhoneNumber(formGroup: FormGroup, index: number): void {
    const control = <FormArray>formGroup.controls['phoneNumbers'];
    control.removeAt(index);
  }

  public createEmail(): FormGroup {
    return new FormGroup({
      email: new FormControl('', Validators.required)
    });
  }

  public addEmail(formGroup: FormGroup): void {
    const control = <FormArray>formGroup.controls['emails'];
    control.push(this.createEmail());
  }

  public removeEmail(formGroup: FormGroup, index: number): void {
    const control = <FormArray>formGroup.controls['emails'];
    control.removeAt(index);
  }

  public openLink(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }

  public shouldShowAddButton(formArray: FormArray, index: number): boolean {
    return formArray.length === 1 || index === formArray.length - 1;
  }
}

const usefulLinkFormGroup = (dataItem: UsefulLinkDto) =>
  new FormGroup({
    externalId: new FormControl(dataItem.externalId),
    name: new FormControl(dataItem.name, Validators.required),
    url: new FormControl(dataItem.url ? dataItem.url : '', Validators.required),
    order: new FormControl(dataItem.order ? dataItem.order : null),
    isVisible: new FormControl(dataItem.externalId ? dataItem.isVisible : true)
  });

const formGroup = (dataItem: SupportContactDto) =>
  new FormGroup({
    externalId: new FormControl(dataItem.externalId),
    name: new FormControl(dataItem.name ? dataItem.name : ''),
    phoneNumbers: new FormArray(
      dataItem.phoneNumbers ? dataItem.phoneNumbers.map(phone => new FormGroup({
        phoneNumber: new FormControl(phone)
      })) : []
    ),
    emails: new FormArray(
      dataItem.emails ? dataItem.emails.map(email => new FormGroup({
        email: new FormControl(email)
      })) : []
    ),
    chatLink: new FormControl(dataItem.chatLink)
  });

export function customValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const formGroup = control as FormGroup;
    const phoneNumbers = formGroup.get('phoneNumbers') as FormArray;
    const emails = formGroup.get('emails') as FormArray;
    const chatLink = formGroup.get('chatLink')?.value;

    const phonePattern = /^\d{11}$/;
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const chatLinkPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})[\/\w .-]*\/?$/;

    let hasError = false;

    // Проверка телефонов
    phoneNumbers.controls.forEach((control, index) => {
      const phoneNumber = control.get('phoneNumber')?.value;
      const isSingleAndEmpty = phoneNumbers.length === 1 && !phoneNumber;
      if (!isSingleAndEmpty && phoneNumber && !phonePattern.test(phoneNumber)) {
        hasError = true;
      }
    });

    // Проверка email
    emails.controls.forEach((control, index) => {
      const email = control.get('email')?.value;
      const isSingleAndEmpty = emails.length === 1 && !email;
      if (!isSingleAndEmpty && email && !emailPattern.test(email)) {
        hasError = true;
      }
    });

    // Проверка chatLink
    if (chatLink && !chatLinkPattern.test(chatLink)) {
      hasError = true;
    }

    return hasError ? { 'invalidForm': true } : null;
  };
}

function formatPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber || phoneNumber.length !== 11) {
    return phoneNumber;  // Возвращаем как есть, если номер не содержит 11 цифр
  }

  const formatted = `+${phoneNumber[0]} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 9)}-${phoneNumber.slice(9)}`;
  return formatted;
}
