import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, Observable, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {DisplaySetting} from "../../models/support/system-setting.model";

@Injectable({
  providedIn: 'root',
})
export class DisplaySettingService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.support.displaySetting}`;

  constructor(private http: HttpClient) {}

  public GetAllDisplaySettings(): Observable<DisplaySetting[]> {
    return this.http.get<DisplaySetting[]>(this.baseUrl + '/GetAllDisplaySettings/');
  }

  public EditDisplaySetting(request: DisplaySetting[]): Observable<DisplaySetting[]> {
    return this.http.post<DisplaySetting[]>(this.baseUrl + '/EditDisplaySetting/', request)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}
