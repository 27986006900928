import {Guid} from "guid-typescript";

export class LocalSignatoryRead {
  public id!: Guid | string;
  public dictSignatoryRoleId!: Guid | string;
  public trainingLevels!: LocalSignatoryTrainingLevelReadDto[];
  public faculties!: LocalSignatoryFacultyReadDto[];
  public filials!: LocalSignatoryFilialReadDto[];
  public dictLocalSignatoryRoleId!: Guid | string;
}

export class LocalSignatoryCertificate {
  public personExternalId?: Guid | string;
  public globalSignatoryId?: string;
  public postName?: string;
  public fio?: string;
}

export class LocalSignatoryTrainingLevelReadDto {
  public id!:  Guid | string;
  public trainingLevelName!: string;
}

export class LocalSignatoryFacultyReadDto {
  public id!:  Guid | string;
  public facultyName!: string;
  public facultyShortName!: string;
}

export class LocalSignatoryFilialReadDto {
  public id!:  Guid | string;
  public filialSName!: string;
}

export class LocalSignatoryDict {
  trainingLevels!: LocalSignatoryDictDto[];
  faculties!: LocalSignatoryDictDto[];
  filials!: LocalSignatoryDictDto[];
}

export class LocalSignatoryDictDto {
  public id?: Guid | string;
  public shortName?: string;
}

export class LocalSignatoryAdd {
  public globalSignatoryId!: Guid | string;
  public trainingLevels!: string[] | Guid[];
  public faculties!: string[] | Guid[];
  public filials!: string[] | Guid[];
  public localSignatoryRole!: number;
}

export class LocalSignatoryUpdate {
  public id!: Guid | string;
  public globalSignatoryId!: Guid | string;
  public trainingLevels!: string[] | Guid[];
  public faculties!: string[] | Guid[];
  public filials!: string[] | Guid[];
  public localSignatoryRole!: number;
}

export class LocalSignatoryRole {
  public externalId!: Guid | string;
  public name!: string;
  public order?: number;
  public value!: number;
}
