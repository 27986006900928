import {Component, OnInit, Input, Output, EventEmitter, NgZone, Renderer2, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Router} from "@angular/router";
import {openDialog} from 'src/app/helpers/dialogHelper';
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {FormationDiplomaService} from '../../../../services/gia/formationdiploma.service';
import {ApplicationDataModel, MarksModel, disciplineForApplication} from '../../../../models/gia/formationdiploma.model';
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {Subscription, fromEvent} from "rxjs";
import {tap, take} from "rxjs/operators";
import {RowClassArgs} from "@progress/kendo-angular-grid";
import {State, process} from "@progress/kendo-data-query";
import {ControlAction, mark, isGoodMarkValue} from '../../../../models/gia/enums/controlAction.enum';
import {LoaderType} from "@progress/kendo-angular-indicators";
import {CellClickEvent} from '@progress/kendo-angular-grid';
import {GiaUserAccessService} from "../../../../services/useraccess/gia-user-access.service";
import {GiaTabsEnum} from "../../../../models/gia/enums/giaTabs.enum";

const closest = (node: any, predicate: any) => {
    while (node && !predicate(node)) {
    node = node.parentNode;
    }

    return node;
};

const tableRow = (node: any) => node.tagName.toLowerCase() === "tr";

@Component({
    selector : 'app-applicationdiploma',
    templateUrl: './applicationdiploma.component.html',
    styleUrls : ['./applicationdiploma.component.scss']
})

export class ApplicationDiplomaComponent implements OnInit, OnDestroy {
    @Input() studentId: string = "";

    @Output() saveGeneralInfo = new EventEmitter();
    @Output() getBasicInformations = new EventEmitter();

    public applicationData: ApplicationDataModel = new ApplicationDataModel;
    public type: LoaderType = "converging-spinner";
    public loaderVisible: boolean = false;

    public disciplines: disciplineForApplication[] | null = [];
    public marks: MarksModel[] = [];
    public badMarksId: string[] = [];

    public loading: boolean = false;

    public blocknames: any = {isDiscipline: 'Дисциплины', isPractical: 'Практики', isCourseWork: 'Курсовые работы', isExamination: 'Государственная итоговая аттестация'};
    public otherBlocks: any = {isFacultative: 'Факультативные дисциплины'};
    public total: {creditUnit: number, hours: number} = {creditUnit: 0, hours: 0};

    public changeDisciplines: boolean = false;

    private indexStartBlock = 0;
    private indexEndblock = 0;
    public gridData: {data: any, total: number} = process(this.disciplines ? this.disciplines : [], {});
    private currentSubscription: Subscription = new Subscription;

    public saveNumbering: boolean = false;
    public allowEdit: boolean = false;

    constructor(
        private router: Router,
        private dialogService: DialogService,
        private formationDiplomaService: FormationDiplomaService,
        private notificationService: NotificationsService,

        private zone: NgZone,
        private renderer: Renderer2,
        private changeDetector: ChangeDetectorRef,
        private userAccessService: GiaUserAccessService

    ) {}

    async ngOnInit() {
        await this.getAccessLevel();
        this.getApplicationInfo();
        this.getMark();
    }

    public async getAccessLevel(){
        this.allowEdit = await this.userAccessService.hasEditingGia({
          studentId: this.studentId,
          section: GiaTabsEnum.дипломы
        });
    }

    public getApplicationInfo() {
        this.loading = true;
        this.loaderVisible = true;

        this.formationDiplomaService.getApplicationData(this.studentId)
          .subscribe(response => {
            this.applicationData = response;
            this.applicationData.disciplines?.forEach(element => {
                if (this.editMark(element)) element.editable = true;
            });

            this.loading = false;
            this.loaderVisible = false;

            this.total = {creditUnit: 0, hours: 0};

            if (this.applicationData.disciplines && this.applicationData.disciplines?.some(el => el.serialNumber == null)) this.saveNumbering = true;
            this.applicationData.disciplines && this.applicationData.disciplines.length !== 0 ? this.distributionByBlocks() : this.gridData = process([], {});
          })
    }

    private distributionByBlocks() {
        this.disciplines = [];
        for (let key in this.blocknames) {
            this.sortDiscilines(key);
        }

        this.disciplines?.push({'disciplineId': null, 'disciplineName': 'Итого', 'creditUnit': Math.round(this.total.creditUnit * 100) / 100, 'hours': this.total.hours, 'total': true});

        for (let key in this.otherBlocks) {
            this.sortDiscilines(key);
        }

        if (this.saveNumbering == true) this.saveApplicationInfo(this.applicationData.diplomaInfoId, true);

        this.gridData = process(this.disciplines ? this.disciplines : [], {});
        const item: any = this.currentSubscription;
        if (this.allowEdit && item.initialTeardown == undefined) setTimeout(() => this.currentSubscription = this.handleDragAndDrop(), 2000);
    }

    private sortDiscilines(key: string) {
        let arrayfilter: disciplineForApplication[] | undefined = [];
        let creditUnitsAndHours: {creditUnit: number, hours: number} = {creditUnit: 0, hours: 0};

        if (key == 'isDiscipline') {
            arrayfilter = this.applicationData.disciplines?.filter(el => !el.isCourseWork && !el.isFacultative && !el.isPractical && !el.isExamination);
          } else {
              arrayfilter = this.applicationData.disciplines?.filter((el: any) => el[key]);
          }
          if (arrayfilter?.length !== 0) {
            creditUnitsAndHours = this.summCreditUnitsAndHours(arrayfilter);
            this.total.creditUnit += +creditUnitsAndHours.creditUnit;
            this.total.hours += +creditUnitsAndHours.hours;
            this.disciplines?.push({'disciplineId': null,
                                   'disciplineName': this.blocknames[key] ? this.blocknames[key] : this.otherBlocks[key],
                                   'creditUnit': creditUnitsAndHours.creditUnit,
                                   'hours': creditUnitsAndHours.hours});

            if (arrayfilter) {
                const numbers: any = arrayfilter.filter(el => el.serialNumber).map(item => item.serialNumber);

                arrayfilter?.some(el => el.serialNumber == null) || (arrayfilter.length !== Math.max.apply(null, numbers)) ? this.numbering(arrayfilter)
                : Array.prototype.push.apply(this.disciplines, arrayfilter.sort((a: any, b: any) => a.serialNumber - b.serialNumber));
            }
          }
    }

    private summCreditUnitsAndHours(array: disciplineForApplication[] | undefined) {
        if (array) return {'creditUnit': Math.round(array.reduce((sum: number, current: disciplineForApplication) => sum + current.creditUnit, 0) * 100) / 100,
                   'hours': array.reduce((sum: number, current: disciplineForApplication) => sum + current.hours, 0)};
        return {'creditUnit': 0, 'hours': 0};
    }

    private numbering(array: disciplineForApplication[] | undefined) {
        if (array) {
            for (let i = 0; i < array.length; i++) {
                array[i].serialNumber = i + 1;
                this.disciplines?.push(array[i])
            }
        }
    }

    public getMark() {
        this.formationDiplomaService.getMarks()
            .subscribe(response => {
                this.marks = response;
                this.badMarksId = this.marks.filter(item => !isGoodMarkValue.includes(item.value)).map(el => el.id);
            })
    }

    public addDiscipline(dataItem?: disciplineForApplication) {
        if (!this.applicationData.id || this.changeDisciplines) {
            this.notificationService.showError('Сохраните приложение диплома.');
            return;
        }

        this.formationDiplomaService.sendDiscipline.next ({
            discipline: dataItem ? dataItem : new disciplineForApplication,
        });

        this.router.navigate([`gia/formatdiploma/${this.studentId}/adddiscipline`]);
    }

    public fillStudyPlan() {
        const dialog: DialogRef = openDialog(this.dialogService, 'Заполнить на основании учебного плана студента?', 450, 200, 250);

        dialog.result.subscribe((result) => {
            if (!(result instanceof DialogCloseResult) && result.text == 'Да'){
                this.formationDiplomaService.fillingStudentStudyPlan(this.studentId)
                   .subscribe(response => {
                        this.applicationData.disciplines = response;
                        this.saveInformation();
                    },
                    error => {
                        this.notificationService.showError('Произошла ошибка');
                    })
            }
        })
    }

    public saveInformation() {
        this.saveGeneralInfo.emit();
    }

    public saveApplicationInfo(diplomaInfoId: string, numbering?: boolean) {
      if (!this.allowEdit && !numbering)
        return;

      this.applicationData.diplomaInfoId = diplomaInfoId;

      this.formationDiplomaService.saveApplicationData(this.applicationData)
        .subscribe(response => {
                if (!numbering) {
                    this.notificationService.showSuccess('Данные приложения успешно сохранены');
                    this.getApplicationInfo();
                    this.getBasicInformations.emit();
                }
                this.changeDisciplines = false;
                this.saveNumbering = false;
            },
            error => {
                this.notificationService.showError('Произошла ошибка');
                this.getApplicationInfo();
            })
    }

    public clearAll() {
        const dialog: DialogRef = openDialog(this.dialogService, 'Вы действительно хотите очистить список дисциплин?');

        dialog.result.subscribe((result) => {
            if (!(result instanceof DialogCloseResult) && result.text == 'Да'){
                    this.formationDiplomaService.clearDisciplines(this.studentId)
                    .subscribe(response => {
                        this.disciplines = null;
                        this.currentSubscription = new Subscription;
                        this.getApplicationInfo();
                    },
                    error => {
                        this.notificationService.showError('Произошла ошибка');
                    })
            }
        })
    }

    public selectionChangeMark(value: MarksModel, dataItem: disciplineForApplication) {
        this.changeDisciplines = true;

        const mark = this.marks.find(el => el.id == value.id);
        const index = this.disciplines?.findIndex(el => el.disciplineId == dataItem.disciplineId && el.disciplineType == dataItem.disciplineType);
        if (this.disciplines && index) this.disciplines[index].markValue = mark?.value;
    }

    public dataStateChange(state: State): void {
        this.gridData = process(this.disciplines ? this.disciplines : [], {});
        this.changeDetector.markForCheck();
        this.currentSubscription.unsubscribe();
        this.zone.onStable
            .pipe(take(1))
            .subscribe(() => this.currentSubscription = this.handleDragAndDrop());
    }

    private handleDragAndDrop(): Subscription {
       
       const sub = new Subscription(() => {});
       let draggedItemIndex: number;
       let startIndex: number = 0;
       let element: disciplineForApplication = new disciplineForApplication;

       const tableRows = Array.from(document.getElementsByClassName("move"));
       tableRows.forEach((item) => {
         this.renderer.setAttribute(item, "draggable", "true");
         const dragStart = fromEvent<DragEvent>(item, "dragstart");
         const dragOver = fromEvent(item, "dragover");
         const dragEnd = fromEvent(item, "dragend");
        
         sub.add(
           dragStart
             .pipe(
               tap(({ dataTransfer }) => {
                 try {
                   const dragImgEl = document.createElement("span");
                   dragImgEl.setAttribute(
                     "style",
                     "position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;"
                   );
                   document.body.appendChild(dragImgEl);
                   dataTransfer?.setDragImage(dragImgEl, 0, 0);
                 } catch (err) {
                   // IE doesn't support setDragImage
                 }
                 try {
                   // Firefox won't drag without setting data
                   dataTransfer?.setData("application/json", "");
                 } catch (err) {
                   // IE doesn't support MIME types in setData
                 }
               })
             )
             .subscribe(({ target }) => {
               this.indexStartBlock = 0;
               this.indexEndblock = 0;
               const row: HTMLTableRowElement = <HTMLTableRowElement>target;
               draggedItemIndex = row.rowIndex;
               startIndex = row.rowIndex;
               for (let i = draggedItemIndex; i >= 0; i--){
                  if (!this.gridData.data[i].disciplineId && i < draggedItemIndex) {
                      this.indexStartBlock = i;
                      break;
                  }
               }
               for (let i = draggedItemIndex; i < this.gridData.data.length; i++){
                  if (!this.gridData.data[i].disciplineId && i > draggedItemIndex) {
                      this.indexEndblock = i;
                      break;
                  }
               }
               if (this.indexEndblock == 0) this.indexEndblock = this.gridData.data.length;

               const dataItem = this.gridData.data[draggedItemIndex];
               if (!dataItem) {
                alert('Перемещение невозможно. Снимите выделение с блока.');
                return;
               }
               dataItem.dragging = true;
               element = dataItem;
             })
         );

         sub.add(
           dragOver.subscribe((e: any) => {
             e.preventDefault();
             const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
             const dropIndex = closest(e.target, tableRow).rowIndex;
             const dropItem = this.gridData.data[dropIndex];
             this.changeDisciplines = true;

             draggedItemIndex = dropIndex;
             this.zone.run(() =>
               this.gridData.data.splice(dropIndex, 0, dataItem)
             );
           })
         );

         sub.add(
           dragEnd.subscribe((e: any) => {
             e.preventDefault();
             if (draggedItemIndex < this.indexStartBlock || draggedItemIndex > this.indexEndblock) {
                    this.notificationService.showError('Перемещать дисциплину можно только в пределах соответствующего блока.');
                    const dataItem = this.gridData.data[startIndex - 1];
                    this.gridData.data.splice(draggedItemIndex, 1)[0];
                    this.gridData.data.splice(startIndex, 0, element);
                    this.gridData.data[startIndex].dragging = false;
                    return
             } else {
                const minNumber = Math.min(startIndex, draggedItemIndex);
                const maxNumber = Math.max(startIndex, draggedItemIndex);
                if (!this.gridData.data[minNumber - 1]) {
                    return;
                }
                let serialNumber = this.gridData.data[minNumber - 1].serialNumber ?? 0;
                for (let i = minNumber; i < maxNumber + 1; i++) {
                    this.gridData.data[i].serialNumber = serialNumber + 1;
                    serialNumber ++ ;
                }
                const dataItem = this.gridData.data[draggedItemIndex];
                dataItem.dragging = false;
             }
           })
         );
       });
       return sub;
     }

    public ngOnDestroy(): void {
       this.currentSubscription.unsubscribe();
    }

    public rowCallback(context: RowClassArgs) {
        return context.dataItem.disciplineId ? {move: true, dragging: context.dataItem.dragging} : {move: false, blocktitle: true};
    }

    public getMarkDiscipline(markId: string) {
        return this.marks.find(el => el.id == markId)?.name
    }

    public onChange(value: string | boolean) {
        this.changeDisciplines = true;
    }

    public getDisciplineMarks(dataItem: disciplineForApplication) {
        let filterMarks: MarksModel[] = [];
        filterMarks = this.marks.filter(el => isGoodMarkValue.includes(el.value))
        if (dataItem.controlActionEnum) {
            dataItem.controlActionEnum == ControlAction.test ? filterMarks = filterMarks.filter(el => el.value <= mark.isTest || el.value == mark.failed)
                                                             : filterMarks = filterMarks.filter(el => el.value < mark.failed && el.value !== mark.isTest);
        }
        return filterMarks;
    }

    public checkField(value: string) {
        return value == null;
    }

    public checkMarks() {
        return this.applicationData.disciplines?.some(el => el.markId == null);
    }

    public cellClickHandler({
        sender,
        rowIndex,
        dataItem,
        columnIndex,
        }: CellClickEvent): void {
            if (!this.allowEdit)
              return;

            if (columnIndex !== 4) this.addDiscipline(dataItem);
    }

    public deleteDisciplines(value: disciplineForApplication[] | null) {
        return value && value.length == 0 || !value;
    }

    public showMark(dataItem: disciplineForApplication) {
        return !dataItem.isAddedManually && dataItem.markId && !this.badMarksId.includes(dataItem.markId);
    }

    public editMark(dataItem: disciplineForApplication) {
        return dataItem.isAddedManually || dataItem.disciplineId && (!dataItem.markId || this.badMarksId.includes(dataItem.markId));
    }
}
