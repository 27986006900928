import {Component, OnInit} from '@angular/core';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {
  EditEvent,
  GridComponent,
  PagerSettings,
  RemoveEvent
} from '@progress/kendo-angular-grid';
import {boolOptions} from '../../../models/contingent/options';
import {FileRestrictions} from '@progress/kendo-angular-upload';
import {environment} from '../../../../environments/environment';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {openDialog} from '../../../helpers/dialogHelper';
import {NotificationsService} from '../../../services/notifications/notifications.service';
import {MilitaryTemplate} from '../../../models/contingent/militarytemplate.model';
import {DownloadFile} from '../../../helpers/downloadFile-helper';
import {DictTrainingLevel} from '../../../models/contingent/dicttraininglevel.model';
import {CommondictService} from '../../../services/contingent/commondict.service';
import {MilitaryCommissariat} from '../../../models/contingent/militarycommissariat.model';
import {MilitaryCommissariatService} from '../../../services/contingent/military-commissariat.service';
import {MilitaryCertificateTypes} from '../../../models/contingent/enums/military-certificate-types.enum';
import {MilitaryFormTypesService} from '../../../services/contingent/militaryformtypes.service';
import {MilitaryFormType} from '../../../models/contingent/militaryformtypes.model';
import {MilitaryTemplateService} from '../../../services/contingent/military-template.service';
import {saveAs} from '@progress/kendo-file-saver';
import {
  AllMilitaryForm,
  EditParams,
  ExportMilitaryForm,
  GetParams,
  MilitaryForm, MilitaryFormGet
} from '../../../models/contingent/military-form.model';
import {MilitaryFormService} from '../../../services/contingent/military-form.service';
import {DateFromUTCAsLocal, DateToString, TimeZoneFix} from '../../../helpers/date-helper';
import {militaryAcademicStates} from '../../../models/contingent/military-academic-states';
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";
import {AccessLevelEnum} from "../../../models/contingent/enums/access-level.enum";
import {CellOptions, ExcelExportData} from '@progress/kendo-angular-excel-export';
import {FilterDescriptor} from '@progress/kendo-data-query';


@Component({
  selector: 'app-military-certificates',
  templateUrl: './military-certificates.component.html',
  styleUrls: ['./military-certificates.component.scss']
})
export class MilitaryCertificatesComponent implements OnInit {

  public editable = false;
  public editMode = false;
  public loading = false;
  public isTemplatesOn = false;
  protected editedRowIndex?: number;

  public pageSize = 10;
  public pagerSettings: PagerSettings = {
    type: 'numeric',
    buttonCount: 5,
    pageSizes: [10, 20, 50],
    info: true,
    previousNext: true,
    position: 'bottom'
  };
  public filterSettings: DropDownFilterSettings = {
    operator: 'contains',
  };
  public restrictions: FileRestrictions = {
    maxFileSize: environment.contingent.maxFileSize,
    allowedExtensions: ['.docx'],
  };
  public headerCellOptions: CellOptions = {
    background: '#FFF',
    color: '#000',
    borderTop: {color: '#000'},
    borderBottom: {color: '#000'},
    borderLeft: {color: '#000'},
    borderRight: {color: '#000'},
  };
  public cellOptions: CellOptions = {
    borderTop: {color: '#000'},
    borderBottom: {color: '#000'},
    borderLeft: {color: '#000'},
    borderRight: {color: '#000'},
  };

  public trainingLevels: DictTrainingLevel[] = [];
  public militaryCommissariats: MilitaryCommissariat[] = [];
  public certificateTypes: MilitaryFormType[] = [];

  public certificates: AllMilitaryForm[] = [];
  public form?: MilitaryFormGet;
  public studentId?: string;
  public templates: MilitaryTemplate[] = [];

  public filters= this.getFiltersFormGroup();
  public templateForm!: FormGroup;

  protected readonly MilitaryCertificateTypes = MilitaryCertificateTypes;
  protected readonly Array = Array;
  protected readonly militaryAcademicStates = militaryAcademicStates;

  constructor(
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private commonDictService: CommondictService,
    private militaryCommissariatService: MilitaryCommissariatService,
    private militaryFormTypesService: MilitaryFormTypesService,
    private militaryTemplateService: MilitaryTemplateService,
    private militaryFormService: MilitaryFormService,
    private userAccessService: ContingentUserAccessService,
  ) {
    this.mapCertificatesData = this.mapCertificatesData.bind(this);
  }

  ngOnInit(): void {
    this.getAccessLevel();
    this.getDicts();
  }

  private getDicts() {
    this.getTrainingLevels();
    this.getMilitaryCommissariats();
    this.getCertificateTypes();
  }

  private getTrainingLevels() {
    this.commonDictService.getAllTrainingLevel().subscribe((response) => {
      this.trainingLevels = response;
    });
  }

  private getMilitaryCommissariats() {
    this.militaryCommissariatService.getAllMilitaryCommissariat().subscribe((response) => {
      this.militaryCommissariats = response;
    });
  }

  private getCertificateTypes() {
    this.militaryFormTypesService.getMilitaryFormTypes().subscribe((response) => {
      this.certificateTypes = response;
    });
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.editable = response.militaryCertificateAccessLevel === AccessLevelEnum.write
        }
      });
  }

  public typeChange(value?: MilitaryCertificateTypes) {
    this.getCertificates(value);
  }

  private getCertificates(value?: MilitaryCertificateTypes) {
    const type = value ?? this.filters.value.certificateType;
    if (type) {
      this.loading = true;
      this.militaryFormService.getCertificates(type).subscribe({
        next: (response) => {
          this.certificates = response;
          this.certificates.map((item) => {
            item.registrationDate = DateFromUTCAsLocal(item.registrationDate);
            item.updatedAt = DateFromUTCAsLocal(item.updatedAt);
          });
          this.loading = false;
        },
        error: (error) => {
          this.notificationService.showError(error);
          this.certificates = [];
          this.loading = false;
        }
      });
    }
  }

  public switchPages() {
    this.isTemplatesOn = !this.isTemplatesOn;
    this.loading = false;
    this.closeHandler();

    this.isTemplatesOn && this.getTemplates();
  }

  private getTemplates() {
    this.loading = true;
    this.militaryTemplateService.getMilitaryTemplates().subscribe((response) => {
      this.templates = response;
      this.loading = false;
    });
  }

  public getCertificateTypeName(value?: MilitaryCertificateTypes | null) {
    return value ? this.certificateTypes.find((item) =>
      item.formType === value)?.formName ?? '' : '';
  }

  public getTrainingLevelName(value?: string) {
    return value ? this.trainingLevels.find((item) =>
      item.dictTrainingLevelExternalId === value)?.trainingLevelName ?? '' : '';
  }

  public getIsEnrolledText(value?: boolean) {
    return militaryAcademicStates.find((item) =>
      item.value === value)?.text ?? '';
  }

  public getBoolOption(value?: boolean) {
    return boolOptions.find((item) => item.value === !!value)?.text;
  }

  public downloadFile(file: File|string, templateId?: string) {
    if (templateId) {
      this.militaryTemplateService.getTemplateFile(templateId).subscribe({
        next: (value) => {
          saveAs(value, file as string);
        },
        error: () => {
          this.notificationService.showSuccess('Не удалось скачать файл');
        },
      });
    } else {
      DownloadFile(file as File);
    }
  }

  public mapCertificatesData(grid: GridComponent): ExcelExportData {
    const data = this.filterCertificatesData(this.certificates, grid.filter?.filters as FilterDescriptor[])
      .map((item, index): ExportMilitaryForm => {
        return {
          ...item,
          number: ++index,
          date: DateToString(item.registrationDate),
          dictTrainingLevelName: this.getTrainingLevelName(item.dictTrainingLevelId),
          enrolled: this.getIsEnrolledText(item.isEnrolled),
        };
      });
    return {data};
  }

  private filterCertificatesData(data: AllMilitaryForm[], filters?: FilterDescriptor[]) {
    if (!filters) return data;
    let result = data;
    filters.forEach((filter) => {
      result = result.filter((item) => {
        const field = item[filter.field as keyof AllMilitaryForm];
        if (field instanceof Date) {
          return filter.operator === 'lte' ? field <= filter.value : field >= filter.value;
        } else if (typeof field === 'string') {
          return field.toLowerCase().includes(filter.value.toLowerCase());
        } else {
          return field === filter.value;
        }
      });
    });
    return result;
  }

  public editCertificate({rowIndex, dataItem}: EditEvent) {
    this.editedRowIndex = rowIndex;
    this.studentId = dataItem?.studentId;
    dataItem ? this.getCertificate(dataItem?.externalId) : this.editMode = true;
  }

  private getCertificate(formId: string) {
    const params: GetParams = {
      type: this.filters.value.certificateType as MilitaryCertificateTypes,
      studentId: this.studentId as string,
      formId: formId,
    };
    this.militaryFormService.getCertificateById(params).subscribe(
      (response) => {
        this.form = response;
        this.editMode = true;
      },
      () => this.notificationService.showError('Не удалось получить данные справки'),
    );
  }

  public removeCertificate({dataItem}: RemoveEvent) {
    const dialog: DialogRef = openDialog(this.dialogService,
      `Удалить справку
      ${dataItem.registrationDate ? `от ${DateToString(dataItem.registrationDate)}` : ''}
       на студента ${dataItem.fio}?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        const params: GetParams = {
          type: this.filters.value.certificateType as MilitaryCertificateTypes,
          formId: dataItem.externalId,
          studentId: dataItem.studentId,
        };
        this.militaryFormService.deleteCertificate(params).subscribe({
          next: () => {
            this.getCertificates();
            this.notificationService.showSuccess('Справка успешно удалена');
          },
          error: (error) => this.notificationService.showError(error),
        });
      }
    });
  }

  public saveCertificate({form, print}: {form: FormGroup, print?: boolean}) {
    if (this.filters.value.certificateType === MilitaryCertificateTypes.appendix4
      || this.filters.value.certificateType === MilitaryCertificateTypes.appendix5) {
      form.value.globalSignatoryId = form.value.signatories[0].globalSignatoryId;
      form.value.globalSignatoryFio = form.value.signatories[0].fio;
      form.value.globalSignatoryPostName = form.value.signatories[0].postName;
    }

    const params: EditParams = {
      type: this.filters.value.certificateType as MilitaryCertificateTypes,
      formId: form.value.externalId,
      form: this.TimeZoneFix(form),
    };

    if (this.editedRowIndex === undefined) {
      this.militaryFormService.addCertificate(params).subscribe({
        next: (response) => {
          this.saveSuccessHandler(this.fixPrintParams(response, params.form), print);
        },
        error: (error) => this.notificationService.showError(error),
      });
    } else {
      this.militaryFormService.updateCertificate(params).subscribe({
        next: (response) => {
          this.saveSuccessHandler(this.fixPrintParams(response, params.form), print);
        },
        error: (error) => this.notificationService.showError(error),
      });
    }
  }

  private TimeZoneFix(form: FormGroup): MilitaryForm {
    if (this.filters.value.certificateType !== MilitaryCertificateTypes.form10) {
      form.value.registrationDate = TimeZoneFix(form.value.registrationDate);
    } else {
      form.value.driverLicense.licenseIssueDate = TimeZoneFix(form.value.driverLicense.licenseIssueDate);
      form.value.driverLicense.licenseEndDate = TimeZoneFix(form.value.driverLicense.licenseEndDate);
    }

    if (this.filters.value.certificateType === MilitaryCertificateTypes.appendix4
      || this.filters.value.certificateType === MilitaryCertificateTypes.appendix5) {
      form.value.studyPeriodEndDate = TimeZoneFix(form.value.studyPeriodEndDate);
    }

    if (this.filters.value.certificateType === MilitaryCertificateTypes.appendix5) {
      form.value.councilOrderDate = TimeZoneFix(form.value.councilOrderDate);
      form.value.dissertationDefenseDate = TimeZoneFix(form.value.dissertationDefenseDate);
    }

    return form.value;
  }

  private fixPrintParams(response: MilitaryForm, form: MilitaryForm) {
    if (!response.studentId) response.studentId = form.studentId;
    if (!response.fio) response.fio = form.fio;
    return response;
  }

  private saveSuccessHandler(response: MilitaryForm, print?: boolean) {
    this.getCertificates();
    this.notificationService.showSuccess('Справка успешно сохранена');
    print && this.exportCertificate(response);
    this.closeHandler();
  }

  public exportCertificate(value: MilitaryForm | AllMilitaryForm) {
    const params: GetParams = {
      type: this.filters.value.certificateType as MilitaryCertificateTypes,
      studentId: value.studentId,
      formId: value.externalId as string,
    };
    this.militaryFormService.getPrintForm(params).subscribe({
      next: (file) => saveAs(file, `Форма ${params.type} ${value.fio}.docx`),
      error: () => this.notificationService.showError('Не удалось экспортировать печатную форму'),
    });
  }

  public editTemplate({rowIndex, dataItem}: EditEvent) {
    this.editMode = true;
    this.editedRowIndex = rowIndex;
    this.templateForm = this.getTemplateFormGroup(dataItem);
  }

  public removeTemplate({dataItem}: RemoveEvent) {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить ${dataItem.name}?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        this.militaryTemplateService.deleteMilitaryTemplate(dataItem.externalId).subscribe({
          next: () => {
            this.getTemplates();
            this.notificationService.showSuccess('Шаблон удалён');
          },
          error: (error) => {
            this.notificationService.showError(error.error.MilitaryFormPrintTemplate[0] ?? 'Не удалось удалить шаблон');
          }
        });
      }
    });
  }

  public saveTemplate() {
    if (this.templateForm.invalid) {
      this.notificationService.showError('Заполните обязательные поля');
      return;
    }

    const observer = {
      next: () => {
        this.getTemplates();
        this.closeHandler();
        this.notificationService.showSuccess(
          `Шаблон ${this.templateForm.value.externalId ? 'изменён' : 'сохранён'}`);
      },
      error: () => {
        this.notificationService.showError(
          `Не удалось ${this.templateForm.value.externalId ? 'изменить' : 'сохранить'} шаблон`);
      }
    }
    if (this.templateForm.value.externalId) {
      this.militaryTemplateService.updateMilitaryTemplate(this.templateForm.value).subscribe(observer);
    } else {
      this.militaryTemplateService.addMilitaryTemplate(this.templateForm.value).subscribe(observer);
    }
  }

  public closeHandler() {
    this.editedRowIndex = undefined;
    this.form = undefined;
    this.editMode = false;
  }

  private getFiltersFormGroup() {
    return new FormGroup({
      certificateType: new FormControl<MilitaryCertificateTypes|null>(null),
      fio: new FormControl<string|null>(null),
    });
  }

  private getTemplateFormGroup(value?: MilitaryTemplate) {
    return new FormGroup({
      externalId: new FormControl(value?.externalId ?? null),
      name: new FormControl<string|null>(value?.name ?? null, Validators.required),
      dictMilitaryFormTypeId: new FormControl<string|null>(value?.dictMilitaryFormTypeId ?? null, Validators.required),
      file: new FormControl(value?.fileName ?? null, Validators.required),
      isDefault: new FormControl<boolean>(!!value?.isDefault),
    });
  }
}
