export enum Role {
    Admin = 'admin',
    Contingent = 'contingent',
    Session = 'session',
    CurrentControl = 'currentcontrol',
    Projecting = 'projecting',
    Dictionaries = 'dictionaries',
    Announcements = 'announcements',
    Disciplineworkload = 'disciplineworkload',
    Publication = 'publication',
    Classroom = 'classroom',
    LkPersonPayslip = 'lkPersonPayslip',
    Cards = "cards",
    ReportDesigner = "report-designer",
    Oferta = "oferta",
    GIA = "gia",
    Diplomablanks = "diplomablanks",
}
