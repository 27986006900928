import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {DisciplineStatement, StudentByDiscipline} from 'src/app/models/middlecontrol/groupInSession.model';
import { DictsService } from '../../../services/middlecontrol/dicts.service';
import { SessionService } from 'src/app/services/middlecontrol/session.service';
import {
  markReason,
  MarksModel,
} from 'src/app/models/middlecontrol/marks.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import { RetakeService } from 'src/app/services/middlecontrol/retake.service';
import { Router } from '@angular/router';
import { MiddleControlUserAccessService } from '../../../services/useraccess/middle-control-user-access.service';
import { Subscription } from 'rxjs';
import {CurrentDayEnd, DateFromUTCAsLocal} from "../../../helpers/date-helper";
import {
  MiddleControlSheetDisciplineQuery,
  MiddleControlSheetListQuery
} from "../../../models/middlecontrol/query.model";
import {DownloadFile} from "../../../helpers/downloadFile-helper";
import {SheetService} from "../../../services/middlecontrol/sheet.service";
import {SignatoriesModel, Signatory} from "../../../models/middlecontrol/sheets.model";
import {Guid} from "guid-typescript";
import {PersonsModel} from "../../../models/middlecontrol/persons.model";
import {DropDownFilterSettings, DropDownListComponent} from "@progress/kendo-angular-dropdowns";

@Component({
  selector: 'app-disciplinetable',
  templateUrl: './disciplinetable.component.html',
  styleUrls: ['./disciplinetable.component.scss'],
})
export class DisciplinetableComponent implements OnInit {
  @Input() eduGroupId = '';
  @Input() planId: string | undefined = undefined;
  @Input() semesterNumber = 0;
  @Input() disciplineId = '';
  @Input() editable?: boolean;
  @Input() electiveDisciplineId: string | null = null;
  @Input() contingentDate: Date = CurrentDayEnd();
  @Input() selectedMarkDate = CurrentDayEnd();
  @Input() choiceOfTeacher: boolean = true;
  @Input() showExamList: boolean = false;
  @Output() onChangeState: EventEmitter<string> = new EventEmitter();
  @Output() onChangeStudentId: EventEmitter<{ studentId: string }> =
    new EventEmitter();
  @Output() onChangeGeneralDisciplineId: EventEmitter<{ disciplineId?: string }> =
    new EventEmitter();

  public currentDate: Date = CurrentDayEnd();
  public group: StudentByDiscipline[] = [];
  public gradeLevelList: MarksModel[] = [];
  public selectedStudents: string[] = [];
  public markToAll: MarksModel | null = null;
  public markDate: string = '';
  public controlActionType: string = '';
  public checkedDateDefault: { [key: string]: boolean } = {};
  public checkedDate: { [key: string]: boolean } = {};
  public checkedStudent: { [key: string]: boolean } = {};
  public dictReasons: markReason[] = [];
  public isOpenDialog: boolean = false;
  public massExaminer: string | null = null;
  public reasonMarkDate: Date = DateFromUTCAsLocal(this.markDate || 0);
  public currentReason: markReason | null = null;
  private dialogParams: {
    mark?: MarksModel;
    studentsIds: string[];
    isDateUpdate: boolean;
  } | null = null;
  public isLoading: boolean = false;
  public isCheckDateAll = false;
  public isCheckStudentAll = false;
  public marks: { [key: string]: MarksModel } = {};
  private disciplineInfoSubscription$!: Subscription;
  private markDateSubscription$!: Subscription;
  private marksSubscription$!: Subscription;
  private personsSubscription$!: Subscription;
  public persons: PersonsModel[] = [];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public negativeMarksId: any = [];
  public positiveMarksId: any = [];
  public noShowGoodReason = -1;
  public constRetakeId = "286e7065-93a7-41ad-a2c9-9cf64039612a";

  public disciplineInfo: Partial<DisciplineStatement> = {};
  public signerRequest: Signatory = {};
  public signer: SignatoriesModel = {
    fio: 'Фамилия Имя Отчество',
    postName: 'должность',
    personId: Guid.create(),
  };

  public studentId = '';
  public mark = '';

  constructor(
    private dictService: DictsService,
    private sessionService: SessionService,
    private notificationService: NotificationsService,
    private retakeService: RetakeService,
    private router: Router,
    private userAccessService: MiddleControlUserAccessService,
    private sheetService: SheetService,
  ) {}

  ngOnInit(): void {
    this.getGroupByDiscipline();
    this.disciplineInfoSubscription$ =
      this.sessionService.disciplineInfo$.subscribe((info) => {
        this.fillGradeLevelList();
        this.controlActionType = this.sessionService.getDisciplineType();
      });
    this.getReasons();
    this.markDateSubscription$ = this.sessionService.markDate$.subscribe(
      (date) => {
        this.markDate = this.sessionService.convertDateToUTCDateOnly(date);
        this.reasonMarkDate = date;
      }
    );
    this.marksSubscription$ = this.dictService.marks$.subscribe((response) => {
      let allMarks =response;
      this.negativeMarksId = allMarks.filter((element: any) => element.isGoodMark == false);
      this.positiveMarksId = allMarks.filter((element: any) => element.isGoodMark == true);
      this.marks = response.reduce((dictMarks, mark) => {
        dictMarks[mark.markName] = mark;
        return dictMarks;
      }, {} as { [key: string]: MarksModel });
    });

    this.disciplineInfoSubscription$ =
      this.sessionService.disciplineInfo$.subscribe((info) => {
        this.disciplineInfo = info;
        if (info.signatory)
          this.signer = {
            fio: info.signatory?.fio,
            postName: info.signatory?.postName,
            personId: info.signatory?.personId,
          };
      });

    this.personsSubscription$ = this.dictService.persons$.subscribe(
      (response) => {
        this.persons = response;
      }
    );

    document.addEventListener('mouseup', this.changeMark.bind(this));
  }

  public changeMark(event: Event) {
    const control = event.target as HTMLInputElement;
    if (control.outerHTML.indexOf('k-list-item-text') > -1 && control.innerText == this.mark) {
      const element = this.gradeLevelList.find((el) => el.markName == control.innerText);
      if (element) this.onStudentMarkChanged(element, [this.studentId]);
      document.removeEventListener('click', this.changeMark.bind(this));
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['contingentDate']) {
      this.getGroupByDiscipline();
    }
  }

  public getReasons(){
    this.dictService.getMarkReasons().subscribe((response) => {
      this.dictReasons = response;
    });
  }

  public getGroupByDiscipline() {
    this.isLoading = true;

    let filter = new MiddleControlSheetDisciplineQuery()
    filter.eduGroupId = this.eduGroupId
    filter.semester = this.semesterNumber
    filter.disciplineId = this.disciplineId
    filter.electiveDisciplineId = this.electiveDisciplineId
    filter.contingentDate = this.contingentDate
    filter.markDate = this.selectedMarkDate
    filter.isCurrentSemester = +this.currentDate !== +this.contingentDate
    filter.planId = this.planId

    this.sessionService
      .getSheetStudentsByDiscipline(filter)
      .subscribe((response) => {
        this.group = response;
        this.isLoading = false;
        this.updateMarksStatistic();
        this.checkedDateDefault = this.group.reduce((checked, student) => {
          checked[student.studentId] = false;
          return checked;
        }, {} as { [key: string]: boolean });

        this.checkedDate = { ...this.checkedDateDefault };
        this.checkedStudent = { ...this.checkedDateDefault };

        let i = 0

        this.group.forEach((student) => {
          if (student.retake) {
            this.retakeService
              .getRetakeEditData(student.studentId, this.disciplineId, this.electiveDisciplineId)
              .subscribe((retakes) => {
                retakes.forEach((info) => {
                  info.markDate = this.retakeService.formateDateStringFromUTC(
                    info.markDate
                  );
                  student.retakes = retakes.filter(
                    (retakeInfo) =>
                      retakeInfo.isVisible &&
                      retakeInfo.markChangeReasonName === 'Пересдача'
                  );
                });
              });
          }
          i++;
        });
      });
  }

  ngOnDestroy() {
    this.sessionService.disciplineInfo$.next({});
    this.disciplineInfoSubscription$.unsubscribe();
    this.marksSubscription$.unsubscribe();
    this.markDateSubscription$.unsubscribe();
    this.personsSubscription$?.unsubscribe();
    this.sessionService.markDate$.next(new Date());
    this.personsSubscription$?.unsubscribe();
  }

  public updateMarksStatistic() {
    this.sessionService.marksStatistic$.next(
      this.group
        .filter((student) => student.disciplineMark !== null)
        .map((student) => student.disciplineMark as MarksModel)
    );
  }

  public changeProjectName(studentId: string) {
    const student = this.group.find(
      (student) => student.studentId === studentId
    );
    if (student) {
      const saveProjectName = {
        studentId,
        disciplineId: this.disciplineId,
        projectName: student.projectName?.toString() || '',
      };
      this.sessionService
        .changeProjectName(saveProjectName)
        .subscribe((response) =>
          this.notificationService.showSuccess('Тема успешно обновлена')
        );
    }
  }

  public tempMark: MarksModel|null = null

  public onStudentMarkOpened(studentId: string){
    const studentMark = this.group.find((x)=>x.studentId ===studentId)
    if(studentMark!=null) {
      this.tempMark = studentMark.disciplineMark
      //studentMark.disciplineMark = null
    }
  }

  public onStudentMarkClosed(studentId: string, marks: MarksModel|null){
    if(marks!=null){
      const studentMark = this.group.find((x)=>x.studentId ===studentId)
      if(studentMark!=null) studentMark.disciplineMark = this.tempMark
      this.onStudentMarkChanged(marks, [studentId])
    }
  }

  private markDateIsInvalid(students: (unknown & {markDate: string | Date})[]) {
    if (students.some((item) => DateFromUTCAsLocal(item.markDate) > new Date())) {
      this.notificationService.showError('Дата получения оценки больше текущей даты!');
      return true;
    }
    return false;
  }

  public onStudentMarkChanged(mark: MarksModel, studentsIds: string[], view?: DropDownListComponent) {

    if (this.negativeMarksId.findIndex((element:any) => element.id == mark.id) !== -1 && this.positiveMarksId.findIndex((element:any) => element.id == this.tempMark?.id) !== -1
      && this.tempMark?.markValue !== this.noShowGoodReason) {
      this.dictReasons = this.dictReasons.filter((element: any) => element.isError == true);
    } else if (this.tempMark?.markValue == this.noShowGoodReason)
      this.dictReasons.splice(this.dictReasons.findIndex(el => el.id == this.constRetakeId), 1);
    else this.getReasons();

    const students = this.group.filter(x =>  studentsIds.includes(x.studentId)).map((student) =>
      ({
        studentId: student?.studentId,
        dictMarkId: mark?.id,
        markDate: this.isOpenDialog
        ? this.sessionService.convertDateToUTCDateOnly(this.reasonMarkDate)
        : this.markDate,
        disciplineId: student?.generalDisciplineId ?? this.disciplineId,
        electiveDisciplineId: student?.generalElectiveDisciplineId ?? this.electiveDisciplineId,
        dictMarkChangeReasonId: this.isOpenDialog ? this.currentReason?.id : null,
      })
    )

    if (
      !this.isOpenDialog &&
      studentsIds.some((studentId) => {
        const student = this.group.find(
          (student) => student.studentId === studentId
        );
        return student?.studentMarkId !== null;
      })
    ) {
      this.openDialog();
      this.dialogParams = { mark, studentsIds, isDateUpdate: false };
      return;
    }

    if(this.isOpenDialog && !this.currentReason?.id)
    {
      this.dialogParams = { mark, studentsIds, isDateUpdate: false };
      return;
    }

    if (this.markDateIsInvalid(students)) {
      if (studentsIds.length === 1) view?.writeValue(this.tempMark);
      return;
    }

    this.sessionService
      .changeMarksDiscipline(this.disciplineId, students)
      .subscribe((response) => {
        response.forEach((studentInfo: any) => {
          const student = this.group.find(
            (student) => student.studentId === studentInfo.studentId
          );
          if(student!=undefined && student.disciplineMark === null)
            student.disciplineMark = mark
          if (student) {
            if (this.currentReason?.isRetake && student.disciplineMark) {
              const markInfo = {
                markName: student.disciplineMark.markName,
                markDate: this.retakeService.formateDateStringFromUTC(
                  student.markDate || ''
                ),
                createdAt: '',
                updatedAt: '',
                markChangeReasonName: 'Пересдача',
                isVisible: true,
                isInTime: true,
                fio: '',
                login: '',
                id: student.disciplineMark.id.toString(),
              };
              if (!student.retakes) student.retakes = [markInfo];
              else student.retakes.push(markInfo);
            }

            student.disciplineMark = mark || null;
            student.studentMarkId = studentInfo.studentMarkId;
            student.markDate = studentInfo.markDate;
            student.retake = studentInfo.reTake;
          }
        });

        const disciplineInfo = this.sessionService.disciplineInfo$.getValue();
        /*console.log('info',disciplineInfo)
        if (disciplineInfo.sheetStatus !== 'проверена')
          this.sessionService
            .changeDisciplineSheetStatus({
              sheetId: disciplineInfo.sheetId || '',
              dictSheetStatusId: this.dictService.dictSheetStatus.Filled.id,
            })
            .subscribe((response) => {
              if (response) {
                const newSheetStatus = Object.values(
                  this.dictService.dictSheetStatus
                ).find(
                  (sheetStatus) =>
                    sheetStatus.id.toLowerCase() === response.dictSheetStatusId
                );
                this.sessionService.disciplineInfo$.next({
                  ...disciplineInfo,
                  sheetStatus: newSheetStatus?.name,
                });
              }
            });*/

        this.updateMarksStatistic();
        this.notificationService.showSuccess('Даннные успешно обновлены');
        if (this.selectedStudents.length) this.selectedStudents = [];
        this.currentReason = null;
        this.checkedStudent = { ...this.checkedDateDefault };
        this.isCheckStudentAll = false;
      });
  }

  public onStudentExaminerChanged(examinerName: string, student: StudentByDiscipline) {

    if(examinerName.length) {
      const examiner =
        this.persons?.find((person) => person.fio === examinerName) || null;

      if (examiner)
        this.sessionService
          .addStudentExaminer({
            studentId: student?.studentId,
            personId: examiner.id,
            eduGroupId: this.eduGroupId,
            semesterNum: this.semesterNumber,
            electiveDisciplineId: student?.generalElectiveDisciplineId ??
              (this.disciplineInfo.electiveDisciplineId || null),
            disciplineId: student?.generalDisciplineId ?? this.disciplineId,
          })
          .subscribe((response) => {
            student.examinerId = response?.externalId ?? null;
            this.notificationService.showSuccess('Даннные успешно обновлены');
          });
    }
    else if(student?.examinerId)
      this.sessionService
        .deleteStudentExaminer(student?.examinerId)
        .subscribe((response) => {
          this.notificationService.showSuccess('Даннные успешно обновлены');
        });
  }

  public onAllStudentExaminerChanged(examinerName: string) {
    if (examinerName.length) {
      const examiner =
        this.persons?.find((person) => person.fio === examinerName) || null;

      if (examiner)
        this.massExaminer = examiner.fio
    }
  }

  public applyExaminerChanged() {
    if(this.massExaminer && this.massExaminer.length) {
      const examiner =
        this.persons?.find((person) => person.fio === this.massExaminer) || null;

      if (examiner)
        this.sessionService
          .addListStudentExaminer({
            students: this.group.filter(student => student.sessionAllowed).map(student => ({
                studentId: student.studentId,
                disciplineId: student.generalDisciplineId
                  ?? this.disciplineId,
                electiveDisciplineId: student.generalElectiveDisciplineId
                  ?? (this.disciplineInfo.electiveDisciplineId || null),
            })),
            personId: examiner.id,
            eduGroupId: this.eduGroupId,
            semesterNum: this.semesterNumber,
            electiveDisciplineId:
              this.disciplineInfo.electiveDisciplineId || null,
            disciplineId: this.disciplineId,
          })
          .subscribe((response) => {
            this.group.forEach(student => {
              if(student.sessionAllowed){
                student.examinerId = response.find(x => x.studentId === student.studentId)?.externalId ?? null;
                student.examinerName = examiner.fio;
              }
            })
            this.massExaminer = null;
            this.notificationService.showSuccess('Даннные успешно обновлены');
          });
    }
  }

  public fillGradeLevelList() {
    this.dictService.filterMarksByDisciplineType(
      this.sessionService.getDisciplineType()
    );
    this.gradeLevelList = this.dictService.marksByDiciplineType$.getValue();
  }

  public selectAll() {
    const rows = document.querySelectorAll('tbody > tr[role="row"]');
    if (this.isCheckStudentAll) {
      this.checkedStudent = { ...this.checkedDateDefault };
      rows.forEach((row, index) => {
        if (!this.selectedStudents.includes(this.group[index].studentId)) {
          row.classList.remove('k-state-selected');
        }
      });
    } else {
      for (let studentId in this.checkedStudent) {
        if(this.group.find(student => student.studentId == studentId)?.sessionAllowed)
          this.checkedStudent[studentId] = true;
      }
      rows.forEach((row, index) => {
        if(this.group[index].sessionAllowed)
          row.classList.add('k-state-selected')
      });
    }
    this.isCheckStudentAll = !this.isCheckStudentAll;
  }

  public selectAllDateChecked() {
    const rows = document.querySelectorAll('tbody > tr[role="row"]');
    if (this.isCheckDateAll) {
      this.checkedDate = { ...this.checkedDateDefault };
      rows.forEach((row, index) => {
        if (!this.selectedStudents.includes(this.group[index].studentId)) {
          row.classList.remove('k-state-selected');
        }
      });
    } else {
      for (let studentId in this.checkedDate) {
        if(this.group.find(student => student.studentId == studentId)?.sessionAllowed)
          this.checkedDate[studentId] = true;
      }
      rows.forEach((row, index) => {
        if(this.group[index].sessionAllowed)
          row.classList.add('k-state-selected')
      });
    }
    this.isCheckDateAll = !this.isCheckDateAll;
  }

  public changeMarkToAll(mark: MarksModel) {
    this.markToAll = mark;
  }

  public applyMarkToAll() {
    const studentsIds = Object.keys(this.checkedStudent).filter(
      (key) => this.checkedStudent[key]
    );

    if (this.markToAll && studentsIds.length)
      this.onStudentMarkChanged(this.markToAll, studentsIds);
  }

  public applyDateToAll() {
    const studentsIds = Object.keys(this.checkedDate).filter(
      (key) => this.checkedDate[key]
    );

    if (
      !this.isOpenDialog &&
      studentsIds.some((studentId) => {
        const student = this.group.find(
          (student) => student.studentId === studentId
        );
        return student?.studentMarkId !== null;
      })
    ) {
      this.openDialog();
      this.dialogParams = { studentsIds, isDateUpdate: true };
      return;
    }

    const studentsUpdate = studentsIds.map((studentId) => {
      const studentInfo = this.group.find(
        (student) => student.studentId === studentId
      );
      return {
        studentMarkId: studentInfo?.studentMarkId || null,
        dictMarkChangeReasonId: this.isOpenDialog
          ? this.currentReason?.id || null
          : null,
        markDate: this.isOpenDialog
          ? this.sessionService.convertDateToUTCDateOnly(this.reasonMarkDate)
          : this.markDate,
      };
    });

    const filterStudent = studentsUpdate.filter((student ) => student.studentMarkId != null);

    if (this.markDateIsInvalid(filterStudent)) return;

    this.sessionService.changeMarkDate(filterStudent).subscribe((response) => {
      response.forEach((studentInfo: any) => {
        const student = this.group.find(
          (student) => student.studentId === studentInfo.studentId
        );
        if (student) {
          student.markDate = studentInfo.markDate;
        }
      });
      this.notificationService.showSuccess('Дата успешно обновлена');
      this.checkedDate = { ...this.checkedDateDefault };
      this.isCheckDateAll = false;
      this.currentReason = null;
    });
  }

  public getDateString(dateISOString: string) {
    if (dateISOString) {
      const date = DateFromUTCAsLocal(dateISOString);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${this.formatDate(day)}.${this.formatDate(
        month
      )}.${date.getFullYear()}`;
    } else return '';
  }

  public printExamList(studentId: any){
    this.onPrintPersonPost()
    this.sheetService
      .printExamList({
          eduGroupId: this.eduGroupId,
          semester: this.semesterNumber,
          disciplineId: this.disciplineId,
          electiveDisciplineId: this.disciplineInfo.electiveDisciplineId || null,
          contingentDate: this.contingentDate,
          markDate: this.selectedMarkDate,
          studentId: studentId,
          signer: this.signerRequest
        }
      )
      .subscribe(
        (response) => {
          DownloadFile(response);
        },
      );
  }

  onPrintPersonPost(): void{
    if(this.signer.postName === "должность")
    {
      this.signerRequest.postName = ""
      this.signerRequest.personId = null
    }
    else this.signerRequest = this.signer
    this.signerRequest.personId = this.signerRequest.personId?.toString()
  }

  public formatDate(value: number) {
    return value > 9 ? value : '0' + value;
  }

  public getMarkInfo(value: number) {
    return this.gradeLevelList.find((mark) => mark.markValue === value);
  }

  public onChangeReason(reason: any) {
    this.currentReason = reason;
  }

  public closeDialog() {
    this.getReasons();
    this.isOpenDialog = false;
  }

  public openDialog() {
    this.reasonMarkDate = this.sessionService.markDate$.getValue();
    this.isOpenDialog = true;
  }

  public changeMarkDateReason(date: Date) {
    this.reasonMarkDate = date;
  }

  public acceptDialog() {
    if (this.dialogParams) {
      if (!this.currentReason) {
        this.notificationService.showError('Выберите причину смены оценки');
        return;
      }
      if (!this.dialogParams.isDateUpdate)
        this.dialogParams.mark &&
        this.onStudentMarkChanged(
          this.dialogParams.mark,
          this.dialogParams.studentsIds
        );
      else this.applyDateToAll();

      this.closeDialog();
    }
    this.getReasons();
  }

  public openRetakeEdit(studentId: string, disciplineId?: string) {
    this.onChangeState.emit('retakeEdit');
    this.onChangeStudentId.emit({ studentId });
    this.onChangeGeneralDisciplineId.emit({ disciplineId });
  }

  public checkedChange(event: any, studentId: string) {
    this.checkedDate[studentId] = event.target?.checked;

    const row = event.target.closest('tr[role="row"]');
    if (event.target?.checked) row.classList.add('k-state-selected');
    else if (!this.selectedStudents.includes(studentId))
      row.classList.remove('k-state-selected');

    this.isCheckDateAll = Object.values(this.checkedDate).every(
      (checked) => checked
    );
  }

  public checkedStudentChange(event: any, studentId: string) {
    this.checkedStudent[studentId] = event.target?.checked;

    const row = event.target.closest('tr[role="row"]');
    if (event.target?.checked) row.classList.add('k-state-selected');
    else if (!this.selectedStudents.includes(studentId))
      row.classList.remove('k-state-selected');

    this.isCheckStudentAll = Object.values(this.checkedStudent).every(
      (checked) => checked
    );
  }

  public getRetakeNumber(retake: number) {
    const remainder = 2 - retake;
    return remainder > 0 ? remainder : 0;
  }

  public navigateTo(studentId: string): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/contingent/studentmaininfo/${studentId}`])
    );

    window.open(url, '_blank');
  }

  public handleFilter(value: string) {
    if (value.length > 1) this.dictService.updatePersons(value);
  }

  public applyExaminatorToAll() {
  }
}
