<app-settings *ngIf="isAdmin"></app-settings>
<h1>{{TitleName()}}</h1>
<div class="announcement" *ngIf="!announcementInEditingMode && !usefulLinkInEditingMode && isShowed(enum.announcement)">
  <div class="display-flex" *ngIf="isAdmin">
    <span (click)="EditAnnouncement()" class="k-button-icon k-icon k-i-pencil m-r10"> </span>
  </div>
  <kendo-editor  [(ngModel)]="announcement.content" [iframe]="false"  [readonly]="true" class="editor" >
    <kendo-toolbar class="display-none"></kendo-toolbar>
  </kendo-editor>
</div>
<div class="announcement" *ngIf="announcementInEditingMode && !usefulLinkInEditingMode && isShowed(enum.announcement)">

  <kendo-editor
    #editor
    class="m-b30 h-400 montserrat hook"
    [(ngModel)]="announcement.content"
    [iframe]="false"
    [ngClass]="{ border_red: borderEditorRed, border_none: !borderEditorRed }"
    (valueChange)="valueChangeEditor()"
  >
    <kendo-toolbar>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorUnderlineButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorStrikethroughButton
        ></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button
          kendoEditorSubscriptButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorSuperscriptButton
        ></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
      <kendo-toolbar-dropdownlist
        kendoEditorFormat
      ></kendo-toolbar-dropdownlist>
      <kendo-toolbar-dropdownlist
        kendoEditorFontSize
      ></kendo-toolbar-dropdownlist>
      <kendo-toolbar-colorpicker
        kendoEditorForeColor
        view="gradient"
      ></kendo-toolbar-colorpicker>
      <kendo-toolbar-colorpicker
        kendoEditorBackColor
        view="gradient"
      ></kendo-toolbar-colorpicker>
    </kendo-toolbar>
    <kendo-toolbar>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button
          kendoEditorAlignLeftButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorAlignCenterButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorAlignRightButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorAlignJustifyButton
        ></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button
          kendoEditorInsertUnorderedListButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorInsertOrderedListButton
        ></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
      <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button
          kendoEditorAddColumnBeforeButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorAddColumnAfterButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorAddRowBeforeButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorAddRowAfterButton
        ></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button
          kendoEditorDeleteColumnButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorDeleteRowButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorDeleteTableButton
        ></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button
          kendoEditorMergeCellsButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button
          kendoEditorSplitCellButton
        ></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
      <kendo-toolbar-buttongroup>
        <kendo-toolbar-button
          kendoEditorCreateLinkButton
        ></kendo-toolbar-button>
        <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
      </kendo-toolbar-buttongroup>
      <!--       <kendo-toolbar-button kendoEditorViewSourceButton  ></kendo-toolbar-button>-->
      <kendo-toolbar-button
        icon="image"
      ></kendo-toolbar-button>
    </kendo-toolbar>
  </kendo-editor>
  <div class="button-control">
    <button
      kendoButton
      class="k-button k-button-sm k-rounded-md k-button-solid ng-star-inserted white m-r20"
      size="small"
      (click)="CancelAnnouncementEditingMode()"
    >
      отмена
    </button>
    <button
      kendoButton
      class="k-button k-button-sm k-rounded-md k-button-solid ng-star-inserted blue"
      size="small"
      (click)="SaveAnnouncement()"
    >
      Опубликовать
    </button>
  </div>
</div>

<div class="linksToShow" *ngIf="!announcementInEditingMode && !usefulLinkInEditingMode && isShowed(enum.links)">
  <p>Полезные ссылки</p>
  <button size="small" class="addBtn" kendoButton themeColor="success" icon="plus" (click)="EditUsefulLink(this.usefulLink)" *ngIf="isAdmin">
    Добавить
  </button>
  <div class="linksBlock" *ngFor="let item of usefulLinks">
    <div class="linkIcons" *ngIf="isAdmin">
      <span (click)="EditUsefulLink(item)" class="k-button-icon k-icon k-i-pencil m-r10"> </span>
      <span (click)="DeleteUsefulLink(item)" class="k-button-icon k-icon k-i-delete m-r10"> </span>
    </div>
    <div class="linkText" *ngIf="isAdmin || (!isAdmin && item.isVisible)">
      <a class="link" target="_blank" href="{{item.url}}">{{item.name}}</a>
    </div>
  </div>
</div>

<div *ngIf="usefulLinkInEditingMode && isShowed(enum.links)">
  <div class="button-control">
    <button
      kendoButton
      class="k-button k-button-sm k-rounded-md k-button-solid ng-star-inserted white m-r20"
      size="small"
      (click)="CancelUsefulLinkEditingMode()"
    >
      отмена
    </button>
    <button
      class="save-button"
      kendoButton
      size="small"
      themeColor="primary"
      (click)="SaveUsefulLink()"
      [disabled]="isFormInvalid()"
    >
      Сохранить
    </button>
  </div>

  <div class="grid-container">
    <div class="container-form">
      <form *ngIf="usefulLinkInEditingMode" class="contactContainer" [formGroup]="usefulLinkFormGroup">

        <div class="content-row">
          <kendo-label text="Наименование ссылки"></kendo-label>
          <dd>
            <kendo-formfield>
              <div class="superFlex">
                <kendo-textbox
                  [clearButton]="true"
                  formControlName="name"
                  placeholder="Наименование ссылки"
                  required
                ></kendo-textbox>
                <span class="required">*</span>
              </div>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Ссылка"></kendo-label>
          <dd>
            <kendo-formfield>
              <div class="superFlex">
                <kendo-textbox
                  [clearButton]="true"
                  formControlName="url"
                  placeholder="Ссылка"
                  required
                ></kendo-textbox>
                <span class="required">*</span>
              </div>
            </kendo-formfield>
            <kendo-formerror
              *ngIf="usefulLinkFormGroup.controls['url'].touched &&
                   usefulLinkFormGroup.controls['url'].dirty &&
                   !checkURLFormat(usefulLinkFormGroup.controls['url'].value)">
              Ошибка: Некорректный формат ссылки на соц. сеть.
            </kendo-formerror>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Порядок сортировки"></kendo-label>
          <dd>
            <kendo-formfield>
              <div class="superFlex">
                <kendo-numerictextbox
                  [min]="1"
                  formControlName="order"
                  format="n0"
                >
                </kendo-numerictextbox>
                <span class="required" style="visibility: hidden">*</span>
              </div>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Отображать ссылку для сотрудников"></kendo-label>
          <dd>
            <kendo-formfield>
              <input type="checkbox" kendoCheckBox formControlName="isVisible"/>
            </kendo-formfield>
          </dd>
        </div>

      </form>
    </div>
  </div>
</div>

<div [formGroup]="formGroup" class="contactsToShow" *ngIf="!announcementInEditingMode && !usefulLinkInEditingMode && isShowed(enum.contacts)">
  <p>Блок с контактами тех.служб</p>
  <div class="grid-container">
    <kendo-grid [kendoGridBinding]="supportContacts" (remove)="removeHandler($event)"
                (add)="addHandler($event)"
                (edit)="editHandler($event)"
                [sortable]="true"
                [filterable]="true">

      <ng-template kendoGridToolbarTemplate position="top" *ngIf="isAdmin">
        <button kendoGridAddCommand  size="small" themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
        <div *ngIf="isInEditingMode" class="editingButtons">
          <button kendoButton
                  (click)="cancelHandler()">
            Отмена
          </button>
          <button kendoButton
                  [disabled]="formGroup.value.name.length == 0"
                  themeColor="primary"
                  (click)="saveCurrent()">
            Сохранить
          </button>
        </div>
      </ng-template>

      <kendo-grid-column field="name"
                         title="Название службы"
                         headerClass="grid-header"
                         [width]="400">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
            [column]="column"
            [filter]="filter"
            [showOperators]="false"
            operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>

        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                           <span class="align-cells">
                               <kendo-textbox
                                 size="small"
                                 [formControl]="formGroup.get('name')"
                                 [clearButton]="true">
                               </kendo-textbox>
                           </span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="align-cells">
                        {{dataItem.name}}
                      </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="phoneNumbers" [filterable]="false" title="Телефонные номера" headerClass="grid-header" [width]="300">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <div formArrayName="phoneNumbers">
            <div *ngFor="let phone of formGroup.get('phoneNumbers').controls; let i = index" [formGroupName]="i" class="contacts-row">
              <kendo-maskedtextbox formControlName="phoneNumber" class="fixed-input"></kendo-maskedtextbox>
              <div>
                <!-- Условие для показа кнопки удаления -->
                <button kendoButton icon="delete" fillMode="flat" size="small" rounded="full"
                        (click)="removePhoneNumber(formGroup, i)"
                        *ngIf="formGroup.get('phoneNumbers').controls.length > 1">
                </button>
                <button kendoButton icon="plus" fillMode="flat" size="small" rounded="full"
                        *ngIf="shouldShowAddButton(formGroup.get('phoneNumbers'), i)"
                        (click)="addPhoneNumber(formGroup, true)">
                </button>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="contacts-align-cells">
            <ng-container *ngFor="let phone of dataItem.phoneNumbers; let i = index">
              <span>{{ phone }}</span><br *ngIf="i < dataItem.phoneNumbers.length - 1"/>
            </ng-container>
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="emails" title="E-mail" [filterable]="false" headerClass="grid-header" [width]="300">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <div formArrayName="emails">
            <div *ngFor="let email of formGroup.get('emails').controls; let i = index" [formGroupName]="i" class="contacts-row">
              <kendo-textbox formControlName="email"
                             [pattern]="'^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'"
                             class="fixed-input" [clearButton]="true">
              </kendo-textbox>
              <div>
                <!-- Условие для показа кнопки удаления -->
                <button kendoButton icon="delete" fillMode="flat" size="small" rounded="full"
                        (click)="removeEmail(formGroup, i)"
                        *ngIf="formGroup.get('emails').controls.length > 1">
                </button>
                <button kendoButton icon="plus" fillMode="flat" size="small" rounded="full"
                        *ngIf="shouldShowAddButton(formGroup.get('emails'), i)"
                        (click)="addEmail(formGroup)">
                </button>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="contacts-align-cells">
            <ng-container *ngFor="let email of dataItem.emails; let i = index">
              <span>{{ email }}</span><br *ngIf="i < dataItem.emails.length - 1"/>
            </ng-container>
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="chatLink"
                         title="Ссылка на чат"
                         headerClass="grid-header"
                         [width]="300"
                         [filterable]="false">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                           <span class="align-cells">
                               <kendo-textbox
                                 pattern="^https://.*"
                                 size="small"
                                 [maxlength]=50
                                 [formControl]="formGroup.get('chatLink')"
                                 [clearButton]="true">
                               </kendo-textbox>
                           </span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="align-cells">
                        <button *ngIf="dataItem.chatLink" class="link-button" kendoButton themeColor="secondary" icon="hyperlink-open" fillMode="flat" size="medium" rounded="full"
                                (click)="openLink(dataItem.chatLink)">
                        </button>
                      </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="" [width]="100" *ngIf="isAdmin">
        <ng-template kendoGridCellTemplate>
        <span class="align-cells">
          <button kendoGridEditCommand themeColor="secondary" icon="pencil" fillMode="flat" size="small" rounded="full"></button>
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="small" rounded="full"></button>
        </span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>

<div kendoDialogContainer></div>
