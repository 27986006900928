import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { DictFilialService } from 'src/app/services/dicts/dictfilial.service';
import { DictFilial } from 'src/app/models/dicts/dictfilial.model';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import {CommonDictionariesUserAccessService} from "../../../services/useraccess/common-dictionaries-user-access.service";
import { isChildOf } from 'src/app/helpers/elementRef-helper';
import { Router} from "@angular/router";

@Component({
  selector: 'app-filial',
  templateUrl: './filial.component.html',
  styleUrls: ['./filial.component.css']
})
export class FilialComponent implements OnInit {

  public allFilial: DictFilial[] = [];
  public Filial: DictFilial = {
    filialName: '',
    filialSName:'',
    city:'',
    address:'',
    filialPrintName:'',
  }
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(
    private FilialService: DictFilialService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService: CommonDictionariesUserAccessService,
    private router: Router,
  ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel()
  //  this.getAllFilial();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")/*&&this.orderValid*/) {
        this.saveCurrent();
      }
    });
  }

  //public orderValid: boolean=true;

  public orderChange(value: any): void {
    //this.orderValid = this.allFilial.find(_ => _.order === value) == undefined;
  }

  public onStateChange(): void {
    this.getAllFilial();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);
    this.formGroup = formGroup({
      filialName: "",
    });
    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.FilialService.addfilial(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllFilial();
              this.notificationService.showSuccess("Добавлено");
              this.Filial = {
                filialName: '',
                filialSName:'',
                city:'',
                address:'',
                filialPrintName:'',
              }
            },
            error:() => {
              this.notificationService.showError('Не удалось добавить филиал')
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.FilialService.updatefilial(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllFilial();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения')
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllFilial() {
    this.FilialService.getAllfilial()
      .subscribe(
        response => {
          this.allFilial = response;
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          if (response.accessLevelFilial == 0){
            this.notificationService.showError("У вас нет прав для просмотра данной страницы");
            this.router.navigate(['/profile']);
          } else {
            this.editable = response.accessLevelFilial == 2;
            this.getAllFilial();
          }
        },error:() => {}});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: "+ dataItem.filialName + "?",
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.FilialService.deletefilial(dataItem.filialId)
            .subscribe({
              next:() => {
                this.getAllFilial();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }}
            );
        }
        else this.opened = false;
      }
    });
  }


  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { filialName: any; filialId?: any; filialSName?: any; city?: any; address?: any; order?: any; filialPrintName?: any}) =>
  new UntypedFormGroup({
    filialId: new UntypedFormControl(dataItem.filialId),
    filialName: new UntypedFormControl(dataItem.filialName, Validators.required),
    filialSName: new UntypedFormControl(dataItem.filialSName, Validators.required),
    city: new UntypedFormControl(dataItem.city),
    address: new UntypedFormControl(dataItem.address),
    order: new UntypedFormControl(dataItem.order),
    filialPrintName: new UntypedFormControl(dataItem.filialPrintName)
  });
