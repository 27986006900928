<middle-control-menu-access></middle-control-menu-access>
<kendo-stacklayout [gap]="15" orientation="horizontal" class="wrapper">
  <aside
    class="params"
    *ngIf="
      state !== 'retake' && state !== 'session-date' && state !== 'retakeEdit'
    "
  >
    <app-middlecontrolhometree
      [checkGroup]="true"
      [(selectedSemester)]="semester"
      [(expandedKeys)]="expandedKeys"
      [(selectedKeys)]="selectedKeys"
      [(selectedTrainingLevel)]="selectedTrainingLevel"
      (onChangeGroup)="onSelectionTreeChange($event)"
      (selectedSemesterChange)="onChangeSemester($event)"
      (onChangeDate)="onChangeDate($event)"
      [treeType]="'department'"
    ></app-middlecontrolhometree>
    <div class="buttons">
      <p kendoTypography variant="p" class="buttons__title">Массовая печать</p>
      <button
        kendoButton
        icon="print"
        (click)="onNavigateTo('printstatement')"
        class="buttons__action buttons__action--icon"
      >
        Ведомостей
      </button>
      <button
        kendoButton
        icon="print"
        (click)="onNavigateTo('printsessionresult')"
        class="buttons__action buttons__action--icon"
      >
        Результатов сессии
      </button>
      <button
        kendoButton
        icon="print"
        (click)="onNavigateTo('printreport')"
        class="buttons__action buttons__action--icon"
      >
        Отчета по вузу
      </button>
      <button kendoButton
              icon="print"
              (click)="onNavigateTo('debtors-by-course')"
              class="buttons__action buttons__action--icon"
      >Списка должников</button>
    </div>
  </aside>
  <div [ngSwitch]="state">
    <section *ngSwitchCase="'main'" class="session-wrapper">
      <app-middlecontrolfilters
        [showSigner]="showSigner"
        [showExamList]="showExamList"
        [group]="selectedGroup"
        [contingentDate]="contingentDate"
        [markDate]="markDate"
        [(semester)]="semester"
        [(lastLoaded)]="lastLoaded"
        (stateChange)="onChangeState($event)"
        (onChangeEditable)="onChangeEditable($event)"
        (onChangeEditableStudentSession)="onChangeEditableStudentSession($event)"
      >
      </app-middlecontrolfilters>
      <app-sessiontable
        [group]="selectedGroup"
        [contingentDate]="contingentDate"
        [markDate]="markDate"
        [editableStudentSession]="editableStudentSession"
        [semesterNumber]="semester?.semesterNum || 0"
        [lastLoaded]="lastLoaded"
        (onChangeState)="onChangeState($event)"
        (onChangeDiscipline)="onChangeDiscipline($event)"
        (onChangeStudent)="onChangeStudentId($event)"
      ></app-sessiontable>
      <app-sessiontablelegend></app-sessiontablelegend>
    </section>
    <section *ngSwitchCase="'discipline'" class="discipline-wrapper">
      <app-statementsettings
        [choiceOfTeacher]="choiceOfTeacher"
        [choiceOfSigner]="choiceOfSigner"
        [groupName]="selectedGroup.text"
        [planId]="planId"
        [contingentDate]="contingentDate"
        [selectedMarkDate]="markDate"
        (onChangeEditableDiscipline)="onChangeEditableDiscipline($event)"
        [eduGroupId]="eduGroupId"
        [facultyId]="facultyId"
        [semesterNumber]="semester?.semesterNum || 0"
        [disciplineId]="disciplineId"
        (onComeback)="onComeback()"
      ></app-statementsettings>
      <app-disciplinetable
        [choiceOfTeacher]="choiceOfTeacher"
        [showExamList]="showExamList"
        [eduGroupId]="eduGroupId"
        [planId]="planId"
        [contingentDate]="contingentDate"
        [selectedMarkDate]="markDate"
        [editable]="editableDiscipline"
        [semesterNumber]="semester?.semesterNum || 0"
        [disciplineId]="disciplineId"
        [electiveDisciplineId]="electiveDisciplineId"
        (onChangeState)="onChangeState($event)"
        (onChangeStudentId)="onChangeStudentId($event)"
        (onChangeGeneralDisciplineId)="onChangeGeneralDisciplineId($event)"
      ></app-disciplinetable>
      <app-disciplinefooter (onComeback)="onComeback()"></app-disciplinefooter>
    </section>
    <section *ngSwitchCase="'retakeView'">
      <app-retake
        [(studentId)]="studentId"
        [(studentName)]="studentName"
        [semester]="semester"
        (onComeback)="onComeback()"
      ></app-retake>
    </section>
    <section *ngSwitchCase="'session-date'">
      <app-sessiondate
        [(studentId)]="studentId"
        [(studentName)]="studentName"
        [(groupName)]="groupName"
        [semesterNumber]="semester?.semesterNum || 0"
        (onComeback)="onComeback()"
      ></app-sessiondate>
    </section>
    <section *ngSwitchCase="'retakeEdit'">
      <app-retake-edit
        [(studentId)]="studentId"
        [disciplineId]="generalDisciplineId ? generalDisciplineId : disciplineId"
        [electiveDisciplineId]="electiveDisciplineId"
        (onCancel)="onChangeState('discipline')"
      ></app-retake-edit>
    </section>
  </div>
</kendo-stacklayout>
