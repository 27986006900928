import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {ContingentUserAccess} from "../../models/useraccess/contingent/contingentuseraccess.model";
import {map} from "rxjs/operators";
import {handleError} from "../../helpers/errorHandle-helper";
import {CardsUserAccess} from "../../models/useraccess/cards/cardsuseraccess.model";

@Injectable({
  providedIn: 'root'
})
export class CardsUserAccessService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.cardsUserAccess}`;
  constructor(private http: HttpClient) { }

  public getAccess(): Observable<CardsUserAccess[]> {
    return this.http.get<CardsUserAccess[]>(this.baseUrl + '/GetAccess/')
      .pipe(catchError(handleError));
  }

  public getAccessLevel(): Observable<CardsUserAccess> {
    return this.http.get<CardsUserAccess>(this.baseUrl + '/GetAccessLevel')
      .pipe(catchError(handleError));
  }

  public getUserAccessLevel():Observable<boolean> {
    return this.http.get<CardsUserAccess>(this.baseUrl + '/GetAccessLevel')
      .pipe(
        map(
          (response)=> response.accessSettings
        )
      );
  }

  public getSalaryProjectLevel():Observable<boolean> {
    return this.http.get<CardsUserAccess>(this.baseUrl + '/GetAccessLevel')
      .pipe(
        map(
          (response)=> {
            console.log(response); return response.accessLevelSalaryProject !== 0}
        )
      );
  }

  //Save access
  public saveAccess(useraccess: CardsUserAccess): Observable<CardsUserAccess> {
    return this.http.post<CardsUserAccess>(this.baseUrl + '/SaveAccess/', useraccess)
      .pipe(catchError(handleError));
  }

  //Delete access
  public deleteAccess(id: string): Observable<CardsUserAccess> {
    return this.http.delete<CardsUserAccess>(this.baseUrl + '/DeleteAccess/' + id)
      .pipe(catchError(handleError));
  }
}
