import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { QualificationdegreeService } from 'src/app/services/dicts/qualificationdegree.service';
import { TrainingLevelService } from 'src/app/services/dicts/traininglevel.service';
import { QualificationDegree } from 'src/app/models/dicts/qualificationdegree.model';
import { TrainingLevel } from 'src/app/models/dicts/traininglevel.model';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  CommonDictionariesUserAccessService
} from "../../../services/useraccess/common-dictionaries-user-access.service";
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Router} from "@angular/router";

@Component({
  selector: 'app-qualificationdegree',
  templateUrl: './qualificationdegree.component.html',
  styleUrls: ['./qualificationdegree.component.css']
})
export class QualificationdegreeComponent implements OnInit {

  public traininglevels: TrainingLevel[] = [];
  public traininglevel: TrainingLevel = {
    dictTrainingLevelId: 0,
    trainingLevelName: '',
    trainingLevelSName: '',
    orderNum: 0,
  }

  public qualificationdegrees: QualificationDegree[] = [];
  public qualificationdegree: QualificationDegree = {
    dictQualificationId: 0,
    qualificationName: '',
    qualificationSName: '',
    dictTrainingLevelId: '',
    dictTrainingLevel: this.traininglevel
  }
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;
  public names: string[] | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private qualificationdegreeService: QualificationdegreeService,
              private traininglevelService: TrainingLevelService,
              private renderer: Renderer2,
              private notificationService: NotificationsService,
              private userAccessService: CommonDictionariesUserAccessService,
              private dialogService: DialogService,
              private router: Router,
  ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel()
    this.getAllTrainingLevel();
  //  this.getAllQualificationDegree();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  //Get traininglevel name for id
  public nameTrainingLevel(id: string): TrainingLevel | undefined {
    return this.traininglevels.find((x) => x.dictTrainingLevelExternalId === id);
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      dictQualificationId: 0,
      qualificationName: '',
      qualificationSName: '',
      dictTrainingLevelId: '',
      dictTrainingLevel: this.traininglevel
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
    sender,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return
    if (this.isNew) {
      rowIndex += 1;
    }

    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.qualificationdegreeService.addQualificationDegree(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllQualificationDegree();
              this.notificationService.showSuccess("Добавлено");
              this.qualificationdegree = {
                dictQualificationId: 0,
                qualificationName: '',
                qualificationSName: '',
                dictTrainingLevelId: '',
                dictTrainingLevel: {
                  dictTrainingLevelId: 0,
                  trainingLevelName: '',
                  trainingLevelSName: '',
                  orderNum: 0,
                }
              }
            },
            error:() => {
              this.notificationService.showError("Не удалось добавить запись");
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.qualificationdegreeService.updateQualificationDegree(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllQualificationDegree();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError("Не удалось изменить запись");
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllTrainingLevel() {
    this.traininglevelService.getAllTrainingLevel()
      .subscribe(
        response => {
          this.traininglevels = response;
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          if (response.accessLevelQualification == 0){
            this.notificationService.showError("У вас нет прав для просмотра данной страницы");
            this.router.navigate(['/profile']);
          } else {
             this.editable = response.accessLevelQualification != 1;
             this.getAllQualificationDegree();
          }
        },error:() => {}});
  }

  //Getting all entries from dictionary
  public getAllQualificationDegree() {
    this.qualificationdegreeService.getAllQualificationDegree()
      .subscribe(
        response => {
          this.qualificationdegrees = response;
        }
      );
  }


 //Deleting an entry from dictionary
 public removeHandler({ dataItem }: RemoveEvent): void {
  const dialog: DialogRef = this.dialogService.open({
    title: "Пожалуйста подтвердите",
    content: `Вы действительно хотите удалить: ${dataItem.qualificationName.toLowerCase()}?`,
    actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
    width: 450,
    height: 200,
    minWidth: 250,
  });
  dialog.result.subscribe((result) => {
    if (result instanceof DialogCloseResult) {
    } else {

      if(result.text == "Да"){
        this.opened = false;
        this.qualificationdegreeService.deleteQualificationDegree(dataItem.dictQualificationExternalId)
        .subscribe({
          next:() => {
            this.getAllQualificationDegree();
            this.notificationService.showSuccess("Удалено");
          },
          error:(error) => {
          this.notificationService.showError(error.error);
        }}
        );
          }
        else{
          this.opened = false;
        }
    }
  });
}

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
  }

  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };
}

const formGroup = (dataItem: { dictQualificationId: any; dictQualificationExternalId?: string; qualificationName: any; qualificationSName: any; dictTrainingLevelId: any; dictTrainingLevel: any; }) =>
  new FormGroup({
    dictQualificationId: new FormControl(dataItem.dictQualificationId),
    dictQualificationExternalId: new FormControl(dataItem.dictQualificationExternalId),
    qualificationName: new FormControl(dataItem.qualificationName, Validators.required),
    qualificationSName: new FormControl(dataItem.qualificationSName, Validators.required),
    dictTrainingLevelId: new FormControl(dataItem.dictTrainingLevelId, Validators.required),
    dictTrainingLevel: new FormControl(dataItem.dictTrainingLevel, Validators.required),
  });


const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

