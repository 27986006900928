import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import { RetakeEditModel } from '../../../models/middlecontrol/retake.model';
import { RetakeService } from '../../../services/middlecontrol/retake.service';

@Component({
  selector: 'app-retake-edit',
  templateUrl: './retakeedit.component.html',
  styleUrls: ['./retakeedit.component.scss'],
})
export class RetakeeditComponent implements OnInit {
  @Input() studentId: string = '';
  @Input() disciplineId: string = '';
  @Input() electiveDisciplineId: string | null = null;
  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() studentIdChange: EventEmitter<string> = new EventEmitter();

  public checkedAll: boolean = false;
  public isLoading: boolean = false;

  public dataGrid: RetakeEditModel[] = [];

  constructor(
    private retakeService: RetakeService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.retakeService
      .getRetakeEditData(this.studentId, this.disciplineId, this.electiveDisciplineId)
      .subscribe((response) => {
        response.forEach((info) => {
          info.createdAt = this.retakeService.formateDateStringFromUTC(
            info.createdAt
          );
          info.updatedAt = this.retakeService.formateDateStringFromUTC(
            info.updatedAt
          );
          info.markDate = this.retakeService.formateDateStringFromUTC(
            info.markDate
          );
        });
        this.dataGrid = response;
        this.isCheckedAll();
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this.studentIdChange.emit('');
  }

  public isCheckedAll(): void {
    if (
      this.dataGrid.reduce(
        (value: boolean | undefined, item: RetakeEditModel) =>
          value && item.isVisible,
        true
      )
    ) {
      this.checkedAll = true;
    } else this.checkedAll = false;
  }

  public onSave(): void {
    const saveData = this.dataGrid.map(({ id, isInTime, isVisible }) => ({
      id,
      isInTime,
      isVisible,
    }));
    this.retakeService.saveRetakeEditData(saveData).subscribe((response) => {
      if (response?.length) {
        this.notificationService.showSuccess('Данные успешно обновлены');
        this.onGoBack();
      }
    });
  }

  public onGoBack(): void {
    this.onCancel.emit();
  }

  public onToggleCheckAll(event: Event): void {
    this.dataGrid = this.dataGrid.map(
      (item: RetakeEditModel, index: number) => {
        if (index !== this.dataGrid.length - 1)
          item.isVisible = (<HTMLInputElement>event.target).checked;
        return item;
      }
    );
  }

  public onChangeDropdownList(value: string, id: string): void {
    this.dataGrid = this.dataGrid.map((item: RetakeEditModel) => {
      if (item.id === id) {
        item.isInTime = value === 'Да';
      }
      return item;
    });
  }
}
