import {Moment} from "moment/moment";

export class DepartmentTree {
  public text?: string;
  public id?: string;
  public value?: DepartmentTree[];
  public isExpanded: boolean = true
}

export class DepartmentTreeRequest {
  public contingentDate?: Date|Moment;
  public trainingLevelId?: string;
}
