import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import {
  AddEvent,
  GridComponent,
  RemoveEvent,
  CellClickEvent,
  CancelEvent,
  PagerPosition,
  GridDataResult
} from '@progress/kendo-angular-grid';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {checkRole} from "../../../helpers/token/role-check";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Role} from "../../../models/useraccess/role";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {
  LocalSignatoryDict,
  LocalSignatoryDictDto,
  LocalSignatoryFacultyReadDto,
  LocalSignatoryFilialReadDto,
  LocalSignatoryRead,
  LocalSignatoryRole,
  LocalSignatoryTrainingLevelReadDto
} from "../../../models/contingent/localsignatory.model";
import {LocalSignatoryService} from "../../../services/contingent/local-signatory.service";
import {DepartmentService} from "../../../services/contingent/department.service";
import {CommondictService} from "../../../services/contingent/commondict.service";
import {arrayRewrite, valueChangeBySourceName} from "../../../helpers/multiselect-helper";
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";
import {PagerType} from "@progress/kendo-angular-pager";
import { DictFilialService } from '../../../services/contingent/dictfilial.service';
import { DictLocalSignatoryRoleService } from '../../../services/contingent/dictLocalSignatoryRole.service';
import { CompositeFilterDescriptor, FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { DictSignatoryRole } from '../../../models/contingent/dictSignatoryRole.model';
import { DictSignatoryRoleService } from '../../../services/contingent/dictsignatoryrole.service';

@Component({
  selector: 'local-signatory',
  templateUrl: './localsignatory.component.html',
  styleUrls: ['./localsignatory.component.scss']
})
export class LocalSignatoryComponent implements OnInit {
  public localSignatories: LocalSignatoryRead[] = [];
  public faculties: LocalSignatoryFacultyReadDto[] = [];
  public trainingLevels: LocalSignatoryTrainingLevelReadDto[] = [];
  public filials: LocalSignatoryFilialReadDto[] = []
  public dictSignatoryRoles: DictSignatoryRole[] = [];

  public trainingLevelsEdit: any[] = [];
  public facultiesEdit: any[] = [];
  public filialsEdit: any[] = [];
  public formGroup: FormGroup | undefined;
  public editable: boolean = false;
  public isAdmin: boolean = false;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
  public dataSources = ['trainingLevels', 'faculties', 'filials'];
  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };
  public gridView!: GridDataResult;
  public localSignatoryRoles: LocalSignatoryRole[] = [];

  //#region Grid controls
  public position: "top" | "bottom" | "both" = "both";
  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;
  public opened = false;
  public loading = false;
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = [20, 50, 100];
  public previousNext = true;
  public positionPager: PagerPosition = 'bottom';
  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }
  //#endregion

  constructor(private dictSignatoryRoleService: DictSignatoryRoleService,
              private localSignatoryService: LocalSignatoryService,
              private departmentService: DepartmentService,
              private commonService: CommondictService,
              private userAccessService: ContingentUserAccessService,
              private renderer: Renderer2,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private tokenService: TokenStorageService,
              private jwtService: JwtHelperService,
              private dictFilialService: DictFilialService,
              private dictLocalSignatoryRoleService: DictLocalSignatoryRoleService
    ) {
    this.isAdmin = checkRole(tokenService, jwtService, Role.Admin);
  }

  public ngOnInit(): void {
    this.getAccessLevel();
    this.getFaculties();
    this.getTrainingLevels();
    this.getGlobalSignatoryRoles();
    this.getLocalSignatories();
    this.getAllFilials();
    this.getLocalSignatoryRoles();

    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  //#region Get Data

  private getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe(response => this.editable = response.localSignatoryAccessLevel || this.isAdmin);
  }

  private getFaculties() {
    this.faculties = [];
    this.departmentService.getAllFaculty(1).subscribe(
      response => {
        for (let i = 0; i < response.length; i++) {
          let item = new LocalSignatoryFacultyReadDto();
          if (response[i].facultyExternalId)
            item.id = response[i].facultyExternalId as string;
          if (response[i].name)
            item.facultyName = response[i].name as string;
          if (response[i].shortName)
            item.facultyShortName = response[i].shortName as string;
          this.faculties.push(item);
        }
      }
    );
    if (this.faculties) {
      let item = new LocalSignatoryFacultyReadDto();
      item.id = '';
      item.facultyShortName = 'Все';
      this.faculties.unshift(item);
    }
  }

  private getTrainingLevels() {
    this.trainingLevels = [];
    this.commonService.getAllTrainingLevel().subscribe(
      response => {
        for (let i = 0; i < response.length; i++) {
          let item = new LocalSignatoryTrainingLevelReadDto();
          if (response[i].dictTrainingLevelExternalId)
            item.id = response[i].dictTrainingLevelExternalId as string;
          item.trainingLevelName = response[i].trainingLevelName as string;
          this.trainingLevels.push(item);
        }
      }
    );
    if (this.trainingLevels) {
      let item = new LocalSignatoryTrainingLevelReadDto();
      item.id = '';
      item.trainingLevelName = 'Все';
      this.trainingLevels.unshift(item);
    }
  }

  private getGlobalSignatoryRoles() {
    this.dictSignatoryRoleService.getDictSignatoryRole()
      .subscribe({
        next:(response) => {
          this.dictSignatoryRoles = response;
        }, error:() => {
          this.notificationService.showError("Не удалось получить список ролей");
        }
      });
  }

  private getLocalSignatories() {
    this.loading = true;
    this.localSignatoryService.get()
      .subscribe({
        next:(response) => {
          this.localSignatories = response;
          if (this.localSignatories) {
            for (let i = 0; i < this.localSignatories.length; i++) {
              valueChangeBySourceName(this.localSignatories[i].trainingLevels, "id", null, "");
              valueChangeBySourceName(this.localSignatories[i].trainingLevels, "trainingLevelName", null, "Все");
              valueChangeBySourceName(this.localSignatories[i].faculties, "id", null, "");
              valueChangeBySourceName(this.localSignatories[i].faculties, "facultyName", null, "Все");
              valueChangeBySourceName(this.localSignatories[i].filials, "id", null, "");
              valueChangeBySourceName(this.localSignatories[i].filials, "filialSName", null, "Все");
            }
          }
          this.loading = false;
          this.loadItems();
        }, error:() => {
          this.notificationService.showError("Не удалось получить список локальных подписантов");
          this.loading = false;
        }});
  }

  public getAllFilials() {
    this.filials = [];
    this.dictFilialService.getAllfilial()
      .subscribe(
        response => {
          for (let i = 0; i < response.length; i++) {
            let item = new LocalSignatoryFilialReadDto();
            if (response[i].dictFilialExternalId)
              item.id = response[i].dictFilialExternalId as string;
            item.filialSName = response[i].filialSName as string;
            this.filials.push(item);
          }
        }
      );
      if (this.filials) {
        let item = new LocalSignatoryFilialReadDto();
        item.id = '';
        item.filialSName = 'Все';
        this.filials.unshift(item);
      }
  }

  public getLocalSignatoryRoles() {
    this.dictLocalSignatoryRoleService.getLocalSignatoryRoles()
      .subscribe(
        response => {
          this.localSignatoryRoles = response;
        }
      );
  }

  //#endregion

  //#region Grid Event Handlers

  //Start Adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);
    this.trainingLevelsEdit = [];
    this.facultiesEdit = [];
    this.filialsEdit = [];

    this.formGroup = formGroup(new LocalSignatoryRead());
    this.isLine = true;
    this.isNew = true;

    sender.addRow(this.formGroup);
  }
  //Start Editing
  public editHandler({sender, rowIndex, dataItem}: CellClickEvent): void {
    let filterTrainingLevels: any = [];
    let filterFaculties: any = [];
    let filterFilials: any = [];

    // Вывод уровня подготовки в редактирование
    for(let t = 0; t < dataItem.trainingLevels.length; t++) {
      for (let i=0; i < this.trainingLevels.length; i++) {
        if(this.trainingLevels[i].trainingLevelName === dataItem.trainingLevels[t].trainingLevelName)
        {
          filterTrainingLevels.push(this.trainingLevels[i])
        }
      }
    }

    // Вывод факультетов в редактирование
    for(let t = 0; t < dataItem.faculties.length; t++) {
      for (let i=0; i < this.faculties.length; i++) {
        if(this.faculties[i].id === dataItem.faculties[t].id)
        {
          filterFaculties.push(this.faculties[i])
        }
      }
    }

    // Вывод филиалов в редактирование
    for(let t = 0; t < dataItem.filials.length; t++) {
      for (let i=0; i < this.filials.length; i++) {
        if(this.filials[i].id === dataItem.filials[t].id)
        {
          filterFilials.push(this.filials[i] )
        }
      }
    }

    this.trainingLevelsEdit = filterTrainingLevels;
    this.facultiesEdit = filterFaculties;
    this.filialsEdit = filterFilials;

    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return
    if (this.isNew) {
      rowIndex += 1;
    }

    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }
  //Cancel
  public cancelHandler({ sender, rowIndex }: CancelEvent): void {
    this.closeEditor(sender, rowIndex);
  }
  public btnCancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }
  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.localSignatoryService.delete(dataItem.id)
          .subscribe({
            next:() => {
              this.getLocalSignatories();
              this.notificationService.showSuccess("Удалено");
            },
            error:(error) => {
            this.notificationService.showError(error.error);
          }}
          );
        } else{
          this.opened = false;
        }
      }
    });
  }
  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public saveHandler(event: any) {
    //console.log('save', event);
  }

  //#endregion

  //#region Helper methods

  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  private saveRow(): void {
    if (!this.isInEditingMode) {
      this.closeEditor(this.grid);
      return;
    }

    if (!this.formGroup) return;

    let dataItem = this.formGroup.value;
    let data = {} as any;
    if (dataItem.id)
      data.id = dataItem.id;
    data.dictSignatoryRoleId = dataItem.dictSignatoryRoleId;
    data.trainingLevels = dataItem.trainingLevels.map(function (data: any) { return data.id == "" ? null : data.id; });
    data.faculties = dataItem.faculties.map(function (data: any) { return data.id == "" ? null : data.id; });
    data.filials = dataItem.filials.map(function (data: any) { return data.id == "" ? null : data.id; });
    data.dictLocalSignatoryRoleId = dataItem.dictLocalSignatoryRoleId;

    if (this.isNew && this.formGroup !== undefined) {
      this.localSignatoryService.add(data)
        .subscribe({
          next:() => {
            this.getLocalSignatories();
            this.notificationService.showSuccess("Добавлено");
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить запись");
          }}
        );
    }
    else if (this.formGroup !== undefined) {
      this.localSignatoryService.update(data)
        .subscribe({
          next:() => {
            this.getLocalSignatories();
            this.notificationService.showSuccess("Обновлено");
          },
          error:(error) => {
            console.log(error);
            this.notificationService.showError("Не удалось изменить запись");
          }}
        );
    }

    this.closeEditor(this.grid);
  }

  public getTrainingLevelName(data: any): string {
    let name = '';
    for (let i = 0; i < data.length; i++) {
      name += data[i].trainingLevelName + (i + 1 < data.length ? ', ' : '');
    }
    return name;
  }

  public getFacultyName(data: any): string {
    let name = '';
    for (let i = 0; i < data.length; i++) {
      name += data[i].facultyName + (i + 1 < data.length ? ', ' : '');
    }
    return name;
  }

  public getFilialName(data: any): string {
    let name = '';
    for (let i = 0; i < data.length; i++) {
      name += data[i].filialSName + (i + 1 < data.length ? ', ' : '');
    }
    return name;
  }

  public getSignatoryRoleName(signatoryRoleId: any): string | undefined {
    let signatoryRole = this.dictSignatoryRoles.find(_ => _.id === signatoryRoleId);
    return signatoryRole?.name;
  }

  public getLocalSignatoryRoleName(localSignatoryRoleId: any): string | undefined {
    let localSignatoryRole = this.localSignatoryRoles.find(_ => _.externalId === localSignatoryRoleId);
    return localSignatoryRole?.name;
  }

  //#endregion

  //#region DropDownList Events

  public facultyValueChange(value: any, dataItem: any) {
    dataItem.faculties = arrayRewrite(dataItem.faculties, "id");
    this.facultiesEdit = arrayRewrite(value, "id");
  }

  public trainingLevelValueChange(value: any, dataItem: any) {
    dataItem.trainingLevels = arrayRewrite(dataItem.trainingLevels, "id");
    this.trainingLevelsEdit = arrayRewrite(value, "id");
  }

  public filialValueChange(value: any, dataItem: any) {
    dataItem.filials = arrayRewrite(dataItem.filials, "id");
    this.filialsEdit = arrayRewrite(value, "id");
  }

  //#endregion

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.loadItems();
  }

  public getSource(source: LocalSignatoryRead, key: string): LocalSignatoryDictDto[] {
    return source[key as keyof LocalSignatoryDict];
  }

  public loadItems() {
    let data = this.localSignatories.slice();

    for (let i = 0; i < this.dataSources.length; i++) {
      const filter = this.filter.filters.map((item) => item as FilterDescriptor).find((item) => item.field === this.dataSources[i]);
      if (filter)
        data = data.filter((_) =>
          this.getSource(_, this.dataSources[i])
            .map((_) => _.id)
            .includes(filter?.value),
        );
    }
    const filters = {
      logic: this.filter.logic,
      filters: this.filter.filters.filter((item) => {
        const i = item as FilterDescriptor;
        return i.field && !this.dataSources.includes(i.field.toString());
      }),
    };

    data = filterBy(data.slice(0), filters);

    this.gridView = {
      data: data,
      total: data.length,
    };
  }
}

const formGroup = (dataItem: LocalSignatoryRead) => new FormGroup({
  id: new FormControl(dataItem.id),
  trainingLevels: new FormControl(dataItem.trainingLevels, Validators.required),
  faculties: new FormControl(dataItem.faculties, Validators.required),
  filials: new FormControl(dataItem.filials, Validators.required),
  dictSignatoryRoleId: new FormControl(dataItem.dictSignatoryRoleId, Validators.required),
  dictLocalSignatoryRoleId: new FormControl(dataItem.dictLocalSignatoryRoleId)
});

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);
const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

