import { Component, OnInit } from '@angular/core';
import {BreadCrumbItem} from "@progress/kendo-angular-navigation";
import {DrawerItemExpandedFn} from "@progress/kendo-angular-layout";
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {TokenStorageService} from "../../../services/token.service";
import {Role} from "../../../models/useraccess/role";
import { items } from 'src/app/models/cards/menu-items';
import {AcademicStateService} from "../../../services/contingent/academicstate.service";
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";
import {CardsUserAccessService} from "../../../services/useraccess/cards-user-access.service";

@Component({
  selector: 'card-header',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class CardLayoutComponent implements OnInit {


  //Menu items
  public items: any[] = items;
  public isMainPage: boolean = this.router.url.endsWith('/cards');

  public onItemClick(item: BreadCrumbItem): void {
    const selectedItemIndex = this.items.findIndex((i) => i.text === item.text);
    const url = this.items
      .slice(0, selectedItemIndex + 1)
      .map((i) => `${i.text.toLowerCase()}` );
    this.router.navigate(url);
  }
  public expandedIndices = [2];
  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  constructor(private academicstateService: AcademicStateService,
              private router: Router,
              private jwtHelper: JwtHelperService,
              private tokenStore: TokenStorageService,
              private cardsUserAccessService: CardsUserAccessService,
  ) {
  }

  ngOnInit(): void {
    this.getAccessLevel()
  }

  public onSelect( item:any ): void {
    localStorage.removeItem('group_settings')
  }

  public getAccessLevel() {
    this.cardsUserAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.accessSetting(!response.accessSettings, response.accessLevelSalaryProject)
        },
        error:() => {
          this.accessSetting(true, 2)
        }}
      );
  }

  public accessSetting(accessSetting: boolean, accessLevelSalaryProject: number){
    // Hide admin items
    const token = this.tokenStore.getAuthToken();
    let user;
    if(token !== null)
    {
      user = this.jwtHelper.decodeToken(token);
    }
    let role = user.role;
    if (!role.includes(Role.Admin)) {
      this.items[0].items = this.items[0].items.filter((a: { accessSetting: boolean; accessSalaryProject: boolean; } ) =>
        (accessSetting ? a.accessSetting == null : true)
        && (accessLevelSalaryProject === 0 ? a.accessSalaryProject == null : true)
      );
    }
  }
}
