<gia-settings></gia-settings>
<h1>Диплом</h1>

<div class="selectContent w-850">
    <dd class="list">
      <span class="selectText">
        Статус заполнения диплома
      </span>
      <kendo-dropdownlist class="w-600"
                          [data]="fillingStatuses"
                          textField="name"
                          valueField="id"
                          [disabled] = !allowEdit
                          [valuePrimitive]="true"
                          name="fillingStatus"
                          [(ngModel)]="generalInformation.diplomaFillStatus">
      </kendo-dropdownlist>
      <span class="required m-l10">*</span>
    </dd>

    <dd class="list">
      <span class="selectText">
        Шаблон печати диплома
      </span>
      <kendo-dropdownlist class="w-600" [ngClass]="{'borderred': !generalInformation.printTemplateId}"
                          [data]="patternPrints"
                          textField="printTemplateName"
                          valueField="printTemplateId"
                          [disabled] = !allowEdit
                          [valuePrimitive]="true"
                          name="patternPrint"
                          [(ngModel)]="generalInformation.printTemplateId">
      </kendo-dropdownlist>
      <span class="required m-l10">*</span>
    </dd>
</div>

<div class="buttons">
    <button kendoButton (click)="getDiploma()">Печать диплома</button>
    <button kendoButton (click)="getAttachment(AttachmentTemplateEnumList.one)">Приложение 1-4 стр.</button>
    <button kendoButton (click)="getAttachment(AttachmentTemplateEnumList.two)">Приложение 2-3 стр.</button>
    <button kendoButton (click)="getAttachment(AttachmentTemplateEnumList.all)">Приложение двустор.</button>
</div>

<div class="selectContent m-t25">
    <dd class="list">
      <span class="selectText">
        ФИО обучающегося
      </span>
      <p class="w-315 link blue" (click)="openCard(generalInformation.studentId)"> {{ generalInformation.studentFio ?? "-"}} </p>
    </dd>

    <dd class="list">
      <span class="selectText">
        Направление подготовки
      </span>
      <p class="w-315">{{ generalInformation.educationStandardName ?? "-"}}</p>
    </dd>

    <dd class="list">
        <span class="selectText">
          Квалификация
        </span>
        <p class="w-315">{{ generalInformation.qualificationName ?? "-"}}</p>
      </dd>

      <dd class="list">
        <span class="selectText">
          Номер в приказе о допуске
        </span>
      <!--  <p class="w-300" *ngIf="generalInformation.admissionOrder !== null">{{ generalInformation.admissionOrder }}</p>-->
        <p class="w-300">
          <kendo-textbox [ngClass]="{'borderred': !admissionOrder || admissionOrder.trim() == ''}"
                         [disabled]="generalInformation.admissionOrder !== null"
                         (valueChange)="changeAdmissionOrder($event)"
                         [(ngModel)]="admissionOrder">
          </kendo-textbox>
        </p>
        <span class="required m-l10">*</span>
      </dd>
</div>

<kendo-tabstrip class="custom-tabs" (tabSelect)="onSelect($event)" [keepTabContent]="true">
    <kendo-tabstrip-tab title="Основные сведения" [selected]=basicInformation [disabled]="blockTabstrip">
        <ng-template kendoTabContent>
            <app-basicinformation
              [studentId]="studentId"
              [generalInformation]="generalInformation"
              [originalGeneralInformation]="originalGeneralInformation"
              [admissionOrder]="admissionOrder"
              (getGeneralInformation)="getGeneralInformation()"
              (saveGeneralInfo)="saveGeneralInfo()"
              [allowEdit]=allowEdit
              (basicLoading)="onLoading($event)"
              (fillRequiredFields) ="requiredFields($event)"
              >
            </app-basicinformation>
        </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="Приложение к диплому" [selected]=application [disabled]="getLoad() || blockTabstrip">
        <ng-template kendoTabContent>
            <app-applicationdiploma
              [studentId]="studentId"
              (saveGeneralInfo)="saveGeneralInfo()"
              (getBasicInformations)="getBasicInformations()">
            </app-applicationdiploma>
        </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="Дополнительные сведения" [disabled]="getLoad() || blockTabstrip">
        <ng-template kendoTabContent>
          <app-additionaldata
            [studentId]="studentId"
            [allowEdit] = allowEdit
            (saveGeneralInfo)="saveGeneralInfo()"
            [error]="error">
          </app-additionaldata>
        </ng-template>
    </kendo-tabstrip-tab>

</kendo-tabstrip>
