<kendo-stacklayout [gap]="20" orientation="vertical" class="sesssion-date">
  <h2 class="sesssion-date__title">Индивидуальные сроки сессии</h2>
  <h2 class="sesssion-date__fio">{{studentName}} ({{groupName}})</h2>
  <div class="sesssion-date__info">
    <p>
      Если дисциплины не указаны, то идивидуальный срок является общим на весь
      семестр. Не допускается одновременное использование общего индивидуального
      срока и срока по дисциплине.
    </p>
    <p>
      В сроках индувидуальной сессии студента указывается диапазон дат начала и
      конца сроков сессии включительно
    </p>
  </div>

  <div class="sesssion-date__table">
    <kendo-grid
      class="table"
      scrollable="virtual"
      [data]="gridData"
      [loading]="isLoading"
      #grid
      (cellClick)="editHandler($event)"
      (cellClose)="closeRow($event)"
    >
      <ng-template kendoGridLoadingTemplate>
        <div class="table__loader">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column
        title="Дата начала"
        [width]="170"
        [headerClass]="'table__header'"
        [class]="'table__cell'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells">
            {{dataItem.sessionBegin | date: 'dd.MM.yyyy':'GMT'}}
          </div>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem let-formGroup="formGroup">
          <div class="table__cell table__cell--custom table__cell--input">
            <kendo-datepicker
                    calendarType="infinite"
                    [formControl]="formGroup.get('sessionBegin')"
            ></kendo-datepicker>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        title="Дата окончания"
        [width]="170"
        [headerClass]="'table__header'"
        [class]="'table__cell'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells">
            {{dataItem.sessionEnd | date: 'dd.MM.yyyy':'GMT'}}
          </div>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem let-formGroup="formGroup">
          <div class="table__cell table__cell--custom table__cell--input">
            <kendo-datepicker
                    calendarType="infinite"
                    [formControl]="formGroup.get('sessionEnd')"
            ></kendo-datepicker>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        title="Номер распоряжения"
        [width]="190"
        [headerClass]="'table__header'"
        [class]="'table__cell'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells">
            {{dataItem.orderNumber}}
          </div>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem let-formGroup="formGroup">
          <div class="table__cell table__cell--custom table__cell--input">
            <kendo-textbox [formControl]="formGroup.get('orderNumber')"> </kendo-textbox>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        title="Дата распоряжения"
        [width]="170"
        [headerClass]="'table__header'"
        [class]="'table__cell'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells">
            {{dataItem.orderDate | date: 'dd.MM.yyyy':'GMT'}}
          </div>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem let-formGroup="formGroup">
          <div class="table__cell table__cell--custom table__cell--input">
            <kendo-datepicker
                    calendarType="infinite"
                    [formControl]="formGroup.get('orderDate')"
            ></kendo-datepicker>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        title="Основание продления"
        [width]="220"
        [headerClass]="'table__header'"
        [class]="'table__cell'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells">
            {{dataItem.orderReason}}
          </div>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem let-formGroup="formGroup">
          <div class="table__cell table__cell--custom table__cell--input">
            <kendo-textbox [formControl]="formGroup.get('orderReason')"> </kendo-textbox>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        title="Дисциплины"
        [width]="425"
        [headerClass]="'table__header'"
        [class]="'table__cell'"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
          <div class="alignCells">
            {{dataItem.studentSessionDiscipline.name}}
          </div>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem let-formGroup="formGroup">
          <div class="table__cell table__cell--custom table__cell--input">
            <kendo-dropdownlist
                    [data]="disciplineList"
                    [formControl]="formGroup.get('studentSessionDiscipline')"
                    [(value)]="dataItem.studentSessionDiscipline"
                    textField="name"
                    valueField="id"
            >
            </kendo-dropdownlist>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        title=""
        [width]="123"
        [headerClass]="'table__header'"
        [class]="'table__cell'"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div class="table__cell table__cell--custom table__cell--text">
            <button
              kendoButton
              class="table__button"
              (click)="deleteItem(rowIndex)"
            >
              Удалить
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <button
      kendoButton
      (click)="addItem()"
      fillMode="outline"
      icon="plus"
      class="button button--add"
    >
      Добавить
    </button>
  </div>
  <div class="sesssion-date__buttons">
    <button kendoButton (click)="handleClose()">Отмена</button>
    <button kendoButton class="button button--OK" (click)="handleSave()">
      Сохранить
    </button>
  </div>
</kendo-stacklayout>