import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AddEvent, CellClickEvent, GridComponent, PageSizeItem, RemoveEvent} from "@progress/kendo-angular-grid";
import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder, FormGroup } from "@angular/forms";
import { MilitaryProfilesService } from 'src/app/services/contingent/militaryprofiles.service';
import { MilitaryProfiles } from 'src/app/models/contingent/militaryprofiles.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { openRemoveDialog } from 'src/app/helpers/dialogHelper';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { isChildOf } from "src/app/helpers/elementRef-helper";
import {ContingentUserAccessService} from "../../../../services/useraccess/contingent-user-access.service";

@Component({
    selector: 'app-militaryprofiles',
    templateUrl: './militaryprofiles.component.html',
    styleUrls: ['./militaryprofiles.component.scss']
  })

export class MilitaryProfilesComponent implements OnInit {

    public militaryprofiles: MilitaryProfiles[] = [];

    public pageSize = 10;
    public sizes:(number | PageSizeItem)[] = [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50}, {text: 'Все', value : 'all'}];

    public formGroup: UntypedFormGroup | undefined;
    @ViewChild(GridComponent) private grid!: GridComponent;

    public get isInEditingMode(): boolean {
        return this.editedRowIndex !== undefined || this.isNew;
    }
    private editedRowIndex: number | undefined;
    private isNew = false;
    private isLine = false;

    public militaryProfilesId: string = "";

    constructor(
        private militaryProfilesService: MilitaryProfilesService,
        private notificationService: NotificationsService,
        private userAccessService: ContingentUserAccessService,
        private dialogService: DialogService,
        private renderer: Renderer2,
    ) {}

    public ngOnInit(): void {
        this.getAccessLevel();
        this.getMilitaryProfiles();

        this.renderer.listen("document", "click", ({ target }) => {
            if (!isChildOf(target, "k-grid")) {
              this.saveCurrent();
            }
        });
    }

    public getMilitaryProfiles() {
        this.militaryProfilesService.getMilitaryProfiles()
            .subscribe(response =>{
                this.militaryprofiles = response;
            })
    }

    public editHandler({
            sender,
            columnIndex,
            rowIndex,
            dataItem,
        }: CellClickEvent): void {
        if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
             return;
        }

        if(!this.editable) return
        this.isLine = true;
        this.formGroup = formGroup(dataItem);
        this.editedRowIndex = rowIndex;
        this.militaryProfilesId = dataItem.dictMilitaryProfileExternalId;

        sender.editRow(rowIndex, this.formGroup, { columnIndex });
    }

    public addHandler ({ sender }: AddEvent): void {
        this.closeEditor(sender);

        this.formGroup = formGroup({
            militaryProfileName: "",
        });

        this.isLine = true;
        this.isNew = true;
        sender.addRow(this.formGroup);
    }

    public removeHandler({ dataItem }: RemoveEvent): void {
        const dialog: DialogRef = openRemoveDialog(this.dialogService, "категорию " + dataItem.militaryProfileName.toLowerCase(), 450, 200, 250);

        dialog.result.subscribe((result) => {
            if (result instanceof DialogCloseResult) {
            } else {
              if(result.text == "Да"){
                this.militaryProfilesService.deleteMilitaryProfiles(dataItem.dictMilitaryProfileExternalId)
                .subscribe(
                  (response) => {
                    this.getMilitaryProfiles();
                    this.notificationService.showSuccess("Удалено");
                  },
                  (error) => {
                    this.notificationService.showError(error.error);
                  }
                );
              }
            }
        });
    }

    public saveCurrent() {
        if (this.formGroup && !this.formGroup.valid) return;

        this.isLine = false;
        this.saveRow();
    }

    private saveRow(): void {
        if (this.formGroup == undefined) return;

        if (this.isInEditingMode) {
            //Adding new entry to dictionary
            if (this.isNew == true) {
                this.militaryProfilesService.addMilitaryProfiles(this.formGroup.value)
                .subscribe(
                    response => {
                        this.getMilitaryProfiles();
                        this.notificationService.showSuccess("Добавлено");
                    },
                    error => {
                        this.notificationService.showError("Не удалось сохранить запись");
                    }
                );
            }
            else {
                //Update
                this.militaryProfilesService.updateMilitaryProfiles(this.formGroup.value, this.militaryProfilesId)
                    .subscribe(
                    response => {
                        this.getMilitaryProfiles();
                        this.notificationService.showSuccess("Сохранено");
                    },
                    error => {
                        this.notificationService.showError("Не удалось изменить запись");
                    }
                    );
            }
        }

        this.closeEditor(this.grid, this.editedRowIndex)
    }

    public editable?: boolean

    public getAccessLevel() {
      this.userAccessService.getAccessLevel()
        .subscribe({
          next:(response) => {
            this.editable = response.dictAccessLevel != 1
          },error:() => {}});
    }

    public cancelHandler() {
        this.closeEditor(this.grid);
    }

    private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
        grid.closeRow(rowIndex);
        this.isNew = false;
        this.isLine = false;
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }

}

const formGroup = (dataItem: { militaryProfileName: string; order?: number;}) =>
  new UntypedFormGroup({
    order: new UntypedFormControl(dataItem.order),
    militaryProfileName: new UntypedFormControl(dataItem.militaryProfileName, Validators.required),
  });
