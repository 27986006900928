export enum FilterEduGroupNameEnum {
  DictTrainingLevel = 0,
  Faculty,
  EducationStandard,
  FacultyEducationPlan,
  EducationProgram,
  DictStudyForm,
  DictAcceleratedStudyBase,
  CourseLessProfilingCourse,
}
