import { DictFilial } from "./dictfilial.model";
import { StudPerson, StudPersonCont } from "./studperson.model";
import { StudentSemestr } from "./studentsemestr.model";
import { Identifications, IdentificationsContDto } from "./identifications.model";
import {Citizenship} from "./citizenship.model";
import { StudentBudgetState, StudentBudgetStateCont } from "./studentbudgetstate.model";
import { StudEduGroup } from "./studedugroup.model";
import { Student } from "./student.model";
import { StudentAcademicState } from "./studentacademicstate.model";
import { Languages } from "./languages.model";
import { Moment } from "moment";
import { OrdersStudyPeriod } from "./ordersstudyperiod.model";
import { Orders } from "./orders.model";
import { StudPlan } from "./studplan.model";
import { SortDescriptor } from "@progress/kendo-data-query";
import { LocalizedNumericTextBoxMessagesDirective } from "@progress/kendo-angular-inputs";
import { Military, MilitaryCont } from "./military.model";
import { AddressCont, Addresses } from "./addresses.model";
import { FamilyMembers } from "./familymembers.model";
import { EduGroup, EduGroupCont } from "./edugroup.model";
import { EducationDocCont } from "./educationdocs.model";
import { AdditionalFields } from "./additionalfields.model";
import { Guid } from "guid-typescript";
import {OrdersCategory} from "./orderscategory.model";

export class Contingent{
  public studentId!: number;
  public studentExternalId?: Guid;
  public studentNumber!:string;
  public studPersonId!: number;
  public studPersons!: StudPerson;
  public studentSemestr!: StudentSemestr;
  public course!: number;
  public groupName?: string;
  public studyForm?: string;
  public studyFormId?: string;
  public studentBudgetState!: StudentBudgetState;
  public studentAcademicState!: StudentAcademicState;
  public studentBudget!: string;
  public facultyName?: string;
  public ball?: string;
  public email?: string;
  public phone?: string;
  public phoneFormatted?: string;
  public studEduGroup!: StudEduGroup;
  public studEduGroupId?: number;
  public eduGroups?: EduGroup;
  public eduGroup?: EduGroup;
  public identifications!: Identifications;
  public dictCitizenship!: Citizenship;
  public languages!: Languages[];
  public ordersStudyPeriod?: OrdersStudyPeriod;
  public studPlan?: StudPlan;
  public military?: Military;
  public familyMember?: FamilyMembers;
  public health?: AdditionalFields;
  public compatriot?: AdditionalFields;
  public familyState?: AdditionalFields;
  public sportType?: AdditionalFields;
  public sportCategory?: AdditionalFields;
  public benefit?: AdditionalFields;
  public deletedStudent?: boolean;
  public courseNum?:number;
  public editable?: boolean;
  public elder?: boolean;
  public studentCount?: number;
  public parallelStudy?: ParallelStudyDto[];
  public citizenshipType?: AdditionalFields;
  public isCompatriot?: string;
  public birthPlace?: string;
  public semesterResult?: number;
}

export class ParallelStudyDto{
  public groupName!: string
  public academicState!: string
}

export class ContingentBack{
  public back?:boolean
  public apply?:boolean
}

export class ContingentAllStudent{
  public studentId?: number;
  public studentExternalId!: Guid;
  public studentNumber?:string;
  public organizationName?:string;
  public postName?:string;
  public studentAcademicState?:string;
  public familyState?:string;
  public studentAcademicStates?:StudentAcademicState;
  public studPersons?:StudPersonCont;
  public eduGroups?: EduGroupCont;
  public military?: MilitaryCont;
  public studPlan?: StudPlan;
  public ordersStudyPeriod?: OrdersStudyPeriod;
  public studentBudgetState?:StudentBudgetStateCont;
  public educationDoc?:EducationDocCont;
  public identifications?:IdentificationsContDto;
  public studEduGroupExternalId?:Guid;
  public address?: AddressCont;
  public familyMember?: FamilyMemberCont;
  public actualAddress?: AddressCont;
  public ball?:string;
  public graduateYear?:string;
  public dateStart?:string;
  public medicalAvailability?:string;
  public medicalDate?:string;
  public scientificPersonName?:string;
  public hostelAvailability?:string;
  public hostel?:string;
  public hostelRoom?:string;
  public health?:string;
  public languages?:string;
  public benefit?:string;
  public benefitId?:string;
  public sportCategory?:string;
  public sportType?:string;
  public courseNum?: number;
  public achievementsCount?: number;
  public groupName?: string;
  public groupExternalId?: Guid;
  public facultyName?: string;
  public educationProgramName?: string;
  public educationStandardName?: string;
  public studyForm?: string;
  public studyLevel?: string;
  public trainingLevel?: string;
  public profilingCourseNumber?: number;
  public educationPlanId?: Guid;
  public groupPlanId?: Guid;
  public studPlanId?: Guid;
  public scientificPersonId?: Guid;
  public customerOrganizationId?: Guid;
  public employerOrganizationId?: Guid;
  public dictStateId?: Guid;
  public starostaId?: Guid;
  public filialId?: Guid;
  public studyLevelId?: Guid;
  public trainingLevelId?: Guid;
}

export class FamilyMemberCont{
  public memberStatus?: string;
  public fio?: string;
  public phoneNumber?: string;
  public address?: string;
  public familyMember?: string;
}

export class ContingentSearchStudent{
  public studentId?: number;
  public studentExternalId?: Guid;
  public fullName?: string;
  public birthday?: string;
  public educationStandardName?: string;
  public studentAcademicState?: string;
  public trainingLevel?: string;
}

export class ContingentSet{
  public students!: Student;
  public studentExternalId?: Guid;
  public studentId?: Guid;
  public studPersons!:StudPerson;
  public identifications!:Identifications;
  public studentBudgetState!:StudentBudgetState;
  public studentAcademicState!: StudentAcademicState;
  public languages?: [];
  public studEduGroup!: StudEduGroup;
  public studPlanId?:Guid;
  public studPlan?: StudPlan;
  public familyMemberId?:Guid;
  public familyMember?: FamilyMembers;
  public healthId?:number;
  public health?: string;
  public isCompatriotId?:number;
  public isCompatriot?: string;
  public familyStatusId?:number;
  public familyStatus?: string;
  public studPersonId?: string;
}

export class Filter {
  public contingentDate?: Date|Moment;
  public markDate?: Date|Moment;

  public fio?: string;
  public isMale?: boolean;
  public citizenshipId?: Guid[];
  public ageBegin?: Date|Moment;
  public ageEnd?: Date|Moment;
  public ageStart?: number;
  public ageFinish?: number;
  public orderBegin?: Date|Moment;
  public orderEnd?: Date|Moment;
  public orderStart?: Date|Moment;
  public orderFinish?: Date|Moment;
  public orderStartBegin?: Date|Moment;
  public orderStartEnd?: Date|Moment;
  public approveVAKDateBegin?: Date|Moment;
  public approveVAKDateEnd?: Date|Moment;
  public dissertationDateBegin?: Date|Moment;
  public dissertationDateEnd?: Date|Moment;
  public graduateYear?: number;
  public orderType?: Guid;
  public orderCategory?: Guid;
  public orderNumber?: string;
  public budgetCategory?: string;
  public budgetSubCategory?: string;
  public course?:number[];
  public group?:Guid[];
  public trainingLevel?: (Guid|string)[];
  public dictStudentAcademicStateId?: (Guid|string)[];
  public organizations?: string[];
  public regState?: string[];
  public scientificPersons?: string[];
  public dictMilitaryStatusId?: Guid;
  public dictMilitaryEnlistmentStatusId?: Guid;
  public commissariatId?: Guid[];
  public militaryEnlistmentCommissariatId?: Guid[];
  public healthId?: string;
  public faculties?: Guid[];
  public departments?: string[];
  public dictStudyFormId?: Guid[];
  public studyStart?: number;
  public studyFinish?: number;
  public sideFilterItems?: string[];
  public post?: string[];
  public work?: boolean;
  public isBudgetSelected?: (Guid|string)[];
  public organizationEmployerId?:number;
  public educationProgram?:Guid;
  public educationStandard?:string;
  public benefit?:Guid;
  public elder?:boolean;
  public directionActivityId?: (Guid|string)[];
  public hasAchievements?: boolean;
  public hasOrder?: boolean;
  public smartSearch?: string;
  public institution?: string;
  public thisInstitution?: boolean;
  public secondHigherEducation?: boolean;
  public employed?: boolean;
  public map?: boolean;
  public filial?: string;
}

export class Page{
  public count?: number;
  public pageNumber?: number;
  public pageSize!: number;
}

export class SavedFilters {
  public filter?: Filter;
  public tempHiddenColumns?: string[];
  public tempVisibleColumns?: string[];
}

export class ContingentRequest{
  public filter!: Filter;
  public page!: Page;
  public sort?: SortDescriptor[];
}

export class ContingentSelection {
  public studentId?: Guid;
  public studPersonId?: string;
  public studentAcademicStateId?: Guid;
  public studEduGroupId?: Guid;
  public ordersStudyPeriodId?: Guid;
  public budget?: StudPlan;
  public studPlanId?: Guid;
  public course?: CourseChangeDto;
  public currentCourseNum?: number;
  public ordersStudyPeriod?: OrdersStudyPeriod;
  public studyPeriodStart?: any;
  public studyPeriodFinish?: any;
  public courseNum?: number;
  //Old Data
  public oldDictStudentAcademicStateId?: Guid;
  public oldEduGroupId?: Guid;
  public oldEducationPlanId?: Guid;
  public oldBudgetId?: Guid;
  public oldOrganizationId?: Guid;
  public oldOrdersStudyPeriodId?: Guid;
  public oldDateBegin?: Date | null;
  public oldDateEnd?: Date | null;
  public oldMilitaryStatusId?: string;
  public oldBenefitId?: string;
  public oldScientificPersonId?: Guid;
  public oldCustomerOrganizationId?: Guid;
  public oldEmployerOrganizationId?: Guid;
  public oldDictStateId?: Guid;
}

export class ContingentMassOperationDto {
  public orders?: OrdersMassDto;
  public dictStudentAcademicStateId?: string;
  public eduGroupId?: string;
  public planId?: string;
  public budgetId?: Guid;
  public organizationId?: Guid;
  public militaryStatusId?: string;
  public dictBenefitId?: string;
  public scientificPersonId?: Guid;
  public customerOrganizationId?: Guid;
  public employerOrganizationId?: Guid;
  public dictStateId?: Guid;
  public MilitaryFormPrintTemplateId?: Guid;
  public Number?: string;
  public RegistrationDate? : Date;
  public OrderId?: Guid;
  public IsEnrolled? : boolean;
  public DictMilitaryCommissariatId?: Guid;
  public MilitaryCommissariatAddress?: string;
  public DictMilitaryRankId?: Guid;
  public MilitarySpecialtyNumber?: string;
  public Signatories?: MilitaryFormTwoSignatoryDto[];
  //Author
  public author?: string;
}

export class MilitaryFormTwoSignatoryDto {
  public globalSignatoryId?: string;
  public fio?: string;
  public postName?: string;
}

export class ContingentSelectionSet{
  public contingentSelected?: ContingentSelection[];
}

export class CourseChangeDto{
  public firstSemesterBegin?:Date;
  public firstSemesterEnd?:Date;
  public secondSemesterBegin?:Date;
  public secondSemesterEnd?:Date;
  public educationPlanId?:Guid;
  public eduGroupId?:Guid;
  public courseNum?:number;
}

export class OrdersMassDto {
  public dictOrdersCategoryId?: Guid;
  public number?: string;
  public orderDate?: Date;
  public dateBegin?: Date;
  public dateEnd?: Date;
  public comment?: string;
  public orderSed?: string;
  public lastEdit?: Date;
  public index?: number;
  public fileFullName?: string;
  public file?: File;
}
