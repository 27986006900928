import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckableSettings } from '@progress/kendo-angular-treeview';
import { TrainingLevelsModel } from 'src/app/models/middlecontrol/trainingLevels.model';
import { SemesterYearsModel } from '../../../models/middlecontrol/semesterYears.model';
import { TreeCommonModel } from '../../../models/middlecontrol/trees.model';
import {DateModel} from "../../../models/middlecontrol/date.model";
import {CurrentDayEnd} from "../../../helpers/date-helper";
import { StatementSettingsService } from 'src/app/services/middlecontrol/statement-settings.service';
import { StatementSettings } from 'src/app/models/middlecontrol/statementSetting.model';
import { StatementSettingsEnum } from 'src/app/models/middlecontrol/enums/statement-setting.enum';

@Component({
  selector: 'app-middlecontrol',
  templateUrl: './middlecontrolhome.component.html',
  styleUrls: ['./middlecontrolhome.component.scss'],
})
export class MiddlecontrolHomeComponent implements OnInit {
  public selectedGroup: TreeCommonModel = {} as TreeCommonModel;
  public contingentDate: Date = CurrentDayEnd();
  public markDate: Date = CurrentDayEnd();
  public eduGroupId: string = '';
  public planId?: string;
  public facultyId: string = '';
  public controlActionName: string = '';
  public generalDisciplineId?: string;
  public disciplineId: string = '';
  public electiveDisciplineId: string | null = null;
  public studentId: string = '';
  public studentName: string = '';
  public groupName: string = '';

  public treeSettings: CheckableSettings = { enabled: false };
  public selectedDiscipline: string = '';

  public stateList: string[] = [
    'main',
    'session',
    'discipline',
    'retakeView',
    'sessionDatesEdit',
    'sessionEdit',
    'retakeEdit',
  ];
  public state: string = '';
  public lastLoaded: Date | null = null;
  public semester: SemesterYearsModel | null = null;
  public expandedKeys: string[] = [];
  public selectedKeys: string[] = [];
  public selectedTrainingLevel?: string[] = undefined;
  public showSigner: boolean = true;
  public showExamList: boolean = false;
  public choiceOfTeacher: boolean = true;
  public choiceOfSigner: boolean = true;
  public statementSettingsEnum = StatementSettingsEnum;

  constructor(
    private router: Router,
    private statementSettingsService: StatementSettingsService,
  ) {}

  ngOnInit(): void {
    this.getStatementSettings();
  }

  public onNavigateTo(path: string): void {
    localStorage.removeItem("middle_control_settings")
    this.router.navigate([`/middlecontrol/${path}`]);
  }

  public onSelectionTreeChange(group: TreeCommonModel) {
    if (group.id && this.selectedKeys.length) {
      this.selectedGroup = group;
      this.eduGroupId = group.id.toString().trim().split('_').slice(-1)[0];
      this.facultyId = group.id.toString().trim().split('_')[0];
      this.planId = group.planId;
      this.state = 'main';
    }
  }

  public onComeback(): void {
    this.state = 'main';
  }

  public onChangeSemester(value: SemesterYearsModel | null) {
    this.semester = value;
    this.state = ''
  }

  public onChangeDate(value: DateModel) {
    this.contingentDate = value.contingentDate;
    this.markDate = value.markDate;
  }

  public onChangeState(value: string) {
    this.state = value;
  }

  public editable?: boolean;
  public editableDiscipline?: boolean;
  public editableStudentSession?: boolean;
  public editableSheetApprove?: boolean;

  public onChangeEditable(value: boolean) {
    this.editable = value;
  }

  public onChangeEditableDiscipline(value: boolean) {
    this.editableDiscipline = value;
  }

  public onChangeEditableStudentSession(value: boolean) {
    this.editableStudentSession = value;
  }

  public onChangeEditableSheetApprove(value: boolean) {
    this.editableSheetApprove = value;
  }

  public onChangeDiscipline(discipline: {
    disciplineId: string;
    electiveDisciplineId: string | null;
  }) {
    this.disciplineId = discipline.disciplineId;
    this.electiveDisciplineId = discipline.electiveDisciplineId;
  }

  public onChangeStudentId(student: {
    studentId: string;
    studentName?: string;
    groupName?: string;
  }) {
    this.studentId = student.studentId;
    if (student.studentName) this.studentName = student.studentName;
    if (student.groupName) this.groupName = student.groupName;
  }

  public onChangeGeneralDisciplineId(discipline: {
    disciplineId?: string;
  }) {
    this.generalDisciplineId = discipline.disciplineId;
  }

  public getStatementSettings() {
    this.statementSettingsService.GetStatementSettings().subscribe(
      (response : StatementSettings) => {
        this.showExamList = response.statementSettings
          .find((item: any) => item.statementSettingsEnum === this.statementSettingsEnum.exam_list)?.value;
        this.showSigner = response.statementSettings
          .find((item: any) => item.statementSettingsEnum === this.statementSettingsEnum.vybor_podpisanta_svodnaya_vedomost)?.value;
        this.choiceOfTeacher = response.statementRadioSettings
          .find((item : any) => item.statementSettingsEnum === this.statementSettingsEnum.vybor_prepodavatelya)?.value === 'select_teacher';
        this.choiceOfSigner = response.statementRadioSettings
          .find((item : any) => item.statementSettingsEnum === this.statementSettingsEnum.vybor_podpisanta_vedomost_po_discipline)?.value === 'select_signer';
      }
    );
  }
}
