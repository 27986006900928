<kendo-grid
  [data]="gridData"
  class="table"
  scrollable="virtual"
  [loading]="isLoading"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="table__loader">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <kendo-grid-column
    title="№"
    [sticky]="true"
    [width]="50"
    [class]="'table__cell'"
    [headerClass]="'table__header'"
  >
    <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">{{
      rowIndex + 1
    }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    title="ФИО"
    [sticky]="true"
    [width]="200"
    [class]="'table__cell table__cell--fio'"
    [headerClass]="'table__header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        [class]="{ 'table__cell--marked': dataItem.isMarked }"
        (click)="navigateTo(dataItem.studentId)"
      >
        {{ dataItem.fio }}
      </p></ng-template
    >
  </kendo-grid-column>
  <kendo-grid-column
    title="№ зачетной книжки"
    field="studentNumber"
    [headerClass]="'table__header'"
    [width]="80"
    [class]="'table__cell table__cell--custom--left'"
  ></kendo-grid-column>
  <kendo-grid-column
    field="studentBudget"
    [width]="80"
    [headerClass]="'table__header table__header--group table__header--vertical'"
    [class]="'table__cell'"
  >
    <ng-template kendoGridHeaderTemplate>
      <div class="table__subheader table__subheader--cursor-default">
        <p>Источник финансирования</p>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column-group
    title="{{ controlActionName }}"
    [headerClass]="'table__header'"
    *ngFor="let controlActionName of headerControlActions"
  >
    <kendo-grid-column
      [headerClass]="'table__header--group'"
      [width]="controlActions[controlActionName].length > 1 ? 60 : 80"
      [class]="'table__cell table__cell--custom'"
      *ngFor="let discipline of controlActions[controlActionName]"
    >
      <ng-template kendoGridHeaderTemplate>
        <div
          [class]="{
            table__subheader: true,
            'table__subheader--link': true,
            'table__subheader--complete': discipline.sheetStatus === 'проверена'
          }"
          (click)="
            openDiscipline(
              discipline.educationPlanDisciplineId,
              discipline.educationPlanElectiveDisciplineId
            )
          "
        >
          <p class="overflow">
            {{trimText((discipline.electiveName
            ? discipline.electiveName + " (" + discipline.name + ")"
            : discipline.name)
            + (discipline.isDistributed ? ", рассред." : ""))
            }}
          </p>
          <span class="exam-date" *ngIf="controlActionName === TypeWorkEnum.exam">
            {{(discipline.examDate == null ? "???" : discipline.examDate| date: "dd.MM")}}
          </span>
        </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <p
          [class]="{
            table__cell: true,
            'table__cell--height100': true,
            'table__cell--gray': getImplemented(dataItem, discipline),
            'text--bold': getNotInTime(dataItem, discipline)
          }"
          [style]="{
            color: getMarkProperty(dataItem, discipline, 'markColor')
          }"
        >
          {{ getMark(dataItem, discipline) }}
        </p>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column
    [headerClass]="'table__header table__header--group table__header--vertical'"
    [width]="130"
    [class]="'table__cell table__cell--custom'"
  >
    <ng-template kendoGridHeaderTemplate>
      <div class="table__subheader table__subheader--cursor-default">
        <p>Индивидуальные сроки сессии</p>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="table__session-cell">
        <div *ngIf="dataItem.sessionBegin">
          <p>{{ dataItem.sessionBegin }}&nbsp;-</p>
          <p>{{ dataItem.sessionEnd }}</p>
        </div>
        <button
          *ngIf="editableStudentSession"
          kendoButton
          icon="edit"
          fillMode="clear"
          (click)="openSessionDate(dataItem.studentId, dataItem.fio, group.text)"
        ></button>
      </div> </ng-template
  ></kendo-grid-column>
  <kendo-grid-column
    [headerClass]="'table__header table__header--group table__header--vertical'"
    [width]="44"
    [class]="'table__cell table__cell--custom'"
  >
    <ng-template kendoGridHeaderTemplate>
      <div class="table__subheader table__subheader--cursor-default">
        <p>Пересдачи</p>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        class="table__cell table__cell--height100 table__cell--cursor-pointer"
        (click)="openRetakeView(dataItem.studentId, dataItem.fio)"
      >
        {{ dataItem.retake }}
      </p>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
