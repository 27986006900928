import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import {
  AddEvent,
  GridComponent,
  RemoveEvent,
  CellClickEvent,
  CancelEvent,
  PagerPosition
} from '@progress/kendo-angular-grid';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {checkRole} from "../../../helpers/token/role-check";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Role} from "../../../models/useraccess/role";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {arrayRewrite, valueChangeBySourceName} from "../../../helpers/multiselect-helper";
import {
  LocalSignatoryEduRead,
  LocalSignatoryFacultyEduReadDto,
  LocalSignatoryFilialEduReadDto,
  LocalSignatoryKafedraReadDto,
  LocalSignatoryTrainingLevelEduReadDto
} from "../../../models/education/localsignatory.model";
import {GlobalSignatoryEduRead} from "../../../models/education/globalsignatory.model";
import {GlobalSignatoryEduService} from "../../../services/education/global-signatory.service";
import {LocalSignatoryEduService} from "../../../services/education/local-signatory.service";
import {EducationUserAccessService} from "../../../services/useraccess/education-user-access.service";
import {FacultyService} from "../../../services/education/Faculty/faculty.service";
import {TrainingLevelService} from "../../../services/education/TrainingLevel/traininglevel.service";
import {KafedraService} from "../../../services/education/Kafedra/kafedra.service";
import {PagerType} from "@progress/kendo-angular-pager";
import { FilialService } from '../../../services/education/Filial/filial.service';

@Component({
  selector: 'local-signatory',
  templateUrl: './local-signatory.component.html',
  styleUrls: ['./local-signatory.component.scss']
})
export class LocalSignatoryEduComponent implements OnInit {
  public globalSignatories: GlobalSignatoryEduRead[] = [];
  public localSignatories: LocalSignatoryEduRead[] = [];
  public kafedras: LocalSignatoryKafedraReadDto[] = [];
  public faculties: LocalSignatoryFacultyEduReadDto[] = [];
  public trainingLevels: LocalSignatoryTrainingLevelEduReadDto[] = [];
  public filials: LocalSignatoryFilialEduReadDto[] = [];

  public trainingLevelsEdit: any[] = [];
  public facultiesEdit: any[] = [];
  public filialsEdit: any[] = [];

  public formGroup: FormGroup | undefined;
  public editable: boolean = false;
  public isAdmin: boolean = false;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
  public localSignatoryRole: any[] = [
    {id: 1, text: 'Согласующий' },
    {id: 2, text: 'Утверждающий' }
  ]

  //#region Grid controls
  public position: "top" | "bottom" | "both" = "both";
  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;
  public opened = false;
  public loading = false;
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = [20, 50, 100];
  public previousNext = true;
  public positionPager: PagerPosition = 'bottom';
  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }
  //#endregion

  constructor(private globalSignatoryService: GlobalSignatoryEduService,
              private localSignatoryService: LocalSignatoryEduService,
              private trainingLevelService: TrainingLevelService,
              private facultyService: FacultyService,
              private kafedraService: KafedraService,
              private userAccessService: EducationUserAccessService,
              private renderer: Renderer2,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private filialService: FilialService,
              private tokenService: TokenStorageService,
              private jwtService: JwtHelperService
    ) {
    this.isAdmin = checkRole(tokenService, jwtService, Role.Admin);
  }

  public ngOnInit(): void {
    this.getAccessLevel();
    this.getKafedras();
    this.getFaculties();
    this.getTrainingLevels();
    this.getGlobalSignatories();
    this.getLocalSignatories();
    this.getFilials();

    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  //#region Get Data

  private getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe(response => this.editable = response.localSignatoryAccessLevel || this.isAdmin);
  }

  private getKafedras() {
    this.kafedras = [];
    this.kafedraService.getAllKafedras().subscribe(
      (response: any) => {
        for (let i = 0; i < response.length; i++) {
          let item = new LocalSignatoryKafedraReadDto();
          item.id = response[i].kafedraId;
          item.name = response[i].kafedraName;
          this.kafedras.push(item);
        }
      }
    );
  }

  private getFaculties() {
    this.faculties = [];
    this.facultyService.getAllFaculties().subscribe(
    (response: any) => {
        for (let i = 0; i < response.length; i++) {
          let item = new LocalSignatoryFacultyEduReadDto();
          item.facultyId = response[i].facultyId;
          item.facultyName = response[i].facultyName;
          item.facultyShortName = response[i].facultyShortName;
          this.faculties.push(item);
        }
      }
    );
    if (this.faculties) {
      let item = new LocalSignatoryFacultyEduReadDto();
      item.facultyId = '';
      item.facultyShortName = 'Все';
      this.faculties.unshift(item);
    }
  }

  private getTrainingLevels() {
    this.trainingLevels = [];
    this.trainingLevelService.getAllTrainingLevels().subscribe(
      response => {
        for (let i = 0; i < response.length; i++) {
          let item = new LocalSignatoryTrainingLevelEduReadDto();
          item.trainingLevelId = response[i].trainingLevelExternalId!;
          item.trainingLevelName = response[i].trainingLevelName;
          this.trainingLevels.push(item);
        }
      }
    );
    if (this.trainingLevels) {
      let item = new LocalSignatoryTrainingLevelEduReadDto();
      item.trainingLevelId = '';
      item.trainingLevelName = 'Все';
      this.trainingLevels.unshift(item);
    }
  }

  private getFilials() {
    this.filials = [];
    this.filialService.getAllFilials().subscribe(
      response => {
        for (let i = 0; i < response.length; i++) {
          let item = new LocalSignatoryFilialEduReadDto();
          item.filialId = response[i].filialId!;
          item.filialSName = response[i].filialShortName;
          this.filials.push(item);
        }
      }
    );
    if (this.filials) {
      let item = new LocalSignatoryFilialEduReadDto();
      item.filialId = '';
      item.filialSName = 'Все';
      this.filials.unshift(item);
    }
  }

  private getGlobalSignatories() {
    this.globalSignatoryService.get()
      .subscribe(
        response => {
          this.globalSignatories = response;
        }, error => {
          this.notificationService.showError("Не удалось получить список глобальных подписантов");
        });
  }

  private getLocalSignatories() {
    this.loading = true;
    this.localSignatoryService.get()
      .subscribe(
        response => {
          this.localSignatories = response;
          if (this.localSignatories) {
            for (let i = 0; i < this.localSignatories.length; i++) {
              valueChangeBySourceName(this.localSignatories[i].trainingLevels, "trainingLevelId", null, "");
              valueChangeBySourceName(this.localSignatories[i].trainingLevels, "trainingLevelName", null, "Все");
              valueChangeBySourceName(this.localSignatories[i].faculties, "facultyId", null, "");
              valueChangeBySourceName(this.localSignatories[i].faculties, "facultyName", null, "Все");
              valueChangeBySourceName(this.localSignatories[i].filials, "filialId", null, "");
              valueChangeBySourceName(this.localSignatories[i].filials, "filialSName", null, "Все");
            }
          }
          this.loading = false;
        }, error => {
          this.notificationService.showError("Не удалось получить список локальных подписантов");
          this.loading = false;
        });
  }

  //#endregion

  //#region Grid Event Handlers

  //Start Adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);
    this.trainingLevelsEdit = [];
    this.facultiesEdit = [];
    this.filialsEdit = [];

    this.formGroup = formGroup(new LocalSignatoryEduRead());
    this.isLine = true;
    this.isNew = true;

    sender.addRow(this.formGroup);
  }
  //Start Editing
  public editHandler({sender, isEdited, rowIndex, dataItem}: CellClickEvent): void {
    let filterTrainingLevels: any = [];
    let filterFaculties: any = [];
    let filterFilials: any = [];

    // Вывод уровня подготовки в редактирование
    for(var t = 0; t < dataItem.trainingLevels.length; t++) {
      for (var i=0; i < this.trainingLevels.length; i++) {
        if(this.trainingLevels[i].trainingLevelName === dataItem.trainingLevels[t].trainingLevelName)
        {
          filterTrainingLevels.push(this.trainingLevels[i])
        }
      }
    }

    // Вывод факультетов в редактирование
    if(dataItem.faculties)
      for(var t = 0; t < dataItem.faculties.length; t++) {
        for (var i=0; i < this.faculties.length; i++) {
          if(this.faculties[i].facultyId === dataItem.faculties[t].facultyId)
          {
            filterFaculties.push(this.faculties[i])
          }
        }
      }

    // Вывод филиалов в редактирование
    for(var t = 0; t < dataItem.filials.length; t++) {
      for (var i=0; i < this.filials.length; i++) {
        if(this.filials[i].filialSName === dataItem.filials[t].filialSName)
        {
          filterFilials.push(this.filials[i])
        }
      }
    }

    this.trainingLevelsEdit = filterTrainingLevels;
    this.facultiesEdit = filterFaculties;
    this.filialsEdit = filterFilials;

    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return
    if (this.isNew) {
      rowIndex += 1;
    }

    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }
  //Cancel
  public cancelHandler({ sender, rowIndex }: CancelEvent): void {
    this.closeEditor(sender, rowIndex);
  }
  public btnCancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }
  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.localSignatoryService.delete(dataItem.id)
          .subscribe(
            ( response: any) => {
              this.getLocalSignatories();
              this.notificationService.showSuccess("Удалено");
            },
            error => {
            this.notificationService.showError(error.error);
          }
          );
        } else{
          this.opened = false;
        }
      }
    });
  }
  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
  public saveHandler(event: any) {
    //console.log('save', event);
  }

  //#endregion

  //#region Helper methods

  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  private saveRow(): void {
    if (!this.isInEditingMode) {
      this.closeEditor(this.grid);
      return;
    }

    if (!this.formGroup) return;

    let dataItem = this.formGroup.value;
    let data = {} as any;
    if (dataItem.id)
      data.id = dataItem.id;
    data.globalSignatoryId = dataItem.globalSignatory.id;
    data.graduationKafedraId = dataItem.graduationKafedra?.id;
    data.trainingLevels = dataItem.trainingLevels.map(function (data: any) { return data.trainingLevelId == "" ? null : data.trainingLevelId; });
    data.faculties = [null];//dataItem.faculties.map(function (data: any) { return data.facultyId == "" ? null : data.facultyId; });
    data.localSignatoryRole = dataItem.localSignatoryRole;
    data.filials = dataItem.filials.map(function (data: any) { return data.filialId == "" ? null : data.filialId; });
    if (dataItem.localSignatoryRole == 1)
      data.ordinal = dataItem.ordinal;

    if (this.isNew == true && this.formGroup !== undefined) {
      this.localSignatoryService.add(data)
        .subscribe(
          response => {
            this.getLocalSignatories();
            this.notificationService.showSuccess("Добавлено");
          },
          error => {
            this.notificationService.showError("Не удалось добавить запись");
          }
        );
    }
    else if (this.formGroup !== undefined) {
      this.localSignatoryService.update(data)
        .subscribe(
          response => {
            this.getLocalSignatories();
            this.notificationService.showSuccess("Обновлено");
          },
          error => {
            console.log(error);
            this.notificationService.showError("Не удалось изменить запись");
          }
        );
    }

    this.closeEditor(this.grid);
  }

  public getTrainingLevelName(data: any): string {
    let name = '';
    for (let i = 0; i < data.length; i++) {
      name += data[i].trainingLevelName + (i + 1 < data.length ? ', ' : '');
    }
    return name;
  }

  public getFilialName(data: any): string {
    let name = '';
    for (let i = 0; i < data.length; i++) {
      name += data[i].filialSName + (i + 1 < data.length ? ', ' : '');
    }
    return name;
  }

  public getFacultyName(data: any): string {
    let name = '';
    for (let i = 0; i < data.length; i++) {
      name += data[i].facultyName + (i + 1 < data.length ? ', ' : '');
    }
    return name;
  }

  public getLocalSignatoryRoleName(localSignatoryRoleId: any): string | undefined {
    let localSignatoryRole = this.localSignatoryRole.find(_ => _.id === localSignatoryRoleId);
    return localSignatoryRole?.text;
  }

  //#endregion

  //#region DropDownList Events

  public facultyValueChange(value: any, dataItem: any) {
    dataItem.faculties = arrayRewrite(dataItem.faculties, "facultyId");
    this.facultiesEdit = arrayRewrite(value, "facultyId");
  }

  public trainingLevelValueChange(value: any, dataItem: any) {
    dataItem.trainingLevels = arrayRewrite(dataItem.trainingLevels, "trainingLevelId");
    this.trainingLevelsEdit = arrayRewrite(value, "trainingLevelId");
  }

  public filialValueChange(value: any, dataItem: any) {
    dataItem.filials = arrayRewrite(dataItem.filials, "filialId");
    this.filialsEdit = arrayRewrite(value, "filialId");
  }

  //#endregion
}

const formGroup = (dataItem: LocalSignatoryEduRead) => new FormGroup({
  id: new FormControl(dataItem.id),
  graduationKafedra: new FormControl(dataItem.graduationKafedra/*, Validators.required*/),
  trainingLevels: new FormControl(dataItem.trainingLevels, Validators.required),
  faculties: new FormControl(dataItem.faculties/*, Validators.required*/),
  globalSignatory: new FormControl(dataItem.globalSignatory, Validators.required),
  localSignatoryRole: new FormControl(dataItem.localSignatoryRole, Validators.required),
  ordinal: new FormControl(dataItem.ordinal),
  filials: new FormControl(dataItem.filials, Validators.required),
});

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);
const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

