import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {DictAcceleratedStudyBaseName} from "../../models/contingent/dict-accelerated-study-base.model";

@Injectable({
  providedIn: 'root'
})
export class DictAcceleratedStudyBaseService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictacceleratedstudybase}`;

  constructor(private http: HttpClient) { }

  public getDictAcceleratedStudyBaseNames(): Observable<DictAcceleratedStudyBaseName[]> {
    return this.http.get<DictAcceleratedStudyBaseName[]>(this.baseUrl  + '/GetNameList');
  }
}
