import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {BehaviorSubject, catchError, lastValueFrom, Observable, of} from 'rxjs';
import { environment } from '../../../environments/environment';
import { SemesterYearsModel } from '../../models/middlecontrol/semesterYears.model';
import { StudyLevelsModel } from '../../models/middlecontrol/studyLevels.model';
import { TrainingLevelsModel } from '../../models/middlecontrol/trainingLevels.model';
import { PersonsModel } from '../../models/middlecontrol/persons.model';
import { markReason, MarksModel } from '../../models/middlecontrol/marks.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import {StudyFormModel} from "../../models/middlecontrol/studyform.model";
import {FacultyModel} from "../../models/middlecontrol/faculty.model";
import {KafedraModel} from "../../models/middlecontrol/kafedra.model";
import { DictStatementSettingsCategory } from 'src/app/models/middlecontrol/dictStatementSettingsCategory.model';
import {DictFilial} from "../../models/middlecontrol/dictfilial.model";
import { DictSignatoryRole } from '../../models/middlecontrol/dictSignatoryRole.model';

@Injectable({
  providedIn: 'root',
})
export class DictsService {
  baseUrl = `${environment.apiEndpoint}`;

  public semesterYears$ = new BehaviorSubject<SemesterYearsModel[]>([]);
  public studyLevels$ = new BehaviorSubject<StudyLevelsModel[]>([]);
  public trainingLevels$ = new BehaviorSubject<TrainingLevelsModel[]>([]);
  public studyForms$ = new BehaviorSubject<StudyFormModel[]>([]);
  public faculty$ = new BehaviorSubject<FacultyModel[]>([]);
  public kafedra$ = new BehaviorSubject<KafedraModel[]>([]);
  public persons$ = new BehaviorSubject<PersonsModel[]>([]);
  public marks$ = new BehaviorSubject<MarksModel[]>([]);
  public marksByDiciplineType$ = new BehaviorSubject<MarksModel[]>([]);
  public filials$ = new BehaviorSubject<DictFilial[]>([]);
  public dictSheetStatus = {
    Empty: { id: 'D4B358FB-4A95-4E5D-8E8C-74E9DD1C409A', name: 'Не заполнена' },
    Filled: { id: '0E0EE86C-20B3-47F8-94AD-21E7EAB47227', name: 'Заполнена' },
    Approved: { id: '61625E7D-68C9-4DA8-BBC7-C41A31D8381D', name: 'Проверена' },
  };
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) {}

  public getSemesterYears(): Observable<SemesterYearsModel[]> {
    return this.http
      .get<SemesterYearsModel[]>(
        this.baseUrl + environment.apiPaths.session.semesteryears
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось получить список семестров');
          return of([]);
        })
      );
  }

  public async updateDictSemesterYears() {
    await lastValueFrom(this.getSemesterYears()).then((response) => {
      this.semesterYears$.next(response);
    });
  }

  public getStudyLevels(): Observable<StudyLevelsModel[]> {
    return this.http
      .get<StudyLevelsModel[]>(
        this.baseUrl + environment.apiPaths.session.studylevels
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список уровней образования'
          );
          return of([]);
        })
      );
  }

  public updateStudyLevels() {
    this.getStudyLevels().subscribe((response) => {
      this.studyLevels$.next(response);
    });
  }

  public getTrainingLevels(): Observable<TrainingLevelsModel[]> {
    return this.http
      .get<TrainingLevelsModel[]>(
        this.baseUrl + environment.apiPaths.session.traininglevels
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список уровней подготовки'
          );
          return of([]);
        })
      );
  }

  public async updateTrainingLevels() {
    await lastValueFrom(this.getTrainingLevels()).then((response) => {
      this.trainingLevels$.next(response);
    });
  }

  public getFilial(): Observable<DictFilial[]> {
    return this.http
      .get<DictFilial[]>(
        this.baseUrl + environment.apiPaths.session.filials
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список филиалов'
          );
          return of([]);
        })
      );
  }

  public async updateFilial() {
    await lastValueFrom(this.getFilial()).then((response) => {
      this.filials$.next(response);
    });
  }

  public getStudyForms(): Observable<StudyFormModel[]> {
    return this.http.get<StudyFormModel[]>(
      this.baseUrl + environment.apiPaths.session.studyforms
    );
  }

  // TODO: тест
  public getStudyFormsTest(): Observable<{ id: string, name: string }[]> {
    return this.http.get<{ id: string, name: string }[]>(
      this.baseUrl + environment.apiPaths.session.studyforms
    );
  }

  public updateStudyForms() {
    this.getStudyForms().subscribe((response) => {
      this.studyForms$.next(response);
    });
  }

  public getFaculty(): Observable<FacultyModel[]> {
    return this.http.get<FacultyModel[]>(
      this.baseUrl + environment.apiPaths.session.faculty
    );
  }

  public updateFAculty() {
    this.getFaculty().subscribe((response) => {
      this.faculty$.next(response);
    });
  }

  public getKafedra(): Observable<KafedraModel[]> {
    return this.http.get<KafedraModel[]>(
      this.baseUrl + environment.apiPaths.session.kafedra
    );
  }

  public updateKafedra() {
    this.getKafedra().subscribe((response) => {
      this.kafedra$.next(response);
    });
  }

  public getPersons(filterValue: string): Observable<PersonsModel[]> {
    return this.http
      .get<PersonsModel[]>(
        this.baseUrl +
          environment.apiPaths.session.persons +
          `?searchString=${filterValue}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список сотрудников'
          );
          return of([]);
        })
      );
  }

  public updatePersons(filterValue: string) {
    this.getPersons(filterValue).subscribe((response) => {
      this.persons$.next(response);
    });
  }

  public getMarks(): Observable<MarksModel[]> {
    return this.http
      .get<MarksModel[]>(this.baseUrl + environment.apiPaths.session.marks)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить словарь для оценок'
          );
          return of([]);
        })
      );
  }

  public updateMarks() {
    this.getMarks().subscribe((response) => {
      this.marks$.next(response);
    });
  }

  public getAllPostsPerson(personId: string) {
    return this.http
      .get<string[]>(
        this.baseUrl + environment.apiPaths.session.personPosts + `/${personId}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить должность сотрудника'
          );
          return of([]);
        })
      );
  }

  public filterMarksByDisciplineType(type: string) {
    const marks = this.marks$.getValue();
    const gradeLevelList = marks.filter((item) =>
      type === 'Зачет'
        ? item.markValue < 1 || item.markValue === 6
        : item.markValue !== 0 && item.markValue !== 6
    );
    this.marksByDiciplineType$.next(gradeLevelList);
  }

  public getMarkReasons() {
    return this.http
      .get<markReason[]>(this.baseUrl + environment.apiPaths.session.reasons)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список причин изменения оценки'
          );
          return of([]);
        })
      );
  }

  public GetStatementSettingCategory(): Observable<DictStatementSettingsCategory[]> {
    return this.http
      .get<DictStatementSettingsCategory[]>(this.baseUrl+ environment.apiPaths.session.statementSettingsCategory);
  }

  public getDictSignatoryRole(): Observable<DictSignatoryRole[]> {
    return this.http.get<DictSignatoryRole[]>(this.baseUrl + environment.apiPaths.session.signatoryRole);
  }
}
