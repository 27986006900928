import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import {  Observable } from "rxjs";
import { DictSignatoryRole } from "../../models/contingent/dictSignatoryRole.model";

@Injectable({
  providedIn: "root"
})
export class DictSignatoryRoleService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.globalSignatoryRole}`;

  constructor(private http: HttpClient) {
  }

    public getDictSignatoryRole(): Observable<DictSignatoryRole[]> {
        return this.http.get<DictSignatoryRole[]>(this.baseUrl);
    }
}