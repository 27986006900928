import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { DialogService, DialogRef, DialogCloseResult } from "@progress/kendo-angular-dialog";
import { NotificationsService } from "src/app/services/notifications/notifications.service";
import { openRemoveDialog } from "src/app/helpers/dialogHelper";
import { WorkTypesModel, SubtypesModel } from "src/app/models/contingent/worktypes.model";
import {
    AddEvent,
    CancelEvent,
    EditEvent,
    RemoveEvent,
    SaveEvent,
    TreeListComponent,
} from "@progress/kendo-angular-treelist";
import { WorkTypesService } from "src/app/services/contingent/worktypes.service";
import { DictAchievementService } from 'src/app/services/contingent/dictachievement.service'
import { DictAchievements } from "src/app/models/contingent/dictachievement.model";
import {DictFilial} from "../../../../models/contingent/dictfilial.model";
import {DictFilter} from "../../../../models/contingent/filter.model";
import {DictFilialService} from "../../../../services/contingent/dictfilial.service";
import {ContingentUserAccessService} from "../../../../services/useraccess/contingent-user-access.service";


@Component({
    selector: 'app-worktypes',
    templateUrl: './worktypes.component.html',
    styleUrls: ['./worktypes.component.scss']
})

export class WorktypesComponent implements OnInit {

    public worktypes: WorkTypesModel[] = [];
    public worktypesByFilter: WorkTypesModel[] = [];
    public categoryAchievements: DictAchievements[] = [];
    public categoryAchievementsByFilter: DictAchievements[] = [];
    public filials: DictFilial[] = [];
    public secondFilter: DictFilter = {
      dictFilialExternalId: undefined
    }
    public subtypes: SubtypesModel[] = [];

    public pageSize = 10;
    public sizes = [10, 20, 50];
    public buttonCount = 5;
    public info = true;

    public formGroup: UntypedFormGroup = formGroup({
      name: "",
    });

    public defaultItemFilial: { filialSName: string; dictFilialExternalId: string | null } = {
      filialSName: "Не выбрано",
      dictFilialExternalId: null,
    };

    public editedItem: WorkTypesModel | undefined;
    public isParent: boolean = false;
    public newLine: boolean = false;

    constructor(
        private renderer: Renderer2,
        private notificationService: NotificationsService,
        private dialogService: DialogService,
        private workTypesService: WorkTypesService,
        private dictAchievementService: DictAchievementService,
        private dictFilialService: DictFilialService,
        private userAccessService: ContingentUserAccessService,
    ) { }

    public ngOnInit(): void {
      this.getAccessLevel();
      this.getWorkType();
      this.getAchievement();
      this.getAllFilials();
    }

    public getWorkType() {
      this.workTypesService.getWorkTypes()
        .subscribe(response => {
          this.worktypes = response;
          this.applyFilter();
        })
    }

    public getAchievement() {
      this.dictAchievementService.getCategoryAchievement()
          .subscribe(response => {
              this.categoryAchievements = response;
          })
    }

    public editHandler({ sender, dataItem}: EditEvent): void {
        if (this.newLine) {
          this.notificationService.showError('Отмените добавление записи');
          return;
        }
        if(!this.editable) return
        this.closeEditor(sender, this.editedItem);

        this.formGroup = formGroup(dataItem);
        this.editedItem = dataItem;
        sender.editRow(dataItem, this.formGroup);
    }

    public addHandler({ sender, dataItem, parent }: AddEvent): void {
        this.closeEditor(sender, this.editedItem);
        this.newLine = true;

        if (parent) {
            sender.expand(parent);
            this.isParent = true;
        }

        this.formGroup = formGroup({
            name: "",
            directionActivityId: parent ? parent.directionActivityId : "",
        });

        sender.addRow(this.formGroup, parent);
    }

    public removeHandler({ sender, dataItem, parent }: RemoveEvent): void {
        if (this.newLine) {
          this.notificationService.showError('Отмените добавление записи');
          return;
        }
        this.closeEditor(sender, this.editedItem);

        const dialog: DialogRef = openRemoveDialog(this.dialogService, dataItem.name.toLowerCase(), 450, 200, 250);

        dialog.result.subscribe((result) => {
          if (result instanceof DialogCloseResult) {
          } else {
            if(result.text == "Да") {

              this.workTypesService.deleteWorkTypes(dataItem.id)
                .subscribe({
                  next:() => {
                    this.getWorkType();
                    this.notificationService.showSuccess("Удалено");
                  },
                  error:(error) => {
                    this.notificationService.showError(error.error);
                  }});
            }
          }
        });
    }

    public saveHandler({
        sender,
        dataItem,
        parent,
        formGroup,
        isNew,
      }: SaveEvent): void {

        if (this.formGroup == undefined) return;

        if (this.formGroup.value.mark == null) this.formGroup.value.mark = 0;

        if (isNew) {
          if (parent) {
            this.formGroup.value.parentId = parent.id;
          }

          this.workTypesService.addWorkTypes(this.formGroup.value)
            .subscribe({
              next:() => {
                this.getWorkType();
                this.notificationService.showSuccess("Добавлено");
              },
              error:() => {
                this.notificationService.showError(Error);
              }}
            );
        } else {
          this.formGroup.value.id = dataItem.id;
          this.workTypesService.updateWorkTypes({ ...this.formGroup?.value, ...this.secondFilter })
          .subscribe({
            next:() => {
              this.getWorkType();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError(Error);
            }}
          );
        }

      this.closeEditor(sender, dataItem, isNew);
    }

    public getAllFilials() {
      this.dictFilialService.getAllfilial()
        .subscribe(
          response => {
            this.filials = response;
          }
        );
    }

    public editable?: boolean

    public getAccessLevel() {
      this.userAccessService.getAccessLevel()
        .subscribe({
          next:(response) => {
            this.editable = response.dictAccessLevel != 1
          },error:() => {}});
    }

    public cancelHandler({ sender, dataItem, isNew }: CancelEvent): void {
      this.closeEditor(sender, dataItem, isNew);
    }

    private closeEditor( treelist: TreeListComponent, dataItem?: WorkTypesModel, isNew = false): void {
        treelist.closeRow(dataItem, isNew);
        this.editedItem = undefined;
        this.isParent = false;
        this.newLine = false;
        this.formGroup = formGroup({
          name: "",
        });
        this.getWorkType();
    }

    public getActivityName(id: string) {
      return this.categoryAchievements.find(el => el.id == id)?.name;
    }

    public valueChangeDirectionActivity(id: string) {
      this.subtypes = this.worktypes.filter(el => el.parentId == this.editedItem?.id);
    }

    public applyFilter() {
      this.categoryAchievementsByFilter = this.categoryAchievements.filter((item: DictAchievements) => item.filialId == this.secondFilter.dictFilialExternalId);
      this.worktypesByFilter = this.worktypes.filter((item: WorkTypesModel) => this.categoryAchievementsByFilter.find((x: DictAchievements) => x.id == item.directionActivityId));
    }

    private getTree(array: SubtypesModel[], id: string) {
      array.forEach((el: any) => {
        el.directionActivityId = id;
        el.items = this.worktypes.filter(item => item.parentId == el.id);
        this.getTree(el.items, id);
      })
    }

}

const formGroup = (dataItem: {name: string; mark?: number; order?: number; parentId?: string; tooltip?: string; workValidity?: number; directionActivityId?: string}) =>
  new UntypedFormGroup({
    name: new UntypedFormControl(dataItem.name, Validators.required),
    mark:  new UntypedFormControl(dataItem.mark),
    order: new UntypedFormControl(dataItem.order),
    parentId: new UntypedFormControl(dataItem.parentId),
    tooltip: new UntypedFormControl(dataItem.tooltip),
    workValidity: new UntypedFormControl(dataItem.workValidity),
    directionActivityId: new UntypedFormControl(dataItem.directionActivityId, Validators.required),
  });
