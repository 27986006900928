import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HorizontalAlign } from '@progress/kendo-angular-layout';
import { ListBoxToolbarConfig } from '@progress/kendo-angular-listbox';
import { ContingentBack } from 'src/app/models/contingent/contingent.model';
import { FilterSelect } from 'src/app/models/contingent/filterselect.model';
import { listbox, selectlistbox } from 'src/app/models/contingent/listbox-items';
import { PersistanceService } from 'src/app/services/contingent/persistance.service';

@Component({
  selector: 'app-contingentsettings',
  templateUrl: './contingentsettings.component.html',
  styleUrls: ['./contingentsettings.component.scss']
})
export class ContingentsettingsComponent implements OnInit {

  public hiddenColumns: string[] = [];
  public visibleColumns: string[] = [];

  constructor(private persister: PersistanceService,
              private router: Router,) {}

  ngOnInit(): void {
    const olddata_vis = localStorage.getItem('VIS_COL');
    const olddata_hidd = localStorage.getItem('HIDD_COL');
    const sel_fltr = localStorage.getItem('SEL_FLTR');

    if (olddata_vis == null && olddata_hidd == null) {
      this.listboxreset();
    }
    else {
      this.listboxreset()
      let oldCount = (olddata_vis!==null?JSON.parse(olddata_vis).length:0) + (olddata_hidd!==null?JSON.parse(olddata_hidd).length:0)
      if(this.listCount===oldCount) {
        if (olddata_vis !== null) this.visibleColumns = JSON.parse(olddata_vis); else {
          this.visibleColumns = []
        }
        if (olddata_hidd !== null) this.hiddenColumns = JSON.parse(olddata_hidd); else {
          this.hiddenColumns = []
        }
      }
    }
    if(this.visibleColumns.includes("Наименование военного комиссариата")||this.hiddenColumns.includes("Наименование военного комиссариата"))
      this.listboxreset()
    if(sel_fltr!==null) this.fmodel = JSON.parse(sel_fltr);
  }

  public listCount = 0
  public hAlign: HorizontalAlign = "center";
  public gap = 0;
  public toolbarSettingsFirst: ListBoxToolbarConfig = {
    tools: ["transferTo", "transferFrom", "transferAllTo", "transferAllFrom", "moveUp", "moveDown"],
  }

  public applyHandler(){
    localStorage.setItem('VIS_COL', JSON.stringify(this.visibleColumns));
    localStorage.setItem('HIDD_COL', JSON.stringify(this.hiddenColumns));
    localStorage.setItem('SEL_FLTR', JSON.stringify(this.fmodel));
    //this.persister.set('SOME_KEY', this.hiddenColumns);
    //this.router.navigateByUrl(`/contingent`);
  }


  public closeHandler(){
    this.router.navigateByUrl(`/contingent`).then();
  };

  public apply: ContingentBack={
    apply: true
  }

  public fmodel: FilterSelect = {
    fio: true,
    citizenship: true,
    regRegion: true,
    health: true,
    isMale: true,
    age: true,
    agedate: true,
    militaryCategory: true,
    militaryCommissariat: true,
    graduateYear: true,
    orderType:true,
    orderCategory: true,
    orderDate: true,
    orderNumber: true,
    trainingLevel: true,
    studyForm: true,
    educationWay: true,
    finishYear: true,
    academicState: true,
    course: true,
    educationProgram: true,
    startYear: true,
    faculty: true,
    eduGroup: true,
    isBudget: true,
    budgetCategory: true,
    budgetSubCategory: true,
    scientificPerson: true,
    dissertationDate: true,
    vakYear: true,
    isWork: true,
    customerName: true,
    organizationName: true,
    post: true,
    benefit: true,
    elder: true,
    dictAchievements: true,
    hasAchievements: true,
    hasOrder: true,
    employer: true,
    map: true,
    institution: true,
    thisInstitution: true,
    secondEducation: true,
    departments: true,
    employed: true,
    orderStartDate: true,
    approveVAKDate: true,
    filial: true,
  };

  public firstElement = "№ п/п"

  public  listBoxChange(dataItem:any):void{
    if(!this.moveButtons.includes(dataItem)) {
      this.hiddenColumns.sort()
      if(this.hiddenColumns.includes(this.firstElement))this.setOnFirstPosition(this.hiddenColumns)
      /*this.visibleColumns.sort()
      if(this.visibleColumns.includes(this.firstElement))this.setOnFirstPosition(this.visibleColumns)*/
    }
  }

  public setOnFirstPosition(arr:any[]):void{
    arr.unshift(...arr.splice(arr.indexOf(this.firstElement),1))
  }

  public moveButtons: any[] = [
    "moveUp",
    "moveDown",
  ]

  listboxreset(): void {
    this.hiddenColumns = listbox.slice()
    this.visibleColumns = selectlistbox.slice()
    this.listCount=this.hiddenColumns.length+this.visibleColumns.length
  }
}
