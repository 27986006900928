<kendo-stacklayout [gap]="20" orientation="vertical" class="settings">
  <div class="settings__titleItem" [ngSwitch]="disciplineInfo.sheetStatus">
    <h2 class="settings__title">
      {{ disciplineInfo.disciplineName }} ({{ disciplineInfo.type }})
    </h2>
    <button class="settings__filledButton" [disabled]="true" kendoButton *ngSwitchCase="'заполнена'">Заполнена</button>
    <button class="settings__emptyButton" [disabled]="true" kendoButton *ngSwitchCase="'не заполнена'">Не заполнена</button>
    <button class="settings__approvedButton" [disabled]="true" kendoButton *ngSwitchCase="'проверена'">Проверена</button>
  </div>
  <div class="settings__item">
    <p class="settings__text--bold">Группа {{ groupName }}</p>
    <p class="settings__text">
      Профиль (программа): {{ disciplineInfo.profileDiscipline }}</p>
  </div>
  <div class="settings__dates">
    <kendo-label
      text="Дата {{
        disciplineInfo.type && getControlAction(disciplineInfo.type)
      }}"
      class="settings__text"
    >
    </kendo-label>
    <kendo-datepicker
            calendarType="infinite"
            [(ngModel)]="examDate"
            [disabled]="!editable"
            (valueChange)="changeExamDate($event)"
            class="settings__input"
    ></kendo-datepicker>
  </div>
  <div class="settings__dates">
    <kendo-label
            text="Дата получения оценки (дата ведомости)"
            class="settings__text"
    >
    </kendo-label>
    <kendo-datepicker
            calendarType="infinite"
            [(ngModel)]="markDate"
            [disabled]="!editable"
            (valueChange)="changeMarkDate($event)"
            [ngClass]="{
              settings__input: true,
              'k-invalid': markDateIsInvalid
            }"
    ></kendo-datepicker>
    <span *ngIf="markDateIsInvalid"
          class="settings__text settings__text--red">
      Дата получения оценки больше текущей даты!
    </span>
  </div>
  <div class="settings__item">
    <p class="settings__text">Печать ведомости:</p>
    <app-signerparams
      *ngIf="choiceOfSigner"
      [signer]="signer"
      [semesterNum]="semesterNumber"
      [editable]="editable"
      [eduGroupId]="eduGroupId"
      [facultyId]="facultyId"
      [trainingLevelId]="trainingLevelId"
      [disciplineId]="disciplineId"
      (signerPostChange)="onChangeSignerPost($event)"
      (signerChange)="onChangeSigner($event)"
      [electiveDisciplineId]="disciplineInfo.electiveDisciplineId || ''"
    ></app-signerparams>
  </div>
  <div class="settings__item" *ngIf="choiceOfTeacher">
    <kendo-label text="Преподаватель" class="settings__text">
      <kendo-autocomplete
        [data]="personsToFilter"
        valueField="fio"
        placeholder="Введите ФИО сотрудника..."
        [clearButton]="true"
        [filterable]="true"
        [disabled]="!editable"
        [value]="examiner?.fio || ''"
        (filterChange)="handleFilter($event)"
        (valueChange)="onChangeFindSigner($event)"
        class="settings__input settings__input--large"
      >
      </kendo-autocomplete>
    </kendo-label>
  </div>
  <div class="settings__item">
    <div class="buttons">
      <button kendoButton icon="print" (click)="onPrintForm(0)" class="button button--action button--icon">
        Ведомость
        <kendo-loader *ngIf="loadingEmptyList" size="small"></kendo-loader>
      </button>
      <button kendoButton icon="print" (click)="onPrintForm(1)" class="button button--action button--icon">
        Ведомость с оценками
        <kendo-loader *ngIf="loadingFilledList" size="small"></kendo-loader>
      </button>
      <button kendoButton icon="print" (click)="onPrintForm(2)" class="button button--action button--icon">
        Ведомость акад. задолженности
        <kendo-loader *ngIf="loadingAdditionalSession" size="small"></kendo-loader>
      </button>
      <button kendoButton icon="print" (click)="onPrintForm(3)" class="button button--action button--icon">
        Ведомость акад. задолженности с оценками
        <kendo-loader *ngIf="loadingAdditionalSessionWithMark" size="small"></kendo-loader>
      </button>

      <div class="buttons__navigate">
        <discipline-action-buttons
          (onComeback)="handleClose()"
          [editableSheetApprove]="editableSheetApprove"
        ></discipline-action-buttons>
      </div>
    </div>
  </div>
</kendo-stacklayout>
