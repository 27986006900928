import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { StudyformtypeService } from 'src/app/services/dicts/studyformtype.service';
import { StudyformService } from 'src/app/services/dicts/studyform.service';
import { StudyFormType } from 'src/app/models/dicts/studyformtype.model';
import { StudyForm } from 'src/app/models/dicts/studyform.model';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  CommonDictionariesUserAccessService
} from "../../../services/useraccess/common-dictionaries-user-access.service";
import { Router} from "@angular/router";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-studyform',
  templateUrl: './studyform.component.html',
  styleUrls: ['./studyform.component.css']
})
export class StudyformComponent implements OnInit {

  public studyformtypes!: StudyFormType[];
  public studyformtype: StudyFormType = {
    dictStudyFormTypeId: 0,
    studyFormTypeName: '',
    studyFormTypeSName: ''
  }
  public studyforms: StudyForm[] = [];
  public studyform: StudyForm = {
    dictStudyFormId: 0,
    studyFormName: '',
    studyFormSName: '',
    studyFormType: '',
    dictStudyFormType: this.studyformtype,
    orderNum: 0
  }
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;
  public names: string[] | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public sort: SortDescriptor[] = [
    {
      field: "",
      dir: "asc",
    },
  ];
  public gridView: any | GridDataResult;

  constructor(private studyformtypeService: StudyformtypeService,
              private studyformService: StudyformService,
              private renderer: Renderer2,
              private notificationService: NotificationsService,
              private userAccessService: CommonDictionariesUserAccessService,
              private dialogService: DialogService,
              private router: Router,
    ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel()
    this.getAllStudyFormType();
    //this.getAllStudyForm();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid") && this.orderValid) {
        this.saveCurrent();
      }
    });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadStudyForm();
  }

  private loadStudyForm(): void {
    this.gridView = {
      data: orderBy(this.studyforms, this.sort),
      total: this.studyforms.length,
    };
  }

  //Get studyform name for id
  public namestudyformtype(id: string): StudyFormType | undefined {
    return this.studyformtypes.find((x) => x.dictStudyFormTypeExternalId === id);
  }


  public onStateChange(): void {
    this.getAllStudyForm();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      dictStudyFormId: 0,
      studyFormName: '',
      studyFormSName: '',
      studyFormType: "",
      dictStudyFormType: this.studyformtype
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
    sender,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if(!this.orderValid) return;
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return
    if (this.isNew) {
      rowIndex += 1;
    }

    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public orderValid: boolean=true;

  public orderChange(value: any): void {
    this.orderValid = value === null || (value > 0 && this.studyforms.find(_ => _.orderNum === value) == undefined);
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
    this.orderValid = true
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.studyformService.addStudyForm(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllStudyForm();
              this.notificationService.showSuccess("Добавлено");
              this.studyform = {
                dictStudyFormId: 0,
                studyFormName: '',
                studyFormSName: '',
                studyFormType: '',
                dictStudyFormType: {
                  dictStudyFormTypeId: 0,
                  studyFormTypeName: '',
                  studyFormTypeSName: ''
                }
              }
            },
            error:() => {
              this.notificationService.showError("Не удалось сохранить запись");
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.studyformService.updateStudyForm(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllStudyForm();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError("Не удалось изменить запись");
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllStudyFormType() {
    this.studyformtypeService.getAllStudyFormType()
      .subscribe(
        response => {
          this.studyformtypes = response;
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          if (response.accessLevelEducation == 0){
            this.notificationService.showError("У вас нет прав для просмотра данной страницы");
            this.router.navigate(['/profile']);
          } else {
            this.editable = response.accessLevelEducation != 1;
            this.getAllStudyForm();
          }
        },error:() => {}});
  }

  //Getting all entries from dictionary
  public getAllStudyForm() {
    this.studyformService.getAllStudyForm()
      .subscribe(
        response => {
          this.studyforms = response;
          this.loadStudyForm();
        }
      );
  }


  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.studyFormName.toLowerCase()}?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {

        if(result.text == "Да"){
          this.opened = false;
          this.studyformService.deleteStudyForm(dataItem.externalId)
          .subscribe({
            next:() => {
              this.getAllStudyForm();
              this.notificationService.showSuccess("Удалено");
            },
            error:(error) => {
            this.notificationService.showError(error.error);
          }}
          );
            }
          else{
            this.opened = false;
          }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }

}

const formGroup = (dataItem: { dictStudentBudgetSubCategoryId?: number; externalId?: string; dictStudentBudgetId?: number; dictStudentBudget?: any; budgetSubCategoryName?: string; dictStudyFormId?: any; studyFormName?: any; studyFormSName?: any; studyFormType?: any; dictStudyFormType?: any; forAdmission?: any; studyFormCategory?: any; orderNum?: any;}) =>
  new FormGroup({
    dictStudyFormId: new FormControl(dataItem.dictStudyFormId),
    externalId: new FormControl(dataItem.externalId),
    studyFormName: new FormControl(dataItem.studyFormName, Validators.required),
    studyFormSName: new FormControl(dataItem.studyFormSName, Validators.required),
    studyFormType: new FormControl(dataItem.studyFormType, Validators.required),
    dictStudyFormType: new FormControl(dataItem.dictStudyFormType, Validators.required),
    forAdmission: new FormControl(dataItem.forAdmission),
    orderNum: new FormControl(dataItem.orderNum),
  });


const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

