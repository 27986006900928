export enum DictTypeValueFilterEduGroupNameEnum {
  CourseNum = "Номер курса",
  RangeNumbers = "Диапазон чисел",
  Number = "Число",
  String = "Строка",
  Strings = "Строки",
  StandardCipher = "Направление",
  ProgramCipher = "Профиль",
  SpecializationName ="Специальность",
  SpecializationShortNameIfNameMoreOneWord = "Сокращенное название стандарта, если название стандарта больше одного слова",
  LatinUpperCaseLetters = "Латинские заглавные буквы",
  ShortNameKafedra = "Название кафедры",
  SpecializationShortName = "Сокращенное название стандарта",
  ProgramShortName = "Сокращенное название программы",
  OrganizationName = "Название организации",
  StringIfHasNextPartName = "Строка, если есть следуйщая часть названия",
  LastTwoDigtYear = "Последние 2 циры года",
}
