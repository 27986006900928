import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {handleError} from "../../helpers/errorHandle-helper";
import {
  AddSettingFilterEduGroupName, ChangeIndexFilterSettingFilterEduGroupName, ChangeIndexOrderSettingFilterEduGroupName,
  SettingFilterEduGroupName
} from "../../models/constructor-edu-group-name/setting-filter-edu-group-name";
import {FilterEduGroupNameEnum} from "../../models/constructor-edu-group-name/enums/filter-edu-group-name.enum";

@Injectable({
  providedIn: 'root'
})
export class SettingFilterEduGroupNameService {
  baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.constructorEduGroupNames.settingFilterEduGroupName}`;

  constructor(private http: HttpClient) { }

  public getSettingFilterEduGroupNameList(): Observable<SettingFilterEduGroupName[]> {
    return this.http.get<SettingFilterEduGroupName[]>(`${this.baseUrl}/GetList`)
      .pipe(
        catchError(handleError));
  }

  public addSettingFilterEduGroupName(order: number): Observable<SettingFilterEduGroupName[]> {
    const addSettingFilterEduGroupNameDto = new AddSettingFilterEduGroupName(order);
    return this.http.post<SettingFilterEduGroupName[]>(`${this.baseUrl}/Add`, addSettingFilterEduGroupNameDto)
  }

  public deleteSettingFilterEduGroupName(settingId: string): Observable<SettingFilterEduGroupName[]> {
    return this.http.delete<SettingFilterEduGroupName[]>(`${this.baseUrl}/Delete?settingId=${settingId}`)
  }

  public changeIndexOrder(newOrder: number, settingId: string): Observable<SettingFilterEduGroupName[]> {
    const changeIndexOrderSettingFilterEduGroupNameDto = new ChangeIndexOrderSettingFilterEduGroupName(newOrder, settingId);
    return this.http.put<SettingFilterEduGroupName[]>(`${this.baseUrl}/ChangeIndexOrder`, changeIndexOrderSettingFilterEduGroupNameDto);
  }

  public changeIndexFilter(settingId: string, listOrderPartCondition: FilterEduGroupNameEnum[]): Observable<SettingFilterEduGroupName[]> {
    const changeIndexFilterSettingFilterEduGroupNameDto = new ChangeIndexFilterSettingFilterEduGroupName(settingId, listOrderPartCondition);
    return this.http.put<SettingFilterEduGroupName[]>(`${this.baseUrl}/ChangeIndexFilter`, changeIndexFilterSettingFilterEduGroupNameDto);
  }
}
