import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {AddNewRequest, StudentInfoAddToReestr, UpdateRequest} from '../../../models/cards/studentinfocards';
import {Guid} from 'guid-typescript';
import {ReestrService} from "../../../services/cards/reestr.service";
import {SalaryProjectService} from "../../../services/cards/salary-project.service";
import {SalaryProjectName} from "../../../models/cards/salaryproject";
import {CardsService} from "../../../services/cards/cards.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup} from "@angular/forms";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {lastValueFrom} from "rxjs";
import {StudentInfoCardsEnum} from "../studentinfocards/studentinfocardsenum";
import {StudpersonService} from "../../../services/contingent/studperson.service";
import {NameStudPerson} from "../../../models/contingent/studperson.model";

@Component({
  selector: 'app-add-to-list-forming',
  templateUrl: './add-to-list-forming.component.html',
  styleUrls: ['./add-to-list-forming.component.css']
})
export class AddToListFormingComponent implements OnInit {

  public comboboxStudentInfo: NameStudPerson[] = []
  public selectedStudent: StudentInfoAddToReestr | null = null;
  public salaryProjects: SalaryProjectName[] = [];
  public selectedSalaryProject: Guid | null = null;

  addStudent : FormGroup = new FormGroup ({
    salaryProject: new FormControl(null)
  })

  isUpdate: boolean = false;
  idUpdateRequest?: Guid;
  reestrId?: Guid;
  public titlePage: string = 'Добавление в список формирования карты';

  public isContingentSection: boolean = false;
  @Input() studentId?: Guid;
  @Input() reestrIdFromInput: Guid | null = null;
  @Output() changeSection = new EventEmitter<StudentInfoCardsEnum>();

  constructor(
    private router: Router,
    private activatedroute: ActivatedRoute,
    private reestrService: ReestrService,
    private salaryProjectService: SalaryProjectService,
    private cardService: CardsService,
    private notificationService: NotificationsService,
    private studPersonService: StudpersonService) { }

  ngOnInit(): void {
    this.isContingentSection = !!this.studentId;

    this.idUpdateRequest= this.activatedroute.snapshot.params['id'];
    this.isUpdate = !!this.idUpdateRequest || (this.isContingentSection && this.reestrIdFromInput != null); // Для обновления нам приходит reestrIdFromInput, без него добавление записи
    this.reestrId = this.isContingentSection ? this.reestrIdFromInput : this.activatedroute.snapshot.queryParams["reestrId"];

    if (this.isUpdate) {
      this.titlePage = 'Редактирование запроса на выпуск карты';
    }
    if (this.isContingentSection) {
      this.getStudentInfoByStudentId();
    } else {
      this.isUpdate
          ? this.getStudentInfo(this.idUpdateRequest, this.reestrId)
          : this.getStudentsInfo();
    }

    this.getSalaryProjectsNames();
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  public getStudentInfo(studPersonId: Guid | undefined, reestrId: Guid | undefined) {
    if (!studPersonId) return;

    this.reestrService.getCardsReestrStudentInfo(studPersonId, reestrId)
      .subscribe(
        response => {
          this.selectedStudent = response;
          this.addStudent.get('salaryProject')?.patchValue(this.selectedStudent.salaryProjectId);
        }
      )
  }

  public getStudentInfoByStudentId() {
    if (!this.studentId) return;

    this.reestrService.getCardsReestrStudentInfoByStudentId(this.studentId, this.reestrIdFromInput)
      .subscribe(
        response => {
          this.selectedStudent = response;
          this.addStudent.get('salaryProject')?.patchValue(this.selectedStudent.salaryProjectId);
        }
      );
  }

  public getSalaryProjectsNames() {
    this.salaryProjectService.getSalaryProjectsNames()
      .subscribe(
        response => this.salaryProjects = response
      )
  }

  public getStudentsInfo() {
    this.studPersonService.getNameStudPersonsNotExpelledNotGraduated()
      .subscribe(
        response => this.comboboxStudentInfo = response
      )
  }

  public selectionStudent(nameStudPerson: NameStudPerson) {
    this.getStudentInfo(nameStudPerson.studPersonId, undefined);
  }

  private async addNewCardRequest() {
    if (this.selectedStudent === null ||
      this.selectedSalaryProject === null ||
      this.selectedStudent.errorMessage != '')
    {
      return;
    }

    const addNewRequest: AddNewRequest = {
      studPersonId: this.selectedStudent?.studPersonId,
      salaryProjectId: this.selectedSalaryProject,
    }

    await lastValueFrom(this.cardService.addNewCardRequest(addNewRequest)).then(() => {
      this.notificationService.showSuccess('Студент успешно добавлен в реестр');
      this.onCancel();
    }).catch(() => {
      this.notificationService.showError('Не удалось добавить студента в реестр');
    });
  }

  private async updateCardRequest() {
    if (this.selectedStudent === null || this.selectedSalaryProject === null) {
      return;
    }

    const updateRequest: UpdateRequest = {
      studPersonId: this.selectedStudent?.studPersonId,
      reestrId: this.reestrId!,
      salaryProjectId: this.selectedSalaryProject,
    }

    await lastValueFrom(this.cardService.updateCardRequest(updateRequest)).then(() => {
      this.notificationService.showSuccess('Запись успешно обновлена');
      this.onCancel();
    }).catch(() => {
      this.notificationService.showError('Не удалось добавить запись');
    });
  }

  public async saveCardRequest() {
    if (!this.addStudent.valid) {
      this.notificationService.showError("Для добавления студента нужно выбрать зарплатный проект");
      return;
    }

    if (!this.isUpdate){
      await this.addNewCardRequest();
    } else {
      await this.updateCardRequest();
    }

    this.onCancel();
  }

  onCancel(): void {
    if (this.isUpdate) {
      this.isContingentSection
          ? this.changeSection.emit(StudentInfoCardsEnum.Reestr)
          : this.router.navigate([`cards/reestr/${this.reestrId}`]);
    } else {
      this.isContingentSection
        ? this.changeSection.emit(StudentInfoCardsEnum.Cards)
        : this.router.navigate([`cards`]);
    }
  }

}
