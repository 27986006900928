import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {
  DictTrainingLevelName,
  DictTrainingLevelNameForStudentNumber,
} from "../../models/contingent/dicttraininglevel.model";

@Injectable({
  providedIn: 'root'
})
export class DictTrainingLevelService {


  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dicttraininglevels}`;

  constructor(private http: HttpClient) { }

  //Get all dict training level
  public getDictTrainingLevel(): Observable<DictTrainingLevelNameForStudentNumber[]> {
    return this.http.get<DictTrainingLevelNameForStudentNumber[]>(this.baseUrl + '/GetList');
  }

  public getNamesDictTrainingLevel(): Observable<DictTrainingLevelName[]> {
    return this.http.get<DictTrainingLevelName[]>(this.baseUrl + '/GetNames');
  }

  //Update dict training level
  updatedictTrainingLevel(dictTrainingLevel: DictTrainingLevelNameForStudentNumber): Observable<DictTrainingLevelNameForStudentNumber> {
    return this.http.put<DictTrainingLevelNameForStudentNumber>(this.baseUrl + '/' + dictTrainingLevel.externalId, dictTrainingLevel)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}
