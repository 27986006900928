import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ContingentUserAccess } from 'src/app/models/useraccess/contingent/contingentuseraccess.model';
import { environment } from 'src/environments/environment';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ContingentUserAccessService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.contingentUserAccess}`;

  constructor(private http: HttpClient) { }

  //Get access
  public getAccess(): Observable<ContingentUserAccess[]> {
    return this.http.get<ContingentUserAccess[]>(this.baseUrl + '/GetAccess/')
      .pipe(catchError(this.handleError));
  }

  public getAccessLevel(): Observable<ContingentUserAccess> {
    return this.http.get<ContingentUserAccess>(this.baseUrl + '/GetAccessLevel')
      .pipe(catchError(this.handleError));
  }

  public getUserAccessLevel():Observable<boolean> {
    return this.http.get<ContingentUserAccess>(this.baseUrl + '/GetAccessLevel')
      .pipe(
        map(
          (response)=> response.userAccessLevel
        )
      );
  }

  public getConstructorEduGroupNameAccessLevel(): Observable<boolean> {
    return this.http.get<ContingentUserAccess>(this.baseUrl + '/GetAccessLevel')
      .pipe(
        map(
          (response)=> response.constructorEduGroupNameAccessLevel
        )
      );
  }

  //Save access
  public saveAccess(useraccess: ContingentUserAccess): Observable<ContingentUserAccess> {
    return this.http.post<ContingentUserAccess>(this.baseUrl + '/SaveAccess/', useraccess)
      .pipe(catchError(this.handleError));
  }

  //Delete access
  public deleteAccess(id: string): Observable<ContingentUserAccess> {
    return this.http.delete<ContingentUserAccess>(this.baseUrl + '/DeleteAccess/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
