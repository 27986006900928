<contingent-header></contingent-header>
<h1>Справки по воинскому учету</h1>

<ng-container *ngIf="!isTemplatesOn; else templatesContainer">
  <div class="container" *ngIf="!editMode">
    <div class="filters">
      <form class="k-form k-form_filters" [formGroup]="filters">
        <dl class="edit-form edit-form_filters">
          <div class="content-row">
            <kendo-label text="Справка"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-dropdownlist [data]="certificateTypes"
                                    textField="formName"
                                    valueField="formType"
                                    (valueChange)="typeChange($event)"
                                    [valuePrimitive]="true"
                                    formControlName="certificateType">
                </kendo-dropdownlist>
              </kendo-formfield>
            </dd>
          </div>
        </dl>
      </form>
    </div>
    <div class="template-button">
      <button kendoButton (click)="switchPages()">
        Шаблоны справок
      </button>
    </div>
  </div>

  <div class="grid-container">
    <h4 class="form-title">{{ getCertificateTypeName(filters.value.certificateType) }}</h4>
    <kendo-grid [kendoGridBinding]="certificates"
                *ngIf="!editMode; else editForm"
                [filterable]="true"
                [pageable]="pagerSettings"
                [pageSize]="pageSize"
                (add)="editCertificate($event)"
                (edit)="editCertificate($event)"
                (remove)="removeCertificate($event)"
                [loading]="loading" class="grid">

      <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand
                *ngIf="editable"
                [disabled]="!filters.value.certificateType"
                size="small"
                themeColor="success"
                icon="plus">
          Добавить справку
        </button>
        <kendo-grid-spacer></kendo-grid-spacer>
        <button kendoGridExcelCommand
                [disabled]="!filters.value.certificateType"
                icon="print"
                size="small">
          Печать списка
        </button>
      </ng-template>

      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>

      <kendo-grid-column field="number"
                         headerClass="gridHeader dictionaries"
                         title="№"
                         [filterable]="false"
                         [width]="100">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <span class="alignCells">
            {{ rowIndex + 1 }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="filters.value.certificateType !== MilitaryCertificateTypes.form10"
                         field="registrationDate"
                         headerClass="gridHeader dictionaries"
                         title="Дата регистрации"
                         [width]="400">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <date-range-filter [field]="column.field"
                             [filter]="filter">
          </date-range-filter>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ dataItem.registrationDate | date: "dd.MM.yyyy" }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="fio"
                         headerClass="gridHeader dictionaries"
                         title="ФИО студента"
                         [width]="300">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ dataItem.fio }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="dictTrainingLevelId"
                         headerClass="gridHeader dictionaries"
                         title="Уровень образования"
                         [width]="170">
        <ng-template kendoGridFilterCellTemplate>
          <filter-multiselect [data]="trainingLevels"
                              [listHeight]="230"
                              gridField="dictTrainingLevelId"
                              textField="trainingLevelName"
                              valueField="dictTrainingLevelExternalId"
                              [checkboxes]="true">
          </filter-multiselect>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ getTrainingLevelName(dataItem?.dictTrainingLevelId) }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="filters.value.certificateType === MilitaryCertificateTypes.appendix2"
                         field="isEnrolled"
                         headerClass="gridHeader dictionaries"
                         title="Принят или отчислен"
                         [width]="170">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [data]="militaryAcademicStates"
                           [filter]="filter"
                           gridField="isEnrolled"
                           textField="text"
                           valueField="value">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ getIsEnrolledText(dataItem?.isEnrolled) }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="filters.value.certificateType !== MilitaryCertificateTypes.form10"
                         field="dictMilitaryCommissariatName"
                         headerClass="gridHeader dictionaries"
                         title="Военный комиссариат"
                         [width]="300">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-combobox [data]="militaryCommissariats"
                           [filter]="filter"
                           gridField="dictMilitaryCommissariatName"
                           textField="commissariatName"
                           valueField="commissariatName">
          </filter-combobox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
          {{ dataItem?.dictMilitaryCommissariatName }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="updatedAt"
                         headerClass="gridHeader dictionaries"
                         title="Последнее изменение"
                         [width]="400">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <date-range-filter [field]="column.field"
                             [filter]="filter">
          </date-range-filter>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ dataItem.updatedAt | date: "dd.MM.yyyy HH:mm" }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="personFio"
                         headerClass="gridHeader dictionaries"
                         title="Пользователь"
                         [width]="300">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ dataItem?.personFio }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column [width]="170">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button kendoButton type="button"
                  (click)="exportCertificate(dataItem)"
                  icon="file"
                  fillMode="flat"
                  rounded="full"
                  size="small">
          </button>
          <button kendoGridEditCommand type="button"
                  icon="edit"
                  *ngIf="editable"
                  fillMode="flat"
                  rounded="full"
                  size="small">
          </button>
          <button kendoGridRemoveCommand
                  icon="delete"
                  *ngIf="editable"
                  fillMode="flat"
                  size="small"
                  rounded="full">
          </button>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-excel fileName="certificates.xlsx" [fetchData]="mapCertificatesData">
        <kendo-excelexport-column field="number"
                                  title="№"
                                  [headerCellOptions]="headerCellOptions"
                                  [cellOptions]="cellOptions"
                                  [width]="50">
        </kendo-excelexport-column>

        <kendo-excelexport-column field="date"
                                  title="Дата регистрации"
                                  [hidden]="filters.value.certificateType === MilitaryCertificateTypes.form10"
                                  [headerCellOptions]="headerCellOptions"
                                  [cellOptions]="cellOptions"
                                  [width]="150">
        </kendo-excelexport-column>

        <kendo-excelexport-column field="fio"
                                  title="ФИО обучающегося"
                                  [headerCellOptions]="headerCellOptions"
                                  [cellOptions]="cellOptions">
        </kendo-excelexport-column>

        <kendo-excelexport-column field="dictTrainingLevelName"
                                  title="Уровень образования"
                                  [headerCellOptions]="headerCellOptions"
                                  [cellOptions]="cellOptions"
                                  [width]="170">
        </kendo-excelexport-column>

        <kendo-excelexport-column field="enrolled"
                                  title="Принят или отчислен"
                                  [hidden]="filters.value.certificateType !== MilitaryCertificateTypes.appendix2"
                                  [headerCellOptions]="headerCellOptions"
                                  [cellOptions]="cellOptions"
                                  [width]="170">
        </kendo-excelexport-column>

        <kendo-excelexport-column field="dictMilitaryCommissariatName"
                                  title="Военный комиссариат"
                                  [hidden]="filters.value.certificateType === MilitaryCertificateTypes.form10"
                                  [headerCellOptions]="headerCellOptions"
                                  [cellOptions]="cellOptions">
        </kendo-excelexport-column>
      </kendo-grid-excel>
    </kendo-grid>

    <ng-template #editForm>
      <app-military-certificate-form [type]="filters.value.certificateType"
                                     [data]="form"
                                     [studentId]="studentId"
                                     (close)="closeHandler()"
                                     (save)="saveCertificate($event)">
      </app-military-certificate-form>
    </ng-template>
  </div>
</ng-container>

<ng-template #templatesContainer>
  <div class="template-button template-button_close">
    <button kendoButton (click)="switchPages()">
      Закрыть
    </button>
  </div>
  <div class="grid-container">
    <h5>Шаблоны справок</h5>
    <div *ngIf="editable && !editMode">
      <button kendoButton
              themeColor="success"
              iconClass="k-icon k-i-plus"
              (click)="editTemplate($event)">
        Добавить
      </button>
    </div>
    <kendo-grid [data]="templates"
                *ngIf="!editMode; else editForm"
                [pageable]="pagerSettings"
                [pageSize]="pageSize"
                [loading]="loading"
                (edit)="editTemplate($event)"
                (remove)="removeTemplate($event)" class="grid">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>

      <kendo-grid-column field="name"
                         headerClass="gridHeader dictionaries"
                         title="Наименование шаблона"
                         [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.name }}
        </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="dictMilitaryFormTypeName"
                         headerClass="gridHeader dictionaries"
                         title="Тип справки"
                         [width]="700">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.dictMilitaryFormTypeName }}
        </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column headerClass="gridHeader dictionaries"
                         title="Шаблон"
                         [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            <span class="k-icon k-i-file pointer" (click)="downloadFile(dataItem.fileName, dataItem.externalId)">
            </span>
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="isDefault"
                         headerClass="gridHeader dictionaries"
                         title="Шаблон по умолчанию"
                         [width]="170">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ getBoolOption(dataItem.isDefault) }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column [width]="140">
        <ng-template kendoGridCellTemplate>
          <button kendoGridEditCommand type="button"
                  icon="edit"
                  *ngIf="editable"
                  fillMode="flat"
                  rounded="full"
                  size="small">
          </button>
          <button kendoGridRemoveCommand
                  icon="delete"
                  *ngIf="editable"
                  fillMode="flat"
                  size="small"
                  rounded="full">
          </button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

  <ng-template #editForm>
    <form class="k-form" [formGroup]="templateForm">
      <dl class="edit-form">
        <div class="content-row">
          <kendo-label text="Наименование шаблона"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-textbox formControlName="name" [clearButton]="true"></kendo-textbox>
              <span class="required">*</span>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Тип справки"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-dropdownlist [data]="certificateTypes"
                                  textField="formName"
                                  valueField="externalId"
                                  [valuePrimitive]="true"
                                  formControlName="dictMilitaryFormTypeId">
              </kendo-dropdownlist>
              <span class="required">*</span>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label class="attachment-label" text="Шаблон"></kendo-label>
          <dd>
            <kendo-formfield class="fileselect">
              <kendo-fileselect formControlName="file"
                                [multiple]="false"
                                [restrictions]="restrictions">
                <kendo-fileselect-messages
                  dropFilesHere="Перетащите сюда файл для загрузки"
                  invalidFileExtension="Выбранный файл должен быть в формате {{ restrictions.allowedExtensions?.join(', ') }}">
                </kendo-fileselect-messages>
              </kendo-fileselect>
              <span class="required">*</span>
            </kendo-formfield>
            <span *ngIf="!Array.isArray(templateForm?.value.file) && editedRowIndex !== undefined">
              <a class="link" (click)="downloadFile(templateForm.value.file, templateForm.get('externalId')?.value)">
                Скачать {{ templateForm.value.file }}
              </a>
            </span>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Шаблон по умолчанию"></kendo-label>
          <dd>
            <kendo-formfield>
              <input type="checkbox" kendoCheckBox formControlName="isDefault"/>
            </kendo-formfield>
          </dd>
        </div>
      </dl>
    </form>
    <div>
      <button kendoButton
              size="small"
              *ngIf="editMode"
              (click)="closeHandler()">
        Отмена
      </button>
      <button kendoButton
              class="save-button"
              size="small"
              themeColor="primary"
              *ngIf="editMode"
              (click)="saveTemplate()">
        Сохранить
      </button>
    </div>
  </ng-template>
</ng-template>

<div kendoDialogContainer></div>
