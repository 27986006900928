<kendo-stacklayout [gap]="30" orientation="vertical" class="wrapper">
  <app-middlecontrolhometree
    [checkable]="true"
    [checkGroup]="false"
    [(selectedSemester)]="semester"
    [treeType]="'discipline'"
    [checkedItems]="checkedStatements"
    (selectedTrainingLevelChange)="onChangeTrainingLevel($event)"
    (selectedSemesterChange)="changeSemester($event)"
    (onChangeChecked)="onChangeCheckedStatements($event)"
    (onChangeTreeData)="setGroupList($event)"
    (onChangeDate)="onChangeDate($event)"
  ></app-middlecontrolhometree>
  <div class="signer" *ngIf="showSigner">
    <app-middlecontrolsigneredit
      [(signer)]="signer"
      [trainingLevelId]="trainingLevelId"
      [enableSync]="false"
      [facultyId]="facultyId"
      (signerPostChange)="onChangeSignerPost($event)"
    ></app-middlecontrolsigneredit>
  </div>
  <kendo-stacklayout [gap]="11" orientation="vertical">
    <span class="print--label">Печать</span>
    <button
      kendoButton
      icon="print"
      (click)="onPrintStatement(0)"
      class="button button--action button--icon"
    >
      выбранных ведомостей
      <kendo-loader *ngIf="loadingEmptyStatement" size="small"></kendo-loader>
    </button>
    <button
      kendoButton
      icon="print"
      (click)="onPrintStatement(1)"
      class="button button--action button--icon"
    >
      выбранных ведомостей с оценками
      <kendo-loader *ngIf="loadingFilledStatement" size="small"></kendo-loader>
    </button>
    <button
      kendoButton
      icon="print"
      (click)="onPrintStatement(2)"
      class="button button--action button--icon"
    >
      ведомостей доп. сессии
      <kendo-loader *ngIf="loadingAdditionalSession" size="small"></kendo-loader>
    </button>
    <button
    kendoButton
    icon="print"
    (click)="onPrintStatement(3)"
    class="button button--action button--icon"
  >
    ведомостей доп. сессии с оценками
    <kendo-loader *ngIf="loadingAdditionalSessionWithMark" size="small"></kendo-loader>
  </button>
    <button
      kendoButton
      (click)="onPrintStatementList()"
      class="button button--action"
    >
      сформировать список ведомостей
      <kendo-loader *ngIf="loadingStatementList" size="small"></kendo-loader>
    </button>
  </kendo-stacklayout>
  <button
    kendoButton
    (click)="onCancel()"
    class="button button--action"
    style="margin-top: 38px"
  >
    отмена
  </button>
</kendo-stacklayout>
