import {Guid} from "guid-typescript";
import {TrainingLevelEnum} from './enums/training-level.enum';

export class TrainingLevel {
  public dictTrainingLevelId!: number;
  public dictTrainingLevelExternalId?: Guid | string;
  public trainingLevelName!: string;
  public trainingLevelSName?: string;
  public profileTitle?: string;
  public studentRTitle?: string;
  public studentTitle?: string;
  public studentSingleRTitle?: string;
  public printStandardTitle?: string;
  public printProfileTitle?: string;
  public enum?: TrainingLevelEnum;
  public orderNum!: number;
}
