import {FilterEduGroupNameEnum} from "./enums/filter-edu-group-name.enum";

export class SettingFilterEduGroupName {
  externalId!: string;
  order!: number;
  listOrderPartCondition: FilterEduGroupNameEnum[] = [];
}

export class AddSettingFilterEduGroupName {
  constructor(order: number) {
    this.order = order;
  }

  order!: number;
}

export class ChangeIndexOrderSettingFilterEduGroupName {
  constructor(order: number, settingId: string) {
    this.order = order;
    this.settingId = settingId;
  }

  order!: number;
  settingId!: string;
}

export class ChangeIndexFilterSettingFilterEduGroupName {
  constructor(settingId: string, listOrderPartCondition: FilterEduGroupNameEnum[]) {
    this.settingId = settingId;
    this.listOrderPartCondition = listOrderPartCondition;
  }

  settingId!: string;
  listOrderPartCondition: FilterEduGroupNameEnum[] = [];
}
